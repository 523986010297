import { Pipe, PipeTransform } from '@angular/core';
import { SearchFilterStartupEnum } from '../../enums/SearchFilterStartup.enum';

@Pipe({
  name: 'searchFilterStartupName'
})
export class SearchFilterStartupNamePipe implements PipeTransform {
  transform(searchFilter: SearchFilterStartupEnum): string {
    switch (searchFilter) {
      case SearchFilterStartupEnum.FUNDS:
        return 'Funds';
      case SearchFilterStartupEnum.INVESTMENTS:
        return 'Investments';
      case SearchFilterStartupEnum.LEAD_INVESTMENTS:
        return 'Lead investments';
      case SearchFilterStartupEnum.EXITS:
        return 'Exits';
    }
  }
}
