import { CompanyDTO, CompanyListDTO, VariableDTO } from '@DTOS';
import { CompanyServicioBackend, VariableServicioBackend } from '@serviceBackend';
import { Injectable } from '@angular/core';
import { VariableExtendedModel } from './variable.model';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VariablesService {
  private variableToEdit: VariableDTO;

  constructor(
    private variableServicioBackend: VariableServicioBackend,
    private companyServicioBackend: CompanyServicioBackend
  ) {}

  get VariableToEdit(): VariableDTO {
    return this.variableToEdit;
  }

  set VariableToEdit(t: VariableDTO) {
    this.variableToEdit = t;
  }

  public getVariablesAll(): Observable<Array<VariableDTO>> {
    return this.variableServicioBackend.GetEntidades().pipe(map(x => x.items));
  }

  public getVariableModels(): Observable<Array<VariableExtendedModel>> {
    return this.getVariablesAll().pipe(
      map(x => {
        const arrayModel = new Array<VariableExtendedModel>();
        for (const v of x) {
          arrayModel.push(new VariableExtendedModel(v));
        }
        arrayModel.sort((a, b) => b.Variable.id - a.Variable.id);
        return arrayModel;
      })
    );
  }

  public addVariable(variable: VariableDTO): Observable<VariableDTO> {
    return this.variableServicioBackend.PostEntidad(variable);
  }

  public editVariable(variable: VariableDTO): Observable<VariableDTO> {
    return this.variableServicioBackend.PutEntidad(variable, variable.id);
  }

  public deleteVariable(variable: VariableDTO): Observable<void> {
    return this.variableServicioBackend.DeleteEntidad(variable.id);
  }

  public saveVariablesCompanies(companyListDTO: CompanyListDTO): Observable<Array<CompanyDTO>> {
    return this.companyServicioBackend.UpdateVariablesCompanies(companyListDTO).pipe(map(x => x.items));
  }
}
