import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, inject, input } from '@angular/core';
import { DashboardAnalyticsDTO, VariableOptionAnalyticsDTO, StartupCompanyDTO, TopicDTO } from '@DTOS';
import { FormControl } from '@angular/forms';
import { Subscription, take } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MentorshipModalComponent } from 'src/app/app-shared/mentorship-modal/mentorship-modal.component';
import { DataMentorshipModal } from 'src/app/app-shared/mentorship-modal/interfaces/DataMentorshipModal';
import { ApexAxisChartSeries } from 'ng-apexcharts';

@Component({
  selector: 'app-profile-normal',
  templateUrl: './profile-normal.component.html',
  styleUrls: ['./profile-normal.component.scss']
})
export class ProfileNormalComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public dashboardAnalytics = new DashboardAnalyticsDTO();
  @Input() public startupSelected = new StartupCompanyDTO();
  public scores = input.required<ApexAxisChartSeries>();

  protected topicControl = new FormControl<TopicDTO>(null);
  protected topics: TopicDTO[] = [];
  protected variablesTopic: VariableOptionAnalyticsDTO[] = [];

  private subs = new Subscription();
  private dialog = inject(MatDialog);

  ngOnInit(): void {
    this.subs.add(
      this.topicControl.valueChanges.subscribe(value => {
        this.variablesTopic = this.dashboardAnalytics.variables.filter(
          variableAnalytics => variableAnalytics.variable.topic.id === value.id
        );
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dashboardAnalytics != null && this.dashboardAnalytics != null) {
      const topics: TopicDTO[] = [];
      this.dashboardAnalytics.variables
        .map(variableAnalytics => variableAnalytics.variable.topic)
        .forEach(topic => {
          if (topics.some(topicSelect => topicSelect.id === topic.id)) {
            return;
          }
          topics.push(topic);
        });
      this.topics = topics;
      if (this.topics.length !== 0) {
        this.topicControl.setValue(this.topics[0]);
        this.variablesTopic = this.dashboardAnalytics.variables.filter(
          variableAnalytics => variableAnalytics.variable.topic.id === this.topicControl.value.id
        );
      }
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  protected showMentorship(dashboardVariable: VariableOptionAnalyticsDTO): void {
    const dialogRef = this.dialog.open<MentorshipModalComponent, DataMentorshipModal, string | null>(
      MentorshipModalComponent,
      {
        maxWidth: '70vw',
        data: {
          text: dashboardVariable.insight,
          showAskWarren: dashboardVariable.recommendations.length !== 0 && dashboardVariable.recommendations[0] !== '',
          hasNext: false,
          hasPrevious: false
        } as DataMentorshipModal
      }
    );
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(action => {
        if (action == null) {
          return;
        }
        this.askWarren(dashboardVariable);
      });
  }

  protected askWarren(dashboardVariable: VariableOptionAnalyticsDTO) {
    const dialogRef = this.dialog.open<MentorshipModalComponent, DataMentorshipModal, string | null>(
      MentorshipModalComponent,
      {
        maxWidth: '70vw',
        data: {
          text: dashboardVariable.recommendations[0],
          showAskWarren: false,
          hasNext: dashboardVariable.recommendations.length > 1 && dashboardVariable.recommendations[1] !== '',
          hasPrevious: true
        } as DataMentorshipModal
      }
    );
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(action => {
        if (action == null) {
          return;
        }
        if (action === 'back') {
          this.showMentorship(dashboardVariable);
        } else {
          this.otherPointsView(dashboardVariable);
        }
      });
  }

  protected otherPointsView(dashboardVariable: VariableOptionAnalyticsDTO, index: number = 1) {
    const dialogRef = this.dialog.open<MentorshipModalComponent, DataMentorshipModal, string | null>(
      MentorshipModalComponent,
      {
        maxWidth: '70vw',
        data: {
          text: dashboardVariable.recommendations[index],
          showAskWarren: false,
          hasNext:
            dashboardVariable.recommendations[index + 1] != null && dashboardVariable.recommendations[index + 1] !== '',
          hasPrevious: true
        } as DataMentorshipModal
      }
    );
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(action => {
        if (action == null) {
          return;
        }
        if (action === 'back') {
          if (index > 1) {
            this.otherPointsView(dashboardVariable, index - 1);
          } else {
            this.askWarren(dashboardVariable);
          }
        } else {
          this.otherPointsView(dashboardVariable, index + 1);
        }
      });
  }
}
