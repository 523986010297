import { formatNumber } from '@angular/common';

export const transformSimpleUnit = (amount: number) => {
  if (Math.abs(amount) / 1000000 >= 1) {
    return `${formatNumber(amount / 1000000, 'en', '1.1-1')}M`;
  }
  if (Math.abs(amount) / 1000 >= 1) {
    return `${formatNumber(amount / 1000, 'en', '1.1-1')}K`;
  }
  return amount;
};
