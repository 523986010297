import { Injectable } from '@angular/core';
import { Moment } from 'moment';
import {
  AverageScorePipelineDTO,
  CompanyBasicDTO,
  DashboardAnalyticsDTO,
  InsightsRecomendationsAnalyticsDTO,
  InsightsStartupDTO,
  PipelineAnalyticsDTO,
  PortfolioAnalyticsDTO,
  StartupAnalyticsDTO,
  StartupAnalyticsTopicDTO,
  StartupAnalyticsTopicScoreDTO,
  StartupCompanyBasicDTO,
  StartupMonthScoreDTO,
  StartupPipelineDTO,
  StartupScoreDTO,
  TopicDTO
} from '@DTOS';
import { map, Observable } from 'rxjs';
import { AnalyticsServicioBackend } from '@serviceBackend';

@Injectable({
  providedIn: 'root'
})
export class InvestorsAnalyticsService {
  constructor(private analyticsServicioBackend: AnalyticsServicioBackend) {}

  public getTotalAnalytics(
    company: CompanyBasicDTO,
    startups: StartupCompanyBasicDTO[],
    month: Moment
  ): Observable<PortfolioAnalyticsDTO> {
    const startupsId = startups.map(startup => startup.id).join(',');
    return this.analyticsServicioBackend.GetTotalAnalytics(company.id, startupsId, month);
  }

  public getAnalyticsPortfolioScores(
    from: Moment,
    to: Moment,
    company: CompanyBasicDTO,
    startups: StartupCompanyBasicDTO[]
  ): Observable<Array<StartupScoreDTO>> {
    const startupsId = startups.map(startup => startup.id).join(',');
    return this.analyticsServicioBackend
      .GetAverageScoreAllUserStartups(from, to, company.id, startupsId)
      .pipe(map(x => x.items));
  }

  public getAnalyticsPortfolioTopic(
    company: CompanyBasicDTO,
    startups: StartupCompanyBasicDTO[],
    month: Moment
  ): Observable<Array<StartupAnalyticsTopicScoreDTO>> {
    const startupsId = startups.map(startup => startup.id).join(',');
    return this.analyticsServicioBackend
      .GetAverageScoreByTopicUserStartups(company.id, startupsId, month)
      .pipe(map(x => x.items));
  }

  public getStartupAnalytics(startup: StartupCompanyBasicDTO, month: Moment): Observable<StartupAnalyticsDTO> {
    return this.analyticsServicioBackend.GetTotalAnalyticsStartup(startup.id, month);
  }

  public getStartupAnalyticsTopic(
    startup: StartupCompanyBasicDTO,
    from: Moment,
    to: Moment
  ): Observable<Array<StartupAnalyticsTopicDTO>> {
    return this.analyticsServicioBackend.GetScoreByTopicFromStartup(startup.id, from, to).pipe(map(x => x.items));
  }

  public getStartupInsightsRecomendations(
    startup: StartupCompanyBasicDTO,
    topic: TopicDTO
  ): Observable<InsightsRecomendationsAnalyticsDTO> {
    return this.analyticsServicioBackend.GetInsightsRecommendations(startup.id, topic.id);
  }

  public getTotalAnalyticsPipeline(company: CompanyBasicDTO, month: Moment): Observable<PipelineAnalyticsDTO> {
    return this.analyticsServicioBackend.GetTotalAnalyticsForPipeline(company.id, month);
  }

  public getDashboardAnalytics(startup: StartupCompanyBasicDTO, month: Moment): Observable<DashboardAnalyticsDTO> {
    return this.analyticsServicioBackend.GetDashboardAnalytics(startup.id, month);
  }

  public getPipelineStartups(month: Moment): Observable<StartupPipelineDTO[]> {
    return this.analyticsServicioBackend.GetListsStartupsInPipeline(month).pipe(map(x => x.items));
  }

  public getInsightsVote(startup: StartupCompanyBasicDTO, month: Moment): Observable<InsightsStartupDTO> {
    return this.analyticsServicioBackend.GetInsightsVoteById(startup.id, month);
  }

  public getPipelineAverageScore(month: Moment): Observable<AverageScorePipelineDTO[]> {
    return this.analyticsServicioBackend.GetPipelineAverageScore(month).pipe(map(x => x.items));
  }

  public getScoringStartup(
    startup: StartupCompanyBasicDTO,
    startMonth: Moment,
    endMonth: Moment,
    isParcial: boolean
  ): Observable<StartupMonthScoreDTO> {
    return this.analyticsServicioBackend.GetStartupMonthsScore(startup.id, startMonth, endMonth, isParcial);
  }
}
