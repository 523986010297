import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';

export interface IMonthlyCohortScoreDTO {
  month: number;
  year: number;
  score: number;
}

export class MonthlyCohortScoreDTO implements IDTO<MonthlyCohortScoreDTO, IMonthlyCohortScoreDTO> {
  public month: number = 0;
  public year: number = 0;
  public score: number = 0;

  constructor(json?: Partial<IMonthlyCohortScoreDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<IMonthlyCohortScoreDTO>) {
    if (json.month != null) {
      this.month = json.month;
    }
    if (json.year != null) {
      this.year = json.year;
    }
    if (json.score != null) {
      this.score = json.score;
    }
  }

  public aJSON(): IMonthlyCohortScoreDTO {
    return {
      month: this.month,
      year: this.year,
      score: this.score
    };
  }

  public clon<T extends MonthlyCohortScoreDTO>(clonBase: T): T;
  public clon<T extends MonthlyCohortScoreDTO>(): MonthlyCohortScoreDTO;
  public clon<T extends MonthlyCohortScoreDTO>(clonBase: Nilable<T> = null): T | MonthlyCohortScoreDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.month = this.month;
      clonBase.year = this.year;
      clonBase.score = this.score;
      return clonBase;
    } else {
      const clon = new MonthlyCohortScoreDTO();
      clon.month = this.month;
      clon.year = this.year;
      clon.score = this.score;
      return clon;
    }
  }
}

export class MonthlyCohortScoreModelDTO extends ModelDTO<MonthlyCohortScoreDTO> {
  constructor(entidad: MonthlyCohortScoreDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Month(): number {
    return this._entidad.month;
  }

  public set Month(valor: number) {
    this.cambiarPropiedadYNotificar('month', valor);
  }

  public get Year(): number {
    return this._entidad.year;
  }

  public set Year(valor: number) {
    this.cambiarPropiedadYNotificar('year', valor);
  }

  public get Score(): number {
    return this._entidad.score;
  }

  public set Score(valor: number) {
    this.cambiarPropiedadYNotificar('score', valor);
  }
}
