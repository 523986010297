import { Pipe, PipeTransform } from '@angular/core';
import { OperatorEnum } from '@DTOS';

@Pipe({
  name: 'operatorRule'
})
export class OperatorRulePipe implements PipeTransform {
  transform(operator: OperatorEnum, short: boolean = false): string {
    switch (operator) {
      case OperatorEnum.Equal:
        return short ? '=' : 'Equal';
      case OperatorEnum.NotEqual:
        return short ? '!=' : 'Not equal';
      case OperatorEnum.Less:
        return short ? '<' : 'Less';
      case OperatorEnum.More:
        return short ? '>' : 'More';
      case OperatorEnum.LessEqual:
        return short ? '<=' : 'Less than or equal';
      case OperatorEnum.MoreEqual:
        return short ? '>=' : 'More than or equal';
      case OperatorEnum.StartsWith:
        return 'Starts with';
    }
  }
}
