import { NoteSectionEnum, NoteDTO } from '@DTOS';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NotesModalComponent } from 'src/app/app-shared/notes-modal/notes-modal.component';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotesUtils {
  constructor(private dialogService: MatDialog) {}

  public openNotes(
    section: NoteSectionEnum,
    callbackCargarExtra: ((note: NoteDTO) => void) | null = null,
    callbackFiltrarNotas: ((note: NoteDTO) => boolean) | null = null
  ): Observable<any> {
    const dialogRef = this.dialogService.open(NotesModalComponent);
    dialogRef.componentInstance.loadNotes(section, callbackCargarExtra, callbackFiltrarNotas);
    return dialogRef.afterClosed();
  }
}
