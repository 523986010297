import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';
import { IStartupCompanyBasicDTO, StartupCompanyBasicDTO } from './StartupCompanyBasic.dto';
import { IMonthlyScoreDTO, MonthlyScoreDTO } from './MonthlyScore.dto';

export interface IStartupScoreDTO {
  startup: IStartupCompanyBasicDTO;
  scores: IMonthlyScoreDTO[];
  averageScore: number;
}

export class StartupScoreDTO implements IDTO<StartupScoreDTO, IStartupScoreDTO> {
  public startup: StartupCompanyBasicDTO = new StartupCompanyBasicDTO();
  public scores: MonthlyScoreDTO[] = [];
  public averageScore: number = 0;

  constructor(json?: Partial<IStartupScoreDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<IStartupScoreDTO>) {
    if (json.startup != null) {
      this.startup = new StartupCompanyBasicDTO(json.startup);
    }
    if (json.scores != null) {
      this.scores = json.scores.map(item => new MonthlyScoreDTO(item));
    }
    if (json.averageScore != null) {
      this.averageScore = json.averageScore;
    }
  }

  public aJSON(): IStartupScoreDTO {
    return {
      startup: this.startup.aJSON(),
      scores: this.scores.map(item => item.aJSON()),
      averageScore: this.averageScore
    };
  }

  public clon<T extends StartupScoreDTO>(clonBase: T): T;
  public clon<T extends StartupScoreDTO>(): StartupScoreDTO;
  public clon<T extends StartupScoreDTO>(clonBase: Nilable<T> = null): T | StartupScoreDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.startup = this.startup.clon();
      clonBase.scores = this.scores.map(item => item.clon());
      clonBase.averageScore = this.averageScore;
      return clonBase;
    } else {
      const clon = new StartupScoreDTO();
      clon.startup = this.startup.clon();
      clon.scores = this.scores.map(item => item.clon());
      clon.averageScore = this.averageScore;
      return clon;
    }
  }
}

export class StartupScoreModelDTO extends ModelDTO<StartupScoreDTO> {
  constructor(entidad: StartupScoreDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Startup(): StartupCompanyBasicDTO {
    return this._entidad.startup;
  }

  public set Startup(valor: StartupCompanyBasicDTO) {
    this.cambiarPropiedadYNotificar('startup', valor);
  }

  public get Scores(): MonthlyScoreDTO[] {
    return this._entidad.scores;
  }

  public set Scores(valor: MonthlyScoreDTO[]) {
    this.cambiarPropiedadYNotificar('scores', valor);
  }

  public get AverageScore(): number {
    return this._entidad.averageScore;
  }

  public set AverageScore(valor: number) {
    this.cambiarPropiedadYNotificar('averageScore', valor);
  }
}
