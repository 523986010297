import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';
import { IVariableBasicDTO, VariableBasicDTO } from './VariableBasic.dto';

export interface IResponseBasicDTO {
  id: number;
  cacheStamp: number;
  valueText: string;
  valueNumber: number;
  date: string;
  variable: IVariableBasicDTO;
  year: number;
  month: number;
}

export class ResponseBasicDTO implements IDTO<ResponseBasicDTO, IResponseBasicDTO> {
  public id: number = 0;
  public cacheStamp: number = 0;
  public valueText: string = '';
  public valueNumber: number = 0;
  public date: Date = new Date();
  public variable: VariableBasicDTO = new VariableBasicDTO();
  public year: number = 0;
  public month: number = 0;

  constructor(json?: Partial<IResponseBasicDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<IResponseBasicDTO>) {
    if (json.id != null) {
      this.id = json.id;
    }
    if (json.cacheStamp != null) {
      this.cacheStamp = json.cacheStamp;
    }
    if (json.valueText != null) {
      this.valueText = json.valueText;
    }
    if (json.valueNumber != null) {
      this.valueNumber = json.valueNumber;
    }
    if (json.date != null) {
      this.date = new Date(json.date);
    }
    if (json.variable != null) {
      this.variable = new VariableBasicDTO(json.variable);
    }
    if (json.year != null) {
      this.year = json.year;
    }
    if (json.month != null) {
      this.month = json.month;
    }
  }

  public aJSON(): IResponseBasicDTO {
    return {
      id: this.id,
      cacheStamp: this.cacheStamp,
      valueText: this.valueText,
      valueNumber: this.valueNumber,
      date: this.date.toJSON(),
      variable: this.variable.aJSON(),
      year: this.year,
      month: this.month
    };
  }

  public clon<T extends ResponseBasicDTO>(clonBase: T): T;
  public clon<T extends ResponseBasicDTO>(): ResponseBasicDTO;
  public clon<T extends ResponseBasicDTO>(clonBase: Nilable<T> = null): T | ResponseBasicDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.id = this.id;
      clonBase.cacheStamp = this.cacheStamp;
      clonBase.valueText = this.valueText;
      clonBase.valueNumber = this.valueNumber;
      clonBase.date = new Date(this.date.toJSON());
      clonBase.variable = this.variable.clon();
      clonBase.year = this.year;
      clonBase.month = this.month;
      return clonBase;
    } else {
      const clon = new ResponseBasicDTO();
      clon.id = this.id;
      clon.cacheStamp = this.cacheStamp;
      clon.valueText = this.valueText;
      clon.valueNumber = this.valueNumber;
      clon.date = new Date(this.date.toJSON());
      clon.variable = this.variable.clon();
      clon.year = this.year;
      clon.month = this.month;
      return clon;
    }
  }
}

export class ResponseBasicModelDTO extends ModelDTO<ResponseBasicDTO> {
  constructor(entidad: ResponseBasicDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Id(): number {
    return this._entidad.id;
  }

  public set Id(valor: number) {
    this.cambiarPropiedadYNotificar('id', valor);
  }

  public get CacheStamp(): number {
    return this._entidad.cacheStamp;
  }

  public set CacheStamp(valor: number) {
    this.cambiarPropiedadYNotificar('cacheStamp', valor);
  }

  public get ValueText(): string {
    return this._entidad.valueText;
  }

  public set ValueText(valor: string) {
    this.cambiarPropiedadYNotificar('valueText', valor);
  }

  public get ValueNumber(): number {
    return this._entidad.valueNumber;
  }

  public set ValueNumber(valor: number) {
    this.cambiarPropiedadYNotificar('valueNumber', valor);
  }

  public get Date(): Date {
    return this._entidad.date;
  }

  public set Date(valor: Date) {
    this.cambiarPropiedadYNotificar('date', valor);
  }

  public get Variable(): VariableBasicDTO {
    return this._entidad.variable;
  }

  public set Variable(valor: VariableBasicDTO) {
    this.cambiarPropiedadYNotificar('variable', valor);
  }

  public get Year(): number {
    return this._entidad.year;
  }

  public set Year(valor: number) {
    this.cambiarPropiedadYNotificar('year', valor);
  }

  public get Month(): number {
    return this._entidad.month;
  }

  public set Month(valor: number) {
    this.cambiarPropiedadYNotificar('month', valor);
  }
}
