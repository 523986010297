import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';
import { ITopicDTO, TopicDTO } from './Topic.dto';
import { VariableTypeEnum } from './VariableType.enum';

export interface IVariableBasicDTO {
  id: number;
  cacheStamp: number;
  type: number;
  code: string;
  topic: ITopicDTO;
  name: string;
  description: string;
}

export class VariableBasicDTO implements IDTO<VariableBasicDTO, IVariableBasicDTO> {
  public id: number = 0;
  public cacheStamp: number = 0;
  public type: VariableTypeEnum = VariableTypeEnum.Undefined;
  public code: string = '';
  public topic: TopicDTO = new TopicDTO();
  public name: string = '';
  public description: string = '';

  constructor(json?: Partial<IVariableBasicDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<IVariableBasicDTO>) {
    if (json.id != null) {
      this.id = json.id;
    }
    if (json.cacheStamp != null) {
      this.cacheStamp = json.cacheStamp;
    }
    if (json.type != null) {
      this.type = json.type as VariableTypeEnum;
    }
    if (json.code != null) {
      this.code = json.code;
    }
    if (json.topic != null) {
      this.topic = new TopicDTO(json.topic);
    }
    if (json.name != null) {
      this.name = json.name;
    }
    if (json.description != null) {
      this.description = json.description;
    }
  }

  public aJSON(): IVariableBasicDTO {
    return {
      id: this.id,
      cacheStamp: this.cacheStamp,
      type: this.type,
      code: this.code,
      topic: this.topic.aJSON(),
      name: this.name,
      description: this.description
    };
  }

  public clon<T extends VariableBasicDTO>(clonBase: T): T;
  public clon<T extends VariableBasicDTO>(): VariableBasicDTO;
  public clon<T extends VariableBasicDTO>(clonBase: Nilable<T> = null): T | VariableBasicDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.id = this.id;
      clonBase.cacheStamp = this.cacheStamp;
      clonBase.type = this.type;
      clonBase.code = this.code;
      clonBase.topic = this.topic.clon();
      clonBase.name = this.name;
      clonBase.description = this.description;
      return clonBase;
    } else {
      const clon = new VariableBasicDTO();
      clon.id = this.id;
      clon.cacheStamp = this.cacheStamp;
      clon.type = this.type;
      clon.code = this.code;
      clon.topic = this.topic.clon();
      clon.name = this.name;
      clon.description = this.description;
      return clon;
    }
  }
}

export class VariableBasicModelDTO extends ModelDTO<VariableBasicDTO> {
  constructor(entidad: VariableBasicDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Id(): number {
    return this._entidad.id;
  }

  public set Id(valor: number) {
    this.cambiarPropiedadYNotificar('id', valor);
  }

  public get CacheStamp(): number {
    return this._entidad.cacheStamp;
  }

  public set CacheStamp(valor: number) {
    this.cambiarPropiedadYNotificar('cacheStamp', valor);
  }

  public get Type(): VariableTypeEnum {
    return this._entidad.type;
  }

  public set Type(valor: VariableTypeEnum) {
    this.cambiarPropiedadYNotificar('type', valor);
  }

  public get Code(): string {
    return this._entidad.code;
  }

  public set Code(valor: string) {
    this.cambiarPropiedadYNotificar('code', valor);
  }

  public get Topic(): TopicDTO {
    return this._entidad.topic;
  }

  public set Topic(valor: TopicDTO) {
    this.cambiarPropiedadYNotificar('topic', valor);
  }

  public get Name(): string {
    return this._entidad.name;
  }

  public set Name(valor: string) {
    this.cambiarPropiedadYNotificar('name', valor);
  }

  public get Description(): string {
    return this._entidad.description;
  }

  public set Description(valor: string) {
    this.cambiarPropiedadYNotificar('description', valor);
  }
}
