import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { ResponseBasicDTO, SurveyResponseDTO, TopicDTO, VariableDTO, VariableTypeEnum } from '@DTOS';
import { TopicQuestionsModel } from './topic-interview/topic-questions.model';
import { DataAssessment } from '../../shared/interfaces/DataAssessment';
import { Location } from '@angular/common';
import { StartupsService } from '../services/startups.service';

import { CommandAsync, ICommand } from '@nahuelmorata/ngx-command';
import { ModalService } from '../services/modal/modal.service';
import { AlertButton } from 'src/app/shared/enums/AlertButton.enum';
import { QuestionAnswerItemModel } from './topic-interview/question/question-answer-item.model';
import { NotifierService } from '@nahuelmorata/angular-notifier';
import { UsersUtils } from 'src/app/shared/utils/users/Users.utils';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-assessment',
  templateUrl: './assessment.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssessmentComponent implements OnChanges, OnInit {
  @Input() private editing = false;
  @Input() private dataAssessment: DataAssessment | null;

  protected topics: Array<TopicDTO> = [];
  protected showTopics = false;
  protected confirmCorrectInfo = false;

  protected isViewOnly = false;
  public isDemo = false;

  protected saveCmd: ICommand = new CommandAsync(() => this.insertAnswers());

  private questionTopicMap: Map<string, TopicQuestionsModel> = new Map<string, TopicQuestionsModel>();
  private idsShowMap: Map<number, number[]> = new Map<number, number[]>();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private location: Location,
    private modalService: ModalService,
    private startupService: StartupsService,
    private usersUtils: UsersUtils,
    private notifierService: NotifierService
  ) {}

  ngOnInit() {
    this.isViewOnly = this.usersUtils.isAnalyst() && !this.usersUtils.isAdmin();
    this.isDemo = this.usersUtils.isDemo();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataAssessment != null) {
      this.showTopics = this.dataAssessment.responses.length !== 0;
      this.orderQuestionsByTopicWithTheirAnswers();
    }
  }

  public getTopicQuestionsModel(name: string): TopicQuestionsModel {
    return this.questionTopicMap.get(name);
  }

  public getIdsShow(id: number): number[] {
    return this.idsShowMap.get(id);
  }

  public cancelar() {
    this.location.back();
  }

  private async insertAnswers(): Promise<void> {
    if (!this.confirmCorrectInfo) {
      this.notifierService.notify('error', 'You must confirm the information');
      return;
    }
    if (this.editing) {
      const confirmacion = await this.modalService.decision('Are you sure you want to rewrite the responses?');
      if (confirmacion.ButtonResponse === AlertButton.Cancel) {
        return;
      }
    }
    const filterAnswers = new Array<ResponseBasicDTO>();
    for (const topic of this.questionTopicMap.values()) {
      for (const qa of topic.questionsAnswers) {
        if (
          (qa.answer.valueNumber > 0 && qa.question.type !== VariableTypeEnum.Number) ||
          qa.question.type === VariableTypeEnum.Number ||
          qa.answer.valueText
        ) {
          filterAnswers.push(qa.answer);
        }
      }
    }
    const response = new SurveyResponseDTO();
    response.startup = this.dataAssessment.startup;
    response.response = filterAnswers;
    response.company.id = this.dataAssessment.company.id;
    response.month = this.dataAssessment.dateSelected?.getMonth() + 1;
    response.year = this.dataAssessment.dateSelected?.getFullYear();
    response.confirmCorrectInfo = true;
    await firstValueFrom(this.startupService.insertInterviewAnswer(response));
    await this.modalService.success('Responses have been sent');
    this.location.back();
  }

  private orderQuestionsByTopicWithTheirAnswers() {
    const topics: TopicDTO[] = [];
    this.questionTopicMap = new Map();
    for (const question of this.dataAssessment.company.questions) {
      if (!this.idsShowMap.has(question.topic.id)) {
        this.idsShowMap.set(question.topic.id, []);
      }
      if (this.questionTopicMap.has(question.topic.name)) {
        const topicModel: TopicQuestionsModel = this.questionTopicMap.get(question.topic.name);
        const questionAnswer = this.crearQuestionModel(question);
        topicModel.questionsAnswers.push(questionAnswer);
        this.questionTopicMap.delete(question.topic.name);
        this.questionTopicMap.set(question.topic.name, topicModel);
      } else {
        topics.push(question.topic);
        const questionAnswer = this.crearQuestionModel(question);
        const topicModel = new TopicQuestionsModel();
        topicModel.questionsAnswers.push(questionAnswer);
        this.questionTopicMap.set(question.topic.name, topicModel);
      }
      if (
        question.topic.name.includes('Performance') ||
        this.dataAssessment.variablesShow == null ||
        this.dataAssessment.variablesShow?.includes(question.id)
      ) {
        this.idsShowMap.get(question.topic.id).push(question.id);
      }
    }
    this.topics = topics;
    this.changeDetectorRef.detectChanges();
  }

  private crearQuestionModel(variable: VariableDTO): QuestionAnswerItemModel {
    const answer = new ResponseBasicDTO();
    answer.variable.id = variable.id;
    answer.variable.topic.id = variable.topic.id;
    const answerForQ = this.dataAssessment.responses.find(x => x.variable.id === answer.variable.id);
    if (answerForQ) {
      if (answerForQ.variable.type === VariableTypeEnum.Text) {
        answer.valueText = answerForQ.valueText;
      } else {
        answer.valueNumber = answerForQ.valueNumber;
      }
    }
    return new QuestionAnswerItemModel(variable, answer);
  }
}
