<div class="shadow-box bg-white p-8 rounded-xl flex h-full">
  <div
    class="flex flex-col"
    [ngClass]="{
      'w-1/2': !isMobile()
    }">
    <div class="flex">
      <div class="h-[40px] w-[40px] bg-primary text-white rounded-full mr-4 flex justify-center items-center">
        <i class="fa-solid fa-users fa-lg"></i>
      </div>
      <div class="flex flex-col">
        <h3 class="text-lg">Top Management</h3>
        <small class="text-neutral-400">Founders Names and Roles</small>
      </div>
    </div>
    <div class="overflow-y-auto grow mt-6">
      <ng-container *ngIf="startupSelected.founders.length !== 0">
        <div class="flex" *ngFor="let founder of startupSelected.founders">
          <div class="flex items-center py-2">
            <div class="h-[40px] w-[40px] text-primary mr-4 flex justify-center items-center">
              <i class="fa-solid fa-user fa-lg"></i>
            </div>
            <div class="flex flex-col">
              <h3 class="text-base">{{ founder.name }}</h3>
              <small class="text-neutral-400">{{ founder.position }}</small>
            </div>
          </div>
        </div>
      </ng-container>
      <app-empty-screen-info
        textInfo="Without founders"
        textCallback="Create founder"
        *ngIf="startupSelected.founders.length === 0"
        (clickCallback)="goToEditStartupCmd.ejecutar()"></app-empty-screen-info>
    </div>
  </div>
  @if (!isMobile()) {
    <div class="w-1/2 flex flex-col">
      <div class="flex">
        <div class="h-[40px] w-[40px] bg-primary text-white rounded-full mr-4 flex justify-center items-center">
          <i class="fa-solid fa-star fa-lg"></i>
        </div>
        <div class="flex flex-col">
          <h3 class="text-lg">Topic Reach</h3>
        </div>
      </div>
      <div class="overflow-y-auto grow mt-6 flex flex-col">
        <div class="flex" *ngFor="let topicScore of dashboardAnalytics.topicsScore">
          <div class="flex items-center py-2">
            <div class="h-[40px] w-[40px] text-primary mr-2 flex justify-center items-center">
              <i class="fa-solid fa-star fa-lg"></i>
            </div>
            <div class="flex flex-col justify-center">
              <h3 class="text-base">{{ topicScore.topic.name }} - {{ topicScore.score }} %</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
</div>
@if (isMobile()) {
  <div class="shadow-box bg-white p-8 rounded-xl flex h-full mt-4">
    <div class="flex flex-col">
      <div class="flex">
        <div class="h-[40px] w-[40px] bg-primary text-white rounded-full mr-4 flex justify-center items-center">
          <i class="fa-solid fa-star fa-lg"></i>
        </div>
        <div class="flex flex-col">
          <h3 class="text-lg">Topic Reach</h3>
        </div>
      </div>
      <div class="overflow-y-auto grow mt-6 flex flex-col">
        <div class="flex" *ngFor="let topicScore of dashboardAnalytics.topicsScore">
          <div class="flex items-center py-2">
            <div class="h-[40px] w-[40px] text-primary mr-2 flex justify-center items-center">
              <i class="fa-solid fa-star fa-lg"></i>
            </div>
            <div class="flex flex-col justify-center">
              <h3 class="text-base">{{ topicScore.topic.name }} - {{ topicScore.score }} %</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}
