import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IListaDTO, ListaDTO } from '@nahuelmorata/framework-frontend';
import { Constants } from '../shared/constants';
import { Moment } from 'moment';

import { IPortfolioAnalyticsDTO, PortfolioAnalyticsDTO } from '../DTO/PortfolioAnalytics.dto';
import { IStartupScoreDTO, StartupScoreDTO } from '../DTO/StartupScore.dto';
import { IStartupAnalyticsTopicScoreDTO, StartupAnalyticsTopicScoreDTO } from '../DTO/StartupAnalyticsTopicScore.dto';
import { IStartupAnalyticsDTO, StartupAnalyticsDTO } from '../DTO/StartupAnalytics.dto';
import { IStartupAnalyticsTopicDTO, StartupAnalyticsTopicDTO } from '../DTO/StartupAnalyticsTopic.dto';
import {
  IInsightsRecomendationsAnalyticsDTO,
  InsightsRecomendationsAnalyticsDTO
} from '../DTO/InsightsRecomendationsAnalytics.dto';
import { IPipelineAnalyticsDTO, PipelineAnalyticsDTO } from '../DTO/PipelineAnalytics.dto';
import { IAverageScorePipelineDTO, AverageScorePipelineDTO } from '../DTO/AverageScorePipeline.dto';
import { IDashboardAnalyticsDTO, DashboardAnalyticsDTO } from '../DTO/DashboardAnalytics.dto';
import { IStartupPipelineDTO, StartupPipelineDTO } from '../DTO/StartupPipeline.dto';
import { IInsightsStartupDTO, InsightsStartupDTO } from '../DTO/InsightsStartup.dto';
import { IStartupMonthScoreDTO, StartupMonthScoreDTO } from '@DTOS';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsServicioBackend {
  constructor(protected http: HttpClient) {}

  public GetTotalAnalytics(companyId: number, startups: string, month: Moment): Observable<PortfolioAnalyticsDTO> {
    const params = new HttpParams()
      .append('companyId', companyId.toString())
      .append('startups', startups)
      .append('month', month.format('YYYY-MM-DD'));
    const opciones = { params };
    return this.http
      .get<IPortfolioAnalyticsDTO>(`${Constants.apiURL}/analytics/portfolio`, opciones)
      .pipe(map(entidad => new PortfolioAnalyticsDTO(entidad)));
  }

  public GetAverageScoreAllUserStartups(
    from: Moment,
    to: Moment,
    companyId: number,
    startups: string
  ): Observable<ListaDTO<StartupScoreDTO>> {
    const params = new HttpParams()
      .append('from', from.format('YYYY-MM-DD'))
      .append('to', to.format('YYYY-MM-DD'))
      .append('companyId', companyId.toString())
      .append('startups', startups);
    const opciones = { params };
    return this.http.get<IListaDTO<IStartupScoreDTO>>(`${Constants.apiURL}/analytics/portfolio/scores`, opciones).pipe(
      map(
        lista =>
          new ListaDTO({
            items: lista.items.map(item => new StartupScoreDTO(item)),
            cantidad: lista.cantidad
          })
      )
    );
  }

  public GetAverageScoreByTopicUserStartups(
    companyId: number,
    startups: string,
    month: Moment
  ): Observable<ListaDTO<StartupAnalyticsTopicScoreDTO>> {
    const params = new HttpParams()
      .append('companyId', companyId.toString())
      .append('startups', startups)
      .append('month', month.format('YYYY-MM-DD'));
    const opciones = { params };
    return this.http
      .get<IListaDTO<IStartupAnalyticsTopicScoreDTO>>(`${Constants.apiURL}/analytics/portfolio/topic`, opciones)
      .pipe(
        map(
          lista =>
            new ListaDTO({
              items: lista.items.map(item => new StartupAnalyticsTopicScoreDTO(item)),
              cantidad: lista.cantidad
            })
        )
      );
  }

  public GetTotalAnalyticsStartup(id: number, month: Moment): Observable<StartupAnalyticsDTO> {
    const params = new HttpParams().append('month', month.format('YYYY-MM-DD'));
    const opciones = { params };
    return this.http
      .get<IStartupAnalyticsDTO>(`${Constants.apiURL}/analytics/startup/${id}`, opciones)
      .pipe(map(entidad => new StartupAnalyticsDTO(entidad)));
  }

  public GetScoreByTopicFromStartup(
    id: number,
    from: Moment,
    to: Moment
  ): Observable<ListaDTO<StartupAnalyticsTopicDTO>> {
    const params = new HttpParams().append('from', from.format('YYYY-MM-DD')).append('to', to.format('YYYY-MM-DD'));
    const opciones = { params };
    return this.http
      .get<IListaDTO<IStartupAnalyticsTopicDTO>>(`${Constants.apiURL}/analytics/startup/${id}/topics`, opciones)
      .pipe(
        map(
          lista =>
            new ListaDTO({
              items: lista.items.map(item => new StartupAnalyticsTopicDTO(item)),
              cantidad: lista.cantidad
            })
        )
      );
  }

  public GetInsightsRecommendations(id: number, topic: number): Observable<InsightsRecomendationsAnalyticsDTO> {
    const params = new HttpParams().append('topic', topic.toString());
    const opciones = { params };
    return this.http
      .get<IInsightsRecomendationsAnalyticsDTO>(
        `${Constants.apiURL}/analytics/startup/${id}/insightsRecomendations`,
        opciones
      )
      .pipe(map(entidad => new InsightsRecomendationsAnalyticsDTO(entidad)));
  }

  public GetTotalAnalyticsForPipeline(companyId: number, month: Moment): Observable<PipelineAnalyticsDTO> {
    const params = new HttpParams()
      .append('companyId', companyId.toString())
      .append('month', month.format('YYYY-MM-DD'));
    const opciones = { params };
    return this.http
      .get<IPipelineAnalyticsDTO>(`${Constants.apiURL}/analytics/pipeline`, opciones)
      .pipe(map(entidad => new PipelineAnalyticsDTO(entidad)));
  }

  public GetPipelineAverageScore(monthDate: Moment): Observable<ListaDTO<AverageScorePipelineDTO>> {
    const params = new HttpParams().append('monthDate', monthDate.format('YYYY-MM-DD'));
    const opciones = { params };
    return this.http
      .get<IListaDTO<IAverageScorePipelineDTO>>(`${Constants.apiURL}/analytics/pipeline/average-score`, opciones)
      .pipe(
        map(
          lista =>
            new ListaDTO({
              items: lista.items.map(item => new AverageScorePipelineDTO(item)),
              cantidad: lista.cantidad
            })
        )
      );
  }

  public GetDashboardAnalytics(id: number, month: Moment): Observable<DashboardAnalyticsDTO> {
    const params = new HttpParams().append('month', month.format('YYYY-MM-DD'));
    const opciones = { params };
    return this.http
      .get<IDashboardAnalyticsDTO>(`${Constants.apiURL}/analytics/dashboard/${id}`, opciones)
      .pipe(map(entidad => new DashboardAnalyticsDTO(entidad)));
  }

  public GetListsStartupsInPipeline(month: Moment): Observable<ListaDTO<StartupPipelineDTO>> {
    const params = new HttpParams().append('month', month.format('YYYY-MM-DD'));
    const opciones = { params };
    return this.http
      .get<IListaDTO<IStartupPipelineDTO>>(`${Constants.apiURL}/analytics/pipeline/startups`, opciones)
      .pipe(
        map(
          lista =>
            new ListaDTO({
              items: lista.items.map(item => new StartupPipelineDTO(item)),
              cantidad: lista.cantidad
            })
        )
      );
  }

  public GetInsightsVoteById(id: number, month: Moment): Observable<InsightsStartupDTO> {
    const params = new HttpParams().append('month', month.format('YYYY-MM-DD'));
    const opciones = { params };
    return this.http
      .get<IInsightsStartupDTO>(`${Constants.apiURL}/analytics/insightsVote/${id}`, opciones)
      .pipe(map(entidad => new InsightsStartupDTO(entidad)));
  }

  public GetStartupMonthsScore(
    startupId: number,
    startMonth: Moment,
    endMonth: Moment,
    isParcial: boolean
  ): Observable<StartupMonthScoreDTO> {
    const params = new HttpParams()
      .append('startMonth', startMonth.format('YYYY-MM-DD'))
      .append('endMonth', endMonth.format('YYYY-MM-DD'))
      .append('isParcial', isParcial.toString());
    const opciones = { params };
    return this.http
      .get<IStartupMonthScoreDTO>(`${Constants.apiURL}/analytics/scoring/${startupId}`, opciones)
      .pipe(map(entidad => new StartupMonthScoreDTO(entidad)));
  }
}
