import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataUserShare } from '../../shared/interfaces/DataUserShare';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { RoleEnum } from 'src/app/shared/enums/Role.enum';
import { AuthService, UsuarioDTO } from '@nahuelmorata/framework-frontend';

@Component({
  selector: 'app-users-shared-note',
  templateUrl: './users-shared-note.component.html',
  styleUrls: ['./users-shared-note.component.scss']
})
export class UsersSharedNoteComponent implements OnInit {
  public textInfoEmpty = 'There are no users';
  public showCallbackEmpty = false;

  public users = [];
  public usersSelected = new UntypedFormControl([]);

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: DataUserShare,
    private router: Router,
    private dialogRef: MatDialogRef<UsersSharedNoteComponent>,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.users = this.data.users.filter(user => user.id !== this.authService.usuario.id);
    const usersAccessCreateUser = [RoleEnum.ADMIN, RoleEnum.ASSESSMENT, RoleEnum.VC_ADMIN, RoleEnum.STARTUP_ADMIN];
    this.showCallbackEmpty = usersAccessCreateUser.includes(this.authService.usuario.rol.id);
    if (!this.showCallbackEmpty) {
      this.textInfoEmpty += ', please contact an administrator';
    }
  }

  public save() {
    const usersIds: number[] = this.usersSelected.value.map((user: UsuarioDTO) => user.id);
    this.dialogRef.close(usersIds);
  }

  public close(): void {
    this.dialogRef.close();
  }

  public toCreateUser() {
    this.router.navigate(['/settings/users']);
  }
}
