import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IListaDTO, ListaDTO } from '@nahuelmorata/framework-frontend';
import { Constants } from '../shared/constants';

import { INoteDTO, NoteDTO } from '../DTO/Note.dto';

@Injectable({
  providedIn: 'root'
})
export class NoteServicioBackend {
  constructor(protected http: HttpClient) {}

  public GetNotesBySection(sections: string): Observable<ListaDTO<NoteDTO>> {
    const params = new HttpParams().append('sections', sections);
    const opciones = { params };
    return this.http.get<IListaDTO<INoteDTO>>(`${Constants.apiURL}/notes`, opciones).pipe(
      map(
        lista =>
          new ListaDTO({
            items: lista.items.map(item => new NoteDTO(item)),
            cantidad: lista.cantidad
          })
      )
    );
  }

  public CreateNote(noteDTO: NoteDTO): Observable<NoteDTO> {
    return this.http.post<INoteDTO>(`${Constants.apiURL}/notes`, noteDTO).pipe(map(entidad => new NoteDTO(entidad)));
  }

  public EditNote(noteDTO: NoteDTO, id: number): Observable<NoteDTO> {
    return this.http
      .put<INoteDTO>(`${Constants.apiURL}/notes/${id}`, noteDTO)
      .pipe(map(entidad => new NoteDTO(entidad)));
  }

  public RemoveNote(id: number): Observable<void> {
    return this.http.delete<void>(`${Constants.apiURL}/notes/${id}`);
  }

  public ShareNote(id: number, users: string): Observable<void> {
    const params = new HttpParams().append('users', users);
    const opciones = { params };
    return this.http.post<void>(`${Constants.apiURL}/notes/${id}/shared`, null, opciones);
  }
}
