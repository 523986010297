import { Component, inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { CompanyService } from 'src/app/shared/services/company.service';
import { GlobalStateService } from 'src/app/shared/services/global-state/global-state.service';
import { UsersSharedNoteComponent } from '../users-shared-note/users-shared-note.component';
import { DataUserShare } from 'src/app/shared/interfaces/DataUserShare';
import { NotesService } from '../services/notes/notes.service';
import { getNoteSectionName } from 'src/app/shared/maps';
import { take } from 'rxjs/operators';
import { CommandAsync, ICommand } from '@nahuelmorata/ngx-command';
import { firstValueFrom } from 'rxjs';
import { NoteDTO, NoteSectionEnum } from '@DTOS';
import { AuthService } from '@nahuelmorata/framework-frontend';
import { UsersUtils } from 'src/app/shared/utils/users/Users.utils';

@Component({
  templateUrl: './notes-modal.component.html',
  styleUrls: ['./notes-modal.component.scss']
})
export class NotesModalComponent {
  public title = '';
  public myNote: NoteDTO | null = null;
  public notes: NoteDTO[] = [];
  public showAdd = false;
  public section: NoteSectionEnum;
  public isDemo = inject(UsersUtils).isDemo();

  public personShareCmd: ICommand = new CommandAsync(() => this.openPersonShare());

  private callbackExtra: ((note: NoteDTO) => void) | null = null;
  private callbackUsers: ((note: NoteDTO) => Promise<void>) | null = null;

  constructor(
    private dialogRef: MatDialogRef<NotesModalComponent>,
    private companyService: CompanyService,
    private globalState: GlobalStateService,
    private dialogService: MatDialog,
    private notesService: NotesService,
    private authService: AuthService
  ) {}

  public async loadNotes(
    section: NoteSectionEnum,
    callbackCargarExtra: ((note: NoteDTO) => void) | null = null,
    callbackFiltrarNotas: ((note: NoteDTO) => boolean) | null = null
  ): Promise<void> {
    this.title = getNoteSectionName(section);
    this.section = section;
    let notes = this.notesService.getNotesBySection(section);
    if (callbackFiltrarNotas != null) {
      notes = notes.filter(callbackFiltrarNotas);
    }
    if (notes.some(x => x.userCreated.id === this.authService.usuario.id)) {
      this.myNote = notes.find(x => x.userCreated.id === this.authService.usuario.id)!;
    }
    if (this.myNote != null && callbackCargarExtra != null) {
      callbackCargarExtra(this.myNote);
    } else {
      this.callbackExtra = callbackCargarExtra;
    }
    this.notes = notes;
  }

  public trackById(_: number, note: NoteDTO): number {
    return note.id;
  }

  public close(): void {
    this.dialogRef.close();
  }

  public removeNote() {
    this.showAdd = false;
    this.notes = this.notes.filter(note => note.id !== this.myNote.id);
    this.myNote = null;
  }

  public async changeNote(newNote: NoteDTO) {
    this.showAdd = false;
    if (this.myNote == null) {
      this.notes = [...this.notes, newNote];
      if (this.callbackExtra != null) {
        this.callbackExtra(newNote);
        await firstValueFrom(this.notesService.updateNote(newNote));
      }
      if (this.callbackUsers != null) {
        await this.callbackUsers(newNote);
      }
    } else {
      this.notes = this.notes.map(note => (note.id === newNote.id ? newNote : note));
    }
    this.myNote = newNote;
  }

  public showNewNote(): void {
    this.showAdd = true;
  }

  public hideNewNote(): void {
    this.showAdd = false;
  }

  private async openPersonShare() {
    if (this.globalState.companySelected == null || this.globalState.companySelected.id === 0 || this.isDemo) {
      return;
    }
    const users = await firstValueFrom(this.companyService.usersFromCompany(this.globalState.companySelected));
    const modalRef = this.dialogService.open(UsersSharedNoteComponent, {
      data: { users, note: this.myNote } as DataUserShare
    });
    const usersIds = await firstValueFrom(modalRef.afterClosed().pipe(take(1)));
    this.callbackUsers = async note => await firstValueFrom(this.notesService.shareNoteWith(note, usersIds));
    if (this.myNote != null) {
      await this.callbackUsers(this.myNote);
    }
  }
}
