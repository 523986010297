import { Component, inject, input, OnChanges, signal, SimpleChanges } from '@angular/core';
import { DashboardAnalyticsDTO, StartupCompanyDTO } from '@DTOS';
import moment from 'moment';
import { ApexAxisChartSeries } from 'ng-apexcharts';
import { firstValueFrom } from 'rxjs';
import { InvestorsAnalyticsService } from 'src/app/layout/investors-analytics/investors-analytics.service';

@Component({
  selector: 'app-startup-profile',
  templateUrl: './startup-profile.component.html',
  styleUrls: ['./startup-profile.component.scss']
})
export class StartupProfileComponent implements OnChanges {
  public dashboardAnalytics = input.required<DashboardAnalyticsDTO>();
  public startup = input.required<StartupCompanyDTO>();
  public scores = signal<ApexAxisChartSeries>([]);

  private readonly analyticsService = inject(InvestorsAnalyticsService);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.startup != null) {
      this.loadMonthScores(changes.startup.currentValue);
    }
  }

  private async loadMonthScores(startup: StartupCompanyDTO) {
    const now = moment().subtract(1, 'month');
    const yearAgo = moment().subtract(1, 'month').subtract(1, 'year');
    const startupScores = await firstValueFrom(this.analyticsService.getScoringStartup(startup, yearAgo, now, false));
    this.scores.set([
      {
        name: startup.name,
        data: startupScores.scores
      },
      {
        name: startup.name,
        data: startupScores.scores,
        type: 'area'
      }
    ]);
  }
}
