import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'anyArray'
})
export class AnyArrayPipe implements PipeTransform {
  transform<T>(
    element: T,
    array: T[],
    callback?: (element: T, ...parameters: any[]) => boolean,
    ...parameters: any[]
  ): boolean {
    if (callback != null) {
      return array.some(x => callback(x, element, ...parameters));
    }
    return array.includes(element);
  }
}
