import { Injectable, NgZone, inject } from '@angular/core';
import { ITransportador, LogTipos, PayloadError } from '@nahuelmorata/framework-frontend';
import { ModalService } from 'src/app/app-shared/services/modal/modal.service';

@Injectable({
  providedIn: 'root'
})
export class LoggerTransporterNinety implements ITransportador {
  public modalService = inject(ModalService);
  public zone = inject(NgZone);

  escribir(payload: PayloadError, tipo: LogTipos): void {
    this.zone.run(() => {
      if (tipo === LogTipos.DEBUG) {
      }

      if (tipo === LogTipos.ERROR) {
        this.modalService.error(payload.mensaje);
      }

      if (tipo === LogTipos.INFO) {
      }

      if (tipo === LogTipos.WARNING) {
      }
    });
  }
}
