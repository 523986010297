<h5 class="text-neutral-600 text-2xl mb-1.5">Missing Assessments</h5>
<div class="table-responsive">
  <table mat-table [dataSource]="dataSource" class="all-radius">
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>Date</th>
      <td mat-cell *matCellDef="let date">
        {{ date.date | date: 'MM/yyyy' }} {{ date.autoCreated ? '(Autocreated)' : '' }}
      </td>
    </ng-container>

    <ng-container matColumnDef=" ">
      <th mat-header-cell *matHeaderCellDef class="!text-right">Actions</th>
      <td mat-cell *matCellDef="let date" class="!text-right">
        <button mat-stroked-button color="primary" (click)="selectDate(date)">
          {{ date.autoCreated ? 'Review' : 'Create' }}
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" class="cursor-pointer"></tr>
  </table>
</div>
