import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';
import { ICompanyDTO, CompanyDTO } from './Company.dto';

export interface ICompanyListDTO {
  companies: ICompanyDTO[];
}

export class CompanyListDTO implements IDTO<CompanyListDTO, ICompanyListDTO> {
  public companies: CompanyDTO[] = [];

  constructor(json?: Partial<ICompanyListDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<ICompanyListDTO>) {
    if (json.companies != null) {
      this.companies = json.companies.map(item => new CompanyDTO(item));
    }
  }

  public aJSON(): ICompanyListDTO {
    return {
      companies: this.companies.map(item => item.aJSON())
    };
  }

  public clon<T extends CompanyListDTO>(clonBase: T): T;
  public clon<T extends CompanyListDTO>(): CompanyListDTO;
  public clon<T extends CompanyListDTO>(clonBase: Nilable<T> = null): T | CompanyListDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.companies = this.companies.map(item => item.clon());
      return clonBase;
    } else {
      const clon = new CompanyListDTO();
      clon.companies = this.companies.map(item => item.clon());
      return clon;
    }
  }
}

export class CompanyListModelDTO extends ModelDTO<CompanyListDTO> {
  constructor(entidad: CompanyListDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Companies(): CompanyDTO[] {
    return this._entidad.companies;
  }

  public set Companies(valor: CompanyDTO[]) {
    this.cambiarPropiedadYNotificar('companies', valor);
  }
}
