import { Component, inject, input, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FilesDialogComponent } from 'src/app/app-shared/files-dialog/files-dialog.component';
import { transformSimpleUnit } from 'src/app/shared/utils/NumberUnits.utils';
import { StartupCompanyDTO } from '@DTOS';

@Component({
  selector: 'app-general-information',
  templateUrl: './general-information.component.html',
  styleUrls: ['./general-information.component.scss']
})
export class GeneralInformationComponent {
  @Input() public startup = new StartupCompanyDTO();
  @Input() public scoring = 0;
  public isMobile = input<boolean>(false);

  public transformSimpleUnit = transformSimpleUnit;

  private readonly dialog = inject(MatDialog);

  public openAttachments() {
    this.dialog.open(FilesDialogComponent, {
      data: this.startup
    });
  }

  // TODO: Refactor this method
  public formatNumber(value: number) {
    if (value > 1000000) {
      return `${value / 1000000}M`;
    }
    if (value > 1000) {
      return `${value / 1000}K`;
    }
  }
}
