import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderNinetyUpsilonSigmaComponent } from './header-ninety-upsilon-sigma/header-ninety-upsilon-sigma.component';
import { FooterNinetyUpsilonSigmaComponent } from './footer-ninety-upsilon-sigma/footer-ninety-upsilon-sigma.component';
import { RouterModule } from '@angular/router';
import { NgxCommandModule } from '@nahuelmorata/ngx-command';
import { SharedModule } from '../shared/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { AppSharedModule } from '../app-shared/app-shared.module';
import { MatRippleModule } from '@angular/material/core';
import { HasPermissionPipe } from './pipes/has-permission/has-permission.pipe';
import { LoggerTransporterNinety } from './logger/LoggerTransporterNinety';
import { LoggerTransportadorToken } from '@nahuelmorata/framework-frontend';

@NgModule({
  declarations: [HeaderNinetyUpsilonSigmaComponent, FooterNinetyUpsilonSigmaComponent, HasPermissionPipe],
  imports: [
    CommonModule,
    RouterModule,
    NgxCommandModule,
    SharedModule,
    MatIconModule,
    MatMenuModule,
    MatBadgeModule,
    MatButtonModule,
    MatListModule,
    MatDialogModule,
    AppSharedModule,
    MatRippleModule
  ],
  exports: [HeaderNinetyUpsilonSigmaComponent, FooterNinetyUpsilonSigmaComponent],
  providers: [{ provide: LoggerTransportadorToken, useClass: LoggerTransporterNinety, multi: true }]
})
export class CoreModule {}
