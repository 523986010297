import { Pipe, PipeTransform } from '@angular/core';
import { OperatingStatusEnum } from '@DTOS';

@Pipe({
  name: 'operatingStatus'
})
export class OperatingStatusPipe implements PipeTransform {
  transform(status: OperatingStatusEnum): string {
    switch (status) {
      case OperatingStatusEnum.Active:
        return 'Active';
      case OperatingStatusEnum.Inactive:
        return 'Inactive';
    }
  }
}
