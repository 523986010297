import { Component, Input } from '@angular/core';
import moment, { Moment } from 'moment';
import { ApexAxisChartSeries, ApexOptions } from 'ng-apexcharts';

@Component({
  selector: 'app-scores-month-information',
  templateUrl: './scores-month-information.component.html',
  styleUrls: ['./scores-month-information.component.scss']
})
export class ScoresMonthInformationComponent {
  @Input() public scores: ApexAxisChartSeries = [];

  public readonly labelsMonth = new Array<Moment>(13)
    .fill(null)
    .map(() => moment().subtract(1, 'month'))
    .map((x, index) => x.subtract(12 - index, 'months'))
    .map(x => x.format('M/YY'));

  protected chartOptions: ApexOptions = {
    chart: {
      height: 350,
      type: 'line',
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    stroke: {
      curve: 'smooth'
    },
    fill: {
      type: 'solid',
      opacity: [0.35, 1]
    },
    legend: {
      show: false
    },
    tooltip: {
      enabled: false
    }
  };
}
