import { UserEntity, UserPasswordEntity } from '@domain/user';
import { Nilable } from '@nahuelmorata/ngx-extras';
import { List } from 'immutable';
import { Observable } from 'rxjs';

export abstract class UserPort {
  users$: Observable<List<UserEntity>>;
  abstract getUsers(): Observable<List<UserEntity>>;
  abstract insertUser(user: UserEntity): Observable<UserEntity>;
  abstract updateUser(user: UserEntity): Observable<UserEntity>;
  abstract deleteUser(user: UserEntity): Observable<void>;
  abstract changePassword(user: UserEntity, userPassword?: Nilable<UserPasswordEntity>): Observable<void>;
}
