<span mat-dialog-title>Enter a URL</span>
<div mat-dialog-content class="h-75">
  <div class="flex flex-col h-full">
    <mat-form-field class="grow pad-form" [style.width.px]="500">
      <mat-label> {{ title }}</mat-label>
      <input matInput type="url" id="url" name="url" [placeholder]="placeholder" [formControl]="formControl" />
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions class="flex justify-end">
  <button mat-stroked-button color="primary" (click)="cancel()">Close</button>
  <button mat-flat-button color="primary" (click)="send()">Send</button>
</div>
