import { Pipe, PipeTransform } from '@angular/core';
import { CompanyRuleTypeEnum } from '@DTOS';

@Pipe({
  name: 'companyRuleType'
})
export class CompanyRuleTypePipe implements PipeTransform {
  transform(companyRuleType: CompanyRuleTypeEnum): string {
    switch (companyRuleType) {
      case CompanyRuleTypeEnum.Answers:
        return 'Answers';
      case CompanyRuleTypeEnum.Profile:
        return 'Startup profile';
      case CompanyRuleTypeEnum.Scoring:
        return 'Scoring';
      case CompanyRuleTypeEnum.Topics:
        return 'Topics';
    }
  }
}
