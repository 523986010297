import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';
import { IStartupAnalyticsTopicScoreDTO, StartupAnalyticsTopicScoreDTO } from './StartupAnalyticsTopicScore.dto';
import { IVariableOptionAnalyticsDTO, VariableOptionAnalyticsDTO } from './VariableOptionAnalytics.dto';

export interface IDashboardAnalyticsDTO {
  parcialScoreLastMonth: number;
  scoreLastMonth: number;
  topicsScore: IStartupAnalyticsTopicScoreDTO[];
  avgProfitPerEmployee: number;
  totalExpendituresTarget: number;
  revenueTarget: number;
  totalRaisedCapitalDate: number;
  companyValuation: number;
  revenueChurnRate: number;
  revenueGrowthRate: number;
  recurringRevenue: number;
  customerChurnRate: number;
  activeUsers: number;
  averageRevenuePerUser: number;
  avgCustomerAcquisitionCost: number;
  revenue: number;
  ebitda: number;
  variables: IVariableOptionAnalyticsDTO[];
}

export class DashboardAnalyticsDTO implements IDTO<DashboardAnalyticsDTO, IDashboardAnalyticsDTO> {
  public parcialScoreLastMonth: number = 0;
  public scoreLastMonth: number = 0;
  public topicsScore: StartupAnalyticsTopicScoreDTO[] = [];
  public avgProfitPerEmployee: number = 0;
  public totalExpendituresTarget: number = 0;
  public revenueTarget: number = 0;
  public totalRaisedCapitalDate: number = 0;
  public companyValuation: number = 0;
  public revenueChurnRate: number = 0;
  public revenueGrowthRate: number = 0;
  public recurringRevenue: number = 0;
  public customerChurnRate: number = 0;
  public activeUsers: number = 0;
  public averageRevenuePerUser: number = 0;
  public avgCustomerAcquisitionCost: number = 0;
  public revenue: number = 0;
  public ebitda: number = 0;
  public variables: VariableOptionAnalyticsDTO[] = [];

  constructor(json?: Partial<IDashboardAnalyticsDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<IDashboardAnalyticsDTO>) {
    if (json.parcialScoreLastMonth != null) {
      this.parcialScoreLastMonth = json.parcialScoreLastMonth;
    }
    if (json.scoreLastMonth != null) {
      this.scoreLastMonth = json.scoreLastMonth;
    }
    if (json.topicsScore != null) {
      this.topicsScore = json.topicsScore.map(item => new StartupAnalyticsTopicScoreDTO(item));
    }
    if (json.avgProfitPerEmployee != null) {
      this.avgProfitPerEmployee = json.avgProfitPerEmployee;
    }
    if (json.totalExpendituresTarget != null) {
      this.totalExpendituresTarget = json.totalExpendituresTarget;
    }
    if (json.revenueTarget != null) {
      this.revenueTarget = json.revenueTarget;
    }
    if (json.totalRaisedCapitalDate != null) {
      this.totalRaisedCapitalDate = json.totalRaisedCapitalDate;
    }
    if (json.companyValuation != null) {
      this.companyValuation = json.companyValuation;
    }
    if (json.revenueChurnRate != null) {
      this.revenueChurnRate = json.revenueChurnRate;
    }
    if (json.revenueGrowthRate != null) {
      this.revenueGrowthRate = json.revenueGrowthRate;
    }
    if (json.recurringRevenue != null) {
      this.recurringRevenue = json.recurringRevenue;
    }
    if (json.customerChurnRate != null) {
      this.customerChurnRate = json.customerChurnRate;
    }
    if (json.activeUsers != null) {
      this.activeUsers = json.activeUsers;
    }
    if (json.averageRevenuePerUser != null) {
      this.averageRevenuePerUser = json.averageRevenuePerUser;
    }
    if (json.avgCustomerAcquisitionCost != null) {
      this.avgCustomerAcquisitionCost = json.avgCustomerAcquisitionCost;
    }
    if (json.revenue != null) {
      this.revenue = json.revenue;
    }
    if (json.ebitda != null) {
      this.ebitda = json.ebitda;
    }
    if (json.variables != null) {
      this.variables = json.variables.map(item => new VariableOptionAnalyticsDTO(item));
    }
  }

  public aJSON(): IDashboardAnalyticsDTO {
    return {
      parcialScoreLastMonth: this.parcialScoreLastMonth,
      scoreLastMonth: this.scoreLastMonth,
      topicsScore: this.topicsScore.map(item => item.aJSON()),
      avgProfitPerEmployee: this.avgProfitPerEmployee,
      totalExpendituresTarget: this.totalExpendituresTarget,
      revenueTarget: this.revenueTarget,
      totalRaisedCapitalDate: this.totalRaisedCapitalDate,
      companyValuation: this.companyValuation,
      revenueChurnRate: this.revenueChurnRate,
      revenueGrowthRate: this.revenueGrowthRate,
      recurringRevenue: this.recurringRevenue,
      customerChurnRate: this.customerChurnRate,
      activeUsers: this.activeUsers,
      averageRevenuePerUser: this.averageRevenuePerUser,
      avgCustomerAcquisitionCost: this.avgCustomerAcquisitionCost,
      revenue: this.revenue,
      ebitda: this.ebitda,
      variables: this.variables.map(item => item.aJSON())
    };
  }

  public clon<T extends DashboardAnalyticsDTO>(clonBase: T): T;
  public clon<T extends DashboardAnalyticsDTO>(): DashboardAnalyticsDTO;
  public clon<T extends DashboardAnalyticsDTO>(clonBase: Nilable<T> = null): T | DashboardAnalyticsDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.parcialScoreLastMonth = this.parcialScoreLastMonth;
      clonBase.scoreLastMonth = this.scoreLastMonth;
      clonBase.topicsScore = this.topicsScore.map(item => item.clon());
      clonBase.avgProfitPerEmployee = this.avgProfitPerEmployee;
      clonBase.totalExpendituresTarget = this.totalExpendituresTarget;
      clonBase.revenueTarget = this.revenueTarget;
      clonBase.totalRaisedCapitalDate = this.totalRaisedCapitalDate;
      clonBase.companyValuation = this.companyValuation;
      clonBase.revenueChurnRate = this.revenueChurnRate;
      clonBase.revenueGrowthRate = this.revenueGrowthRate;
      clonBase.recurringRevenue = this.recurringRevenue;
      clonBase.customerChurnRate = this.customerChurnRate;
      clonBase.activeUsers = this.activeUsers;
      clonBase.averageRevenuePerUser = this.averageRevenuePerUser;
      clonBase.avgCustomerAcquisitionCost = this.avgCustomerAcquisitionCost;
      clonBase.revenue = this.revenue;
      clonBase.ebitda = this.ebitda;
      clonBase.variables = this.variables.map(item => item.clon());
      return clonBase;
    } else {
      const clon = new DashboardAnalyticsDTO();
      clon.parcialScoreLastMonth = this.parcialScoreLastMonth;
      clon.scoreLastMonth = this.scoreLastMonth;
      clon.topicsScore = this.topicsScore.map(item => item.clon());
      clon.avgProfitPerEmployee = this.avgProfitPerEmployee;
      clon.totalExpendituresTarget = this.totalExpendituresTarget;
      clon.revenueTarget = this.revenueTarget;
      clon.totalRaisedCapitalDate = this.totalRaisedCapitalDate;
      clon.companyValuation = this.companyValuation;
      clon.revenueChurnRate = this.revenueChurnRate;
      clon.revenueGrowthRate = this.revenueGrowthRate;
      clon.recurringRevenue = this.recurringRevenue;
      clon.customerChurnRate = this.customerChurnRate;
      clon.activeUsers = this.activeUsers;
      clon.averageRevenuePerUser = this.averageRevenuePerUser;
      clon.avgCustomerAcquisitionCost = this.avgCustomerAcquisitionCost;
      clon.revenue = this.revenue;
      clon.ebitda = this.ebitda;
      clon.variables = this.variables.map(item => item.clon());
      return clon;
    }
  }
}

export class DashboardAnalyticsModelDTO extends ModelDTO<DashboardAnalyticsDTO> {
  constructor(entidad: DashboardAnalyticsDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get ParcialScoreLastMonth(): number {
    return this._entidad.parcialScoreLastMonth;
  }

  public set ParcialScoreLastMonth(valor: number) {
    this.cambiarPropiedadYNotificar('parcialScoreLastMonth', valor);
  }

  public get ScoreLastMonth(): number {
    return this._entidad.scoreLastMonth;
  }

  public set ScoreLastMonth(valor: number) {
    this.cambiarPropiedadYNotificar('scoreLastMonth', valor);
  }

  public get TopicsScore(): StartupAnalyticsTopicScoreDTO[] {
    return this._entidad.topicsScore;
  }

  public set TopicsScore(valor: StartupAnalyticsTopicScoreDTO[]) {
    this.cambiarPropiedadYNotificar('topicsScore', valor);
  }

  public get AvgProfitPerEmployee(): number {
    return this._entidad.avgProfitPerEmployee;
  }

  public set AvgProfitPerEmployee(valor: number) {
    this.cambiarPropiedadYNotificar('avgProfitPerEmployee', valor);
  }

  public get TotalExpendituresTarget(): number {
    return this._entidad.totalExpendituresTarget;
  }

  public set TotalExpendituresTarget(valor: number) {
    this.cambiarPropiedadYNotificar('totalExpendituresTarget', valor);
  }

  public get RevenueTarget(): number {
    return this._entidad.revenueTarget;
  }

  public set RevenueTarget(valor: number) {
    this.cambiarPropiedadYNotificar('revenueTarget', valor);
  }

  public get TotalRaisedCapitalDate(): number {
    return this._entidad.totalRaisedCapitalDate;
  }

  public set TotalRaisedCapitalDate(valor: number) {
    this.cambiarPropiedadYNotificar('totalRaisedCapitalDate', valor);
  }

  public get CompanyValuation(): number {
    return this._entidad.companyValuation;
  }

  public set CompanyValuation(valor: number) {
    this.cambiarPropiedadYNotificar('companyValuation', valor);
  }

  public get RevenueChurnRate(): number {
    return this._entidad.revenueChurnRate;
  }

  public set RevenueChurnRate(valor: number) {
    this.cambiarPropiedadYNotificar('revenueChurnRate', valor);
  }

  public get RevenueGrowthRate(): number {
    return this._entidad.revenueGrowthRate;
  }

  public set RevenueGrowthRate(valor: number) {
    this.cambiarPropiedadYNotificar('revenueGrowthRate', valor);
  }

  public get RecurringRevenue(): number {
    return this._entidad.recurringRevenue;
  }

  public set RecurringRevenue(valor: number) {
    this.cambiarPropiedadYNotificar('recurringRevenue', valor);
  }

  public get CustomerChurnRate(): number {
    return this._entidad.customerChurnRate;
  }

  public set CustomerChurnRate(valor: number) {
    this.cambiarPropiedadYNotificar('customerChurnRate', valor);
  }

  public get ActiveUsers(): number {
    return this._entidad.activeUsers;
  }

  public set ActiveUsers(valor: number) {
    this.cambiarPropiedadYNotificar('activeUsers', valor);
  }

  public get AverageRevenuePerUser(): number {
    return this._entidad.averageRevenuePerUser;
  }

  public set AverageRevenuePerUser(valor: number) {
    this.cambiarPropiedadYNotificar('averageRevenuePerUser', valor);
  }

  public get AvgCustomerAcquisitionCost(): number {
    return this._entidad.avgCustomerAcquisitionCost;
  }

  public set AvgCustomerAcquisitionCost(valor: number) {
    this.cambiarPropiedadYNotificar('avgCustomerAcquisitionCost', valor);
  }

  public get Revenue(): number {
    return this._entidad.revenue;
  }

  public set Revenue(valor: number) {
    this.cambiarPropiedadYNotificar('revenue', valor);
  }

  public get Ebitda(): number {
    return this._entidad.ebitda;
  }

  public set Ebitda(valor: number) {
    this.cambiarPropiedadYNotificar('ebitda', valor);
  }

  public get Variables(): VariableOptionAnalyticsDTO[] {
    return this._entidad.variables;
  }

  public set Variables(valor: VariableOptionAnalyticsDTO[]) {
    this.cambiarPropiedadYNotificar('variables', valor);
  }
}
