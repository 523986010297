import { Injectable } from '@angular/core';
import { TopicDTO } from '@DTOS';
import { TopicServicioBackend } from '@serviceBackend';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TopicsService {
  private topicToEdit: TopicDTO;

  constructor(private topicServicioBackend: TopicServicioBackend) {}

  get TopicToEdit(): TopicDTO {
    return this.topicToEdit;
  }

  set TopicToEdit(t: TopicDTO) {
    this.topicToEdit = t;
  }

  public getTopics(): Observable<Array<TopicDTO>> {
    return this.topicServicioBackend.GetEntidades().pipe(map(topics => topics.items));
  }

  public addTopic(topic: TopicDTO): Observable<TopicDTO> {
    return this.topicServicioBackend.PostEntidad(topic);
  }

  public editTopic(topic: TopicDTO): Observable<TopicDTO> {
    return this.topicServicioBackend.PutEntidad(topic, topic.id);
  }

  public deleteTopic(topic: TopicDTO): Observable<void> {
    return this.topicServicioBackend.DeleteEntidad(topic.id);
  }
}
