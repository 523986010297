import { NoteSectionEnum } from '@DTOS';

const noteSectionNames = new Map<NoteSectionEnum, string>();
noteSectionNames.set(NoteSectionEnum.PortfolioTotalCompanies, 'Selected / Total Companies in Portfolio');
noteSectionNames.set(NoteSectionEnum.PortfolioTotalValuation, 'Total Valuation');
noteSectionNames.set(NoteSectionEnum.PortfolioTotalInvestment, 'Total Investment');
noteSectionNames.set(NoteSectionEnum.PortfolioTotalRaised, 'Total Raised Capital');
noteSectionNames.set(NoteSectionEnum.PortfolioAverageScore, 'Average Score');
noteSectionNames.set(NoteSectionEnum.PortfolioScoreGrowth, 'Score Growth Last 12 months');
noteSectionNames.set(NoteSectionEnum.PortfolioScoreGrowthBefore, 'Score Growth Last Month vs Month Before');
noteSectionNames.set(NoteSectionEnum.PortfolioBestWorstScore, 'Best / Worst Startup');
noteSectionNames.set(NoteSectionEnum.PipelineTotalCompanies, 'Total Companies in Pipeline');
noteSectionNames.set(NoteSectionEnum.PipelineTotalPotencialInvestment, 'Total potencial investment');
noteSectionNames.set(NoteSectionEnum.PipelineTotalCapitalInvested, 'Total capital invested');
noteSectionNames.set(NoteSectionEnum.PipelineEvaluatedCompanies, 'Total evaluated companies to date');
noteSectionNames.set(NoteSectionEnum.StartupNameDescription, 'Description');
noteSectionNames.set(NoteSectionEnum.StartupValuation, 'Company Valuation');
noteSectionNames.set(NoteSectionEnum.StartupRaisedCapital, 'Total raised capital to date');
noteSectionNames.set(NoteSectionEnum.StartupIndustry, 'Industry');
noteSectionNames.set(NoteSectionEnum.StartupFirstLastScore, 'First / Last Score');
noteSectionNames.set(NoteSectionEnum.StartupEbitda, 'EBITDA');
noteSectionNames.set(NoteSectionEnum.StartupRevenue, 'Revenue');
noteSectionNames.set(NoteSectionEnum.StartupAcquisition, 'Customer Acquisition Cost');
noteSectionNames.set(NoteSectionEnum.StartupARPU, 'Average revenue per user (ARPU)');
noteSectionNames.set(NoteSectionEnum.StartupMAU, 'Monthly active users (MAU)');
noteSectionNames.set(NoteSectionEnum.StartupCCR, 'Customer churn rate (CCR)');
noteSectionNames.set(NoteSectionEnum.StartupMRR, 'Monthly recurring revenue (MRR)');
noteSectionNames.set(NoteSectionEnum.StartupRevenueRate, 'Revenue growth rate');
noteSectionNames.set(NoteSectionEnum.StartupRCR, 'Revenue churn rate (RCR)');
noteSectionNames.set(NoteSectionEnum.StartupYearlyRevenue, 'Yearly Revenue Target');
noteSectionNames.set(NoteSectionEnum.StartupYearlyExpenses, 'Yearly expenses target');
noteSectionNames.set(NoteSectionEnum.StartupBestWorstTopic, 'Best / Worst Topic');
noteSectionNames.set(NoteSectionEnum.Insight, 'Insight');
noteSectionNames.set(NoteSectionEnum.Recommendation, 'Recommendation');
noteSectionNames.set(NoteSectionEnum.Cohort, 'Cohort');
noteSectionNames.set(NoteSectionEnum.CohortStartup, 'Startup from Cohort');

export const getNoteSectionName: (section: NoteSectionEnum) => string = section => noteSectionNames.get(section) ?? '';
