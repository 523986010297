import { Component } from '@angular/core';
import moment from 'moment';
import { InvestorsAnalyticsService } from 'src/app/layout/investors-analytics/investors-analytics.service';
import { DashboardAnalyticsDTO, StartupCompanyDTO } from '@DTOS';
import { StartupsService } from '../services/startups.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-startup-profile-modal',
  templateUrl: './startup-profile-modal.component.html',
  styleUrls: ['./startup-profile-modal.component.scss']
})
export class StartupProfileModalComponent {
  protected loading = true;
  protected dashboardAnalytics: DashboardAnalyticsDTO;
  protected startup: StartupCompanyDTO;

  constructor(
    private startupsService: StartupsService,
    private analyticsService: InvestorsAnalyticsService
  ) {}

  public async loadData(startupId: number) {
    const model = await firstValueFrom(this.startupsService.getStartup(startupId));
    const month = moment();
    month.add(-1, 'month');
    this.startup = model.obtenerEntidad();
    this.dashboardAnalytics = await firstValueFrom(this.analyticsService.getDashboardAnalytics(this.startup, month));
    this.loading = false;
  }
}
