import { Pipe, PipeTransform } from '@angular/core';
import { StartupTypeEnum } from '@DTOS';
import { StartupCompanyModelDTO } from '@DTOS';

@Pipe({
  name: 'isPipeline'
})
export class IsPipelinePipe implements PipeTransform {
  transform(startupModel: StartupCompanyModelDTO): boolean {
    return [StartupTypeEnum.Lead, StartupTypeEnum.Shortlist, StartupTypeEnum.Selected].includes(startupModel.Type);
  }
}
