<div class="flex flex-col px-1">
  <app-general-information
    class="mt-4"
    [isMobile]="true"
    [startup]="startupSelected"
    [scoring]="dashboardAnalytics.scoreLastMonth"></app-general-information>
  <app-scoring-information
    [dashboardAnalytics]="dashboardAnalytics"
    class="mt-4"
    [isMobile]="true"></app-scoring-information>
  <app-scores-month-information class="mt-4" [scores]="scores()"></app-scores-month-information>
  <app-founders-topics-information
    class="mt-4"
    [startupSelected]="startupSelected"
    [isMobile]="true"
    [dashboardAnalytics]="dashboardAnalytics"></app-founders-topics-information>
  <app-business-finantials-information
    class="mt-4"
    [isMobile]="true"
    [startupSelected]="startupSelected"></app-business-finantials-information>
</div>
