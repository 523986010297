<span mat-dialog-title>Notes for {{ title }}</span>
<div mat-dialog-content class="h-75">
  <div class="flex flex-col h-full">
    @if (!showAdd) {
      <a mat-flat-button color="primary" (click)="showNewNote()">{{ myNote == null ? 'Add' : 'Edit' }} note</a>
    } @else {
      <div class="flex flex-col">
        <div class="flex justify-between mb-2">
          <h5>{{ myNote == null ? 'New' : 'Edit' }} note</h5>
          <div>
            <mat-icon
              class="cursor-pointer mr-2"
              (click)="personShareCmd.ejecutar()"
              [matTooltip]="isDemo ? 'Disabled on demo' : null"
              >person_add
            </mat-icon>
            <mat-icon class="cursor-pointer" (click)="hideNewNote()">close</mat-icon>
          </div>
        </div>
        <app-note
          [note]="myNote"
          [section]="section"
          [edit]="true"
          (changeNote)="changeNote($event)"
          (removeNote)="removeNote()"></app-note>
      </div>
    }
    <div class="border-bottom w-full my-3"></div>
    @for (note of notes; track note.id) {
      <app-note
        [note]="note"
        [isMy]="note === myNote"
        (changeNote)="changeNote($event)"
        [class.mt-3]="$index !== 0"></app-note>
    }
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-stroked-button color="primary" (click)="close()">Close</button>
</div>
