import { CustomState } from './CustomState';
import { UserActions } from '../actions';
import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { PaymentService } from 'src/app/app-shared/services/payment/payment.service';
import { firstValueFrom } from 'rxjs';

export interface IUserState {
  hasPayment: boolean;
  trialEnds: Date | null;
}

@State<IUserState>({
  name: 'user',
  defaults: {
    hasPayment: false,
    trialEnds: null
  }
})
@Injectable()
export class UserState extends CustomState<IUserState> {
  constructor(private paymentService: PaymentService) {
    super();
  }

  @Action(UserActions.LoadSubscription)
  public async hasNewVersion(ctx: StateContext<IUserState>) {
    const subscriptionInfo = await firstValueFrom(this.paymentService.getPaymentInfo());
    if (subscriptionInfo == null) {
      return;
    }
    this.sendNewState(ctx, {
      hasPayment: subscriptionInfo.hasPayment,
      trialEnds: subscriptionInfo.trialEnds
    });
  }
}
