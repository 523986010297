import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';
import { IStartupCompanyBasicDTO, StartupCompanyBasicDTO } from './StartupCompanyBasic.dto';
import { IMonthlyCohortScoreDTO, MonthlyCohortScoreDTO } from './MonthlyCohortScore.dto';

export interface IStartupCohortScoreDTO {
  startup: IStartupCompanyBasicDTO;
  scores: IMonthlyCohortScoreDTO[];
}

export class StartupCohortScoreDTO implements IDTO<StartupCohortScoreDTO, IStartupCohortScoreDTO> {
  public startup: StartupCompanyBasicDTO = new StartupCompanyBasicDTO();
  public scores: MonthlyCohortScoreDTO[] = [];

  constructor(json?: Partial<IStartupCohortScoreDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<IStartupCohortScoreDTO>) {
    if (json.startup != null) {
      this.startup = new StartupCompanyBasicDTO(json.startup);
    }
    if (json.scores != null) {
      this.scores = json.scores.map(item => new MonthlyCohortScoreDTO(item));
    }
  }

  public aJSON(): IStartupCohortScoreDTO {
    return {
      startup: this.startup.aJSON(),
      scores: this.scores.map(item => item.aJSON())
    };
  }

  public clon<T extends StartupCohortScoreDTO>(clonBase: T): T;
  public clon<T extends StartupCohortScoreDTO>(): StartupCohortScoreDTO;
  public clon<T extends StartupCohortScoreDTO>(clonBase: Nilable<T> = null): T | StartupCohortScoreDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.startup = this.startup.clon();
      clonBase.scores = this.scores.map(item => item.clon());
      return clonBase;
    } else {
      const clon = new StartupCohortScoreDTO();
      clon.startup = this.startup.clon();
      clon.scores = this.scores.map(item => item.clon());
      return clon;
    }
  }
}

export class StartupCohortScoreModelDTO extends ModelDTO<StartupCohortScoreDTO> {
  constructor(entidad: StartupCohortScoreDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Startup(): StartupCompanyBasicDTO {
    return this._entidad.startup;
  }

  public set Startup(valor: StartupCompanyBasicDTO) {
    this.cambiarPropiedadYNotificar('startup', valor);
  }

  public get Scores(): MonthlyCohortScoreDTO[] {
    return this._entidad.scores;
  }

  public set Scores(valor: MonthlyCohortScoreDTO[]) {
    this.cambiarPropiedadYNotificar('scores', valor);
  }
}
