import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { VariableOptionAnalyticsDTO } from '@DTOS';

@Component({
  selector: 'app-table-variables',
  templateUrl: './table-variables.component.html',
  styleUrls: ['./table-variables.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableVariablesComponent {
  @Input() public minWidth = 140;
  @Input() public maxWidth: number | null = null;
  @Input() public variables: VariableOptionAnalyticsDTO[] = [];
  @Output() public openMentorship = new EventEmitter<VariableOptionAnalyticsDTO>();

  public mapTitle(variableAnalytics: VariableOptionAnalyticsDTO) {
    return variableAnalytics.variable.name;
  }
}
