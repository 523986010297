import { ChangeDetectionStrategy, Component, TemplateRef, contentChild, input, signal } from '@angular/core';
import { CardTypeEnum } from '../../shared/enums/CardType.enum';
import { FirstRowTemplateDirective } from './directives/first-row-template/first-row-template.directive';
import { SecondRowTemplateDirective } from './directives/second-row-template/second-row-template.directive';
import { CustomTemplateDirective } from './directives/custom-template/custom-template.directive';

@Component({
  selector: 'app-card-analytic',
  templateUrl: './card-analytic.component.html',
  styleUrls: ['./card-analytic.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardAnalyticComponent {
  public cardType = input.required<CardTypeEnum>();
  public onHoverListen = input(true);

  public readonly CardTypeEnum = CardTypeEnum;

  public readonly firstRowTemplate = contentChild(FirstRowTemplateDirective, { read: TemplateRef });
  public readonly secondRowTemplate = contentChild(SecondRowTemplateDirective, { read: TemplateRef });
  public readonly customTemplate = contentChild(CustomTemplateDirective, { read: TemplateRef });

  public onHover = signal(false);
}
