import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IListaDTO, ListaDTO } from '@nahuelmorata/framework-frontend';
import { Constants } from '../shared/constants';
import { Moment } from 'moment';

import { IStartupCompanyBasicDTO, StartupCompanyBasicDTO } from '../DTO/StartupCompanyBasic.dto';
import { UserStartupsDTO } from '../DTO/UserStartups.dto';
import { IStartupMissingAssessmentDTO, StartupMissingAssessmentDTO } from '../DTO/StartupMissingAssessment.dto';
import { CrunchbaseRequestDTO } from '../DTO/CrunchbaseRequest.dto';
import { IStartupCompanyDTO, StartupCompanyDTO } from '../DTO/StartupCompany.dto';
import { ISearchStartupDTO, SearchStartupDTO } from '../DTO/SearchStartup.dto';
import { SearchStartupFilterDTO } from '../DTO/SearchStartupFilter.dto';
import { ISearchStartupSizeDTO, SearchStartupSizeDTO } from '../DTO/SearchStartupSize.dto';
import { StartupSendEmailsDTO } from '../DTO/StartupSendEmails.dto';
import { IStartupCountSendEmailsDTO, StartupCountSendEmailsDTO } from '../DTO/StartupCountSendEmails.dto';
import { StartupChangeTypeDTO } from '../DTO/StartupChangeType.dto';
import { IStartupSummaryDTO, StartupSummaryDTO } from '../DTO/StartupSummary.dto';

@Injectable({
  providedIn: 'root'
})
export class StartupCompanyServicioBackend {
  constructor(protected http: HttpClient) {}

  public GetUserCompanies(): Observable<ListaDTO<StartupCompanyBasicDTO>> {
    return this.http.get<IListaDTO<IStartupCompanyBasicDTO>>(`${Constants.apiURL}/startups`).pipe(
      map(
        lista =>
          new ListaDTO({
            items: lista.items.map(item => new StartupCompanyBasicDTO(item)),
            cantidad: lista.cantidad
          })
      )
    );
  }

  public GetUserCompaniesByCompany(companyId: number): Observable<ListaDTO<StartupCompanyBasicDTO>> {
    return this.http
      .get<IListaDTO<IStartupCompanyBasicDTO>>(`${Constants.apiURL}/startups/byCompany/${companyId}`)
      .pipe(
        map(
          lista =>
            new ListaDTO({
              items: lista.items.map(item => new StartupCompanyBasicDTO(item)),
              cantidad: lista.cantidad
            })
        )
      );
  }

  public AddRemoveAccessCompanyStartup(userStartupsDTO: UserStartupsDTO, userId: number): Observable<void> {
    return this.http.post<void>(`${Constants.apiURL}/startups/users/${userId}`, userStartupsDTO);
  }

  public GetMissingAssessments(id: number): Observable<StartupMissingAssessmentDTO> {
    return this.http
      .get<IStartupMissingAssessmentDTO>(`${Constants.apiURL}/startups/${id}/assessment/missing`)
      .pipe(map(entidad => new StartupMissingAssessmentDTO(entidad)));
  }

  public ImportFromCrunchbase(crunchbaseRequestDTO: CrunchbaseRequestDTO): Observable<StartupCompanyDTO> {
    return this.http
      .post<IStartupCompanyDTO>(`${Constants.apiURL}/startups/import/crunchbase`, crunchbaseRequestDTO)
      .pipe(map(entidad => new StartupCompanyDTO(entidad)));
  }

  public CopyAssessment(id: number, month: Moment, startMonth: Moment, endMonth: Moment): Observable<void> {
    const params = new HttpParams()
      .append('month', month.format('YYYY-MM-DD'))
      .append('startMonth', startMonth.format('YYYY-MM-DD'))
      .append('endMonth', endMonth.format('YYYY-MM-DD'));
    const opciones = { params };
    return this.http.post<void>(`${Constants.apiURL}/startups/${id}/assessment/copy`, null, opciones);
  }

  public Search(name: string, size: number, page: number): Observable<ListaDTO<SearchStartupDTO>> {
    const params = new HttpParams()
      .append('name', name)
      .append('size', size.toString())
      .append('page', page.toString());
    const opciones = { params };
    return this.http.get<IListaDTO<ISearchStartupDTO>>(`${Constants.apiURL}/startups/search`, opciones).pipe(
      map(
        lista =>
          new ListaDTO({
            items: lista.items.map(item => new SearchStartupDTO(item)),
            cantidad: lista.cantidad
          })
      )
    );
  }

  public Discover(
    searchStartupFilterDTO: SearchStartupFilterDTO,
    size: number = 1,
    page: number = 10
  ): Observable<SearchStartupSizeDTO> {
    const params = new HttpParams().append('size', size.toString()).append('page', page.toString());
    const opciones = { params };
    return this.http
      .post<ISearchStartupSizeDTO>(`${Constants.apiURL}/startups/discover`, searchStartupFilterDTO, opciones)
      .pipe(map(entidad => new SearchStartupSizeDTO(entidad)));
  }

  public SendEmails(startupSendEmailsDTO: StartupSendEmailsDTO): Observable<StartupCountSendEmailsDTO> {
    return this.http
      .post<IStartupCountSendEmailsDTO>(`${Constants.apiURL}/startups/sendEmails`, startupSendEmailsDTO)
      .pipe(map(entidad => new StartupCountSendEmailsDTO(entidad)));
  }

  public UpdateType(startupChangeTypeDTO: StartupChangeTypeDTO, id: number): Observable<void> {
    return this.http.put<void>(`${Constants.apiURL}/startups/${id}/updateType`, startupChangeTypeDTO);
  }

  public ArchiveStartup(id: number): Observable<void> {
    return this.http.patch<void>(`${Constants.apiURL}/startups/${id}/archive`, null);
  }

  public GetStartupSummary(id: number): Observable<StartupSummaryDTO> {
    return this.http
      .get<IStartupSummaryDTO>(`${Constants.apiURL}/startups/${id}/summary`)
      .pipe(map(entidad => new StartupSummaryDTO(entidad)));
  }

  public GetEntidad(id: number): Observable<StartupCompanyDTO> {
    return this.http
      .get<IStartupCompanyDTO>(`${Constants.apiURL}/startups/${id}`)
      .pipe(map(entidad => new StartupCompanyDTO(entidad)));
  }

  public PutEntidad(startupCompanyDTO: StartupCompanyDTO, id: number): Observable<StartupCompanyDTO> {
    return this.http
      .put<IStartupCompanyDTO>(`${Constants.apiURL}/startups/${id}`, startupCompanyDTO)
      .pipe(map(entidad => new StartupCompanyDTO(entidad)));
  }

  public DeleteEntidad(id: number): Observable<void> {
    return this.http.delete<void>(`${Constants.apiURL}/startups/${id}`);
  }

  public GetEntidades(offset: number = 0, limit: number = 0): Observable<ListaDTO<StartupCompanyDTO>> {
    const params = new HttpParams().append('offset', offset.toString()).append('limit', limit.toString());
    const opciones = { params };
    return this.http.get<IListaDTO<IStartupCompanyDTO>>(`${Constants.apiURL}/startups`, opciones).pipe(
      map(
        lista =>
          new ListaDTO({
            items: lista.items.map(item => new StartupCompanyDTO(item)),
            cantidad: lista.cantidad
          })
      )
    );
  }

  public PostEntidad(startupCompanyDTO: StartupCompanyDTO): Observable<StartupCompanyDTO> {
    return this.http
      .post<IStartupCompanyDTO>(`${Constants.apiURL}/startups`, startupCompanyDTO)
      .pipe(map(entidad => new StartupCompanyDTO(entidad)));
  }
}
