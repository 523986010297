import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';

export interface ICompanyUserStartupDTO  {
    startups: number[];
}

export class CompanyUserStartupDTO implements IDTO<CompanyUserStartupDTO, ICompanyUserStartupDTO> {
    public startups: number[] = [];

    constructor(json?: Partial<ICompanyUserStartupDTO>) {
        if (json != null) {
            this.cargarDTO(json);
        }
    }

    public cargarDTO(json: Partial<ICompanyUserStartupDTO>) {
        if (json.startups != null) {
            this.startups = json.startups;
        }
    }

    public aJSON(): ICompanyUserStartupDTO {
        return {
            startups: this.startups
        };
    }

    public clon<T extends CompanyUserStartupDTO>(clonBase: T): T;
    public clon<T extends CompanyUserStartupDTO>(): CompanyUserStartupDTO;
    public clon<T extends CompanyUserStartupDTO>(clonBase: Nilable<T> = null): T | CompanyUserStartupDTO {
        if (clonBase !== undefined && clonBase !== null) {
            clonBase.startups = this.startups;
            return clonBase;
        } else {
            const clon = new CompanyUserStartupDTO();
            clon.startups = this.startups;
            return clon;
        }
    }
}

export class CompanyUserStartupModelDTO extends ModelDTO<CompanyUserStartupDTO> {
    constructor(entidad: CompanyUserStartupDTO) {
        super(entidad);
    }

    protected limpiar(): void {
    }

    public get Startups(): number[] {
        return this._entidad.startups;
    }

    public set Startups(valor: number[]) {
        this.cambiarPropiedadYNotificar('startups', valor);
    }
}


