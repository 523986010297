import { Pipe, PipeTransform } from '@angular/core';
import { CompanyRuleDTO, VariableDTO, CompanyRuleTypeEnum, SelectOptionDTO, VariableStartupProfileEnum } from '@DTOS';
import { formatFoundingType } from '../../utils/FoundingType.utils';
import { formatProfitType } from '../../utils/ProfitType.utils';

@Pipe({
  name: 'companyRuleValue'
})
export class CompanyRuleValuePipe implements PipeTransform {
  transform(rule: CompanyRuleDTO, variables: VariableDTO[]): string {
    switch (rule.type) {
      case CompanyRuleTypeEnum.Answers: {
        const variableRule = variables.find(
          x =>
            x.selectOptions.some(selectOption => selectOption.id === rule.value) ||
            x.rangeOptions.some(rangeOption => rangeOption.id === rule.value)
        );
        if (variableRule == null) {
          return '';
        }
        if (variableRule.selectOptions.some(selectOption => selectOption.id === rule.value)) {
          return variableRule.selectOptions.find(selectOption => selectOption.id === rule.value).text;
        } else {
          const rangeOption = variableRule.rangeOptions.find(x => x.id === rule.value);
          return `${rangeOption.min} - ${rangeOption.max}`;
        }
      }
      case CompanyRuleTypeEnum.Profile:
        let variable: VariableDTO;
        let option: SelectOptionDTO;
        switch (rule.variable) {
          case VariableStartupProfileEnum.LastFoundingType:
          case VariableStartupProfileEnum.CurrentFounding:
            return formatFoundingType(rule.value);
          case VariableStartupProfileEnum.ProfitType:
            return formatProfitType(rule.value);
          case VariableStartupProfileEnum.QuantityEmployees:
            variable = variables.find(x => x.id === 98) as VariableDTO;
            option = variable.selectOptions.find(x => x.id === rule.value);
            return option.text;
          case VariableStartupProfileEnum.PercentageLoansRevenue:
            variable = variables.find(x => x.id === 96) as VariableDTO;
            option = variable.selectOptions.find(x => x.id === rule.value);
            return option.text;
          case VariableStartupProfileEnum.PercentageCompanyHandsFounders:
            variable = variables.find(x => x.id === 99) as VariableDTO;
            option = variable.selectOptions.find(x => x.id === rule.value);
            return option.text;
          default:
            return rule.value.toString();
        }
      case CompanyRuleTypeEnum.Scoring:
      case CompanyRuleTypeEnum.Topics:
        return rule.value.toString();
    }
  }
}
