import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';
import { StartupTypeEnum } from './StartupType.enum';

export interface IStartupChangeTypeDTO {
  id: number;
  startupType: number;
}

export class StartupChangeTypeDTO implements IDTO<StartupChangeTypeDTO, IStartupChangeTypeDTO> {
  public id: number = 0;
  public startupType: StartupTypeEnum = StartupTypeEnum.Lead;

  constructor(json?: Partial<IStartupChangeTypeDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<IStartupChangeTypeDTO>) {
    if (json.id != null) {
      this.id = json.id;
    }
    if (json.startupType != null) {
      this.startupType = json.startupType as StartupTypeEnum;
    }
  }

  public aJSON(): IStartupChangeTypeDTO {
    return {
      id: this.id,
      startupType: this.startupType
    };
  }

  public clon<T extends StartupChangeTypeDTO>(clonBase: T): T;
  public clon<T extends StartupChangeTypeDTO>(): StartupChangeTypeDTO;
  public clon<T extends StartupChangeTypeDTO>(clonBase: Nilable<T> = null): T | StartupChangeTypeDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.id = this.id;
      clonBase.startupType = this.startupType;
      return clonBase;
    } else {
      const clon = new StartupChangeTypeDTO();
      clon.id = this.id;
      clon.startupType = this.startupType;
      return clon;
    }
  }
}

export class StartupChangeTypeModelDTO extends ModelDTO<StartupChangeTypeDTO> {
  constructor(entidad: StartupChangeTypeDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Id(): number {
    return this._entidad.id;
  }

  public set Id(valor: number) {
    this.cambiarPropiedadYNotificar('id', valor);
  }

  public get StartupType(): StartupTypeEnum {
    return this._entidad.startupType;
  }

  public set StartupType(valor: StartupTypeEnum) {
    this.cambiarPropiedadYNotificar('startupType', valor);
  }
}
