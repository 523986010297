import { Component, inject, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommandObservable, ICommand } from '@nahuelmorata/ngx-command';
import { map, Observable, switchMap, tap } from 'rxjs';
import { FilesService } from '../services/files/files.service';
import { StartupCompanyDTO } from '@DTOS';
import { UsersUtils } from 'src/app/shared/utils/users/Users.utils';
import { StartupsService } from '../services/startups.service';
import { ArchivoHostDTO } from '@nahuelmorata/framework-frontend';

@Component({
  selector: 'app-files-dialog',
  templateUrl: './files-dialog.component.html',
  styleUrls: ['./files-dialog.component.scss']
})
export class FilesDialogComponent implements OnInit {
  protected startup = new StartupCompanyDTO();

  public isDemo = inject(UsersUtils).isDemo();
  protected uploadingFile = false;

  protected downloadCmd: ICommand = new CommandObservable((file: any) => this.download(file));
  protected uploadFileCmd: ICommand = new CommandObservable((event: any) => this.uploadFile(event));
  protected deleteCmd: ICommand = new CommandObservable((file: any) => this.delete(file));

  private readonly startupService = inject(StartupsService);

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: StartupCompanyDTO,
    private dialogRef: MatDialogRef<FilesDialogComponent>,
    private filesService: FilesService
  ) {}

  ngOnInit(): void {
    this.startup = this.data;
  }

  protected close() {
    this.dialogRef.close();
  }

  private uploadFile(event: any): Observable<void> {
    const file = event.target.files[0];
    if (file == null) {
      return;
    }
    this.uploadingFile = true;
    const formData = new FormData();
    formData.append('file', file);
    const startupClone = this.startup.clon();
    return this.filesService.uploadFile(formData).pipe(
      tap(x => startupClone.files.push(x)),
      switchMap(() => this.startupService.editStartup(startupClone)),
      tap(x => (this.startup = x)),
      tap(() => (this.uploadingFile = false)),
      map(() => void 0)
    );
  }

  private download(file: ArchivoHostDTO): Observable<void> {
    return this.filesService.downloadStartupFile(file.id, file.nombreOriginal);
  }

  private delete(file: ArchivoHostDTO): Observable<void> {
    const startupClone = this.startup.clon();
    startupClone.files = this.startup.files.filter(startupFile => startupFile.id !== file.id);
    return this.startupService.editStartup(startupClone).pipe(
      tap(x => (this.startup = x)),
      map(() => void 0)
    );
  }
}
