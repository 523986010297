import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';

export interface IVerifyDomainDTO {
  domainExists: boolean;
  hasContactEmail: boolean;
}

export class VerifyDomainDTO implements IDTO<VerifyDomainDTO, IVerifyDomainDTO> {
  public domainExists: boolean = true;
  public hasContactEmail: boolean = true;

  constructor(json?: Partial<IVerifyDomainDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<IVerifyDomainDTO>) {
    if (json.domainExists != null) {
      this.domainExists = json.domainExists;
    }
    if (json.hasContactEmail != null) {
      this.hasContactEmail = json.hasContactEmail;
    }
  }

  public aJSON(): IVerifyDomainDTO {
    return {
      domainExists: this.domainExists,
      hasContactEmail: this.hasContactEmail
    };
  }

  public clon<T extends VerifyDomainDTO>(clonBase: T): T;
  public clon<T extends VerifyDomainDTO>(): VerifyDomainDTO;
  public clon<T extends VerifyDomainDTO>(clonBase: Nilable<T> = null): T | VerifyDomainDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.domainExists = this.domainExists;
      clonBase.hasContactEmail = this.hasContactEmail;
      return clonBase;
    } else {
      const clon = new VerifyDomainDTO();
      clon.domainExists = this.domainExists;
      clon.hasContactEmail = this.hasContactEmail;
      return clon;
    }
  }
}

export class VerifyDomainModelDTO extends ModelDTO<VerifyDomainDTO> {
  constructor(entidad: VerifyDomainDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get DomainExists(): boolean {
    return this._entidad.domainExists;
  }

  public set DomainExists(valor: boolean) {
    this.cambiarPropiedadYNotificar('domainExists', valor);
  }

  public get HasContactEmail(): boolean {
    return this._entidad.hasContactEmail;
  }

  public set HasContactEmail(valor: boolean) {
    this.cambiarPropiedadYNotificar('hasContactEmail', valor);
  }
}
