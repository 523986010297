import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  inject
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { CompanyService } from '../../shared/services/company.service';
import { firstValueFrom, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GlobalStateService } from 'src/app/shared/services/global-state/global-state.service';
import { ICommand } from '@nahuelmorata/ngx-command';
import { UsersUtils } from 'src/app/shared/utils/users/Users.utils';
import { AuthService } from '@nahuelmorata/framework-frontend';
import { CompanyBasicDTO, StartupCompanyBasicDTO } from '@DTOS';
import { StartupsService } from '../services/startups.service';

@Component({
  selector: 'app-filter-startup',
  templateUrl: './filter-startup.component.html',
  styleUrls: ['./filter-startup.component.scss']
})
export class FilterStartupComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public multiple = false;
  @Input() public searchCmd: ICommand;
  @Input() public filterRef: TemplateRef<any>;
  @Input() public startupFilterActive = true;
  @Input() public filterStartup: ((startups: StartupCompanyBasicDTO[]) => StartupCompanyBasicDTO[]) | null = null;
  @Input() public startupToSelected: StartupCompanyBasicDTO | null = null;

  @Output() public companySelected = new EventEmitter<CompanyBasicDTO>();
  @Output() public startupSelected = new EventEmitter<StartupCompanyBasicDTO>();
  @Output() public startupsSelected = new EventEmitter<StartupCompanyBasicDTO[]>();

  public isVC = false;
  public isStartup = false;
  public company = new CompanyBasicDTO();
  public startupsCompany: StartupCompanyBasicDTO[] = [];
  public readonly allStartupsOption = new StartupCompanyBasicDTO();

  public startupsControl = new FormControl<StartupCompanyBasicDTO[]>([]);
  public startupControl = new FormControl<StartupCompanyBasicDTO>(null);
  public startupsFilterControl = new FormControl<string>('');

  private subs = new Subscription();

  private readonly startupService = inject(StartupsService);

  constructor(
    private companyService: CompanyService,
    private globalState: GlobalStateService,
    private usersUtils: UsersUtils,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    void this.loadDataAsync();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.startupToSelected != null && changes.startupToSelected.currentValue != null) {
      this.startupControl.setValue(changes.startupToSelected.currentValue);
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  public compareById(startup1: StartupCompanyBasicDTO, startup2: StartupCompanyBasicDTO): boolean {
    return startup1?.id === startup2?.id;
  }

  public getNameCompany(startup: StartupCompanyBasicDTO): string {
    return startup.name;
  }

  public startupWithoutAll(startup: StartupCompanyBasicDTO) {
    return startup.id !== 0;
  }

  public filterByName(startup: StartupCompanyBasicDTO, filterName: string): boolean {
    if (filterName === '') {
      return true;
    }
    return new RegExp(filterName, 'i').test(startup.name) && startup.id !== 0;
  }

  // TODO: Remove this function
  private emitValues() {
    this.globalState.companySelected = this.company;
    this.companySelected.emit(this.company);
    if (this.multiple) {
      this.startupsSelected.emit(this.startupsControl.value.filter(x => this.startupWithoutAll(x)));
    } else {
      this.startupSelected.emit(this.startupControl.value);
    }
  }

  private loadBooleansUsers() {
    this.isVC = this.usersUtils.isVC();
    this.isStartup = this.usersUtils.isStartup();
  }

  private loadSubscriptions() {
    this.subs.add(this.startupControl.valueChanges.pipe(tap(x => this.startupSelected.emit(x))).subscribe());
    this.subs.add(
      this.startupsControl.valueChanges
        .pipe(
          tap((startups: StartupCompanyBasicDTO[]) => {
            const startupsWithoutAll = startups.filter(x => this.startupWithoutAll(x));
            if (startupsWithoutAll.length === this.startupsCompany.length) {
              this.startupsControl.setValue([this.allStartupsOption, ...this.startupsCompany], { emitEvent: false });
            } else {
              this.startupsControl.setValue([...startupsWithoutAll], { emitEvent: false });
            }
            this.startupsSelected.emit(startups.filter(x => this.startupWithoutAll(x)));
          })
        )
        .subscribe()
    );
  }

  private async loadDataAsync() {
    this.loadBooleansUsers();

    // TODO: Refactor
    this.company = await firstValueFrom(this.companyService.getCompanyDefault());
    this.emitValues();
    const startups = await firstValueFrom(this.startupService.getStartups());
    this.startupsCompany = this.filterStartup != null ? this.filterStartup(startups) : startups;
    if (this.startupsCompany.length === 0) {
      this.emitValues();
      this.searchCmd.ejecutar();
      return;
    }
    if (this.multiple) {
      this.startupsControl.setValue([this.allStartupsOption, ...this.startupsCompany]);
    } else {
      this.startupControl.setValue(this.startupsCompany[0]);
    }

    this.loadSubscriptions();

    this.emitValues();
    this.searchCmd.ejecutar();
  }
}
