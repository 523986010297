import { Component, Input, Output, EventEmitter, TemplateRef, ContentChild, inject } from '@angular/core';
import { ActionsTemplateDirective } from './directives/actions-template/actions-template.directive';
import { CustomCohortViewDirective } from './directives/custom-cohort-view/custom-cohort-view.directive';
import { DataEntityCohort } from './interfaces/DataStartupCohort';
import { StartupUtils } from 'src/app/shared/utils';
import { CommandObservable, ICommand } from '@nahuelmorata/ngx-command';

@Component({
  selector: 'app-cohort-view',
  templateUrl: './cohort-view.component.html',
  styleUrls: ['./cohort-view.component.scss']
})
export class CohortViewComponent {
  @Input() public months: Date[];
  @Input() public datas: DataEntityCohort[];
  @Input() public minWidth = 140;
  @Input() public showCustomComponent = false;
  @Input() public selectedIndex: number | null = null;
  @Output() public selectedIndexChange = new EventEmitter<number>();

  @ContentChild(ActionsTemplateDirective, { read: TemplateRef }) public actionsTemplate: TemplateRef<any>;
  @ContentChild(CustomCohortViewDirective, { read: TemplateRef }) public customCohortViewTemplate: TemplateRef<any>;

  public indexHover: number | null = null;

  public openStartupInfoDialogCmd: ICommand = new CommandObservable((dataEntityCohort: DataEntityCohort) =>
    this.openStartupInfoDialog(dataEntityCohort)
  );

  private readonly startupsUtils = inject(StartupUtils);

  private openStartupInfoDialog(dataEntityCohort: DataEntityCohort) {
    return this.startupsUtils.openStartupInfo(
      dataEntityCohort.entity.id,
      dataEntityCohort.scores[dataEntityCohort.scores.length - 1].score
    );
  }
}
