import { Pipe, PipeTransform } from '@angular/core';
import { Constants } from 'src/app/shared/constants';

@Pipe({
  name: 'addBaseUrl'
})
export class AddBaseUrlPipe implements PipeTransform {
  transform(url: string | null): string | null {
    if (url == null) {
      return null;
    }
    return url.includes('http') ? url : `${Constants.imageURL}/${url}`;
  }
}
