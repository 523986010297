import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reduceArray'
})
export class ReduceArrayPipe implements PipeTransform {
  transform<T, V>(
    array: T[],
    callback: (previousValue: V, currentValue: T, currentIndex: number, array: T[]) => V,
    init?: V
  ): V {
    return array.reduce(callback, init);
  }
}
