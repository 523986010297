import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';
import { IVariableBasicDTO, VariableBasicDTO } from './VariableBasic.dto';

export interface IVariableOptionAnalyticsDTO {
  id: number;
  variable: IVariableBasicDTO;
  weight: number;
  value: string;
  insight: string;
  recommendations: string[];
}

export class VariableOptionAnalyticsDTO implements IDTO<VariableOptionAnalyticsDTO, IVariableOptionAnalyticsDTO> {
  public id: number = 0;
  public variable: VariableBasicDTO = new VariableBasicDTO();
  public weight: number = 0;
  public value: string = '';
  public insight: string = '';
  public recommendations: string[] = [];

  constructor(json?: Partial<IVariableOptionAnalyticsDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<IVariableOptionAnalyticsDTO>) {
    if (json.id != null) {
      this.id = json.id;
    }
    if (json.variable != null) {
      this.variable = new VariableBasicDTO(json.variable);
    }
    if (json.weight != null) {
      this.weight = json.weight;
    }
    if (json.value != null) {
      this.value = json.value;
    }
    if (json.insight != null) {
      this.insight = json.insight;
    }
    if (json.recommendations != null) {
      this.recommendations = json.recommendations;
    }
  }

  public aJSON(): IVariableOptionAnalyticsDTO {
    return {
      id: this.id,
      variable: this.variable.aJSON(),
      weight: this.weight,
      value: this.value,
      insight: this.insight,
      recommendations: this.recommendations
    };
  }

  public clon<T extends VariableOptionAnalyticsDTO>(clonBase: T): T;
  public clon<T extends VariableOptionAnalyticsDTO>(): VariableOptionAnalyticsDTO;
  public clon<T extends VariableOptionAnalyticsDTO>(clonBase: Nilable<T> = null): T | VariableOptionAnalyticsDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.id = this.id;
      clonBase.variable = this.variable.clon();
      clonBase.weight = this.weight;
      clonBase.value = this.value;
      clonBase.insight = this.insight;
      clonBase.recommendations = this.recommendations;
      return clonBase;
    } else {
      const clon = new VariableOptionAnalyticsDTO();
      clon.id = this.id;
      clon.variable = this.variable.clon();
      clon.weight = this.weight;
      clon.value = this.value;
      clon.insight = this.insight;
      clon.recommendations = this.recommendations;
      return clon;
    }
  }
}

export class VariableOptionAnalyticsModelDTO extends ModelDTO<VariableOptionAnalyticsDTO> {
  constructor(entidad: VariableOptionAnalyticsDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Id(): number {
    return this._entidad.id;
  }

  public set Id(valor: number) {
    this.cambiarPropiedadYNotificar('id', valor);
  }

  public get Variable(): VariableBasicDTO {
    return this._entidad.variable;
  }

  public set Variable(valor: VariableBasicDTO) {
    this.cambiarPropiedadYNotificar('variable', valor);
  }

  public get Weight(): number {
    return this._entidad.weight;
  }

  public set Weight(valor: number) {
    this.cambiarPropiedadYNotificar('weight', valor);
  }

  public get Value(): string {
    return this._entidad.value;
  }

  public set Value(valor: string) {
    this.cambiarPropiedadYNotificar('value', valor);
  }

  public get Insight(): string {
    return this._entidad.insight;
  }

  public set Insight(valor: string) {
    this.cambiarPropiedadYNotificar('insight', valor);
  }

  public get Recommendations(): string[] {
    return this._entidad.recommendations;
  }

  public set Recommendations(valor: string[]) {
    this.cambiarPropiedadYNotificar('recommendations', valor);
  }
}
