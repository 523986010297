import { Injectable, inject } from '@angular/core';
import { RoleEntity } from '@domain/role';
import { RolBackendService } from '@nahuelmorata/framework-frontend';
import { RolePort } from '@ports/role';
import { Observable, concatMap, defer, map, of, switchMap, tap } from 'rxjs';
import { CacheEntityService } from '../../cache/cache-entity.service';
import { RoleMapper } from './mappers';
import { List } from 'immutable';
import { PermissionMapper } from '@adapters/permission';

/**
 * @deprecated Use RolePort instead
 */
@Injectable({
  providedIn: 'root'
})
export class RoleAdapterService extends CacheEntityService<RoleEntity> implements RolePort {
  public roles$ = this.entities$;
  private rolesServiceBackend = inject(RolBackendService);

  public getRoles(): Observable<List<RoleEntity>> {
    return defer(() =>
      this.hasCache()
        ? of(List(this.getEntities()))
        : this.rolesServiceBackend.ObtenerTodos().pipe(
            map(x => x.items),
            map(x => x.map(y => RoleMapper.toEntity(y))),
            map(x => this.setEntities(x))
          )
    );
  }

  public loadRolePermissions(role: RoleEntity): Observable<RoleEntity> {
    return of(role).pipe(
      switchMap(r =>
        r.permissions != null
          ? of(r)
          : this.rolesServiceBackend.ObtenerEndpoints(r.id).pipe(
              map(x => x.items),
              map(x => x.map(y => PermissionMapper.toEntity(y))),
              map(x => {
                const clonedRole = RoleMapper.clone(r);
                clonedRole.permissions = List(x);
                return clonedRole;
              }),
              tap(x => this.updateEntity(entity => (entity.id === r.id ? x : entity)))
            )
      )
    );
  }

  public insertRole(role: RoleEntity): Observable<RoleEntity> {
    return this.rolesServiceBackend.Agregar(RoleMapper.toDTO(role)).pipe(
      map(x => RoleMapper.toEntity(x)),
      concatMap(x =>
        this.rolesServiceBackend
          .GuardarEndpoints(x.id, role.permissions.map(y => PermissionMapper.toDTO(y)).toArray())
          .pipe(map(() => x))
      ),
      tap(x => this.updateEntity(entity => (entity.id === role.id ? x : entity)))
    );
  }

  public updateRole(role: RoleEntity): Observable<RoleEntity> {
    return this.rolesServiceBackend.ActualizarPorId(RoleMapper.toDTO(role), role.id).pipe(
      map(x => RoleMapper.toEntity(x)),
      concatMap(x =>
        this.rolesServiceBackend
          .GuardarEndpoints(x.id, role.permissions.map(y => PermissionMapper.toDTO(y)).toArray())
          .pipe(map(() => x))
      ),
      tap(x => this.updateEntity(entity => (entity.id === role.id ? x : entity)))
    );
  }

  public deleteRole(role: RoleEntity): Observable<void> {
    return this.rolesServiceBackend
      .BorrarPorId(role.id)
      .pipe(tap(() => this.filterEntities(entity => entity.id !== role.id)));
  }
}
