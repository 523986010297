export enum NoteSectionEnum {
  PortfolioTotalCompanies,
  PortfolioTotalValuation,
  PortfolioTotalInvestment,
  PortfolioTotalRaised,
  PortfolioAverageScore,
  PortfolioScoreGrowth,
  PortfolioScoreGrowthBefore,
  PortfolioBestWorstScore,
  StartupNameDescription,
  StartupValuation,
  StartupRaisedCapital,
  StartupIndustry,
  StartupFirstLastScore,
  StartupEbitda,
  StartupRevenue,
  StartupAcquisition,
  StartupARPU,
  StartupMAU,
  StartupCCR,
  StartupMRR,
  StartupRevenueRate,
  StartupRCR,
  StartupYearlyRevenue,
  StartupYearlyExpenses,
  StartupBestWorstTopic,
  Insight,
  Recommendation,
  Cohort,
  CohortStartup,
  PipelineTotalCompanies,
  PipelineTotalPotencialInvestment,
  PipelineTotalCapitalInvested,
  PipelineEvaluatedCompanies
}
