import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';
import { ICompanyBasicDTO, CompanyBasicDTO } from './CompanyBasic.dto';
import { IStartupScoringDTO, StartupScoringDTO } from './StartupScoring.dto';
import { IChoiceVoteDTO, ChoiceVoteDTO } from './ChoiceVote.dto';
import { IStartupCompanyBasicDTO, StartupCompanyBasicDTO } from './StartupCompanyBasic.dto';

export interface IVoteDTO {
  id: number;
  name: string;
  company: ICompanyBasicDTO;
  startups: IStartupScoringDTO[];
  users: number[];
  choices: IChoiceVoteDTO[];
  createdAt: string;
  bestStartup: IStartupCompanyBasicDTO;
  countUsers: number;
}

export class VoteDTO implements IDTO<VoteDTO, IVoteDTO> {
  public id: number = 0;
  public name: string = '';
  public company: CompanyBasicDTO = new CompanyBasicDTO();
  public startups: StartupScoringDTO[] = [];
  public users: number[] = [];
  public choices: ChoiceVoteDTO[] = [];
  public createdAt: Date = new Date();
  public bestStartup: StartupCompanyBasicDTO = new StartupCompanyBasicDTO();
  public countUsers: number = 0;

  constructor(json?: Partial<IVoteDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<IVoteDTO>) {
    if (json.id != null) {
      this.id = json.id;
    }
    if (json.name != null) {
      this.name = json.name;
    }
    if (json.company != null) {
      this.company = new CompanyBasicDTO(json.company);
    }
    if (json.startups != null) {
      this.startups = json.startups.map(item => new StartupScoringDTO(item));
    }
    if (json.users != null) {
      this.users = json.users;
    }
    if (json.choices != null) {
      this.choices = json.choices.map(item => new ChoiceVoteDTO(item));
    }
    if (json.createdAt != null) {
      this.createdAt = new Date(json.createdAt);
    }
    if (json.bestStartup != null) {
      this.bestStartup = new StartupCompanyBasicDTO(json.bestStartup);
    }
    if (json.countUsers != null) {
      this.countUsers = json.countUsers;
    }
  }

  public aJSON(): IVoteDTO {
    return {
      id: this.id,
      name: this.name,
      company: this.company.aJSON(),
      startups: this.startups.map(item => item.aJSON()),
      users: this.users,
      choices: this.choices.map(item => item.aJSON()),
      createdAt: this.createdAt.toJSON(),
      bestStartup: this.bestStartup.aJSON(),
      countUsers: this.countUsers
    };
  }

  public clon<T extends VoteDTO>(clonBase: T): T;
  public clon<T extends VoteDTO>(): VoteDTO;
  public clon<T extends VoteDTO>(clonBase: Nilable<T> = null): T | VoteDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.id = this.id;
      clonBase.name = this.name;
      clonBase.company = this.company.clon();
      clonBase.startups = this.startups.map(item => item.clon());
      clonBase.users = this.users;
      clonBase.choices = this.choices.map(item => item.clon());
      clonBase.createdAt = new Date(this.createdAt.toJSON());
      clonBase.bestStartup = this.bestStartup.clon();
      return clonBase;
    } else {
      const clon = new VoteDTO();
      clon.id = this.id;
      clon.name = this.name;
      clon.company = this.company.clon();
      clon.startups = this.startups.map(item => item.clon());
      clon.users = this.users;
      clon.choices = this.choices.map(item => item.clon());
      clon.createdAt = new Date(this.createdAt.toJSON());
      clon.bestStartup = this.bestStartup.clon();
      clon.countUsers = this.countUsers;
      return clon;
    }
  }
}

export class VoteModelDTO extends ModelDTO<VoteDTO> {
  constructor(entidad: VoteDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Id(): number {
    return this._entidad.id;
  }

  public set Id(valor: number) {
    this.cambiarPropiedadYNotificar('id', valor);
  }

  public get Name(): string {
    return this._entidad.name;
  }

  public set Name(valor: string) {
    this.cambiarPropiedadYNotificar('name', valor);
  }

  public get Company(): CompanyBasicDTO {
    return this._entidad.company;
  }

  public set Company(valor: CompanyBasicDTO) {
    this.cambiarPropiedadYNotificar('company', valor);
  }

  public get Startups(): StartupScoringDTO[] {
    return this._entidad.startups;
  }

  public set Startups(valor: StartupScoringDTO[]) {
    this.cambiarPropiedadYNotificar('startups', valor);
  }

  public get Users(): number[] {
    return this._entidad.users;
  }

  public set Users(valor: number[]) {
    this.cambiarPropiedadYNotificar('users', valor);
  }

  public get Choices(): ChoiceVoteDTO[] {
    return this._entidad.choices;
  }

  public set Choices(valor: ChoiceVoteDTO[]) {
    this.cambiarPropiedadYNotificar('choices', valor);
  }

  public get CreatedAt(): Date {
    return this._entidad.createdAt;
  }

  public set CreatedAt(valor: Date) {
    this.cambiarPropiedadYNotificar('createdAt', valor);
  }

  public get BestStartup(): StartupCompanyBasicDTO {
    return this._entidad.bestStartup;
  }

  public set BestStartup(valor: StartupCompanyBasicDTO) {
    this.cambiarPropiedadYNotificar('bestStartup', valor);
  }

  public get CountUsers(): number {
    return this._entidad.countUsers;
  }

  public set CountUsers(valor: number) {
    this.cambiarPropiedadYNotificar('countUsers', valor);
  }
}
