import { ChangeDetectionStrategy, Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { CardTitleTemplateDirective } from './directives/card-title-template/card-title-template.directive';

@Component({
  selector: 'app-card-with-title',
  templateUrl: './card-with-title.component.html',
  styleUrls: ['./card-with-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardWithTitleComponent {
  @Input() public title: string | null = null;
  @Input() public backgroundWithColor = false;

  @ContentChild(CardTitleTemplateDirective, { read: TemplateRef })
  public readonly cardTitleTemplate: TemplateRef<any>;
}
