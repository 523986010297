export abstract class UsersUtils {
  public abstract isVCAdmin(): boolean;
  public abstract isVCAnalyst(): boolean;
  public abstract isVCAssessment(): boolean;
  public abstract isVC(): boolean;
  public abstract isStartupAdmin(): boolean;
  public abstract isStartupAnalyst(): boolean;
  public abstract isStartupAssessment(): boolean;
  public abstract isStartupFree(): boolean;
  public abstract isStartup(): boolean;
  public abstract isNinetyAdmin(): boolean;
  public abstract isNinetySupport(): boolean;
  public abstract isNinetyAssessment(): boolean;
  public abstract isNinety(): boolean;
  public abstract isAnalyst(): boolean;
  public abstract isAssessment(): boolean;
  public abstract isAdmin(): boolean;
  public abstract isCompany(): boolean;
  public abstract isAnyUser(): boolean;
  public abstract isDemo(): boolean;
  public abstract isVCAdvisor(): boolean;
  public abstract isVCMentor(): boolean;
  public abstract isVCJury(): boolean;
  public abstract isVCAuditor(): boolean;
  public abstract isStartupAdvisor(): boolean;
  public abstract isStartupMentor(): boolean;
  public abstract isStartupAuditor(): boolean;
  public abstract isUserProfessional(): boolean;
}
