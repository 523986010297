import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';

export interface IBasicDataUserProfesionalDTO {
  name: string;
  lastName: string;
  startups: number[];
}

export class BasicDataUserProfesionalDTO implements IDTO<BasicDataUserProfesionalDTO, IBasicDataUserProfesionalDTO> {
  public name: string = '';
  public lastName: string = '';
  public startups: number[] = [];

  constructor(json?: Partial<IBasicDataUserProfesionalDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<IBasicDataUserProfesionalDTO>) {
    if (json.name != null) {
      this.name = json.name;
    }
    if (json.lastName != null) {
      this.lastName = json.lastName;
    }
    if (json.startups != null) {
      this.startups = json.startups;
    }
  }

  public aJSON(): IBasicDataUserProfesionalDTO {
    return {
      name: this.name,
      lastName: this.lastName,
      startups: this.startups
    };
  }

  public clon<T extends BasicDataUserProfesionalDTO>(clonBase: T): T;
  public clon<T extends BasicDataUserProfesionalDTO>(): BasicDataUserProfesionalDTO;
  public clon<T extends BasicDataUserProfesionalDTO>(clonBase: Nilable<T> = null): T | BasicDataUserProfesionalDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.name = this.name;
      clonBase.lastName = this.lastName;
      clonBase.startups = this.startups;
      return clonBase;
    } else {
      const clon = new BasicDataUserProfesionalDTO();
      clon.name = this.name;
      clon.lastName = this.lastName;
      clon.startups = this.startups;
      return clon;
    }
  }
}

export class BasicDataUserProfesionalModelDTO extends ModelDTO<BasicDataUserProfesionalDTO> {
  constructor(entidad: BasicDataUserProfesionalDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Name(): string {
    return this._entidad.name;
  }

  public set Name(valor: string) {
    this.cambiarPropiedadYNotificar('name', valor);
  }

  public get LastName(): string {
    return this._entidad.lastName;
  }

  public set LastName(valor: string) {
    this.cambiarPropiedadYNotificar('lastName', valor);
  }

  public get Startups(): number[] {
    return this._entidad.startups;
  }

  public set Startups(valor: number[]) {
    this.cambiarPropiedadYNotificar('startups', valor);
  }
}
