import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';
import { StartupTypeEnum } from './StartupType.enum';

export interface IAverageScorePipelineDTO {
  stage: number;
  percent: number;
  count: number;
  score: number;
  archivedCount: number;
  archivedScore: number;
}

export class AverageScorePipelineDTO implements IDTO<AverageScorePipelineDTO, IAverageScorePipelineDTO> {
  public stage: StartupTypeEnum = StartupTypeEnum.Lead;
  public percent: number = 0;
  public count: number = 0;
  public score: number = 0;
  public archivedCount: number = 0;
  public archivedScore: number = 0;

  constructor(json?: Partial<IAverageScorePipelineDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<IAverageScorePipelineDTO>) {
    if (json.stage != null) {
      this.stage = json.stage as StartupTypeEnum;
    }
    if (json.percent != null) {
      this.percent = json.percent;
    }
    if (json.count != null) {
      this.count = json.count;
    }
    if (json.score != null) {
      this.score = json.score;
    }
    if (json.archivedCount != null) {
      this.archivedCount = json.archivedCount;
    }
    if (json.archivedScore != null) {
      this.archivedScore = json.archivedScore;
    }
  }

  public aJSON(): IAverageScorePipelineDTO {
    return {
      stage: this.stage,
      percent: this.percent,
      count: this.count,
      score: this.score,
      archivedCount: this.archivedCount,
      archivedScore: this.archivedScore
    };
  }

  public clon<T extends AverageScorePipelineDTO>(clonBase: T): T;
  public clon<T extends AverageScorePipelineDTO>(): AverageScorePipelineDTO;
  public clon<T extends AverageScorePipelineDTO>(clonBase: Nilable<T> = null): T | AverageScorePipelineDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.stage = this.stage;
      clonBase.percent = this.percent;
      clonBase.count = this.count;
      clonBase.score = this.score;
      clonBase.archivedCount = this.archivedCount;
      clonBase.archivedScore = this.archivedScore;
      return clonBase;
    } else {
      const clon = new AverageScorePipelineDTO();
      clon.stage = this.stage;
      clon.percent = this.percent;
      clon.count = this.count;
      clon.score = this.score;
      clon.archivedCount = this.archivedCount;
      clon.archivedScore = this.archivedScore;
      return clon;
    }
  }
}

export class AverageScorePipelineModelDTO extends ModelDTO<AverageScorePipelineDTO> {
  constructor(entidad: AverageScorePipelineDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Stage(): StartupTypeEnum {
    return this._entidad.stage;
  }

  public set Stage(valor: StartupTypeEnum) {
    this.cambiarPropiedadYNotificar('stage', valor);
  }

  public get Percent(): number {
    return this._entidad.percent;
  }

  public set Percent(valor: number) {
    this.cambiarPropiedadYNotificar('percent', valor);
  }

  public get Count(): number {
    return this._entidad.count;
  }

  public set Count(valor: number) {
    this.cambiarPropiedadYNotificar('count', valor);
  }

  public get Score(): number {
    return this._entidad.score;
  }

  public set Score(valor: number) {
    this.cambiarPropiedadYNotificar('score', valor);
  }

  public get ArchivedCount(): number {
    return this._entidad.archivedCount;
  }

  public set ArchivedCount(valor: number) {
    this.cambiarPropiedadYNotificar('archivedCount', valor);
  }

  public get ArchivedScore(): number {
    return this._entidad.archivedScore;
  }

  public set ArchivedScore(valor: number) {
    this.cambiarPropiedadYNotificar('archivedScore', valor);
  }
}
