import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Constants } from '../shared/constants';

import { ICompanySettingsDTO, CompanySettingsDTO } from '../DTO/CompanySettings.dto';

@Injectable({
  providedIn: 'root'
})
export class SettingsServicioBackend {
  constructor(protected http: HttpClient) {}

  public GetCompanyConfigurations(companyId: number, configs: string): Observable<CompanySettingsDTO> {
    const params = new HttpParams().append('configs', configs);
    const opciones = { params };
    return this.http
      .get<ICompanySettingsDTO>(`${Constants.apiURL}/settings/company/${companyId}`, opciones)
      .pipe(map(entidad => new CompanySettingsDTO(entidad)));
  }

  public SaveCompanyConfigurations(companySettingsDTO: CompanySettingsDTO, companyId: number): Observable<void> {
    return this.http.post<void>(`${Constants.apiURL}/settings/company/${companyId}`, companySettingsDTO);
  }
}
