import { Pipe, PipeTransform } from '@angular/core';
import { SearchFilterVCEnum } from '../../enums/SearchFilterVC.enum';

@Pipe({
  name: 'searchFilterVCName'
})
export class SearchFilterVCNamePipe implements PipeTransform {
  transform(searchFilter: SearchFilterVCEnum): string {
    switch (searchFilter) {
      case SearchFilterVCEnum.FOUNDERS:
        return 'Founders';
      case SearchFilterVCEnum.INDUSTRIES:
        return 'Industries';
      case SearchFilterVCEnum.HEADQUARTERS:
        return 'Headquarters';
      case SearchFilterVCEnum.TOTAL_FOUNDING_AMOUNT:
        return 'Total Founding Amount';
      case SearchFilterVCEnum.CURRENT_FOUNDING_TYPE:
        return 'Current Founding Type';
      case SearchFilterVCEnum.EMPLOYEES:
        return 'Employees';
      case SearchFilterVCEnum.INVESTMENT_RULES:
        return 'My Investment Rules';
      case SearchFilterVCEnum.CUSTOM:
        return 'Custom';
      case SearchFilterVCEnum.SCORING:
        return 'Scoring';
    }
  }
}
