import { IDTO, ModelDTO, IUsuarioDTO, UsuarioDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';
import { IStartupCompanyBasicDTO, StartupCompanyBasicDTO } from './StartupCompanyBasic.dto';

export interface IChoiceVoteDTO {
  id: number;
  user: IUsuarioDTO;
  score: number;
  comment: string;
  startup: IStartupCompanyBasicDTO;
}

export class ChoiceVoteDTO implements IDTO<ChoiceVoteDTO, IChoiceVoteDTO> {
  public id: number = 0;
  public user: UsuarioDTO = new UsuarioDTO();
  public score: number = 0;
  public comment: string = '';
  public startup: StartupCompanyBasicDTO = new StartupCompanyBasicDTO();

  constructor(json?: Partial<IChoiceVoteDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<IChoiceVoteDTO>) {
    if (json.id != null) {
      this.id = json.id;
    }
    if (json.user != null) {
      this.user = new UsuarioDTO(json.user);
    }
    if (json.score != null) {
      this.score = json.score;
    }
    if (json.comment != null) {
      this.comment = json.comment;
    }
    if (json.startup != null) {
      this.startup = new StartupCompanyBasicDTO(json.startup);
    }
  }

  public aJSON(): IChoiceVoteDTO {
    return {
      id: this.id,
      user: this.user.aJSON(),
      score: this.score,
      comment: this.comment,
      startup: this.startup.aJSON()
    };
  }

  public clon<T extends ChoiceVoteDTO>(clonBase: T): T;
  public clon<T extends ChoiceVoteDTO>(): ChoiceVoteDTO;
  public clon<T extends ChoiceVoteDTO>(clonBase: Nilable<T> = null): T | ChoiceVoteDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.id = this.id;
      clonBase.user = this.user.clon();
      clonBase.score = this.score;
      clonBase.comment = this.comment;
      clonBase.startup = this.startup.clon();
      return clonBase;
    } else {
      const clon = new ChoiceVoteDTO();
      clon.id = this.id;
      clon.user = this.user.clon();
      clon.score = this.score;
      clon.comment = this.comment;
      clon.startup = this.startup.clon();
      return clon;
    }
  }
}

export class ChoiceVoteModelDTO extends ModelDTO<ChoiceVoteDTO> {
  constructor(entidad: ChoiceVoteDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Id(): number {
    return this._entidad.id;
  }

  public set Id(valor: number) {
    this.cambiarPropiedadYNotificar('id', valor);
  }

  public get User(): UsuarioDTO {
    return this._entidad.user;
  }

  public set User(valor: UsuarioDTO) {
    this.cambiarPropiedadYNotificar('user', valor);
  }

  public get Score(): number {
    return this._entidad.score;
  }

  public set Score(valor: number) {
    this.cambiarPropiedadYNotificar('score', valor);
  }

  public get Comment(): string {
    return this._entidad.comment;
  }

  public set Comment(valor: string) {
    this.cambiarPropiedadYNotificar('comment', valor);
  }

  public get Startup(): StartupCompanyBasicDTO {
    return this._entidad.startup;
  }

  public set Startup(valor: StartupCompanyBasicDTO) {
    this.cambiarPropiedadYNotificar('startup', valor);
  }
}
