import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { NotesService } from '../services/notes/notes.service';
import { CommandAsync, ICommand } from '@nahuelmorata/ngx-command';
import { firstValueFrom } from 'rxjs';
import { NoteDTO, NoteSectionEnum } from '@DTOS';
import { UsersUtils } from 'src/app/shared/utils/users/Users.utils';

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoteComponent implements OnChanges {
  @Input() public note: NoteDTO | null = null;
  @Input() public section: NoteSectionEnum | null = null;
  @Input() public isMy = false;
  @Input() public edit = false;
  @Output() public changeNote = new EventEmitter<NoteDTO>();
  @Output() public removeNote = new EventEmitter<void>();

  public isDemo = inject(UsersUtils).isDemo();

  public textControl = new UntypedFormControl('', Validators.required);

  public saveCmd: ICommand = new CommandAsync(() => this.save());
  public deleteCmd: ICommand = new CommandAsync(() => this.delete());

  constructor(private noteService: NotesService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.note != null && this.note != null) {
      this.textControl.setValue(this.note.text);
    }
  }

  private async delete() {
    await firstValueFrom(this.noteService.deleteNote(this.note));
    this.removeNote.emit();
  }

  private async save() {
    if (this.note != null) {
      await this.update();
    } else {
      await this.add();
    }
  }

  private async update() {
    if (this.textControl.invalid) {
      return;
    }
    const note = new NoteDTO();
    note.cargarDTO({
      ...this.note,
      timeCreated: this.note.timeCreated.toJSON()
    });
    note.text = this.textControl.value;
    const newNote = await firstValueFrom(this.noteService.updateNote(note));
    this.changeNote.emit(newNote);
  }

  private async add() {
    if (this.textControl.invalid) {
      return;
    }
    const note = new NoteDTO();
    note.text = this.textControl.value;
    note.section = this.section;
    const newNote = await firstValueFrom(this.noteService.addNote(note));
    this.changeNote.emit(newNote);
  }
}
