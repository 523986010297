import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { QuestionAnswerItemModel } from './question/question-answer-item.model';
import { TopicQuestionsModel } from './topic-questions.model';
import { Select } from '@ngxs/store';
import { State } from 'src/app/shared/store/State';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-topic-interview',
  templateUrl: './topic-interview.component.html',
  styleUrls: ['./topic-interview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopicInterviewComponent {
  public topic = input.required<TopicQuestionsModel>();
  public topicName = input.required<string>();
  public variablesShow = input.required<number[]>();
  @Select((state: State) => state.global.isMobile)
  public isMobile$: Observable<boolean>;

  protected filterShow(item: QuestionAnswerItemModel, variablesShow: number[]): boolean {
    return variablesShow?.includes(item.question.id);
  }
}
