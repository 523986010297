import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-url-modal',
  templateUrl: './url-modal.component.html',
  styleUrls: ['./url-modal.component.scss']
})
export class UrlModalComponent {
  public placeholder = '';
  public title = '';

  protected formControl = new FormControl<string>('');

  constructor(private dialogRef: MatDialogRef<UrlModalComponent>) {}

  public cancel() {
    this.dialogRef.close();
  }

  public send() {
    this.dialogRef.close(this.formControl.value);
  }
}
