import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'joinArray'
})
export class JoinArrayPipe implements PipeTransform {
  transform<T>(arreglo: T[], separator: string, mapItems?: (item: T) => string): string {
    if (mapItems == null) {
      return arreglo.join(separator);
    }
    return arreglo.map(mapItems).join(separator);
  }
}
