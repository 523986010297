import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sliceIndex'
})
export class SliceIndexPipe implements PipeTransform {
  transform<T>(array: T[], index: number): T[] {
    return [...array.slice(0, index), ...array.slice(index + 1)];
  }
}
