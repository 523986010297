import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';

export interface IIndustryDTO {
  id: number;
  name: string;
}

export class IndustryDTO implements IDTO<IndustryDTO, IIndustryDTO> {
  public id: number = 0;
  public name: string = '';

  constructor(json?: Partial<IIndustryDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<IIndustryDTO>) {
    if (json.id != null) {
      this.id = json.id;
    }
    if (json.name != null) {
      this.name = json.name;
    }
  }

  public aJSON(): IIndustryDTO {
    return {
      id: this.id,
      name: this.name
    };
  }

  public clon<T extends IndustryDTO>(clonBase: T): T;
  public clon<T extends IndustryDTO>(): IndustryDTO;
  public clon<T extends IndustryDTO>(clonBase: Nilable<T> = null): T | IndustryDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.id = this.id;
      clonBase.name = this.name;
      return clonBase;
    } else {
      const clon = new IndustryDTO();
      clon.id = this.id;
      clon.name = this.name;
      return clon;
    }
  }
}

export class IndustryModelDTO extends ModelDTO<IndustryDTO> {
  constructor(entidad: IndustryDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Id(): number {
    return this._entidad.id;
  }

  public set Id(valor: number) {
    this.cambiarPropiedadYNotificar('id', valor);
  }

  public get Name(): string {
    return this._entidad.name;
  }

  public set Name(valor: string) {
    this.cambiarPropiedadYNotificar('name', valor);
  }
}
