import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  input,
  OnChanges,
  output,
  SimpleChanges
} from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { StartupSummaryDTO } from '@DTOS';
import { StartupsService } from '../services/startups.service';

@Component({
  selector: 'app-summary-startup',
  templateUrl: './summary-startup.component.html',
  styleUrls: ['./summary-startup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SummaryStartupComponent implements OnChanges {
  public startupId = input.required<number>();
  public isMobile = input<boolean>();
  public viewMore = output();

  public startupSummary = new StartupSummaryDTO();
  private changeDetectorRef = inject(ChangeDetectorRef);
  private startupsService = inject(StartupsService);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.startupId != null) {
      void this.loadStartup(changes.startupId.currentValue);
    }
  }

  public formatNumber(value: number) {
    if (value > 1000000) {
      return `${value / 1000000}M`;
    }
    if (value > 1000) {
      return `${value / 1000}K`;
    }
  }

  private async loadStartup(startupId: number) {
    this.startupSummary = await firstValueFrom(this.startupsService.getStartupSummary(startupId));
    this.changeDetectorRef.detectChanges();
  }
}
