import { Pipe, PipeTransform } from '@angular/core';
import { VariableCompanyProfileEnum } from '@DTOS';
import { formatCompanyProfile } from '../../utils/CompanyProfile.utils';

@Pipe({
  name: 'companyProfileRule'
})
export class CompanyProfileRulePipe implements PipeTransform {
  transform(startupProfile: VariableCompanyProfileEnum): string {
    return formatCompanyProfile(startupProfile);
  }
}
