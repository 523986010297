import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';
import { ISearchCompanyDTO, SearchCompanyDTO } from './SearchCompany.dto';

export interface ISearchCompanySizeDTO {
  companies: ISearchCompanyDTO[];
  size: number;
}

export class SearchCompanySizeDTO implements IDTO<SearchCompanySizeDTO, ISearchCompanySizeDTO> {
  public companies: SearchCompanyDTO[] = [];
  public size: number = 0;

  constructor(json?: Partial<ISearchCompanySizeDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<ISearchCompanySizeDTO>) {
    if (json.companies != null) {
      this.companies = json.companies.map(item => new SearchCompanyDTO(item));
    }
    if (json.size != null) {
      this.size = json.size;
    }
  }

  public aJSON(): ISearchCompanySizeDTO {
    return {
      companies: this.companies.map(item => item.aJSON()),
      size: this.size
    };
  }

  public clon<T extends SearchCompanySizeDTO>(clonBase: T): T;
  public clon<T extends SearchCompanySizeDTO>(): SearchCompanySizeDTO;
  public clon<T extends SearchCompanySizeDTO>(clonBase: Nilable<T> = null): T | SearchCompanySizeDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.companies = this.companies.map(item => item.clon());
      clonBase.size = this.size;
      return clonBase;
    } else {
      const clon = new SearchCompanySizeDTO();
      clon.companies = this.companies.map(item => item.clon());
      clon.size = this.size;
      return clon;
    }
  }
}

export class SearchCompanySizeModelDTO extends ModelDTO<SearchCompanySizeDTO> {
  constructor(entidad: SearchCompanySizeDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Companies(): SearchCompanyDTO[] {
    return this._entidad.companies;
  }

  public set Companies(valor: SearchCompanyDTO[]) {
    this.cambiarPropiedadYNotificar('companies', valor);
  }

  public get Size(): number {
    return this._entidad.size;
  }

  public set Size(valor: number) {
    this.cambiarPropiedadYNotificar('size', valor);
  }
}
