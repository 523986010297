import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'intlFormat'
})
export class IntlFormatPipe implements PipeTransform {
  transform(date: Date): string {
    return new Intl.DateTimeFormat(undefined, {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }).format(date);
  }
}
