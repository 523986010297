import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IListaDTO, ListaDTO } from '@nahuelmorata/framework-frontend';
import { Constants } from '../shared/constants';

import { IUserProfessionalBasicDTO, UserProfessionalBasicDTO } from '../DTO/UserProfessionalBasic.dto';
import { CreateUserProfessionalDTO } from '../DTO/CreateUserProfessional.dto';
import { IUserProfessionalDTO, UserProfessionalDTO } from '../DTO/UserProfessional.dto';

@Injectable({
  providedIn: 'root'
})
export class UserProfessionalServicioBackend {
  constructor(protected http: HttpClient) {}

  public GetAllUserProfessionals(): Observable<ListaDTO<UserProfessionalBasicDTO>> {
    return this.http.get<IListaDTO<IUserProfessionalBasicDTO>>(`${Constants.apiURL}/user-professional`).pipe(
      map(
        lista =>
          new ListaDTO({
            items: lista.items.map(item => new UserProfessionalBasicDTO(item)),
            cantidad: lista.cantidad
          })
      )
    );
  }

  public CreateUserProfessional(
    createUserProfessionalDTO: CreateUserProfessionalDTO
  ): Observable<UserProfessionalBasicDTO> {
    return this.http
      .post<IUserProfessionalBasicDTO>(`${Constants.apiURL}/user-professional`, createUserProfessionalDTO)
      .pipe(map(entidad => new UserProfessionalBasicDTO(entidad)));
  }

  public GetUserProfessional(userId: number): Observable<UserProfessionalDTO> {
    return this.http
      .get<IUserProfessionalDTO>(`${Constants.apiURL}/user-professional/${userId}`)
      .pipe(map(entidad => new UserProfessionalDTO(entidad)));
  }

  public UpdateUserProfessional(
    createUserProfessionalDTO: CreateUserProfessionalDTO,
    userId: number
  ): Observable<UserProfessionalBasicDTO> {
    return this.http
      .put<IUserProfessionalBasicDTO>(`${Constants.apiURL}/user-professional/${userId}`, createUserProfessionalDTO)
      .pipe(map(entidad => new UserProfessionalBasicDTO(entidad)));
  }

  public DeleteUserProfessional(userId: number): Observable<void> {
    return this.http.delete<void>(`${Constants.apiURL}/user-professional/${userId}`);
  }
}
