import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BaseCardComponent } from './base-card/base-card.component';
import { WarningCardComponent } from './warning-card/warning-card.component';
import { SortTopicPipe } from './pipes/sort-topic.pipe';
import { SortQuestionAnswerPipe } from './pipes/sort-question-answer.pipe';
import { MinOptionRangePipe } from './pipes/min-option-range.pipe';
import { MaxOptionRangePipe } from './pipes/max-option-range.pipe';
import { FormatMomentPipe } from './pipes/format-moment/format-moment.pipe';
import { JoinArrayPipe } from './pipes/join-array/join-array.pipe';
import { IsPipelinePipe } from './pipes/is-pipeline/is-pipeline.pipe';
import { StartupTypePipe } from './pipes/startup-type/startup-type.pipe';
import { MaxLengthDirective } from './directives/max-length/max-length.directive';
import { AutofocusDirective } from './directives/autofocus/autofocus.directive';
import { OnReturnDirective } from './directives/on-return/on-return.directive';
import { StopPropagationDirective } from './directives/stop-propagation/stop-propagation.directive';
import { NgxCommandModule } from '@nahuelmorata/ngx-command';
import { ListKeydownDirective } from './directives/list-keydown/list-keydown.directive';
import { ConnectionTimeRemainingPipe } from './pipes/connection-time-remaining/connection-time-remaining.pipe';
import { MapArrayPipe } from './pipes/map-array/map-array.pipe';
import { ProfitTypePipe } from './pipes/profit-type/profit-type.pipe';
import { OperatingStatusPipe } from './pipes/operating-status/operating-status.pipe';
import { IntlFormatPipe } from './pipes/intl-format/intl-format.pipe';
import { TextToHtmlPipe } from './pipes/text-to-html/text-to-html.pipe';
import { CompanyRuleTypePipe } from './pipes/company-rule-type/company-rule-type.pipe';
import { OperatorRulePipe } from './pipes/operator-rule/operator-rule.pipe';
import { CompanyRuleVariablePipe } from './pipes/company-rule-variable/company-rule-variable.pipe';
import { CompanyRuleValuePipe } from './pipes/company-rule-value/company-rule-value.pipe';
import { StartupProfileRulePipe } from './pipes/startup-profile-rule/startup-profile-rule.pipe';
import { FilterArrayPipe } from './pipes/filter-array/filter-array.pipe';
import { FoundingTypePipe } from './pipes/founding-type/founding-type.pipe';
import { SearchFilterVCNamePipe } from './pipes/search-filter-vc-name/search-filter-vc-name.pipe';
import { FormatPipe } from './pipes/format/format.pipe';
import { AnyArrayPipe } from './pipes/any-array/any-array.pipe';
import { TimeRemainingPipe } from './pipes/time-remaining/time-remaining.pipe';
import { ScoringFormatPipe } from './pipes/scoring-format/scoring-format.pipe';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { SearchFilterStartupNamePipe } from './pipes/search-filter-startup-name/search-filter-startup-name.pipe';
import { StartupRuleTypePipe } from './pipes/startup-rule-type/startup-rule-type.pipe';
import { CompanyProfileRulePipe } from './pipes/company-profile-rule/company-profile-rule.pipe';
import { ChunkArrayPipe } from './pipes/chunk-array/chunk-array.pipe';
import { IsEmptyValuePipe } from './pipes/is-empty-value/is-empty-value.pipe';
import { SliceIndexPipe } from './pipes/slice-index/slice-index.pipe';
import { AddBaseUrlPipe } from './pipes/add-base-url/add-base-url.pipe';
import { ReduceArrayPipe } from './pipes/reduce-array/reduce-array.pipe';

const pipes = [
  SortTopicPipe,
  SortQuestionAnswerPipe,
  MinOptionRangePipe,
  MaxOptionRangePipe,
  FormatMomentPipe,
  JoinArrayPipe,
  IsPipelinePipe,
  StartupTypePipe,
  ConnectionTimeRemainingPipe,
  MapArrayPipe,
  ProfitTypePipe,
  OperatingStatusPipe,
  FoundingTypePipe,
  IntlFormatPipe,
  CompanyRuleTypePipe,
  OperatorRulePipe,
  CompanyRuleVariablePipe,
  CompanyRuleValuePipe,
  FoundingTypePipe,
  IntlFormatPipe,
  TextToHtmlPipe,
  StartupProfileRulePipe,
  FilterArrayPipe,
  FoundingTypePipe,
  SearchFilterVCNamePipe,
  FormatPipe,
  AnyArrayPipe,
  TimeRemainingPipe,
  ScoringFormatPipe,
  SearchFilterStartupNamePipe,
  StartupRuleTypePipe,
  CompanyProfileRulePipe,
  ChunkArrayPipe,
  IsEmptyValuePipe,
  SliceIndexPipe,
  AddBaseUrlPipe,
  ReduceArrayPipe
];

const directives = [
  MaxLengthDirective,
  AutofocusDirective,
  OnReturnDirective,
  StopPropagationDirective,
  ListKeydownDirective
];

@NgModule({
  declarations: [BaseCardComponent, WarningCardComponent, ...pipes, ...directives],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, NgxCommandModule, MatDividerModule, MatCardModule],
  exports: [BaseCardComponent, WarningCardComponent, ...pipes, ...directives]
})
export class SharedModule {}
