import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IListaDTO, ListaDTO } from '@nahuelmorata/framework-frontend';
import { Constants } from '../shared/constants';

import { ITopicDTO, TopicDTO } from '../DTO/Topic.dto';

@Injectable({
  providedIn: 'root'
})
export class TopicServicioBackend {
  constructor(protected http: HttpClient) {}

  public GetEntidad(id: number): Observable<TopicDTO> {
    return this.http.get<ITopicDTO>(`${Constants.apiURL}/topics/${id}`).pipe(map(entidad => new TopicDTO(entidad)));
  }

  public PutEntidad(topicDTO: TopicDTO, id: number): Observable<TopicDTO> {
    return this.http
      .put<ITopicDTO>(`${Constants.apiURL}/topics/${id}`, topicDTO)
      .pipe(map(entidad => new TopicDTO(entidad)));
  }

  public DeleteEntidad(id: number): Observable<void> {
    return this.http.delete<void>(`${Constants.apiURL}/topics/${id}`);
  }

  public GetEntidades(offset: number = 0, limit: number = 0): Observable<ListaDTO<TopicDTO>> {
    const params = new HttpParams().append('offset', offset.toString()).append('limit', limit.toString());
    const opciones = { params };
    return this.http.get<IListaDTO<ITopicDTO>>(`${Constants.apiURL}/topics`, opciones).pipe(
      map(
        lista =>
          new ListaDTO({
            items: lista.items.map(item => new TopicDTO(item)),
            cantidad: lista.cantidad
          })
      )
    );
  }

  public PostEntidad(topicDTO: TopicDTO): Observable<TopicDTO> {
    return this.http
      .post<ITopicDTO>(`${Constants.apiURL}/topics`, topicDTO)
      .pipe(map(entidad => new TopicDTO(entidad)));
  }
}
