import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';

export interface ISessionInfoDTO {
  invoiceUrl: string;
}

export class SessionInfoDTO implements IDTO<SessionInfoDTO, ISessionInfoDTO> {
  public invoiceUrl: string = '';

  constructor(json?: Partial<ISessionInfoDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<ISessionInfoDTO>) {
    if (json.invoiceUrl != null) {
      this.invoiceUrl = json.invoiceUrl;
    }
  }

  public aJSON(): ISessionInfoDTO {
    return {
      invoiceUrl: this.invoiceUrl
    };
  }

  public clon<T extends SessionInfoDTO>(clonBase: T): T;
  public clon<T extends SessionInfoDTO>(): SessionInfoDTO;
  public clon<T extends SessionInfoDTO>(clonBase: Nilable<T> = null): T | SessionInfoDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.invoiceUrl = this.invoiceUrl;
      return clonBase;
    } else {
      const clon = new SessionInfoDTO();
      clon.invoiceUrl = this.invoiceUrl;
      return clon;
    }
  }
}

export class SessionInfoModelDTO extends ModelDTO<SessionInfoDTO> {
  constructor(entidad: SessionInfoDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get InvoiceUrl(): string {
    return this._entidad.invoiceUrl;
  }

  public set InvoiceUrl(valor: string) {
    this.cambiarPropiedadYNotificar('invoiceUrl', valor);
  }
}
