import { QuestionAnswerItemModel } from './question/question-answer-item.model';
import { VariableTypeEnum } from '@DTOS';

export class TopicQuestionsModel {
  get PartialScore(): number {
    let sum = 0;
    for (const qa of this.questionsAnswers) {
      if (qa.question.type === VariableTypeEnum.Range) {
        const rangeOption = qa.question.rangeOptions.find(
          x => x.min <= qa.answer.valueNumber && qa.answer.valueNumber <= x.max
        );
        if (rangeOption) {
          sum += rangeOption.weight;
        }
      } else if (qa.question.type === VariableTypeEnum.Select) {
        const selectOption = qa.question.selectOptions.find(x => x.id === qa.answer.valueNumber);
        if (selectOption) {
          sum += selectOption.weight;
        }
      }
    }
    sum = sum / this.questionsAnswers.length;
    return sum;
  }

  public questionsAnswers: Array<QuestionAnswerItemModel>;

  constructor() {
    this.questionsAnswers = new Array<QuestionAnswerItemModel>();
  }
}
