import { CompanyUserStartupDTO } from '@DTOS';
import { CompanyEntity } from '@domain/company';
import { UserEntity } from '@domain/user';
import { Observable } from 'rxjs';

export abstract class CompanyPort {
  company$: Observable<CompanyEntity>;
  abstract toggleUserStartups(
    company: CompanyEntity,
    user: UserEntity,
    companyUserStartupDTO: CompanyUserStartupDTO
  ): Observable<void>;
}
