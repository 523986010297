import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IArchivoHostDTO, ArchivoHostDTO } from '@nahuelmorata/framework-frontend';
import { Constants } from '../shared/constants';

@Injectable({
  providedIn: 'root'
})
export class FileServicioBackend {
  constructor(protected http: HttpClient) {}

  public UploadFile(formData: FormData): Observable<ArchivoHostDTO> {
    return this.http
      .post<IArchivoHostDTO>(`${Constants.apiURL}/files`, formData)
      .pipe(map(entidad => new ArchivoHostDTO(entidad)));
  }

  public DownloadFile(fileId: number): Observable<ArrayBuffer> {
    return this.http.get(`${Constants.apiURL}/files/${fileId}`, { responseType: 'arraybuffer' });
  }
}
