import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';
import { ITopicDTO, TopicDTO } from './Topic.dto';
import { IMonthlyScoreDTO, MonthlyScoreDTO } from './MonthlyScore.dto';

export interface IStartupAnalyticsTopicDTO {
  topic: ITopicDTO;
  topicsScore: IMonthlyScoreDTO[];
}

export class StartupAnalyticsTopicDTO implements IDTO<StartupAnalyticsTopicDTO, IStartupAnalyticsTopicDTO> {
  public topic: TopicDTO = new TopicDTO();
  public topicsScore: MonthlyScoreDTO[] = [];

  constructor(json?: Partial<IStartupAnalyticsTopicDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<IStartupAnalyticsTopicDTO>) {
    if (json.topic != null) {
      this.topic = new TopicDTO(json.topic);
    }
    if (json.topicsScore != null) {
      this.topicsScore = json.topicsScore.map(item => new MonthlyScoreDTO(item));
    }
  }

  public aJSON(): IStartupAnalyticsTopicDTO {
    return {
      topic: this.topic.aJSON(),
      topicsScore: this.topicsScore.map(item => item.aJSON())
    };
  }

  public clon<T extends StartupAnalyticsTopicDTO>(clonBase: T): T;
  public clon<T extends StartupAnalyticsTopicDTO>(): StartupAnalyticsTopicDTO;
  public clon<T extends StartupAnalyticsTopicDTO>(clonBase: Nilable<T> = null): T | StartupAnalyticsTopicDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.topic = this.topic.clon();
      clonBase.topicsScore = this.topicsScore.map(item => item.clon());
      return clonBase;
    } else {
      const clon = new StartupAnalyticsTopicDTO();
      clon.topic = this.topic.clon();
      clon.topicsScore = this.topicsScore.map(item => item.clon());
      return clon;
    }
  }
}

export class StartupAnalyticsTopicModelDTO extends ModelDTO<StartupAnalyticsTopicDTO> {
  constructor(entidad: StartupAnalyticsTopicDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Topic(): TopicDTO {
    return this._entidad.topic;
  }

  public set Topic(valor: TopicDTO) {
    this.cambiarPropiedadYNotificar('topic', valor);
  }

  public get TopicsScore(): MonthlyScoreDTO[] {
    return this._entidad.topicsScore;
  }

  public set TopicsScore(valor: MonthlyScoreDTO[]) {
    this.cambiarPropiedadYNotificar('topicsScore', valor);
  }
}
