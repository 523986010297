import { Provider } from '@angular/core';
import { UsersImplUtils } from './utils/users/implementations/UsersImpl.utils';
import { UsersUtils } from './utils/users/Users.utils';
import { SearchCompanyDTO, SearchStartupDTO } from '@DTOS';
import { CacheService } from './services/cache/cache.service';
import { HashidsToken } from './tokens/HashidsToken';
import { SearchStartupCacheToken } from './tokens/SearchStartupCacheToken';

import Hashids from 'hashids';
import { SearchVCCacheToken } from './tokens/SearchVCCacheToken';
import { MatPaginatorDefaultOptions, MAT_PAGINATOR_DEFAULT_OPTIONS } from '@angular/material/paginator';

export const providers: Provider[] = [
  { provide: UsersUtils, useClass: UsersImplUtils },
  {
    provide: SearchStartupCacheToken,
    useValue: CacheService.build<SearchStartupDTO>((x, y) => x.id === y.id)
  },
  {
    provide: SearchVCCacheToken,
    useValue: CacheService.build<SearchCompanyDTO>((x, y) => x.id === y.id)
  },
  {
    provide: HashidsToken,
    useValue: new Hashids()
  },
  {
    provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
    useValue: {
      showFirstLastButtons: true,
      pageSize: 10,
      pageSizeOptions: [5, 10, 25, 50, 100]
    } as MatPaginatorDefaultOptions
  }
];
