<h2 mat-dialog-title>Startup profile</h2>
<mat-dialog-content>
  <div class="flex grow items-center justify-center" *ngIf="loading">
    <mat-spinner></mat-spinner>
  </div>
  <ng-container *ngIf="!loading">
    <app-startup-profile [dashboardAnalytics]="dashboardAnalytics" [startup]="startup"></app-startup-profile>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-stroked-button color="primary" mat-dialog-close>Close</button>
</mat-dialog-actions>
