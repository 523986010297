import { ApplicationRef, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { concat, filter, first, interval, Subscription } from 'rxjs';
import { NotifierService } from '@nahuelmorata/angular-notifier';
import { environment } from '../environments/environment';
import { Store } from '@ngxs/store';
import { GlobalActions } from './shared/store/actions';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private subs = new Subscription();
  private gtmService: GoogleTagManagerService | null = environment.production ? inject(GoogleTagManagerService) : null;

  private readonly breakpointObserver = inject(BreakpointObserver);

  constructor(
    private applicationRef: ApplicationRef,
    private swUpdate: SwUpdate,
    private store: Store,
    private notifierService: NotifierService,
    private router: Router
  ) {}

  ngOnInit() {
    document.body.setAttribute('appVersion', environment.version);
    void this.verifyCacheSW();
    this.breakpointObserver
      .observe([Breakpoints.Handset])
      .subscribe(({ matches }) => this.store.dispatch(new GlobalActions.IsMobile(matches)));
    if (environment.qa || environment.production || environment.develop) {
      const appIsStable$ = this.applicationRef.isStable.pipe(first(isStable => isStable === true));
      const everySixHours$ = interval(6 * 60 * 60 * 1000);
      const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

      this.subs.add(
        everySixHoursOnceAppIsStable$.subscribe(async () => {
          try {
            const updateFound = await this.swUpdate.checkForUpdate();
            if (updateFound) {
              this.store.dispatch(new GlobalActions.NewVersion());
              this.notifierService.notify('info', 'There is a new version available, updating...');
            }
          } catch (err) {}
        })
      );
      this.subs.add(
        this.swUpdate.versionUpdates
          .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
          .subscribe(() => {
            this.store.dispatch(new GlobalActions.NewVersion());
          })
      );
      this.subs.add(
        this.swUpdate.unrecoverable.subscribe(() => {
          this.notifierService.notify('error', 'There was an error updating, please refresh the page');
        })
      );
      this.subs.add(
        appIsStable$.subscribe(async () => {
          try {
            const updateFound = await this.swUpdate.checkForUpdate();
            if (updateFound) {
              this.store.dispatch(new GlobalActions.NewVersion());
              this.notifierService.notify('info', 'There is a new version available, updating...');
            }
          } catch (err) {}
        })
      );
      if (environment.production) {
        this.subs.add(
          this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((item: NavigationEnd) => {
            const gtmTag = {
              event: 'page_view',
              pagePath: item.url
            };
            this.gtmService.pushTag(gtmTag);
          })
        );
      }
      void this.swUpdate.checkForUpdate();
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private async verifyCacheSW() {
    const isCleanSW = Boolean(localStorage.getItem('cleanSW'));
    if (!isCleanSW) {
      const keys = await window.caches.keys();
      await Promise.all(keys.map(key => caches.delete(key)));
      localStorage.setItem('cleanSW', 'true');
    }
  }
}
