import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';

export interface ICompletedSurveysResponseDTO {
  month: number;
  year: number;
}

export class CompletedSurveysResponseDTO implements IDTO<CompletedSurveysResponseDTO, ICompletedSurveysResponseDTO> {
  public month: number = 0;
  public year: number = 0;

  constructor(json?: Partial<ICompletedSurveysResponseDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<ICompletedSurveysResponseDTO>) {
    if (json.month != null) {
      this.month = json.month;
    }
    if (json.year != null) {
      this.year = json.year;
    }
  }

  public aJSON(): ICompletedSurveysResponseDTO {
    return {
      month: this.month,
      year: this.year
    };
  }

  public clon<T extends CompletedSurveysResponseDTO>(clonBase: T): T;
  public clon<T extends CompletedSurveysResponseDTO>(): CompletedSurveysResponseDTO;
  public clon<T extends CompletedSurveysResponseDTO>(clonBase: Nilable<T> = null): T | CompletedSurveysResponseDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.month = this.month;
      clonBase.year = this.year;
      return clonBase;
    } else {
      const clon = new CompletedSurveysResponseDTO();
      clon.month = this.month;
      clon.year = this.year;
      return clon;
    }
  }
}

export class CompletedSurveysResponseModelDTO extends ModelDTO<CompletedSurveysResponseDTO> {
  constructor(entidad: CompletedSurveysResponseDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Month(): number {
    return this._entidad.month;
  }

  public set Month(valor: number) {
    this.cambiarPropiedadYNotificar('month', valor);
  }

  public get Year(): number {
    return this._entidad.year;
  }

  public set Year(valor: number) {
    this.cambiarPropiedadYNotificar('year', valor);
  }
}
