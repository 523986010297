import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';

export interface IMonthlyScoreDTO {
  date: string;
  score: number;
}

export class MonthlyScoreDTO implements IDTO<MonthlyScoreDTO, IMonthlyScoreDTO> {
  public date: Date = new Date();
  public score: number = 0;

  constructor(json?: Partial<IMonthlyScoreDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<IMonthlyScoreDTO>) {
    if (json.date != null) {
      this.date = new Date(json.date);
    }
    if (json.score != null) {
      this.score = json.score;
    }
  }

  public aJSON(): IMonthlyScoreDTO {
    return {
      date: this.date.toJSON(),
      score: this.score
    };
  }

  public clon<T extends MonthlyScoreDTO>(clonBase: T): T;
  public clon<T extends MonthlyScoreDTO>(): MonthlyScoreDTO;
  public clon<T extends MonthlyScoreDTO>(clonBase: Nilable<T> = null): T | MonthlyScoreDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.date = new Date(this.date.toJSON());
      clonBase.score = this.score;
      return clonBase;
    } else {
      const clon = new MonthlyScoreDTO();
      clon.date = new Date(this.date.toJSON());
      clon.score = this.score;
      return clon;
    }
  }
}

export class MonthlyScoreModelDTO extends ModelDTO<MonthlyScoreDTO> {
  constructor(entidad: MonthlyScoreDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Date(): Date {
    return this._entidad.date;
  }

  public set Date(valor: Date) {
    this.cambiarPropiedadYNotificar('date', valor);
  }

  public get Score(): number {
    return this._entidad.score;
  }

  public set Score(valor: number) {
    this.cambiarPropiedadYNotificar('score', valor);
  }
}
