import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { CroppedImageData } from './interfaces';

@Component({
  selector: 'app-photo-editor-dialog',
  templateUrl: './photo-editor-dialog.component.html',
  styleUrls: ['./photo-editor-dialog.component.scss']
})
export class PhotoEditorDialogComponent {
  public imageChangedEvent: any;
  public transformOptions: ImageTransform = {
    rotate: 0,
    scale: 1
  };
  public moveImage = false;

  private fileImage: Blob | null;

  constructor(
    @Inject(MAT_DIALOG_DATA) imageEvent: Event,
    private dialogRef: MatDialogRef<PhotoEditorDialogComponent, CroppedImageData>
  ) {
    this.imageChangedEvent = imageEvent;
  }

  rotateRight() {
    this.transformOptions = {
      ...this.transformOptions,
      rotate: this.transformOptions.rotate + 45
    };
  }

  rotateLeft() {
    this.transformOptions = {
      ...this.transformOptions,
      rotate: this.transformOptions.rotate - 45
    };
  }

  move() {
    this.moveImage = true;
  }

  clear() {
    this.transformOptions = {
      rotate: 0,
      scale: 1
    };
  }

  zoomIn() {
    this.transformOptions = {
      ...this.transformOptions,
      scale: this.transformOptions.scale + 0.1
    };
  }

  zoomOut() {
    this.transformOptions = {
      ...this.transformOptions,
      scale: this.transformOptions.scale - 0.1
    };
  }

  reset() {
    this.transformOptions = {
      rotate: 0,
      scale: 1
    };
  }

  public close() {
    this.dialogRef.close();
  }

  public exportImage(event: ImageCroppedEvent) {
    this.fileImage = this.imageBase64ToBlob(event.base64);
  }

  public sendImage() {
    this.dialogRef.close({
      image: this.fileImage,
      filename: this.imageChangedEvent.target.files[0].name
    });
  }

  private imageBase64ToBlob(base64Image: string) {
    const mimeType = base64Image.split(',')[0].split(':')[1].split(';')[0];
    const decodedData = atob(base64Image.split(',')[1]);
    const byteNumbers = new Array(decodedData.length);
    for (let i = 0; i < decodedData.length; i++) {
      byteNumbers[i] = decodedData.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
  }
}
