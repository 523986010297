<div class="flex h-full">
  <div
    [ngClass]="{
      'w-1/2': !isMobile(),
      'pr-2': !isMobile(),
      grow: isMobile()
    }">
    <div class="bg-primary-light p-8 rounded-xl flex h-full">
      <div class="flex flex-col items-stretch grow">
        <div class="flex items-center">
          <div class="text-primary mr-2 flex justify-center items-center">
            <i class="fa-light fa-chart-pie-simple text-5xl"></i>
          </div>
          <div class="flex flex-col">
            <h6 class="text-neutral-500">Business</h6>
            <h2 class="text-3xl">USD 180K</h2>
          </div>
        </div>
        <div class="border-b border-black mt-4 mb-10"></div>
        <div class="flex items-center">
          <div class="w-[30px] h-[30px] text-primary mr-2 flex justify-center items-center">
            <i class="fa-solid fa-industry-windows fa-lg"></i>
          </div>
          <div class="flex flex-col">
            <h6 class="mb-0 text-neutral-500 smaller">Industry</h6>
            <span class="font-bold">{{ startupSelected().industries | joinArray: ', ' : industryName }}</span>
          </div>
        </div>
        <div class="flex items-center mt-3">
          <div class="w-[30px] h-[30px] text-primary mr-2 flex justify-center items-center">
            <i class="fa-light fa-arrow-up fa-lg"></i>
          </div>
          <div class="flex flex-col">
            <h6 class="mb-0 text-neutral-500 smaller">Profit type</h6>
            <span class="font-bold">{{ startupSelected().profitType | profitType }}</span>
          </div>
        </div>
        <div class="flex items-center mt-3">
          <div class="w-[30px] h-[30px] text-primary mr-2 flex justify-center items-center">
            <i class="fa-solid fa-toggle-on fa-lg"></i>
          </div>
          <div class="flex flex-col">
            <h6 class="mb-0 text-neutral-500 smaller">Operating Status</h6>
            <span class="font-bold">{{ startupSelected().operatingStatus | operatingStatus }}</span>
          </div>
        </div>
        <div class="flex items-center mt-3">
          <div class="w-[30px] h-[30px] text-primary mr-2 flex justify-center items-center">
            <i class="fa-regular fa-credit-card fa-lg"></i>
          </div>
          <div class="flex flex-col">
            <h6 class="mb-0 text-neutral-500 smaller">Type of startup</h6>
            <span class="font-bold">{{ startupSelected().type | startupType }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  @if (!isMobile()) {
    <div class="w-1/2 pl-2 pr-1">
      <div class="bg-primary-light p-8 rounded-xl flex h-full">
        <div class="flex flex-col items-stretch grow">
          <div class="flex items-center">
            <div class="text-primary mr-2 flex justify-center items-center">
              <i class="fa-regular fa-money-bill text-5xl"></i>
            </div>
            <div class="flex flex-col">
              <h6 class="text-neutral-500">Finantials</h6>
              <h2 class="text-3xl">Finance</h2>
            </div>
          </div>
          <div class="border-b border-black mt-4 mb-10"></div>
          <div class="flex items-center">
            <div class="w-[30px] h-[30px] text-primary mr-2 flex justify-center items-center">
              <i class="fa-regular fa-globe fa-lg"></i>
            </div>
            <div class="flex flex-col">
              <h6 class="mb-0 text-neutral-500 smaller">Total funding amount</h6>
              <span class="font-bold">{{
                startupSelected().totalFundingAmount == null
                  ? 'None'
                  : (startupSelected().totalFundingAmount | currency: 'USD' : 'USD ' : '1.0-0')
              }}</span>
            </div>
          </div>
          <div class="flex items-center mt-3">
            <div class="w-[30px] h-[30px] text-primary mr-2 flex justify-center items-center">
              <i class="fa-regular fa-dollar-sign fa-lg"></i>
            </div>
            <div class="flex flex-col">
              <h6 class="mb-0 text-neutral-500 smaller">Current funding type</h6>
              <span class="font-bold">{{ startupSelected().currentFundingStage | foundingType }}</span>
            </div>
          </div>
          <div class="flex items-center mt-3">
            <div class="w-[30px] h-[30px] text-primary mr-2 flex justify-center items-center">
              <i class="fa-regular fa-hand-holding-dollar fa-lg"></i>
            </div>
            <div class="flex flex-col">
              <h6 class="mb-0 text-neutral-500 smaller">Last funding type</h6>
              <span class="font-bold">{{ startupSelected().lastFundingType | foundingType }}</span>
            </div>
          </div>
          <div class="flex flex-col mt-3">
            <div class="flex items-center">
              <div class="w-[30px] h-[30px] text-primary mr-2 flex justify-center items-center">
                <i class="fa-solid fa-circle-dollar fa-lg"></i>
              </div>
              <div class="flex flex-col">
                <h6 class="mb-0 text-neutral-500 smaller">Received investment</h6>
                <span class="whitespace-nowrap">{{
                  startupSelected().investment | currency: 'USD' : 'USD ' : '1.0-0'
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
</div>
@if (isMobile()) {
  <div class="flex h-full mt-4">
    <div class="grow">
      <div class="bg-primary-light p-8 rounded-xl flex h-full">
        <div class="flex flex-col items-stretch grow">
          <div class="flex items-center">
            <div class="text-primary mr-2 flex justify-center items-center">
              <i class="fa-regular fa-money-bill text-5xl"></i>
            </div>
            <div class="flex flex-col">
              <h6 class="text-neutral-500">Finantials</h6>
              <h2 class="text-3xl">Finance</h2>
            </div>
          </div>
          <div class="border-b border-black mt-4 mb-10"></div>
          <div class="flex items-center">
            <div class="w-[30px] h-[30px] text-primary mr-2 flex justify-center items-center">
              <i class="fa-regular fa-globe fa-lg"></i>
            </div>
            <div class="flex flex-col">
              <h6 class="mb-0 text-neutral-500 smaller">Total funding amount</h6>
              <span class="font-bold">{{
                startupSelected().totalFundingAmount == null
                  ? 'None'
                  : (startupSelected().totalFundingAmount | currency: 'USD' : 'USD ' : '1.0-0')
              }}</span>
            </div>
          </div>
          <div class="flex items-center mt-3">
            <div class="w-[30px] h-[30px] text-primary mr-2 flex justify-center items-center">
              <i class="fa-regular fa-dollar-sign fa-lg"></i>
            </div>
            <div class="flex flex-col">
              <h6 class="mb-0 text-neutral-500 smaller">Current funding type</h6>
              <span class="font-bold">{{ startupSelected().currentFundingStage | foundingType }}</span>
            </div>
          </div>
          <div class="flex items-center mt-3">
            <div class="w-[30px] h-[30px] text-primary mr-2 flex justify-center items-center">
              <i class="fa-regular fa-hand-holding-dollar fa-lg"></i>
            </div>
            <div class="flex flex-col">
              <h6 class="mb-0 text-neutral-500 smaller">Last funding type</h6>
              <span class="font-bold">{{ startupSelected().lastFundingType | foundingType }}</span>
            </div>
          </div>
          <div class="flex flex-col mt-3">
            <div class="flex items-center">
              <div class="w-[30px] h-[30px] text-primary mr-2 flex justify-center items-center">
                <i class="fa-solid fa-circle-dollar fa-lg"></i>
              </div>
              <div class="flex flex-col">
                <h6 class="mb-0 text-neutral-500 smaller">Received investment</h6>
                <span class="whitespace-nowrap">{{
                  startupSelected().investment | currency: 'USD' : 'USD ' : '1.0-0'
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}
