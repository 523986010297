import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';
import { IStartupRuleDTO, StartupRuleDTO } from './StartupRule.dto';
import { OrderEnum } from './Order.enum';
import { VCColumnOrderEnum } from './VCColumnOrder.enum';

export interface ISearchCompanyFilterDTO {
  name: string;
  rules: IStartupRuleDTO[];
  order: number;
  columnOrder: number;
  extraOrder: number;
}

export class SearchCompanyFilterDTO implements IDTO<SearchCompanyFilterDTO, ISearchCompanyFilterDTO> {
  public name: string = '';
  public rules: StartupRuleDTO[] = [];
  public order: OrderEnum = OrderEnum.Asc;
  public columnOrder: VCColumnOrderEnum = VCColumnOrderEnum.Name;
  public extraOrder: number = 0;

  constructor(json?: Partial<ISearchCompanyFilterDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<ISearchCompanyFilterDTO>) {
    if (json.name != null) {
      this.name = json.name;
    }
    if (json.rules != null) {
      this.rules = json.rules.map(item => new StartupRuleDTO(item));
    }
    if (json.order != null) {
      this.order = json.order as OrderEnum;
    }
    if (json.columnOrder != null) {
      this.columnOrder = json.columnOrder as VCColumnOrderEnum;
    }
    if (json.extraOrder != null) {
      this.extraOrder = json.extraOrder;
    }
  }

  public aJSON(): ISearchCompanyFilterDTO {
    return {
      name: this.name,
      rules: this.rules.map(item => item.aJSON()),
      order: this.order,
      columnOrder: this.columnOrder,
      extraOrder: this.extraOrder
    };
  }

  public clon<T extends SearchCompanyFilterDTO>(clonBase: T): T;
  public clon<T extends SearchCompanyFilterDTO>(): SearchCompanyFilterDTO;
  public clon<T extends SearchCompanyFilterDTO>(clonBase: Nilable<T> = null): T | SearchCompanyFilterDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.name = this.name;
      clonBase.rules = this.rules.map(item => item.clon());
      clonBase.order = this.order;
      clonBase.columnOrder = this.columnOrder;
      clonBase.extraOrder = this.extraOrder;
      return clonBase;
    } else {
      const clon = new SearchCompanyFilterDTO();
      clon.name = this.name;
      clon.rules = this.rules.map(item => item.clon());
      clon.order = this.order;
      clon.columnOrder = this.columnOrder;
      clon.extraOrder = this.extraOrder;
      return clon;
    }
  }
}

export class SearchCompanyFilterModelDTO extends ModelDTO<SearchCompanyFilterDTO> {
  constructor(entidad: SearchCompanyFilterDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Name(): string {
    return this._entidad.name;
  }

  public set Name(valor: string) {
    this.cambiarPropiedadYNotificar('name', valor);
  }

  public get Rules(): StartupRuleDTO[] {
    return this._entidad.rules;
  }

  public set Rules(valor: StartupRuleDTO[]) {
    this.cambiarPropiedadYNotificar('rules', valor);
  }

  public get Order(): OrderEnum {
    return this._entidad.order;
  }

  public set Order(valor: OrderEnum) {
    this.cambiarPropiedadYNotificar('order', valor);
  }

  public get ColumnOrder(): VCColumnOrderEnum {
    return this._entidad.columnOrder;
  }

  public set ColumnOrder(valor: VCColumnOrderEnum) {
    this.cambiarPropiedadYNotificar('columnOrder', valor);
  }

  public get ExtraOrder(): number {
    return this._entidad.extraOrder;
  }

  public set ExtraOrder(valor: number) {
    this.cambiarPropiedadYNotificar('extraOrder', valor);
  }
}
