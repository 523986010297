import { IDTO, ModelDTO, UsuarioDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';

import { IUserProfessionalBasicDTO, UserProfessionalBasicDTO } from './UserProfessionalBasic.dto';
import { IStartupCompanyDTO, StartupCompanyDTO } from './StartupCompany.dto';

export interface IUserProfessionalDTO extends IUserProfessionalBasicDTO {
  startups: IStartupCompanyDTO[];
}

export class UserProfessionalDTO
  extends UserProfessionalBasicDTO
  implements IDTO<UserProfessionalDTO, IUserProfessionalDTO>
{
  public startups: StartupCompanyDTO[] = [];

  constructor(json?: Partial<IUserProfessionalDTO>) {
    super(json);

    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public override cargarDTO(json: Partial<IUserProfessionalDTO>) {
    super.cargarDTO(json);

    if (json.startups != null) {
      this.startups = json.startups.map(item => new StartupCompanyDTO(item));
    }
  }

  public override aJSON(): IUserProfessionalDTO {
    return {
      ...super.aJSON(),
      startups: this.startups.map(item => item.aJSON())
    };
  }

  public clon<T extends UserProfessionalDTO>(clonBase: T): T;
  public clon<T extends UserProfessionalDTO>(): UserProfessionalDTO;
  public override clon<T extends UserProfessionalDTO>(clonBase: Nilable<T> = null): T | UserProfessionalDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.startups = this.startups.map(item => item.clon());
      return clonBase;
    } else {
      const clon = super.clon(new UserProfessionalDTO());
      clon.startups = this.startups.map(item => item.clon());
      return clon;
    }
  }
}

export class UserProfessionalModelDTO extends ModelDTO<UserProfessionalDTO> {
  constructor(entidad: UserProfessionalDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get User(): UsuarioDTO {
    return this._entidad.user;
  }

  public set User(valor: UsuarioDTO) {
    this.cambiarPropiedadYNotificar('user', valor);
  }

  public get ExpirationDate(): Date {
    return this._entidad.expirationDate;
  }

  public set ExpirationDate(valor: Date) {
    this.cambiarPropiedadYNotificar('expirationDate', valor);
  }

  public get CreatedAt(): Date {
    return this._entidad.createdAt;
  }

  public set CreatedAt(valor: Date) {
    this.cambiarPropiedadYNotificar('createdAt', valor);
  }

  public get CreatedBy(): UsuarioDTO {
    return this._entidad.createdBy;
  }

  public set CreatedBy(valor: UsuarioDTO) {
    this.cambiarPropiedadYNotificar('createdBy', valor);
  }

  public get Startups(): StartupCompanyDTO[] {
    return this._entidad.startups;
  }

  public set Startups(valor: StartupCompanyDTO[]) {
    this.cambiarPropiedadYNotificar('startups', valor);
  }
}
