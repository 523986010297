import { Injectable } from '@angular/core';
import { PaymentServicioBackend } from '@serviceBackend';
import { RegisterResponseDTO, SubscriptionInfoDTO, SubscriptionStatusDTO, SessionInfoDTO } from '@DTOS';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  constructor(private paymentServicioBackend: PaymentServicioBackend) {}

  public cancelSubscription(): Observable<void> {
    return this.paymentServicioBackend.CancelSubscription();
  }

  public resumeSubscription(): Observable<SessionInfoDTO | null> {
    return this.paymentServicioBackend.ResumeSubscription();
  }

  public getStatusSubscription(): Observable<SubscriptionStatusDTO> {
    return this.paymentServicioBackend.GetStatusSubscription();
  }

  public postVerifySubscription(): Observable<RegisterResponseDTO | null> {
    return this.paymentServicioBackend.VerifySubscription();
  }

  public updatePaymentWithSessionId(sessionId: string): Observable<RegisterResponseDTO> {
    return this.paymentServicioBackend.UpdateSubscription(sessionId);
  }

  public getPaymentInfo(): Observable<SubscriptionInfoDTO> {
    return this.paymentServicioBackend.GetSubscriptionInfo();
  }

  public updatePayment(): Observable<RegisterResponseDTO> {
    return this.paymentServicioBackend.UpdatePayment();
  }
}
