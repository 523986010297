<div class="relative" [style.height.px]="diameter">
  <mat-progress-spinner
    mode="determinate"
    [diameter]="diameter - 10"
    class="!absolute progress-spinner-grey"
    [value]="100"
    [strokeWidth]="10"
    [style.margin.px]="5">
  </mat-progress-spinner>
  <mat-progress-spinner
    mode="determinate"
    [diameter]="diameter"
    [class]="'!absolute ' + circleClass"
    [value]="value"
    [strokeWidth]="20">
  </mat-progress-spinner>
</div>
