import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';

export interface IFounderDTO {
  id: number;
  name: string;
  lastName: string;
  position: string;
  linkedin: string;
}

export class FounderDTO implements IDTO<FounderDTO, IFounderDTO> {
  public id: number = 0;
  public name: string = '';
  public lastName: string = '';
  public position: string = '';
  public linkedin: string = '';

  constructor(json?: Partial<IFounderDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<IFounderDTO>) {
    if (json.id != null) {
      this.id = json.id;
    }
    if (json.name != null) {
      this.name = json.name;
    }
    if (json.lastName != null) {
      this.lastName = json.lastName;
    }
    if (json.position != null) {
      this.position = json.position;
    }
    if (json.linkedin != null) {
      this.linkedin = json.linkedin;
    }
  }

  public aJSON(): IFounderDTO {
    return {
      id: this.id,
      name: this.name,
      lastName: this.lastName,
      position: this.position,
      linkedin: this.linkedin
    };
  }

  public clon<T extends FounderDTO>(clonBase: T): T;
  public clon<T extends FounderDTO>(): FounderDTO;
  public clon<T extends FounderDTO>(clonBase: Nilable<T> = null): T | FounderDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.id = this.id;
      clonBase.name = this.name;
      clonBase.lastName = this.lastName;
      clonBase.position = this.position;
      clonBase.linkedin = this.linkedin;
      return clonBase;
    } else {
      const clon = new FounderDTO();
      clon.id = this.id;
      clon.name = this.name;
      clon.lastName = this.lastName;
      clon.position = this.position;
      clon.linkedin = this.linkedin;
      return clon;
    }
  }
}

export class FounderModelDTO extends ModelDTO<FounderDTO> {
  constructor(entidad: FounderDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Id(): number {
    return this._entidad.id;
  }

  public set Id(valor: number) {
    this.cambiarPropiedadYNotificar('id', valor);
  }

  public get Name(): string {
    return this._entidad.name;
  }

  public set Name(valor: string) {
    this.cambiarPropiedadYNotificar('name', valor);
  }

  public get LastName(): string {
    return this._entidad.lastName;
  }

  public set LastName(valor: string) {
    this.cambiarPropiedadYNotificar('lastName', valor);
  }

  public get Position(): string {
    return this._entidad.position;
  }

  public set Position(valor: string) {
    this.cambiarPropiedadYNotificar('position', valor);
  }

  public get Linkedin(): string {
    return this._entidad.linkedin;
  }

  public set Linkedin(valor: string) {
    this.cambiarPropiedadYNotificar('linkedin', valor);
  }
}
