import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';
import { ReasonEmailEnum } from './ReasonEmail.enum';

export interface ISendEmailDTO {
  startupId: number;
  reason: number;
}

export class SendEmailDTO implements IDTO<SendEmailDTO, ISendEmailDTO> {
  public startupId: number = 0;
  public reason: ReasonEmailEnum = ReasonEmailEnum.NotAccepted;

  constructor(json?: Partial<ISendEmailDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<ISendEmailDTO>) {
    if (json.startupId != null) {
      this.startupId = json.startupId;
    }
    if (json.reason != null) {
      this.reason = json.reason as ReasonEmailEnum;
    }
  }

  public aJSON(): ISendEmailDTO {
    return {
      startupId: this.startupId,
      reason: this.reason
    };
  }

  public clon<T extends SendEmailDTO>(clonBase: T): T;
  public clon<T extends SendEmailDTO>(): SendEmailDTO;
  public clon<T extends SendEmailDTO>(clonBase: Nilable<T> = null): T | SendEmailDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.startupId = this.startupId;
      clonBase.reason = this.reason;
      return clonBase;
    } else {
      const clon = new SendEmailDTO();
      clon.startupId = this.startupId;
      clon.reason = this.reason;
      return clon;
    }
  }
}

export class SendEmailModelDTO extends ModelDTO<SendEmailDTO> {
  constructor(entidad: SendEmailDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get StartupId(): number {
    return this._entidad.startupId;
  }

  public set StartupId(valor: number) {
    this.cambiarPropiedadYNotificar('startupId', valor);
  }

  public get Reason(): ReasonEmailEnum {
    return this._entidad.reason;
  }

  public set Reason(valor: ReasonEmailEnum) {
    this.cambiarPropiedadYNotificar('reason', valor);
  }
}
