<form class="mb-3 mt-2" [formGroup]="formGroup">
  <mat-list>
    <mat-list-item class="background-primary-light has-form-field with-padding-top !rounded-t-3xl">
      @if (variable == null) {
        <app-row-form>
          <ng-template appFirstField>
            <mat-form-field class="grow pr-2">
              <mat-label>Select a type</mat-label>
              <mat-select required name="type" formControlName="type" required>
                <mat-option [value]="null" selected disabled hidden> Select an option</mat-option>
                @for (ruleType of companyTypeRules; track ruleType) {
                  <mat-option [value]="ruleType">
                    {{ ruleType | companyRuleType }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </ng-template>
          <ng-template appSecondField>
            <mat-form-field class="grow pl-2">
              <mat-label>
                {{ formGroup.value.type == null ? 'Variable' : (formGroup.value.type | companyRuleType) }}
              </mat-label>
              <mat-select required name="type" formControlName="variable" required>
                <mat-option [value]="null" selected disabled hidden> Select an option</mat-option>
                @if (formGroup.value.type === CompanyRuleTypeEnum.Scoring) {
                  <mat-option [value]="ScoringRuleEnum.Parcial">Parcial</mat-option>
                  <mat-option [value]="ScoringRuleEnum.Full">Full</mat-option>
                  <mat-option [value]="ScoringRuleEnum.Rank">CB Rank</mat-option>
                }
                @if (formGroup.value.type === CompanyRuleTypeEnum.Topics) {
                  @for (topic of topics; track topic.id) {
                    <mat-option [value]="topic.id">{{ topic.name }}</mat-option>
                  }
                }
                @if (formGroup.value.type === CompanyRuleTypeEnum.Answers) {
                  @for (variable of variables; track variable.id) {
                    <mat-option [value]="variable.id">{{ variable.name }}</mat-option>
                  }
                }
                @if (formGroup.value.type === CompanyRuleTypeEnum.Profile) {
                  @for (startupProfile of startupProfileVariables; track startupProfile) {
                    <mat-option [value]="startupProfile">{{ startupProfile | startupProfileRule }}</mat-option>
                  }
                }
              </mat-select>
            </mat-form-field>
          </ng-template>
        </app-row-form>
      }
    </mat-list-item>
    <mat-list-item class="background-primary-light has-form-field !rounded-b-3xl">
      <app-row-form>
        <ng-template appFirstField>
          <mat-form-field class="grow pr-2">
            <mat-label>Operator</mat-label>
            <mat-select required name="type" formControlName="operator" required>
              <mat-option [value]="null" selected disabled hidden> Select an option</mat-option>
              @if (
                formGroup.value.type === CompanyRuleTypeEnum.Profile &&
                formGroup.value.variable === VariableProfileEnum.Headquarters
              ) {
                <mat-option [value]="OperatorEnum.StartsWith">{{ OperatorEnum.StartsWith | operatorRule }}</mat-option>
              } @else {
                @if (
                  !(
                    formGroup.value.type === CompanyRuleTypeEnum.Profile &&
                    formGroup.value.variable === VariableProfileEnum.Headquarters
                  )
                ) {
                  @for (operator of operators; track operator) {
                    <mat-option [value]="operator">{{ operator | operatorRule }}</mat-option>
                  }
                }
                @if (
                  !(
                    formGroup.value.type === CompanyRuleTypeEnum.Answers ||
                    (formGroup.value.type === CompanyRuleTypeEnum.Profile &&
                      (formGroup.value.variable === VariableProfileEnum.LastFoundingType ||
                        formGroup.value.variable === VariableProfileEnum.CurrentFounding ||
                        formGroup.value.variable === VariableProfileEnum.QuantityEmployees ||
                        formGroup.value.variable === VariableProfileEnum.ProfitType ||
                        formGroup.value.variable === VariableProfileEnum.PercentageLoansRevenue ||
                        formGroup.value.variable === VariableProfileEnum.PercentageCompanyHandsFounders))
                  )
                ) {
                  @for (operator of moreOperators; track operator) {
                    <mat-option [value]="operator">{{ operator | operatorRule }}</mat-option>
                  }
                }
              }
            </mat-select>
          </mat-form-field>
        </ng-template>
        <ng-template appSecondField>
          @if (formGroup.value.type === CompanyRuleTypeEnum.Topics) {
            <mat-form-field class="grow pl-2">
              <mat-label>Value (in %)</mat-label>
              <input matInput type="number" name="value" formControlName="value" max="100" min="0" />
            </mat-form-field>
          }
          @if (formGroup.value.type === CompanyRuleTypeEnum.Answers) {
            <mat-form-field class="grow pl-2">
              <mat-label>Option</mat-label>
              <mat-select formControlName="value" name="value">
                <mat-option [value]="null" selected disabled hidden> Select an option</mat-option>
                @for (option of options; track option.id) {
                  <mat-option [value]="option.id">{{ option.text }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          }
          @if (formGroup.value.type === CompanyRuleTypeEnum.Scoring) {
            <mat-form-field class="grow pl-2">
              <mat-label>Value</mat-label>
              <input matInput type="number" name="value" formControlName="value" />
            </mat-form-field>
          }
          @if (formGroup.value.type === CompanyRuleTypeEnum.Profile) {
            <mat-form-field class="grow pl-2">
              <mat-label>
                Value
                {{
                  [VariableProfileEnum.Investment, VariableProfileEnum.TotalFoundingAmount].includes(
                    formGroup.value.variable
                  )
                    ? ' (in MM)'
                    : ''
                }}
              </mat-label>
              @if (
                formGroup.value.variable === VariableProfileEnum.LastFoundingType ||
                formGroup.value.variable === VariableProfileEnum.CurrentFounding
              ) {
                <mat-select formControlName="value" name="value">
                  <mat-option [value]="null" selected disabled hidden> Select an option</mat-option>
                  @for (option of optionsType; track option) {
                    <mat-option [value]="option">{{ option | foundingType }}</mat-option>
                  }
                </mat-select>
              }
              @if (formGroup.value.variable === VariableProfileEnum.QuantityEmployees) {
                <mat-select formControlName="value" name="value">
                  <mat-option [value]="null" selected disabled hidden> Select an option</mat-option>
                  @for (option of variables | filterAnswers: 98; track option.id) {
                    <mat-option [value]="option.id">{{ option.text }}</mat-option>
                  }
                </mat-select>
              }
              @if (formGroup.value.variable === VariableProfileEnum.ProfitType) {
                <mat-select formControlName="value" name="value">
                  <mat-option [value]="null" selected disabled hidden> Select an option</mat-option>
                  @for (option of profitTypeEnums; track option) {
                    <mat-option [value]="option">{{ option | profitType }}</mat-option>
                  }
                </mat-select>
              }
              @if (formGroup.value.variable === VariableProfileEnum.PercentageLoansRevenue) {
                <mat-select formControlName="value" name="value">
                  <mat-option [value]="null" selected disabled hidden> Select an option</mat-option>
                  @for (option of variables | filterAnswers: 96; track option) {
                    <mat-option [value]="option.id">{{ option.text }}</mat-option>
                  }
                </mat-select>
              }
              @if (formGroup.value.variable === VariableProfileEnum.PercentageCompanyHandsFounders) {
                <mat-select formControlName="value" name="value">
                  <mat-option [value]="null" selected disabled hidden> Select an option</mat-option>
                  @for (option of variables | filterAnswers: 99; track option) {
                    <mat-option [value]="option.id">{{ option.text }}</mat-option>
                  }
                </mat-select>
              }
              @if (formGroup.value.variable | anyArray: variableProfileWithNumber) {
                <input matInput type="number" name="value" formControlName="value" />
              }
              @if (
                formGroup.value.type === CompanyRuleTypeEnum.Profile &&
                formGroup.value.variable === VariableProfileEnum.Headquarters
              ) {
                <input matInput name="valueString" formControlName="valueString" />
              }
              @if (formGroup.value.variable == null) {
                <input matInput type="number" name="value" formControlName="value" />
              }
            </mat-form-field>
          }
          @if (formGroup.value.type == null) {
            <mat-form-field class="grow pl-2">
              <mat-label>Value</mat-label>
              <input matInput type="number" name="value" formControlName="value" />
            </mat-form-field>
          }
        </ng-template>
      </app-row-form>
    </mat-list-item>
  </mat-list>
</form>
