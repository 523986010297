import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';

export interface ISubscriptionStatusDTO {
  trialDays: number;
  active: boolean;
  endDate: string;
  exists: boolean;
  hasSubscription: boolean;
}

export class SubscriptionStatusDTO implements IDTO<SubscriptionStatusDTO, ISubscriptionStatusDTO> {
  public trialDays: number = 0;
  public active: boolean = true;
  public endDate: Date = new Date();
  public exists: boolean = true;
  public hasSubscription: boolean = true;

  constructor(json?: Partial<ISubscriptionStatusDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<ISubscriptionStatusDTO>) {
    if (json.trialDays != null) {
      this.trialDays = json.trialDays;
    }
    if (json.active != null) {
      this.active = json.active;
    }
    if (json.endDate != null) {
      this.endDate = new Date(json.endDate);
    }
    if (json.exists != null) {
      this.exists = json.exists;
    }
    if (json.hasSubscription != null) {
      this.hasSubscription = json.hasSubscription;
    }
  }

  public aJSON(): ISubscriptionStatusDTO {
    return {
      trialDays: this.trialDays,
      active: this.active,
      endDate: this.endDate.toJSON(),
      exists: this.exists,
      hasSubscription: this.hasSubscription
    };
  }

  public clon<T extends SubscriptionStatusDTO>(clonBase: T): T;
  public clon<T extends SubscriptionStatusDTO>(): SubscriptionStatusDTO;
  public clon<T extends SubscriptionStatusDTO>(clonBase: Nilable<T> = null): T | SubscriptionStatusDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.trialDays = this.trialDays;
      clonBase.active = this.active;
      clonBase.endDate = new Date(this.endDate.toJSON());
      clonBase.exists = this.exists;
      clonBase.hasSubscription = this.hasSubscription;
      return clonBase;
    } else {
      const clon = new SubscriptionStatusDTO();
      clon.trialDays = this.trialDays;
      clon.active = this.active;
      clon.endDate = new Date(this.endDate.toJSON());
      clon.exists = this.exists;
      clon.hasSubscription = this.hasSubscription;
      return clon;
    }
  }
}

export class SubscriptionStatusModelDTO extends ModelDTO<SubscriptionStatusDTO> {
  constructor(entidad: SubscriptionStatusDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get TrialDays(): number {
    return this._entidad.trialDays;
  }

  public set TrialDays(valor: number) {
    this.cambiarPropiedadYNotificar('trialDays', valor);
  }

  public get Active(): boolean {
    return this._entidad.active;
  }

  public set Active(valor: boolean) {
    this.cambiarPropiedadYNotificar('active', valor);
  }

  public get EndDate(): Date {
    return this._entidad.endDate;
  }

  public set EndDate(valor: Date) {
    this.cambiarPropiedadYNotificar('endDate', valor);
  }

  public get Exists(): boolean {
    return this._entidad.exists;
  }

  public set Exists(valor: boolean) {
    this.cambiarPropiedadYNotificar('exists', valor);
  }

  public get HasSubscription(): boolean {
    return this._entidad.hasSubscription;
  }

  public set HasSubscription(valor: boolean) {
    this.cambiarPropiedadYNotificar('hasSubscription', valor);
  }
}
