<div class="flex flex-col">
  <div class="flex items-center justify-between">
    <h2 class="font-semibold text-4xl">{{ startup.name }}</h2>
    @if (!isMobile()) {
      <span class="text-primary">
        <i class="fa-solid fa-pencil text-4xl"></i>
      </span>
    }
  </div>
  <div class="flex mt-4">
    <p>{{ startup.description }}</p>
  </div>
  <div class="flex justify-evenly mt-14">
    <div class="flex flex-col items-center">
      <span class="text-primary">
        <i class="fa-solid fa-bullseye-arrow text-6xl"></i>
      </span>
      <span class="text-2xl mt-2 font-semibold">{{ scoring | number: '1.1-1' }}</span>
      <span class="text-xs text-neutral-400 mt-1">Scoring</span>
    </div>
    <div class="flex flex-col items-center">
      <span class="text-primary">
        <i class="fa-light fa-chart-pie-simple text-6xl"></i>
      </span>
      <span class="text-2xl mt-2 font-semibold">{{ startup.investment | format: formatNumber }}</span>
      <span class="text-xs text-neutral-400 mt-1">Investment</span>
    </div>
    <div class="flex flex-col items-center">
      <span class="text-primary">
        <i class="fa-light fa-money-bill text-6xl"></i>
      </span>
      <span class="text-2xl mt-2 font-semibold">{{ startup.totalFundingAmount | format: formatNumber }}</span>
      <span class="text-xs text-neutral-400 mt-1">Total Amount</span>
    </div>
  </div>
  <div class="hidden md:flex mt-14 flex-col">
    <div class="flex items-center">
      <div class="px-2 py-2 bg-primary text-white h-fit rounded-full mr-4">
        <i class="fa-solid fa-circle-info fa-xl"></i>
      </div>
      General information
    </div>
    <div class="flex mt-3">
      <div class="w-1/3">
        <div class="flex items-center">
          <mat-icon class="mx-2 text-primary">person</mat-icon>
          <div class="flex flex-col">
            <h6 class="mb-0 text-neutral-500 smaller">Name</h6>
            <span class="font-bold">{{ startup.name }}</span>
          </div>
        </div>
      </div>
      <div class="w-1/3">
        <div class="flex items-center">
          <mat-icon class="mx-2 text-primary">calendar_today</mat-icon>
          <div class="flex flex-col">
            <h6 class="mb-0 text-neutral-500 smaller">Founded date</h6>
            <span class="font-bold">
              {{ startup.foundedDate == null ? 'None' : (startup.foundedDate | date: 'dd-MM-yyyy') }}
            </span>
          </div>
        </div>
      </div>
      <div class="w-1/3">
        <div class="flex items-center">
          <mat-icon class="mx-2 text-primary">verified</mat-icon>
          <div class="flex flex-col">
            <h6 class="mb-0 text-neutral-500 smaller">Brand Name</h6>
            <span class="font-bold">{{ startup.brandName === '' ? 'None' : startup.brandName }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="flex mt-3">
      <div class="w-1/3">
        <div class="flex items-center">
          <mat-icon class="mx-2 text-primary">domain</mat-icon>
          <div class="flex flex-col">
            <h6 class="mb-0 text-neutral-500 smaller">Headquarters</h6>
            <span class="font-bold">{{ startup.headquarters === '' ? 'None' : startup.headquarters }}</span>
          </div>
        </div>
      </div>
      <div class="w-1/3">
        <div class="flex items-center">
          <mat-icon class="mx-2 text-primary">copyright</mat-icon>
          <div class="flex flex-col">
            <h6 class="mb-0 text-neutral-500 smaller">Legal Name</h6>
            <span class="font-bold">{{ startup.legalName === '' ? 'None' : startup.legalName }}</span>
          </div>
        </div>
      </div>
      <div class="w-1/3">
        <div class="flex items-center">
          <mat-icon class="mx-2 text-primary">language</mat-icon>
          <div class="flex flex-col">
            <h6 class="mb-0 text-neutral-500 smaller">Website</h6>
            <span class="font-bold">{{ startup.website === '' ? 'None' : startup.website }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="flex mt-3">
      <div class="w-1/3">
        <div class="flex items-center">
          <div class="mx-2 text-primary">
            <img
              src="https://content.linkedin.com/content/dam/me/business/en-us/amp/brand-site/v2/bg/LI-Bug.svg.original.svg"
              alt="linkein logo"
              width="24"
              height="24" />
          </div>
          <div class="flex flex-col">
            <h6 class="mb-0 text-neutral-500 smaller">Linkedin</h6>
            <span class="font-bold">{{ startup.linkedin === '' ? 'None' : startup.linkedin }}</span>
          </div>
        </div>
      </div>
      <div class="w-1/3">
        <div class="flex items-center cursor-pointer" (click)="openAttachments()">
          <mat-icon class="mx-2 text-primary">attach_file</mat-icon>
          <div class="flex flex-col">
            <h6 class="mb-0 text-neutral-500 smaller">Attachments</h6>
            <div class="flex items-center">
              <span class="font-bold">{{ startup.files.length }}</span>
              <mat-icon color="primary" class="size-1 ml-2">visibility</mat-icon>
              <mat-icon color="primary" class="size-1 ml-2">edit</mat-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
