import { Pipe, PipeTransform } from '@angular/core';
import { QuestionAnswerItemModel } from '../../app-shared/assessment/topic-interview/question/question-answer-item.model';
import { VariableDTO } from '@DTOS';

@Pipe({
  name: 'sortQuestionAnswer'
})
export class SortQuestionAnswerPipe implements PipeTransform {
  transform(questionAnswerItems: QuestionAnswerItemModel[]): QuestionAnswerItemModel[] {
    return questionAnswerItems.sort((item1, item2) =>
      SortQuestionAnswerPipe.getCode(item1.question) === 0
        ? 1000
        : SortQuestionAnswerPipe.getCode(item1.question) - SortQuestionAnswerPipe.getCode(item2.question)
    );
  }

  private static getCode(variable: VariableDTO): number {
    if (!variable.code.includes('.')) {
      return 0;
    }
    const splitFloat = variable.code.split('.');
    return parseInt(splitFloat[1], 10);
  }
}
