import { Nilable } from '@nahuelmorata/ngx-extras';
import { List } from 'immutable';
import { Observable, ReplaySubject } from 'rxjs';

export class CacheEntityService<T> {
  protected entities$: Observable<List<T>>;
  private _entities$ = new ReplaySubject<Nilable<List<T>>>(1);
  private _entities: Nilable<List<T>>;

  constructor() {
    this.entities$ = this._entities$.asObservable();
  }

  public hasCache(): boolean {
    return this._entities != null;
  }

  public setEntities(entities: T[]): List<T> {
    this._entities = List(entities);
    this._entities$.next(this._entities);
    return this._entities;
  }

  public getEntities(): List<T> {
    return this._entities == null ? List() : List(this._entities);
  }

  public updateEntity(callback: (entity: T, index: number) => T) {
    this._entities = this._entities.map(callback);
    this._entities$.next(this._entities);
  }

  public addEntity(x: T) {
    this._entities = this._entities.push(x);
    this._entities$.next(this._entities);
  }

  public filterEntities(callback: (entity: T, index: number) => boolean) {
    this._entities = this._entities.filter(callback);
    this._entities$.next(this._entities);
  }
}
