import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';
import { CompanyRuleTypeEnum } from './CompanyRuleType.enum';
import { OperatorEnum } from './Operator.enum';

export interface ICompanyRuleDTO {
  id: number;
  type: number;
  variable: number;
  value: number;
  valueString: string;
  operator: number;
  createdAt: string;
}

export class CompanyRuleDTO implements IDTO<CompanyRuleDTO, ICompanyRuleDTO> {
  public id: number = 0;
  public type: CompanyRuleTypeEnum = CompanyRuleTypeEnum.Scoring;
  public variable: number = 0;
  public value: number = 0;
  public valueString: string = '';
  public operator: OperatorEnum = OperatorEnum.Equal;
  public createdAt: Date = new Date();

  constructor(json?: Partial<ICompanyRuleDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<ICompanyRuleDTO>) {
    if (json.id != null) {
      this.id = json.id;
    }
    if (json.type != null) {
      this.type = json.type as CompanyRuleTypeEnum;
    }
    if (json.variable != null) {
      this.variable = json.variable;
    }
    if (json.value != null) {
      this.value = json.value;
    }
    if (json.valueString != null) {
      this.valueString = json.valueString;
    }
    if (json.operator != null) {
      this.operator = json.operator as OperatorEnum;
    }
    if (json.createdAt != null) {
      this.createdAt = new Date(json.createdAt);
    }
  }

  public aJSON(): ICompanyRuleDTO {
    return {
      id: this.id,
      type: this.type,
      variable: this.variable,
      value: this.value,
      valueString: this.valueString,
      operator: this.operator,
      createdAt: this.createdAt.toJSON()
    };
  }

  public clon<T extends CompanyRuleDTO>(clonBase: T): T;
  public clon<T extends CompanyRuleDTO>(): CompanyRuleDTO;
  public clon<T extends CompanyRuleDTO>(clonBase: Nilable<T> = null): T | CompanyRuleDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.id = this.id;
      clonBase.type = this.type;
      clonBase.variable = this.variable;
      clonBase.value = this.value;
      clonBase.valueString = this.valueString;
      clonBase.operator = this.operator;
      clonBase.createdAt = new Date(this.createdAt.toJSON());
      return clonBase;
    } else {
      const clon = new CompanyRuleDTO();
      clon.id = this.id;
      clon.type = this.type;
      clon.variable = this.variable;
      clon.value = this.value;
      clon.valueString = this.valueString;
      clon.operator = this.operator;
      clon.createdAt = new Date(this.createdAt.toJSON());
      return clon;
    }
  }
}

export class CompanyRuleModelDTO extends ModelDTO<CompanyRuleDTO> {
  constructor(entidad: CompanyRuleDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Id(): number {
    return this._entidad.id;
  }

  public set Id(valor: number) {
    this.cambiarPropiedadYNotificar('id', valor);
  }

  public get Type(): CompanyRuleTypeEnum {
    return this._entidad.type;
  }

  public set Type(valor: CompanyRuleTypeEnum) {
    this.cambiarPropiedadYNotificar('type', valor);
  }

  public get Variable(): number {
    return this._entidad.variable;
  }

  public set Variable(valor: number) {
    this.cambiarPropiedadYNotificar('variable', valor);
  }

  public get Value(): number {
    return this._entidad.value;
  }

  public set Value(valor: number) {
    this.cambiarPropiedadYNotificar('value', valor);
  }

  public get ValueString(): string {
    return this._entidad.valueString;
  }

  public set ValueString(valor: string) {
    this.cambiarPropiedadYNotificar('valueString', valor);
  }

  public get Operator(): OperatorEnum {
    return this._entidad.operator;
  }

  public set Operator(valor: OperatorEnum) {
    this.cambiarPropiedadYNotificar('operator', valor);
  }

  public get CreatedAt(): Date {
    return this._entidad.createdAt;
  }

  public set CreatedAt(valor: Date) {
    this.cambiarPropiedadYNotificar('createdAt', valor);
  }
}
