import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'format'
})
export class FormatPipe implements PipeTransform {
  transform<T>(element: T, callback: (element: T, ...parameters: any[]) => string, ...parameters: any[]): string {
    return callback(element, ...parameters);
  }
}
