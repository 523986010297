import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-progress-spinner-background',
  templateUrl: './progress-spinner-background.component.html',
  styleUrls: ['./progress-spinner-background.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatProgressSpinnerModule]
})
export class ProgressSpinnerBackgroundComponent {
  @Input() public diameter: number | null;
  @Input() public value = 0;
  @Input() public withBackground = false;
  @Input() public circleClass = '';
}
