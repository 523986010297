<div mat-dialog-title>Upload image</div>
<div mat-dialog-content>
  <div class="container-crop">
    <image-cropper
      [imageChangedEvent]="imageChangedEvent"
      [roundCropper]="true"
      (imageCropped)="exportImage($event)"
      [allowMoveImage]="moveImage"
      [transform]="transformOptions"
      [cropperMaxHeight]="400"
      [cropperMaxWidth]="400"
      [hideResizeSquares]="true"
      [imageQuality]="70"></image-cropper>
  </div>
  <div class="flex grow justify-center">
    <button mat-button (click)="clear()">
      <i class="fa-solid fa-lg fa-times"></i>
    </button>
    <button mat-button (click)="zoomIn()">
      <i class="fa-solid fa-lg fa-search-plus"></i>
    </button>
    <button mat-button (click)="zoomOut()">
      <i class="fa-solid fa-lg fa-search-minus"></i>
    </button>
    <button mat-button (click)="rotateLeft()">
      <i class="fa-solid fa-lg fa-undo-alt"></i>
    </button>
    <button mat-button (click)="rotateRight()">
      <i class="fa-solid fa-lg fa-redo-alt"></i>
    </button>
    <button mat-button (click)="reset()">
      <i class="fa-solid fa-lg fa-sync-alt"></i>
    </button>
  </div>
</div>
<div mat-dialog-actions class="justify-end">
  <button mat-stroked-button color="primary" (click)="close()">Cancel</button>
  <button mat-raised-button color="primary" (click)="sendImage()">Send</button>
</div>
