import { CompanyUserStartupDTO } from '@DTOS';
import { Injectable, inject } from '@angular/core';
import { CompanyEntity } from '@domain/company';
import { UserEntity } from '@domain/user';
import { CompanyPort } from '@ports/company';
import { CompanyServicioBackend } from '@serviceBackend';
import { Observable, ReplaySubject, take, tap } from 'rxjs';

/**
 * @deprecated Use CompanyPort instead
 */
@Injectable({
  providedIn: 'root'
})
export class CompanyAdapterService implements CompanyPort {
  public company$: Observable<CompanyEntity>;

  private _company$ = new ReplaySubject<CompanyEntity>(1);

  private readonly companyServiceBackend = inject(CompanyServicioBackend);

  constructor() {
    this.company$ = this._company$.asObservable();
    this.loadCompany();
  }

  public toggleUserStartups(
    company: CompanyEntity,
    user: UserEntity,
    companyUserStartupDTO: CompanyUserStartupDTO
  ): Observable<void> {
    return this.companyServiceBackend.ToggleUserToStartups(companyUserStartupDTO, company.id, user.id);
  }

  private loadCompany() {
    this.companyServiceBackend
      .GetDefaultCompany()
      .pipe(
        take(1),
        tap(x => this._company$.next(x))
      )
      .subscribe();
  }
}
