<div class="flex border-b border-neutral-300 py-4 text-primary font-semibold">
  <div class="w-4/5 flex">
    <div class="grow pl-8 flex items-center">Variables</div>
    <div class="w-1/4">Value</div>
  </div>
  <div class="w-1/5 text-center">IA Asist</div>
</div>
<div class="flex flex-col">
  <div
    class="flex py-2 border-neutral-300"
    *ngFor="let variable of variables; last as isLast"
    [class.border-b]="!isLast">
    <div class="w-4/5 flex">
      <div class="grow pl-8 flex items-center">{{ variable.variable.name }}</div>
      <div class="w-1/4 flex items-center">
        <span
          class="py-2 px-4 rounded-lg"
          [ngClass]="{
            'bg-red-300': variable.weight === 0,
            'text-red-800': variable.weight === 0,
            'bg-green-300': variable.weight === 10,
            'text-green-800': variable.weight === 10,
            'bg-yellow-200': variable.weight !== 0 && variable.weight !== 10,
            'text-yellow-800': variable.weight !== 0 && variable.weight !== 10
          }">
          {{ variable.value }}
        </span>
      </div>
    </div>
    <div class="w-1/5 text-center">
      <button mat-button color="primary" (click)="openMentorship.emit(variable)" [disabled]="variable.insight === ''">
        Mentorship
      </button>
    </div>
  </div>
</div>
