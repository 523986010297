import { environment } from 'src/environments/environment';
import { LinkVideo } from './interfaces/videos/LinkVideo';

const urlAPI = environment.production
  ? 'https://backend.ninety.us'
  : environment.qa
    ? 'https://qa.backend.ninety.us'
    : environment.develop
      ? 'https://develop.backend.ninety.us'
      : 'http://localhost:5000';

export const searchDelay = 200;

export class Constants {
  public static get apiURL(): string {
    return urlAPI;
  }

  public static get imageURL(): string {
    return `${this.apiURL}/info/image`;
  }

  public static get title(): string {
    return 'Ninety Upsilon Sigma';
  }
}

export const compareDtoId = (entityOne: any, entityTwo: any) =>
  entityOne && entityTwo ? entityOne.id === entityTwo.id : entityOne === entityTwo;

export const helpVideos: LinkVideo[] = [
  {
    title: 'Creating a Startup',
    link: 'https://player.vimeo.com/video/832054014?h=9f7d7aca45&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
  },
  {
    title: 'Creating an Assessment',
    link: 'https://player.vimeo.com/video/832391704?h=6b6de7fdb4&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
  },
  {
    title: 'Creating Startup connections',
    link: 'https://player.vimeo.com/video/832428741?h=44c7fc9eba&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
  },
  {
    title: 'AI Mentorship module',
    link: 'https://player.vimeo.com/video/833473416?h=c6d9ea41a2&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
  },
  {
    title: 'Adding more users',
    link: 'https://player.vimeo.com/video/834484203?h=b944ab529f&amp;title=0&amp;byline=0&amp;portrait=0&amp;speed=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
  }
];
