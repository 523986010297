import { Injectable } from '@angular/core';
import { CompanyBasicDTO } from '@DTOS';
import { Moment } from 'moment';

@Injectable({
  providedIn: 'root'
})
export class GlobalStateService {
  public companySelected: CompanyBasicDTO | null = null;
  public dateSelected: Moment | null = null;

  constructor() {}
}
