import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IListaDTO, ListaDTO } from '@nahuelmorata/framework-frontend';
import { Constants } from '../shared/constants';

import { IStartupCohortScoreDTO, StartupCohortScoreDTO } from '../DTO/StartupCohortScore.dto';
import { ITopicCohortScoreDTO, TopicCohortScoreDTO } from '../DTO/TopicCohortScore.dto';
import { ICohortDTO, CohortDTO } from '../DTO/Cohort.dto';

@Injectable({
  providedIn: 'root'
})
export class CohortServicioBackend {
  constructor(protected http: HttpClient) {}

  public GetScoresStartups(id: number): Observable<ListaDTO<StartupCohortScoreDTO>> {
    return this.http.get<IListaDTO<IStartupCohortScoreDTO>>(`${Constants.apiURL}/cohorts/analysis/${id}`).pipe(
      map(
        lista =>
          new ListaDTO({
            items: lista.items.map(item => new StartupCohortScoreDTO(item)),
            cantidad: lista.cantidad
          })
      )
    );
  }

  public GetScoresTopicsFromStartup(cohortId: number, startupId: number): Observable<ListaDTO<TopicCohortScoreDTO>> {
    return this.http
      .get<IListaDTO<ITopicCohortScoreDTO>>(`${Constants.apiURL}/cohorts/analysis/${cohortId}/topic/${startupId}`)
      .pipe(
        map(
          lista =>
            new ListaDTO({
              items: lista.items.map(item => new TopicCohortScoreDTO(item)),
              cantidad: lista.cantidad
            })
        )
      );
  }

  public GetEntidad(id: number): Observable<CohortDTO> {
    return this.http.get<ICohortDTO>(`${Constants.apiURL}/cohorts/${id}`).pipe(map(entidad => new CohortDTO(entidad)));
  }

  public PutEntidad(cohortDTO: CohortDTO, id: number): Observable<CohortDTO> {
    return this.http
      .put<ICohortDTO>(`${Constants.apiURL}/cohorts/${id}`, cohortDTO)
      .pipe(map(entidad => new CohortDTO(entidad)));
  }

  public DeleteEntidad(id: number): Observable<void> {
    return this.http.delete<void>(`${Constants.apiURL}/cohorts/${id}`);
  }

  public GetEntidades(offset: number = 0, limit: number = 0): Observable<ListaDTO<CohortDTO>> {
    const params = new HttpParams().append('offset', offset.toString()).append('limit', limit.toString());
    const opciones = { params };
    return this.http.get<IListaDTO<ICohortDTO>>(`${Constants.apiURL}/cohorts`, opciones).pipe(
      map(
        lista =>
          new ListaDTO({
            items: lista.items.map(item => new CohortDTO(item)),
            cantidad: lista.cantidad
          })
      )
    );
  }

  public PostEntidad(cohortDTO: CohortDTO): Observable<CohortDTO> {
    return this.http
      .post<ICohortDTO>(`${Constants.apiURL}/cohorts`, cohortDTO)
      .pipe(map(entidad => new CohortDTO(entidad)));
  }
}
