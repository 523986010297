import { Component, Input, Output, ChangeDetectionStrategy, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-empty-screen-info',
  templateUrl: './empty-screen-info.component.html',
  styleUrls: ['./empty-screen-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmptyScreenInfoComponent {
  @Input() public textInfo = '';
  @Input() public showCallback = true;
  @Input() public textCallback = '';
  @Input() public size = 12;
  @Output() public clickCallback = new EventEmitter<void>();
}
