import { ResponseBasicDTO, VariableDTO } from '@DTOS';

export class QuestionAnswerItemModel {
  public question: VariableDTO;
  public answer: ResponseBasicDTO;

  constructor(q: VariableDTO, a: ResponseBasicDTO) {
    this.question = q;
    this.answer = a;
  }
}
