import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';
import { IStartupCompanyBasicDTO, StartupCompanyBasicDTO } from './StartupCompanyBasic.dto';
import { IStartupAnalyticsTopicScoreDTO, StartupAnalyticsTopicScoreDTO } from './StartupAnalyticsTopicScore.dto';

export interface IStartupAnalyticsDTO {
  startup: IStartupCompanyBasicDTO;
  totalValuation: number;
  totalRaisedCapital: number;
  parcialScoreAdded: number;
  scoreAdded: number;
  parcialScoreLastMonth: number;
  scoreLastMonth: number;
  ebitda: number;
  revenue: number;
  customerAcquisitionCost: number;
  averageRevenuePerUser: number;
  monthlyActiveUsers: number;
  customerChurnRate: number;
  monthlyRecurringRevenue: number;
  revenueGrowthRate: number;
  revenueChurnRate: number;
  yearlyRevenueTarget: number;
  yearlyExpensesTarget: number;
  bestTopic: IStartupAnalyticsTopicScoreDTO;
  worstTopic: IStartupAnalyticsTopicScoreDTO;
}

export class StartupAnalyticsDTO implements IDTO<StartupAnalyticsDTO, IStartupAnalyticsDTO> {
  public startup: StartupCompanyBasicDTO = new StartupCompanyBasicDTO();
  public totalValuation: number = 0;
  public totalRaisedCapital: number = 0;
  public parcialScoreAdded: number = 0;
  public scoreAdded: number = 0;
  public parcialScoreLastMonth: number = 0;
  public scoreLastMonth: number = 0;
  public ebitda: number = 0;
  public revenue: number = 0;
  public customerAcquisitionCost: number = 0;
  public averageRevenuePerUser: number = 0;
  public monthlyActiveUsers: number = 0;
  public customerChurnRate: number = 0;
  public monthlyRecurringRevenue: number = 0;
  public revenueGrowthRate: number = 0;
  public revenueChurnRate: number = 0;
  public yearlyRevenueTarget: number = 0;
  public yearlyExpensesTarget: number = 0;
  public bestTopic: StartupAnalyticsTopicScoreDTO = new StartupAnalyticsTopicScoreDTO();
  public worstTopic: StartupAnalyticsTopicScoreDTO = new StartupAnalyticsTopicScoreDTO();

  constructor(json?: Partial<IStartupAnalyticsDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<IStartupAnalyticsDTO>) {
    if (json.startup != null) {
      this.startup = new StartupCompanyBasicDTO(json.startup);
    }
    if (json.totalValuation != null) {
      this.totalValuation = json.totalValuation;
    }
    if (json.totalRaisedCapital != null) {
      this.totalRaisedCapital = json.totalRaisedCapital;
    }
    if (json.parcialScoreAdded != null) {
      this.parcialScoreAdded = json.parcialScoreAdded;
    }
    if (json.scoreAdded != null) {
      this.scoreAdded = json.scoreAdded;
    }
    if (json.parcialScoreLastMonth != null) {
      this.parcialScoreLastMonth = json.parcialScoreLastMonth;
    }
    if (json.scoreLastMonth != null) {
      this.scoreLastMonth = json.scoreLastMonth;
    }
    if (json.ebitda != null) {
      this.ebitda = json.ebitda;
    }
    if (json.revenue != null) {
      this.revenue = json.revenue;
    }
    if (json.customerAcquisitionCost != null) {
      this.customerAcquisitionCost = json.customerAcquisitionCost;
    }
    if (json.averageRevenuePerUser != null) {
      this.averageRevenuePerUser = json.averageRevenuePerUser;
    }
    if (json.monthlyActiveUsers != null) {
      this.monthlyActiveUsers = json.monthlyActiveUsers;
    }
    if (json.customerChurnRate != null) {
      this.customerChurnRate = json.customerChurnRate;
    }
    if (json.monthlyRecurringRevenue != null) {
      this.monthlyRecurringRevenue = json.monthlyRecurringRevenue;
    }
    if (json.revenueGrowthRate != null) {
      this.revenueGrowthRate = json.revenueGrowthRate;
    }
    if (json.revenueChurnRate != null) {
      this.revenueChurnRate = json.revenueChurnRate;
    }
    if (json.yearlyRevenueTarget != null) {
      this.yearlyRevenueTarget = json.yearlyRevenueTarget;
    }
    if (json.yearlyExpensesTarget != null) {
      this.yearlyExpensesTarget = json.yearlyExpensesTarget;
    }
    if (json.bestTopic != null) {
      this.bestTopic = new StartupAnalyticsTopicScoreDTO(json.bestTopic);
    }
    if (json.worstTopic != null) {
      this.worstTopic = new StartupAnalyticsTopicScoreDTO(json.worstTopic);
    }
  }

  public aJSON(): IStartupAnalyticsDTO {
    return {
      startup: this.startup.aJSON(),
      totalValuation: this.totalValuation,
      totalRaisedCapital: this.totalRaisedCapital,
      parcialScoreAdded: this.parcialScoreAdded,
      scoreAdded: this.scoreAdded,
      parcialScoreLastMonth: this.parcialScoreLastMonth,
      scoreLastMonth: this.scoreLastMonth,
      ebitda: this.ebitda,
      revenue: this.revenue,
      customerAcquisitionCost: this.customerAcquisitionCost,
      averageRevenuePerUser: this.averageRevenuePerUser,
      monthlyActiveUsers: this.monthlyActiveUsers,
      customerChurnRate: this.customerChurnRate,
      monthlyRecurringRevenue: this.monthlyRecurringRevenue,
      revenueGrowthRate: this.revenueGrowthRate,
      revenueChurnRate: this.revenueChurnRate,
      yearlyRevenueTarget: this.yearlyRevenueTarget,
      yearlyExpensesTarget: this.yearlyExpensesTarget,
      bestTopic: this.bestTopic.aJSON(),
      worstTopic: this.worstTopic.aJSON()
    };
  }

  public clon<T extends StartupAnalyticsDTO>(clonBase: T): T;
  public clon<T extends StartupAnalyticsDTO>(): StartupAnalyticsDTO;
  public clon<T extends StartupAnalyticsDTO>(clonBase: Nilable<T> = null): T | StartupAnalyticsDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.startup = this.startup.clon();
      clonBase.totalValuation = this.totalValuation;
      clonBase.totalRaisedCapital = this.totalRaisedCapital;
      clonBase.parcialScoreAdded = this.parcialScoreAdded;
      clonBase.scoreAdded = this.scoreAdded;
      clonBase.parcialScoreLastMonth = this.parcialScoreLastMonth;
      clonBase.scoreLastMonth = this.scoreLastMonth;
      clonBase.ebitda = this.ebitda;
      clonBase.revenue = this.revenue;
      clonBase.customerAcquisitionCost = this.customerAcquisitionCost;
      clonBase.averageRevenuePerUser = this.averageRevenuePerUser;
      clonBase.monthlyActiveUsers = this.monthlyActiveUsers;
      clonBase.customerChurnRate = this.customerChurnRate;
      clonBase.monthlyRecurringRevenue = this.monthlyRecurringRevenue;
      clonBase.revenueGrowthRate = this.revenueGrowthRate;
      clonBase.revenueChurnRate = this.revenueChurnRate;
      clonBase.yearlyRevenueTarget = this.yearlyRevenueTarget;
      clonBase.yearlyExpensesTarget = this.yearlyExpensesTarget;
      clonBase.bestTopic = this.bestTopic.clon();
      clonBase.worstTopic = this.worstTopic.clon();
      return clonBase;
    } else {
      const clon = new StartupAnalyticsDTO();
      clon.startup = this.startup.clon();
      clon.totalValuation = this.totalValuation;
      clon.totalRaisedCapital = this.totalRaisedCapital;
      clon.parcialScoreAdded = this.parcialScoreAdded;
      clon.scoreAdded = this.scoreAdded;
      clon.parcialScoreLastMonth = this.parcialScoreLastMonth;
      clon.scoreLastMonth = this.scoreLastMonth;
      clon.ebitda = this.ebitda;
      clon.revenue = this.revenue;
      clon.customerAcquisitionCost = this.customerAcquisitionCost;
      clon.averageRevenuePerUser = this.averageRevenuePerUser;
      clon.monthlyActiveUsers = this.monthlyActiveUsers;
      clon.customerChurnRate = this.customerChurnRate;
      clon.monthlyRecurringRevenue = this.monthlyRecurringRevenue;
      clon.revenueGrowthRate = this.revenueGrowthRate;
      clon.revenueChurnRate = this.revenueChurnRate;
      clon.yearlyRevenueTarget = this.yearlyRevenueTarget;
      clon.yearlyExpensesTarget = this.yearlyExpensesTarget;
      clon.bestTopic = this.bestTopic.clon();
      clon.worstTopic = this.worstTopic.clon();
      return clon;
    }
  }
}

export class StartupAnalyticsModelDTO extends ModelDTO<StartupAnalyticsDTO> {
  constructor(entidad: StartupAnalyticsDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Startup(): StartupCompanyBasicDTO {
    return this._entidad.startup;
  }

  public set Startup(valor: StartupCompanyBasicDTO) {
    this.cambiarPropiedadYNotificar('startup', valor);
  }

  public get TotalValuation(): number {
    return this._entidad.totalValuation;
  }

  public set TotalValuation(valor: number) {
    this.cambiarPropiedadYNotificar('totalValuation', valor);
  }

  public get TotalRaisedCapital(): number {
    return this._entidad.totalRaisedCapital;
  }

  public set TotalRaisedCapital(valor: number) {
    this.cambiarPropiedadYNotificar('totalRaisedCapital', valor);
  }

  public get ParcialScoreAdded(): number {
    return this._entidad.parcialScoreAdded;
  }

  public set ParcialScoreAdded(valor: number) {
    this.cambiarPropiedadYNotificar('parcialScoreAdded', valor);
  }

  public get ScoreAdded(): number {
    return this._entidad.scoreAdded;
  }

  public set ScoreAdded(valor: number) {
    this.cambiarPropiedadYNotificar('scoreAdded', valor);
  }

  public get ParcialScoreLastMonth(): number {
    return this._entidad.parcialScoreLastMonth;
  }

  public set ParcialScoreLastMonth(valor: number) {
    this.cambiarPropiedadYNotificar('parcialScoreLastMonth', valor);
  }

  public get ScoreLastMonth(): number {
    return this._entidad.scoreLastMonth;
  }

  public set ScoreLastMonth(valor: number) {
    this.cambiarPropiedadYNotificar('scoreLastMonth', valor);
  }

  public get Ebitda(): number {
    return this._entidad.ebitda;
  }

  public set Ebitda(valor: number) {
    this.cambiarPropiedadYNotificar('ebitda', valor);
  }

  public get Revenue(): number {
    return this._entidad.revenue;
  }

  public set Revenue(valor: number) {
    this.cambiarPropiedadYNotificar('revenue', valor);
  }

  public get CustomerAcquisitionCost(): number {
    return this._entidad.customerAcquisitionCost;
  }

  public set CustomerAcquisitionCost(valor: number) {
    this.cambiarPropiedadYNotificar('customerAcquisitionCost', valor);
  }

  public get AverageRevenuePerUser(): number {
    return this._entidad.averageRevenuePerUser;
  }

  public set AverageRevenuePerUser(valor: number) {
    this.cambiarPropiedadYNotificar('averageRevenuePerUser', valor);
  }

  public get MonthlyActiveUsers(): number {
    return this._entidad.monthlyActiveUsers;
  }

  public set MonthlyActiveUsers(valor: number) {
    this.cambiarPropiedadYNotificar('monthlyActiveUsers', valor);
  }

  public get CustomerChurnRate(): number {
    return this._entidad.customerChurnRate;
  }

  public set CustomerChurnRate(valor: number) {
    this.cambiarPropiedadYNotificar('customerChurnRate', valor);
  }

  public get MonthlyRecurringRevenue(): number {
    return this._entidad.monthlyRecurringRevenue;
  }

  public set MonthlyRecurringRevenue(valor: number) {
    this.cambiarPropiedadYNotificar('monthlyRecurringRevenue', valor);
  }

  public get RevenueGrowthRate(): number {
    return this._entidad.revenueGrowthRate;
  }

  public set RevenueGrowthRate(valor: number) {
    this.cambiarPropiedadYNotificar('revenueGrowthRate', valor);
  }

  public get RevenueChurnRate(): number {
    return this._entidad.revenueChurnRate;
  }

  public set RevenueChurnRate(valor: number) {
    this.cambiarPropiedadYNotificar('revenueChurnRate', valor);
  }

  public get YearlyRevenueTarget(): number {
    return this._entidad.yearlyRevenueTarget;
  }

  public set YearlyRevenueTarget(valor: number) {
    this.cambiarPropiedadYNotificar('yearlyRevenueTarget', valor);
  }

  public get YearlyExpensesTarget(): number {
    return this._entidad.yearlyExpensesTarget;
  }

  public set YearlyExpensesTarget(valor: number) {
    this.cambiarPropiedadYNotificar('yearlyExpensesTarget', valor);
  }

  public get BestTopic(): StartupAnalyticsTopicScoreDTO {
    return this._entidad.bestTopic;
  }

  public set BestTopic(valor: StartupAnalyticsTopicScoreDTO) {
    this.cambiarPropiedadYNotificar('bestTopic', valor);
  }

  public get WorstTopic(): StartupAnalyticsTopicScoreDTO {
    return this._entidad.worstTopic;
  }

  public set WorstTopic(valor: StartupAnalyticsTopicScoreDTO) {
    this.cambiarPropiedadYNotificar('worstTopic', valor);
  }
}
