import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';
import { IStartupCompanyBasicDTO, StartupCompanyBasicDTO } from './StartupCompanyBasic.dto';

export interface IStartupScoringDTO {
  startup: IStartupCompanyBasicDTO;
  fullScore: number;
  parcialScore: number;
}

export class StartupScoringDTO implements IDTO<StartupScoringDTO, IStartupScoringDTO> {
  public startup: StartupCompanyBasicDTO = new StartupCompanyBasicDTO();
  public fullScore: number = 0;
  public parcialScore: number = 0;

  constructor(json?: Partial<IStartupScoringDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<IStartupScoringDTO>) {
    if (json.startup != null) {
      this.startup = new StartupCompanyBasicDTO(json.startup);
    }
    if (json.fullScore != null) {
      this.fullScore = json.fullScore;
    }
    if (json.parcialScore != null) {
      this.parcialScore = json.parcialScore;
    }
  }

  public aJSON(): IStartupScoringDTO {
    return {
      startup: this.startup.aJSON(),
      fullScore: this.fullScore,
      parcialScore: this.parcialScore
    };
  }

  public clon<T extends StartupScoringDTO>(clonBase: T): T;
  public clon<T extends StartupScoringDTO>(): StartupScoringDTO;
  public clon<T extends StartupScoringDTO>(clonBase: Nilable<T> = null): T | StartupScoringDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.startup = this.startup.clon();
      clonBase.fullScore = this.fullScore;
      clonBase.parcialScore = this.parcialScore;
      return clonBase;
    } else {
      const clon = new StartupScoringDTO();
      clon.startup = this.startup.clon();
      clon.fullScore = this.fullScore;
      clon.parcialScore = this.parcialScore;
      return clon;
    }
  }
}

export class StartupScoringModelDTO extends ModelDTO<StartupScoringDTO> {
  constructor(entidad: StartupScoringDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Startup(): StartupCompanyBasicDTO {
    return this._entidad.startup;
  }

  public set Startup(valor: StartupCompanyBasicDTO) {
    this.cambiarPropiedadYNotificar('startup', valor);
  }

  public get FullScore(): number {
    return this._entidad.fullScore;
  }

  public set FullScore(valor: number) {
    this.cambiarPropiedadYNotificar('fullScore', valor);
  }

  public get ParcialScore(): number {
    return this._entidad.parcialScore;
  }

  public set ParcialScore(valor: number) {
    this.cambiarPropiedadYNotificar('parcialScore', valor);
  }
}
