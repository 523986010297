<div mat-dialog-title class="flex justify-between">
  <h3>{{ videoData.title }}</h3>
  <a (click)="close()">
    <i class="fa-solid fa-xmark fa-lg"></i>
  </a>
</div>
<div mat-dialog-content class="grow">
  <iframe
    *ngIf="safeUrl != null"
    [src]="safeUrl"
    class="border-0 h-full w-full"
    allow="autoplay; fullscreen; picture-in-picture"
    allowfullscreen></iframe>
</div>
