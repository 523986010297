import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { firstValueFrom, Observable, Subscription } from 'rxjs';
import { TopicsService } from 'src/app/layout/settings/topics/topics.service';
import { VariablesService } from 'src/app/layout/settings/variables/variables.service';
import {
  CompanyRuleDTO,
  CompanyRuleTypeEnum,
  FoundingTypeEnum,
  OperatorEnum,
  ProfitTypeEnum,
  ScoringRuleEnum,
  SelectOptionDTO,
  TopicDTO,
  VariableDTO,
  VariableStartupProfileEnum
} from 'src/app/DTO';
import { CompanyRuleForm, CompanyRuleFormValues } from 'src/app/shared/interfaces/forms';
import { CompanyService } from 'src/app/shared/services/company.service';

@Component({
  selector: 'app-generate-rule-vc',
  templateUrl: './generate-rule-vc.component.html',
  styleUrls: ['./generate-rule-vc.component.scss']
})
export class GenerateRuleVCComponent implements OnInit, OnDestroy {
  @Output() public finishLoading = new EventEmitter<void>();
  @Output() public rule = new EventEmitter<CompanyRuleDTO>();
  @Input() public type: CompanyRuleTypeEnum | null;
  @Input() public variable: number | null;
  @Input() public clean: Observable<void> = new Observable();

  protected formGroup = this.formBuilder.group<CompanyRuleForm>({
    type: new FormControl(null, [Validators.required]),
    variable: new FormControl(null, [Validators.required]),
    operator: new FormControl(null, [Validators.required]),
    value: new FormControl(null),
    valueString: new FormControl(null)
  });

  protected variables: VariableDTO[] = [];
  protected options: SelectOptionDTO[] = [];
  protected topics: TopicDTO[] = [];

  protected readonly companyTypeRules = [
    CompanyRuleTypeEnum.Scoring,
    CompanyRuleTypeEnum.Topics,
    CompanyRuleTypeEnum.Answers,
    CompanyRuleTypeEnum.Profile
  ];
  protected readonly operators = [OperatorEnum.Equal, OperatorEnum.NotEqual];
  protected readonly moreOperators = [
    OperatorEnum.Less,
    OperatorEnum.More,
    OperatorEnum.LessEqual,
    OperatorEnum.MoreEqual
  ];

  protected readonly startupProfileVariables = [
    VariableStartupProfileEnum.Investment,
    VariableStartupProfileEnum.TotalFoundingAmount,
    VariableStartupProfileEnum.FoundersQuantity,
    VariableStartupProfileEnum.LastFoundingType,
    VariableStartupProfileEnum.ProfitType,
    VariableStartupProfileEnum.CurrentFounding,
    VariableStartupProfileEnum.QuantityEmployees,
    VariableStartupProfileEnum.Headquarters,
    VariableStartupProfileEnum.PercentageLoansRevenue,
    VariableStartupProfileEnum.PercentageCompanyHandsFounders
  ];
  protected readonly optionsType = new Array<FoundingTypeEnum>(Object.keys(FoundingTypeEnum).length / 2)
    .fill(0)
    .map((_, i) => i);

  protected readonly variableProfileWithNumber = [
    VariableStartupProfileEnum.Investment,
    VariableStartupProfileEnum.FoundersQuantity,
    VariableStartupProfileEnum.TotalFoundingAmount
  ];
  protected readonly profitTypeEnums = [ProfitTypeEnum.ForProfit, ProfitTypeEnum.NonForProfit];

  protected CompanyRuleTypeEnum = CompanyRuleTypeEnum;
  protected ScoringRuleEnum = ScoringRuleEnum;
  protected VariableProfileEnum = VariableStartupProfileEnum;
  protected OperatorEnum = OperatorEnum;

  private subs = new Subscription();

  constructor(
    private formBuilder: FormBuilder,
    private variablesService: VariablesService,
    private topicsService: TopicsService,
    private companyService: CompanyService
  ) {}

  ngOnInit(): void {
    void this.loadDataAsync();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private async loadDataAsync() {
    await this.loadData();
    this.verifyTypeAndControls();
    if (this.variable != null) {
      this.formGroup.patchValue({
        type: this.type,
        variable: this.variable
      });
      if (this.type === CompanyRuleTypeEnum.Profile && this.variable === VariableStartupProfileEnum.Headquarters) {
        this.formGroup.patchValue({
          operator: OperatorEnum.StartsWith
        });
      }
    }
    this.loadSubscriptions();
    this.finishLoading.emit();
  }

  private verifyTypeAndControls({ type, variable }: Partial<CompanyRuleFormValues> = {}) {
    if (this.variable != null) {
      return;
    }
    const {
      variable: variableControl,
      operator: operatorControl,
      value: valueControl,
      valueString: valueStringControl
    } = this.formGroup.controls;
    if (type == null) {
      variableControl.disable({ emitEvent: false });
      operatorControl.disable({ emitEvent: false });
      valueControl.disable({ emitEvent: false });
      valueStringControl.disable({ emitEvent: false });
      return;
    }
    this.formGroup.enable({ emitEvent: false });

    if (type === CompanyRuleTypeEnum.Answers) {
      if (variable == null) {
        valueControl.disable({ emitEvent: false });
        valueStringControl.disable({ emitEvent: false });
      }
      return;
    }
    if (type === CompanyRuleTypeEnum.Profile) {
      if (variable === VariableStartupProfileEnum.Headquarters) {
        this.formGroup.patchValue(
          {
            operator: OperatorEnum.StartsWith
          },
          { emitEvent: false }
        );
        operatorControl.disable({ emitEvent: false });
      }
      return;
    }
  }

  private async loadData() {
    this.variables = await firstValueFrom(this.variablesService.getVariablesAll());
    this.topics = await firstValueFrom(this.topicsService.getTopics());
    if (this.companyService.companyRuleEditing != null) {
      this.loadEditRule();
    }
  }

  private loadEditRule() {
    const rule = this.companyService.companyRuleEditing;
    this.formGroup.setValue({
      operator: rule.operator,
      type: rule.type,
      value: rule.value,
      variable: rule.variable,
      valueString: rule.valueString
    });
  }

  private loadSubscriptions() {
    this.subs.add(
      this.formGroup.valueChanges.subscribe(values => {
        if (values.type === CompanyRuleTypeEnum.Answers && values.variable != null) {
          this.options = this.variables.find(variable => variable.id === values.variable)?.selectOptions ?? [];
        }
        this.verifyTypeAndControls(this.formGroup.value);
        if (this.formGroup.valid) {
          const rule = new CompanyRuleDTO();
          rule.cargarDTO(this.formGroup.value);
          this.rule.emit(rule);
        }
      })
    );
    this.subs.add(
      this.formGroup.controls.type.valueChanges.subscribe(() => {
        this.formGroup.patchValue(
          {
            variable: this.variable,
            value: null,
            operator: null,
            valueString: null
          },
          { emitEvent: false }
        );
      })
    );
    this.subs.add(
      this.clean.subscribe(() => {
        this.formGroup.reset();
      })
    );
  }
}
