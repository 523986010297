import { RoleMapper } from '@adapters/role';
import { UserEntity } from '@domain/user';
import { UsuarioDTO } from '@nahuelmorata/framework-frontend';

export class UserMapper {
  static toEntity(user: UsuarioDTO): UserEntity {
    return {
      id: user.id,
      user: user.user,
      names: user.nombres,
      lastnames: user.apellidos,
      role: RoleMapper.toEntity(user.rol)
    };
  }

  static clone(user: UserEntity): UserEntity {
    return {
      id: user.id,
      user: user.user,
      names: user.names,
      lastnames: user.lastnames,
      role: RoleMapper.clone(user.role)
    };
  }

  static toDTO(user: UserEntity): UsuarioDTO {
    const userDTO = new UsuarioDTO({
      user: user.user,
      nombres: user.names,
      apellidos: user.lastnames,
      rol: RoleMapper.toDTO(user.role)
    });
    userDTO.id = user.id;
    return userDTO;
  }
}
