import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';
import { ProfitTypeEnum } from './ProfitType.enum';

export interface ICompanyBasicDTO {
  id: number;
  name: string;
  image: string;
  investmentRange: number;
  description: string;
  headquarters: string;
  funds: number;
  investments: number;
  leadInvestments: number;
  exits: number;
  foundingDate: string;
  profitType: number;
  linkedin: string;
  website: string;
  phoneNumber: string;
  email: string;
  confirmCorrectInfo: boolean;
  showInDiscover: boolean;
}

export class CompanyBasicDTO implements IDTO<CompanyBasicDTO, ICompanyBasicDTO> {
  public id: number = 0;
  public name: string = '';
  public image: string = '';
  public investmentRange: number = 0;
  public description: string = '';
  public headquarters: string = '';
  public funds: number = 0;
  public investments: number = 0;
  public leadInvestments: number = 0;
  public exits: number = 0;
  public foundingDate: Date = new Date();
  public profitType: ProfitTypeEnum = ProfitTypeEnum.ForProfit;
  public linkedin: string = '';
  public website: string = '';
  public phoneNumber: string = '';
  public email: string = '';
  public confirmCorrectInfo: boolean = true;
  public showInDiscover: boolean = true;

  constructor(json?: Partial<ICompanyBasicDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<ICompanyBasicDTO>) {
    if (json.id != null) {
      this.id = json.id;
    }
    if (json.name != null) {
      this.name = json.name;
    }
    if (json.image != null) {
      this.image = json.image;
    }
    if (json.investmentRange != null) {
      this.investmentRange = json.investmentRange;
    }
    if (json.description != null) {
      this.description = json.description;
    }
    if (json.headquarters != null) {
      this.headquarters = json.headquarters;
    }
    if (json.funds != null) {
      this.funds = json.funds;
    }
    if (json.investments != null) {
      this.investments = json.investments;
    }
    if (json.leadInvestments != null) {
      this.leadInvestments = json.leadInvestments;
    }
    if (json.exits != null) {
      this.exits = json.exits;
    }
    if (json.foundingDate != null) {
      this.foundingDate = new Date(json.foundingDate);
    }
    if (json.profitType != null) {
      this.profitType = json.profitType as ProfitTypeEnum;
    }
    if (json.linkedin != null) {
      this.linkedin = json.linkedin;
    }
    if (json.website != null) {
      this.website = json.website;
    }
    if (json.phoneNumber != null) {
      this.phoneNumber = json.phoneNumber;
    }
    if (json.email != null) {
      this.email = json.email;
    }
    if (json.confirmCorrectInfo != null) {
      this.confirmCorrectInfo = json.confirmCorrectInfo;
    }
    if (json.showInDiscover != null) {
      this.showInDiscover = json.showInDiscover;
    }
  }

  public aJSON(): ICompanyBasicDTO {
    return {
      id: this.id,
      name: this.name,
      image: this.image,
      investmentRange: this.investmentRange,
      description: this.description,
      headquarters: this.headquarters,
      funds: this.funds,
      investments: this.investments,
      leadInvestments: this.leadInvestments,
      exits: this.exits,
      foundingDate: this.foundingDate.toJSON(),
      profitType: this.profitType,
      linkedin: this.linkedin,
      website: this.website,
      phoneNumber: this.phoneNumber,
      email: this.email,
      confirmCorrectInfo: this.confirmCorrectInfo,
      showInDiscover: this.showInDiscover
    };
  }

  public clon<T extends CompanyBasicDTO>(clonBase: T): T;
  public clon<T extends CompanyBasicDTO>(): CompanyBasicDTO;
  public clon<T extends CompanyBasicDTO>(clonBase: Nilable<T> = null): T | CompanyBasicDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.id = this.id;
      clonBase.name = this.name;
      clonBase.image = this.image;
      clonBase.investmentRange = this.investmentRange;
      clonBase.description = this.description;
      clonBase.headquarters = this.headquarters;
      clonBase.funds = this.funds;
      clonBase.investments = this.investments;
      clonBase.leadInvestments = this.leadInvestments;
      clonBase.exits = this.exits;
      clonBase.foundingDate = new Date(this.foundingDate.toJSON());
      clonBase.profitType = this.profitType;
      clonBase.linkedin = this.linkedin;
      clonBase.website = this.website;
      clonBase.phoneNumber = this.phoneNumber;
      clonBase.email = this.email;
      clonBase.confirmCorrectInfo = this.confirmCorrectInfo;
      clonBase.showInDiscover = this.showInDiscover;
      return clonBase;
    } else {
      const clon = new CompanyBasicDTO();
      clon.id = this.id;
      clon.name = this.name;
      clon.image = this.image;
      clon.investmentRange = this.investmentRange;
      clon.description = this.description;
      clon.headquarters = this.headquarters;
      clon.funds = this.funds;
      clon.investments = this.investments;
      clon.leadInvestments = this.leadInvestments;
      clon.exits = this.exits;
      clon.foundingDate = new Date(this.foundingDate.toJSON());
      clon.profitType = this.profitType;
      clon.linkedin = this.linkedin;
      clon.website = this.website;
      clon.phoneNumber = this.phoneNumber;
      clon.email = this.email;
      clon.confirmCorrectInfo = this.confirmCorrectInfo;
      clon.showInDiscover = this.showInDiscover;
      return clon;
    }
  }
}

export class CompanyBasicModelDTO extends ModelDTO<CompanyBasicDTO> {
  constructor(entidad: CompanyBasicDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Id(): number {
    return this._entidad.id;
  }

  public set Id(valor: number) {
    this.cambiarPropiedadYNotificar('id', valor);
  }

  public get Name(): string {
    return this._entidad.name;
  }

  public set Name(valor: string) {
    this.cambiarPropiedadYNotificar('name', valor);
  }

  public get Image(): string {
    return this._entidad.image;
  }

  public set Image(valor: string) {
    this.cambiarPropiedadYNotificar('image', valor);
  }

  public get InvestmentRange(): number {
    return this._entidad.investmentRange;
  }

  public set InvestmentRange(valor: number) {
    this.cambiarPropiedadYNotificar('investmentRange', valor);
  }

  public get Description(): string {
    return this._entidad.description;
  }

  public set Description(valor: string) {
    this.cambiarPropiedadYNotificar('description', valor);
  }

  public get Headquarters(): string {
    return this._entidad.headquarters;
  }

  public set Headquarters(valor: string) {
    this.cambiarPropiedadYNotificar('headquarters', valor);
  }

  public get Funds(): number {
    return this._entidad.funds;
  }

  public set Funds(valor: number) {
    this.cambiarPropiedadYNotificar('funds', valor);
  }

  public get Investments(): number {
    return this._entidad.investments;
  }

  public set Investments(valor: number) {
    this.cambiarPropiedadYNotificar('investments', valor);
  }

  public get LeadInvestments(): number {
    return this._entidad.leadInvestments;
  }

  public set LeadInvestments(valor: number) {
    this.cambiarPropiedadYNotificar('leadInvestments', valor);
  }

  public get Exits(): number {
    return this._entidad.exits;
  }

  public set Exits(valor: number) {
    this.cambiarPropiedadYNotificar('exits', valor);
  }

  public get FoundingDate(): Date {
    return this._entidad.foundingDate;
  }

  public set FoundingDate(valor: Date) {
    this.cambiarPropiedadYNotificar('foundingDate', valor);
  }

  public get ProfitType(): ProfitTypeEnum {
    return this._entidad.profitType;
  }

  public set ProfitType(valor: ProfitTypeEnum) {
    this.cambiarPropiedadYNotificar('profitType', valor);
  }

  public get Linkedin(): string {
    return this._entidad.linkedin;
  }

  public set Linkedin(valor: string) {
    this.cambiarPropiedadYNotificar('linkedin', valor);
  }

  public get Website(): string {
    return this._entidad.website;
  }

  public set Website(valor: string) {
    this.cambiarPropiedadYNotificar('website', valor);
  }

  public get PhoneNumber(): string {
    return this._entidad.phoneNumber;
  }

  public set PhoneNumber(valor: string) {
    this.cambiarPropiedadYNotificar('phoneNumber', valor);
  }

  public get Email(): string {
    return this._entidad.email;
  }

  public set Email(valor: string) {
    this.cambiarPropiedadYNotificar('email', valor);
  }

  public get ConfirmCorrectInfo(): boolean {
    return this._entidad.confirmCorrectInfo;
  }

  public set ConfirmCorrectInfo(valor: boolean) {
    this.cambiarPropiedadYNotificar('confirmCorrectInfo', valor);
  }

  public get ShowInDiscover(): boolean {
    return this._entidad.showInDiscover;
  }

  public set ShowInDiscover(valor: boolean) {
    this.cambiarPropiedadYNotificar('showInDiscover', valor);
  }
}
