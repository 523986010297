import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';
import { APP_INITIALIZER, Injectable, LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { CoreModule } from './core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { environment } from 'src/environments/environment';
import { NotifierModule } from '@nahuelmorata/angular-notifier';
import { ModalService } from './app-shared/services/modal/modal.service';
import { GlobalState } from './shared/store/state/GlobalState';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule } from '@ngxs/store';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { providers } from './shared/providers';
import { UserState } from './shared/store/state/UserState';
import { coreProviders } from './core/providers';
import { FrameworkFrontendModule } from '@nahuelmorata/framework-frontend';
import { Constants } from './shared/constants';
import { MatIconRegistry } from '@angular/material/icon';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS, MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { MONTH_FORMATS } from './shared/formats';
import { infraestructureProviders } from './infraestructure/infraestructure.providers';

const initializeApp = (_: ModalService) => () => {};

@Injectable()
export class CustomHammerConfig extends HammerGestureConfig {
  overrides = {
    pinch: { enable: false },
    rotate: { enable: false }
  };
}

const mainModules = environment.production
  ? [
      GoogleTagManagerModule.forRoot({
        id: 'GTM-PCBK35ZS'
      })
    ]
  : [];

const devModules =
  !environment.production && !environment.qa && !environment.develop ? [NgxsReduxDevtoolsPluginModule.forRoot()] : [];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    AppRoutingModule,
    HammerModule,
    NgxMatSelectSearchModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production || environment.qa || environment.develop,
      registrationStrategy: 'registerWhenStable:30000'
    }),
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: 'right'
        },
        vertical: {
          position: 'top'
        }
      }
    }),
    FrameworkFrontendModule.forRoot({
      apiURL: Constants.apiURL,
      logger: {
        transportadores: {
          consola: true,
          rollbar: {
            activado: environment.production || environment.qa || environment.develop,
            configuracion: {
              accessToken: '316e3078693a461e8b9faf0a67eb5376',
              captureUncaught: true,
              captureUnhandledRejections: true,
              environment: environment.production ? 'Production' : environment.qa ? 'QA' : 'Develop',
              payload: {
                environment: environment.production ? 'Production' : environment.qa ? 'QA' : 'Develop',
                client: {
                  javascript: {
                    guess_uncaught_frames: true,
                    source_map_enabled: true,
                    code_version: environment.version
                  }
                }
              }
            }
          }
        }
      },
      desarrollo: !environment.production && !environment.qa && !environment.develop
    }),
    NgxsModule.forRoot([GlobalState, UserState]),
    MatMomentDateModule,
    ...mainModules,
    ...devModules
  ],
  providers: [
    { provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerConfig },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { disableClose: false } },
    { provide: LOCALE_ID, useValue: 'en-US' },
    { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [ModalService], multi: true },
    ...coreProviders,
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: { disabled: true } },
    { provide: MAT_DATE_FORMATS, useValue: MONTH_FORMATS },
    ...providers,
    ...infraestructureProviders
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private iconRegistry: MatIconRegistry) {
    this.iconRegistry.setDefaultFontSetClass('material-symbols-rounded');
  }
}
