import { RoleEntity } from '@domain/role';
import { RolDTO } from '@nahuelmorata/framework-frontend';
import { List } from 'immutable';

export class RoleMapper {
  static toEntity(role: RolDTO): RoleEntity {
    return {
      id: role.id,
      name: role.nombre,
      description: role.descripcion
    };
  }

  static clone(role: RoleEntity): RoleEntity {
    return {
      id: role.id,
      name: role.name,
      description: role.description,
      permissions: List(role.permissions)
    };
  }

  static toDTO(role: RoleEntity): RolDTO {
    const roleDTO = new RolDTO({
      nombre: role.name,
      descripcion: role.description
    });
    roleDTO.id = role.id;
    return roleDTO;
  }
}
