import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';
import { IStartupCompanyBasicDTO, StartupCompanyBasicDTO } from './StartupCompanyBasic.dto';

export interface ICohortDTO {
  id: number;
  cacheStamp: number;
  name: string;
  description: string;
  version: number;
  startDate: string;
  endDate: string;
  startups: IStartupCompanyBasicDTO[];
}

export class CohortDTO implements IDTO<CohortDTO, ICohortDTO> {
  public id: number = 0;
  public cacheStamp: number = 0;
  public name: string = '';
  public description: string = '';
  public version: number = 0;
  public startDate: Date = new Date();
  public endDate: Date = new Date();
  public startups: StartupCompanyBasicDTO[] = [];

  constructor(json?: Partial<ICohortDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<ICohortDTO>) {
    if (json.id != null) {
      this.id = json.id;
    }
    if (json.cacheStamp != null) {
      this.cacheStamp = json.cacheStamp;
    }
    if (json.name != null) {
      this.name = json.name;
    }
    if (json.description != null) {
      this.description = json.description;
    }
    if (json.version != null) {
      this.version = json.version;
    }
    if (json.startDate != null) {
      this.startDate = new Date(json.startDate);
    }
    if (json.endDate != null) {
      this.endDate = new Date(json.endDate);
    }
    if (json.startups != null) {
      this.startups = json.startups.map(item => new StartupCompanyBasicDTO(item));
    }
  }

  public aJSON(): ICohortDTO {
    return {
      id: this.id,
      cacheStamp: this.cacheStamp,
      name: this.name,
      description: this.description,
      version: this.version,
      startDate: this.startDate.toJSON(),
      endDate: this.endDate.toJSON(),
      startups: this.startups.map(item => item.aJSON())
    };
  }

  public clon<T extends CohortDTO>(clonBase: T): T;
  public clon<T extends CohortDTO>(): CohortDTO;
  public clon<T extends CohortDTO>(clonBase: Nilable<T> = null): T | CohortDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.id = this.id;
      clonBase.cacheStamp = this.cacheStamp;
      clonBase.name = this.name;
      clonBase.description = this.description;
      clonBase.version = this.version;
      clonBase.startDate = new Date(this.startDate.toJSON());
      clonBase.endDate = new Date(this.endDate.toJSON());
      clonBase.startups = this.startups.map(item => item.clon());
      return clonBase;
    } else {
      const clon = new CohortDTO();
      clon.id = this.id;
      clon.cacheStamp = this.cacheStamp;
      clon.name = this.name;
      clon.description = this.description;
      clon.version = this.version;
      clon.startDate = new Date(this.startDate.toJSON());
      clon.endDate = new Date(this.endDate.toJSON());
      clon.startups = this.startups.map(item => item.clon());
      return clon;
    }
  }
}

export class CohortModelDTO extends ModelDTO<CohortDTO> {
  constructor(entidad: CohortDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Id(): number {
    return this._entidad.id;
  }

  public set Id(valor: number) {
    this.cambiarPropiedadYNotificar('id', valor);
  }

  public get CacheStamp(): number {
    return this._entidad.cacheStamp;
  }

  public set CacheStamp(valor: number) {
    this.cambiarPropiedadYNotificar('cacheStamp', valor);
  }

  public get Name(): string {
    return this._entidad.name;
  }

  public set Name(valor: string) {
    this.cambiarPropiedadYNotificar('name', valor);
  }

  public get Description(): string {
    return this._entidad.description;
  }

  public set Description(valor: string) {
    this.cambiarPropiedadYNotificar('description', valor);
  }

  public get Version(): number {
    return this._entidad.version;
  }

  public set Version(valor: number) {
    this.cambiarPropiedadYNotificar('version', valor);
  }

  public get StartDate(): Date {
    return this._entidad.startDate;
  }

  public set StartDate(valor: Date) {
    this.cambiarPropiedadYNotificar('startDate', valor);
  }

  public get EndDate(): Date {
    return this._entidad.endDate;
  }

  public set EndDate(valor: Date) {
    this.cambiarPropiedadYNotificar('endDate', valor);
  }

  public get Startups(): StartupCompanyBasicDTO[] {
    return this._entidad.startups;
  }

  public set Startups(valor: StartupCompanyBasicDTO[]) {
    this.cambiarPropiedadYNotificar('startups', valor);
  }
}
