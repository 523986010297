import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mapArray'
})
export class MapArrayPipe implements PipeTransform {
  transform<T, U>(value: T[], mapCallback: (element: T, ...parameters: any[]) => U, ...parameters: any[]): U[] {
    return value.map(element => mapCallback(element, ...parameters));
  }
}
