<h1 mat-dialog-title>Create an alert</h1>
<div mat-dialog-content>
  <form [formGroup]="alertFormGroup" class="flex flex-col items-stretch">
    <mat-form-field>
      <mat-label>Title</mat-label>
      <input matInput formControlName="shortMessage" required />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Message</mat-label>
      <textarea matInput formControlName="message" required rows="3"></textarea>
    </mat-form-field>
    <div class="flex">
      <mat-form-field>
        <mat-label>Send after</mat-label>
        <input type="number" matInput formControlName="countTimeAfter" required />
      </mat-form-field>
      <mat-form-field>
        <mat-select required formControlName="timeType">
          <mat-option [value]="0">Hours</mat-option>
          <mat-option [value]="1">Days</mat-option>
          <mat-option [value]="2">Weeks</mat-option>
          <mat-option [value]="3">Months</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <mat-form-field>
      <mat-label>Send by</mat-label>
      <mat-select required formControlName="platformsSend" multiple>
        <mat-option [value]="1">System</mat-option>
        <mat-option [value]="2">Email</mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions align="end">
  <button mat-button (click)="cancel()">Cancel</button>
  <button mat-button (click)="create()" [disabled]="alertFormGroup.invalid">Create</button>
</div>
