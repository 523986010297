import { UserPasswordEntity } from '@domain/user';
import { UsuarioPasswordDTO } from '@nahuelmorata/framework-frontend';

export class UserPasswordMapper {
  static toDTO(userPassword: UserPasswordEntity): UsuarioPasswordDTO {
    return new UsuarioPasswordDTO({
      passwordActual: userPassword.currentPassword,
      passwordNueva: userPassword.newPassword,
      passwordVerificacion: userPassword.verificationPassword
    });
  }
}
