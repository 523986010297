import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';
import { IVariableOptionAnalyticsDTO, VariableOptionAnalyticsDTO } from './VariableOptionAnalytics.dto';

export interface IInsightsStartupDTO {
  variables: IVariableOptionAnalyticsDTO[];
}

export class InsightsStartupDTO implements IDTO<InsightsStartupDTO, IInsightsStartupDTO> {
  public variables: VariableOptionAnalyticsDTO[] = [];

  constructor(json?: Partial<IInsightsStartupDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<IInsightsStartupDTO>) {
    if (json.variables != null) {
      this.variables = json.variables.map(item => new VariableOptionAnalyticsDTO(item));
    }
  }

  public aJSON(): IInsightsStartupDTO {
    return {
      variables: this.variables.map(item => item.aJSON())
    };
  }

  public clon<T extends InsightsStartupDTO>(clonBase: T): T;
  public clon<T extends InsightsStartupDTO>(): InsightsStartupDTO;
  public clon<T extends InsightsStartupDTO>(clonBase: Nilable<T> = null): T | InsightsStartupDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.variables = this.variables.map(item => item.clon());
      return clonBase;
    } else {
      const clon = new InsightsStartupDTO();
      clon.variables = this.variables.map(item => item.clon());
      return clon;
    }
  }
}

export class InsightsStartupModelDTO extends ModelDTO<InsightsStartupDTO> {
  constructor(entidad: InsightsStartupDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Variables(): VariableOptionAnalyticsDTO[] {
    return this._entidad.variables;
  }

  public set Variables(valor: VariableOptionAnalyticsDTO[]) {
    this.cambiarPropiedadYNotificar('variables', valor);
  }
}
