import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IListaDTO, ListaDTO } from '@nahuelmorata/framework-frontend';
import { Constants } from '../shared/constants';
import { Moment } from 'moment';

import { SurveyResponseDTO } from '../DTO/SurveyResponse.dto';
import { IResponseBasicDTO, ResponseBasicDTO } from '../DTO/ResponseBasic.dto';
import { ICompletedSurveysResponseDTO, CompletedSurveysResponseDTO } from '../DTO/CompletedSurveysResponse.dto';
import { IScoreDTO, ScoreDTO } from '../DTO/Score.dto';

@Injectable({
  providedIn: 'root'
})
export class SurveyServicioBackend {
  constructor(protected http: HttpClient) {}

  public SaveAnswers(surveyResponseDTO: SurveyResponseDTO): Observable<void> {
    return this.http.post<void>(`${Constants.apiURL}/survey/answer`, surveyResponseDTO);
  }

  public GetAnswersStartup(
    companyId: number,
    startupId: number,
    month: number,
    year: number
  ): Observable<ListaDTO<ResponseBasicDTO>> {
    const params = new HttpParams().append('month', month.toString()).append('year', year.toString());
    const opciones = { params };
    return this.http
      .get<IListaDTO<IResponseBasicDTO>>(`${Constants.apiURL}/survey/answer/${companyId}/${startupId}`, opciones)
      .pipe(
        map(
          lista =>
            new ListaDTO({
              items: lista.items.map(item => new ResponseBasicDTO(item)),
              cantidad: lista.cantidad
            })
        )
      );
  }

  public GetCompletedAnswersStartup(
    companyId: number,
    startupId: number
  ): Observable<ListaDTO<CompletedSurveysResponseDTO>> {
    return this.http
      .get<
        IListaDTO<ICompletedSurveysResponseDTO>
      >(`${Constants.apiURL}/survey/answer/completed/${companyId}/${startupId}`)
      .pipe(
        map(
          lista =>
            new ListaDTO({
              items: lista.items.map(item => new CompletedSurveysResponseDTO(item)),
              cantidad: lista.cantidad
            })
        )
      );
  }

  public GetStartupsWithScore(companyId: number, answer: string): Observable<ListaDTO<ScoreDTO>> {
    const params = new HttpParams().append('answer', answer);
    const opciones = { params };
    return this.http.get<IListaDTO<IScoreDTO>>(`${Constants.apiURL}/survey/score/${companyId}`, opciones).pipe(
      map(
        lista =>
          new ListaDTO({
            items: lista.items.map(item => new ScoreDTO(item)),
            cantidad: lista.cantidad
          })
      )
    );
  }

  public LastAnsweredByStartup(startupId: number, month: Moment): Observable<ListaDTO<ResponseBasicDTO>> {
    const params = new HttpParams().append('month', month.format('YYYY-MM-DD'));
    const opciones = { params };
    return this.http
      .get<IListaDTO<IResponseBasicDTO>>(`${Constants.apiURL}/survey/last/answered/${startupId}`, opciones)
      .pipe(
        map(
          lista =>
            new ListaDTO({
              items: lista.items.map(item => new ResponseBasicDTO(item)),
              cantidad: lista.cantidad
            })
        )
      );
  }
}
