import { Component, input, Input } from '@angular/core';
import { DashboardAnalyticsDTO } from '@DTOS';
import { CardTypeEnum } from 'src/app/shared/enums/CardType.enum';

@Component({
  selector: 'app-scoring-information',
  templateUrl: './scoring-information.component.html',
  styleUrls: ['./scoring-information.component.scss']
})
export class ScoringInformationComponent {
  @Input() public dashboardAnalytics = new DashboardAnalyticsDTO();
  public isMobile = input<boolean>(false);
  protected CardTypeEnum = CardTypeEnum;
}
