import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';
import { ISearchStartupDTO, SearchStartupDTO } from './SearchStartup.dto';

export interface ISearchStartupSizeDTO {
  startups: ISearchStartupDTO[];
  size: number;
}

export class SearchStartupSizeDTO implements IDTO<SearchStartupSizeDTO, ISearchStartupSizeDTO> {
  public startups: SearchStartupDTO[] = [];
  public size: number = 0;

  constructor(json?: Partial<ISearchStartupSizeDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<ISearchStartupSizeDTO>) {
    if (json.startups != null) {
      this.startups = json.startups.map(item => new SearchStartupDTO(item));
    }
    if (json.size != null) {
      this.size = json.size;
    }
  }

  public aJSON(): ISearchStartupSizeDTO {
    return {
      startups: this.startups.map(item => item.aJSON()),
      size: this.size
    };
  }

  public clon<T extends SearchStartupSizeDTO>(clonBase: T): T;
  public clon<T extends SearchStartupSizeDTO>(): SearchStartupSizeDTO;
  public clon<T extends SearchStartupSizeDTO>(clonBase: Nilable<T> = null): T | SearchStartupSizeDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.startups = this.startups.map(item => item.clon());
      clonBase.size = this.size;
      return clonBase;
    } else {
      const clon = new SearchStartupSizeDTO();
      clon.startups = this.startups.map(item => item.clon());
      clon.size = this.size;
      return clon;
    }
  }
}

export class SearchStartupSizeModelDTO extends ModelDTO<SearchStartupSizeDTO> {
  constructor(entidad: SearchStartupSizeDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Startups(): SearchStartupDTO[] {
    return this._entidad.startups;
  }

  public set Startups(valor: SearchStartupDTO[]) {
    this.cambiarPropiedadYNotificar('startups', valor);
  }

  public get Size(): number {
    return this._entidad.size;
  }

  public set Size(valor: number) {
    this.cambiarPropiedadYNotificar('size', valor);
  }
}
