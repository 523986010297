<div *ngIf="questionAnswer.question.type === variableTypeEnum.Range" class="grow flex items-end">
  <mat-form-field class="grow form-field-slider-numero without-padding">
    <input
      matInput
      type="number"
      [(ngModel)]="questionAnswer.answer.valueNumber"
      name="answer"
      class="text-right"
      [id]="'answer_' + questionAnswer.answer.id"
      [disabled]="isViewOnly" />
  </mat-form-field>
</div>
<mat-form-field
  class="grow without-padding"
  *ngIf="questionAnswer.question.type === variableTypeEnum.Select"
  appearance="fill">
  <mat-select
    [id]="'answer_' + questionAnswer.answer.id"
    name="answer"
    [disabled]="isViewOnly"
    [(ngModel)]="questionAnswer.answer.valueNumber">
    <mat-option [value]="0" selected disabled hidden> Select an option </mat-option>
    <mat-option *ngFor="let posibleAnswer of questionAnswer.question.selectOptions" [value]="posibleAnswer.id">
      {{ posibleAnswer.text }}
    </mat-option>
  </mat-select>
</mat-form-field>
<mat-form-field class="grow without-padding" *ngIf="questionAnswer.question.type === variableTypeEnum.Number">
  <input
    matInput
    class="text-right"
    type="number"
    [id]="'answer_' + questionAnswer.answer.id"
    name="answer"
    [max]="questionAnswer.question.limits.max"
    [min]="questionAnswer.question.limits.min"
    [(ngModel)]="questionAnswer.answer.valueNumber"
    [disabled]="isViewOnly" />
</mat-form-field>
<mat-form-field class="grow without-padding" *ngIf="questionAnswer.question.type === variableTypeEnum.Text">
  <input
    matInput
    type="text"
    [id]="'answer_' + questionAnswer.answer.id"
    name="answer"
    [disabled]="isViewOnly"
    placeholder="{{ questionAnswer.question.name }}"
    [(ngModel)]="questionAnswer.answer.valueText" />
</mat-form-field>
