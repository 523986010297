import { ProfitTypeEnum } from '@DTOS';

export const formatProfitType = (type: ProfitTypeEnum): string => {
  switch (type) {
    case ProfitTypeEnum.ForProfit:
      return 'For Profit';
    case ProfitTypeEnum.NonForProfit:
      return 'Non Profit';
  }
};
