import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';

export interface ISubscriptionInfoDTO {
  hasPayment: boolean;
  trialEnds: string;
}

export class SubscriptionInfoDTO implements IDTO<SubscriptionInfoDTO, ISubscriptionInfoDTO> {
  public hasPayment: boolean = true;
  public trialEnds: Nilable<Date>;

  constructor(json?: Partial<ISubscriptionInfoDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<ISubscriptionInfoDTO>) {
    if (json.hasPayment != null) {
      this.hasPayment = json.hasPayment;
    }
    if (json.trialEnds != null) {
      this.trialEnds = new Date(json.trialEnds);
    }
  }

  public aJSON(): ISubscriptionInfoDTO {
    return {
      hasPayment: this.hasPayment,
      trialEnds: this.trialEnds.toJSON()
    };
  }

  public clon<T extends SubscriptionInfoDTO>(clonBase: T): T;
  public clon<T extends SubscriptionInfoDTO>(): SubscriptionInfoDTO;
  public clon<T extends SubscriptionInfoDTO>(clonBase: Nilable<T> = null): T | SubscriptionInfoDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.hasPayment = this.hasPayment;
      clonBase.trialEnds = new Date(this.trialEnds.toJSON());
      return clonBase;
    } else {
      const clon = new SubscriptionInfoDTO();
      clon.hasPayment = this.hasPayment;
      clon.trialEnds = new Date(this.trialEnds.toJSON());
      return clon;
    }
  }
}

export class SubscriptionInfoModelDTO extends ModelDTO<SubscriptionInfoDTO> {
  constructor(entidad: SubscriptionInfoDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get HasPayment(): boolean {
    return this._entidad.hasPayment;
  }

  public set HasPayment(valor: boolean) {
    this.cambiarPropiedadYNotificar('hasPayment', valor);
  }

  public get TrialEnds(): Date {
    return this._entidad.trialEnds;
  }

  public set TrialEnds(valor: Date) {
    this.cambiarPropiedadYNotificar('trialEnds', valor);
  }
}
