import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';
import { IStartupCompanyBasicDTO, StartupCompanyBasicDTO } from './StartupCompanyBasic.dto';

export interface IScoreDTO {
  startup: IStartupCompanyBasicDTO;
  score: number;
  completed: boolean;
}

export class ScoreDTO implements IDTO<ScoreDTO, IScoreDTO> {
  public startup: StartupCompanyBasicDTO = new StartupCompanyBasicDTO();
  public score: number = 0;
  public completed: boolean = true;

  constructor(json?: Partial<IScoreDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<IScoreDTO>) {
    if (json.startup != null) {
      this.startup = new StartupCompanyBasicDTO(json.startup);
    }
    if (json.score != null) {
      this.score = json.score;
    }
    if (json.completed != null) {
      this.completed = json.completed;
    }
  }

  public aJSON(): IScoreDTO {
    return {
      startup: this.startup.aJSON(),
      score: this.score,
      completed: this.completed
    };
  }

  public clon<T extends ScoreDTO>(clonBase: T): T;
  public clon<T extends ScoreDTO>(): ScoreDTO;
  public clon<T extends ScoreDTO>(clonBase: Nilable<T> = null): T | ScoreDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.startup = this.startup.clon();
      clonBase.score = this.score;
      clonBase.completed = this.completed;
      return clonBase;
    } else {
      const clon = new ScoreDTO();
      clon.startup = this.startup.clon();
      clon.score = this.score;
      clon.completed = this.completed;
      return clon;
    }
  }
}

export class ScoreModelDTO extends ModelDTO<ScoreDTO> {
  constructor(entidad: ScoreDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Startup(): StartupCompanyBasicDTO {
    return this._entidad.startup;
  }

  public set Startup(valor: StartupCompanyBasicDTO) {
    this.cambiarPropiedadYNotificar('startup', valor);
  }

  public get Score(): number {
    return this._entidad.score;
  }

  public set Score(valor: number) {
    this.cambiarPropiedadYNotificar('score', valor);
  }

  public get Completed(): boolean {
    return this._entidad.completed;
  }

  public set Completed(valor: boolean) {
    this.cambiarPropiedadYNotificar('completed', valor);
  }
}
