import { Pipe, PipeTransform } from '@angular/core';
import { ProfitTypeEnum } from '@DTOS';
import { formatProfitType } from '../../utils/ProfitType.utils';

@Pipe({
  name: 'profitType'
})
export class ProfitTypePipe implements PipeTransform {
  transform(type: ProfitTypeEnum): string {
    return formatProfitType(type);
  }
}
