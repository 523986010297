import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterArray'
})
export class FilterArrayPipe implements PipeTransform {
  transform<T>(array: T[], filterCallback: (element: T, ...parameters: any[]) => boolean, ...parameters: any[]): T[] {
    return array.filter(element => filterCallback(element, ...parameters));
  }
}
