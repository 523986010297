import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Constants } from '../shared/constants';

import { StartupRegisterDTO } from '../DTO/StartupRegister.dto';
import { CompanyRegisterDTO } from '../DTO/CompanyRegister.dto';
import { IRegisterResponseDTO, RegisterResponseDTO } from '../DTO/RegisterResponse.dto';
import { IVerifyDomainDTO, VerifyDomainDTO } from '../DTO/VerifyDomain.dto';

@Injectable({
  providedIn: 'root'
})
export class RegisterServicioBackend {
  constructor(protected http: HttpClient) {}

  public RegisterStartup(startupRegisterDTO: StartupRegisterDTO): Observable<void> {
    return this.http.post<void>(`${Constants.apiURL}/register/startup`, startupRegisterDTO);
  }

  public RegisterInvestor(companyRegisterDTO: CompanyRegisterDTO): Observable<void> {
    return this.http.post<void>(`${Constants.apiURL}/register/investor`, companyRegisterDTO);
  }

  public RegisterSubscriptionForAccount(email: string): Observable<RegisterResponseDTO> {
    const params = new HttpParams().append('email', email);
    const opciones = { params };
    return this.http
      .post<IRegisterResponseDTO>(`${Constants.apiURL}/register/register-subscription`, null, opciones)
      .pipe(map(entidad => new RegisterResponseDTO(entidad)));
  }

  public VerifyDomain(domain: string, isStartup: boolean): Observable<VerifyDomainDTO> {
    const params = new HttpParams().append('domain', domain).append('isStartup', isStartup.toString());
    const opciones = { params };
    return this.http
      .post<IVerifyDomainDTO>(`${Constants.apiURL}/register/verify/domain`, null, opciones)
      .pipe(map(entidad => new VerifyDomainDTO(entidad)));
  }

  public TransferOwnership(email: string, userEmail: string): Observable<void> {
    const params = new HttpParams().append('email', email).append('userEmail', userEmail);
    const opciones = { params };
    return this.http.post<void>(`${Constants.apiURL}/register/transfer-ownership`, null, opciones);
  }
}
