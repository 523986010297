import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { Observable, map, of, switchMap, tap } from 'rxjs';
import { StartupsService } from 'src/app/app-shared/services/startups.service';
import { StartupCompanyBasicDTO } from '@DTOS';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { SummaryProfileComponent } from './summary-profile/summary-profile.component';

@Component({
  selector: 'app-all-info-vc',
  templateUrl: './all-info-vc.component.html',
  styleUrls: ['./all-info-vc.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatDialogModule, MatProgressSpinnerModule, MatButtonModule, SummaryProfileComponent]
})
export class AllInfoVcComponent {
  public loading = signal<boolean>(true);
  public startup = signal(new StartupCompanyBasicDTO());
  public scoring = signal<number>(0);

  private readonly startupsService = inject(StartupsService);

  public loadData(startupId: number, scoring: number): Observable<void> {
    return of(void 0).pipe(
      tap(() => this.loading.set(true)),
      tap(() => this.scoring.set(scoring)),
      switchMap(() => this.startupsService.getStartup(startupId)),
      tap(startupDTO => this.startup.set(startupDTO.obtenerEntidad())),
      map(() => void 0),
      tap(() => this.loading.set(false))
    );
  }
}
