import { VariableCompanyProfileEnum } from '@DTOS';

export const formatCompanyProfile: (companyProfile: VariableCompanyProfileEnum) => string = companyProfile => {
  switch (companyProfile) {
    case VariableCompanyProfileEnum.Funds:
      return 'Funds';
    case VariableCompanyProfileEnum.Investments:
      return 'Investments';
    case VariableCompanyProfileEnum.LeadInvestments:
      return 'Lead investments';
    case VariableCompanyProfileEnum.Exits:
      return 'Exits';
  }
};
