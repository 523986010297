import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';

export interface IPublicInfoStartupDTO {
  id: number;
  name: string;
  description: string;
  image: string;
  score: number;
}

export class PublicInfoStartupDTO implements IDTO<PublicInfoStartupDTO, IPublicInfoStartupDTO> {
  public id: number = 0;
  public name: string = '';
  public description: string = '';
  public image: string = '';
  public score: number = 0;

  constructor(json?: Partial<IPublicInfoStartupDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<IPublicInfoStartupDTO>) {
    if (json.id != null) {
      this.id = json.id;
    }
    if (json.name != null) {
      this.name = json.name;
    }
    if (json.description != null) {
      this.description = json.description;
    }
    if (json.image != null) {
      this.image = json.image;
    }
    if (json.score != null) {
      this.score = json.score;
    }
  }

  public aJSON(): IPublicInfoStartupDTO {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      image: this.image,
      score: this.score
    };
  }

  public clon<T extends PublicInfoStartupDTO>(clonBase: T): T;
  public clon<T extends PublicInfoStartupDTO>(): PublicInfoStartupDTO;
  public clon<T extends PublicInfoStartupDTO>(clonBase: Nilable<T> = null): T | PublicInfoStartupDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.id = this.id;
      clonBase.name = this.name;
      clonBase.description = this.description;
      clonBase.image = this.image;
      clonBase.score = this.score;
      return clonBase;
    } else {
      const clon = new PublicInfoStartupDTO();
      clon.id = this.id;
      clon.name = this.name;
      clon.description = this.description;
      clon.image = this.image;
      clon.score = this.score;
      return clon;
    }
  }
}

export class PublicInfoStartupModelDTO extends ModelDTO<PublicInfoStartupDTO> {
  constructor(entidad: PublicInfoStartupDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Id(): number {
    return this._entidad.id;
  }

  public set Id(valor: number) {
    this.cambiarPropiedadYNotificar('id', valor);
  }

  public get Name(): string {
    return this._entidad.name;
  }

  public set Name(valor: string) {
    this.cambiarPropiedadYNotificar('name', valor);
  }

  public get Description(): string {
    return this._entidad.description;
  }

  public set Description(valor: string) {
    this.cambiarPropiedadYNotificar('description', valor);
  }

  public get Image(): string {
    return this._entidad.image;
  }

  public set Image(valor: string) {
    this.cambiarPropiedadYNotificar('image', valor);
  }

  public get Score(): number {
    return this._entidad.score;
  }

  public set Score(valor: number) {
    this.cambiarPropiedadYNotificar('score', valor);
  }
}
