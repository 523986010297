import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';

export interface ICompanySettingsDTO {
  settings: any;
}

export class CompanySettingsDTO implements IDTO<CompanySettingsDTO, ICompanySettingsDTO> {
  public settings: any = {};

  constructor(json?: Partial<ICompanySettingsDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<ICompanySettingsDTO>) {
    if (json.settings != null) {
      this.settings = json.settings;
    }
  }

  public aJSON(): ICompanySettingsDTO {
    return {
      settings: this.settings
    };
  }

  public clon<T extends CompanySettingsDTO>(clonBase: T): T;
  public clon<T extends CompanySettingsDTO>(): CompanySettingsDTO;
  public clon<T extends CompanySettingsDTO>(clonBase: Nilable<T> = null): T | CompanySettingsDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.settings = this.settings;
      return clonBase;
    } else {
      const clon = new CompanySettingsDTO();
      clon.settings = this.settings;
      return clon;
    }
  }
}

export class CompanySettingsModelDTO extends ModelDTO<CompanySettingsDTO> {
  constructor(entidad: CompanySettingsDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Settings(): any {
    return this._entidad.settings;
  }

  public set Settings(valor: any) {
    this.cambiarPropiedadYNotificar('settings', valor);
  }
}
