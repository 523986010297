<div class="flex" [class.pb-2]="!isMobile()" [class.flex-col]="isMobile()">
  <div [class.pr-2]="!isMobile()">
    <app-card-analytic [cardType]="CardTypeEnum.Custom" class="block" [onHoverListen]="false">
      <ng-template appCustomTemplate>
        <div class="flex flex-col justify-between py-4 h-full items-center">
          <div class="flex">
            <div class="h-[40px] w-[40px] bg-primary text-white rounded-full mr-4 flex justify-center items-center">
              <i class="fa-solid fa-chart-simple fa-lg"></i>
            </div>
            <div class="flex flex-col">
              <h3 class="text-lg">Parcial score</h3>
              <small class="text-neutral-400">Finance not included</small>
            </div>
          </div>
          <app-progress-circle
            class="mt-4"
            [value]="dashboardAnalytics.parcialScoreLastMonth"
            [max]="1000"
            [diameter]="200"
            circleClass="progress-spinner-primary"></app-progress-circle>
        </div>
      </ng-template>
    </app-card-analytic>
  </div>
  <div [class.pl-2]="!isMobile()" [class.mt-4]="isMobile()">
    <app-card-analytic [cardType]="CardTypeEnum.Custom" class="block pr-1" [onHoverListen]="false">
      <ng-template appCustomTemplate>
        <div class="flex flex-col justify-between py-4 h-full items-center">
          <div class="flex">
            <div class="h-[40px] w-[40px] bg-primary text-white rounded-full mr-4 flex justify-center items-center">
              <i class="fa-solid fa-chart-simple fa-lg"></i>
            </div>
            <div class="flex flex-col">
              <h3 class="text-lg">Full score</h3>
              <small class="text-neutral-400">Including Finantials</small>
            </div>
          </div>
          <app-progress-circle
            class="mt-4"
            [value]="dashboardAnalytics.scoreLastMonth"
            [max]="1000"
            [diameter]="200"
            circleClass="progress-spinner-pallette4"></app-progress-circle>
        </div>
      </ng-template>
    </app-card-analytic>
  </div>
</div>
