import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';
import { IStartupScoreTotalDTO, StartupScoreTotalDTO } from './StartupScoreTotal.dto';

export interface IPortfolioAnalyticsDTO {
  scoringTotalPortfolio: number;
  percentScoringDifference: number;
  totalStartups: number;
  totalValuation: number;
  totalInvestment: number;
  totalRaisedCapital: number;
  parcialAverageScore: number;
  averageScore: number;
  scoreGrowthLast12Months: number;
  scoreGrowthLastBefore: number;
  bestStartup: IStartupScoreTotalDTO;
  worstStartup: IStartupScoreTotalDTO;
}

export class PortfolioAnalyticsDTO implements IDTO<PortfolioAnalyticsDTO, IPortfolioAnalyticsDTO> {
  public scoringTotalPortfolio: number = 0;
  public percentScoringDifference: number = 0;
  public totalStartups: number = 0;
  public totalValuation: number = 0;
  public totalInvestment: number = 0;
  public totalRaisedCapital: number = 0;
  public parcialAverageScore: number = 0;
  public averageScore: number = 0;
  public scoreGrowthLast12Months: number = 0;
  public scoreGrowthLastBefore: number = 0;
  public bestStartup: StartupScoreTotalDTO = new StartupScoreTotalDTO();
  public worstStartup: StartupScoreTotalDTO = new StartupScoreTotalDTO();

  constructor(json?: Partial<IPortfolioAnalyticsDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<IPortfolioAnalyticsDTO>) {
    if (json.scoringTotalPortfolio != null) {
      this.scoringTotalPortfolio = json.scoringTotalPortfolio;
    }
    if (json.percentScoringDifference != null) {
      this.percentScoringDifference = json.percentScoringDifference;
    }
    if (json.totalStartups != null) {
      this.totalStartups = json.totalStartups;
    }
    if (json.totalValuation != null) {
      this.totalValuation = json.totalValuation;
    }
    if (json.totalInvestment != null) {
      this.totalInvestment = json.totalInvestment;
    }
    if (json.totalRaisedCapital != null) {
      this.totalRaisedCapital = json.totalRaisedCapital;
    }
    if (json.parcialAverageScore != null) {
      this.parcialAverageScore = json.parcialAverageScore;
    }
    if (json.averageScore != null) {
      this.averageScore = json.averageScore;
    }
    if (json.scoreGrowthLast12Months != null) {
      this.scoreGrowthLast12Months = json.scoreGrowthLast12Months;
    }
    if (json.scoreGrowthLastBefore != null) {
      this.scoreGrowthLastBefore = json.scoreGrowthLastBefore;
    }
    if (json.bestStartup != null) {
      this.bestStartup = new StartupScoreTotalDTO(json.bestStartup);
    }
    if (json.worstStartup != null) {
      this.worstStartup = new StartupScoreTotalDTO(json.worstStartup);
    }
  }

  public aJSON(): IPortfolioAnalyticsDTO {
    return {
      scoringTotalPortfolio: this.scoringTotalPortfolio,
      percentScoringDifference: this.percentScoringDifference,
      totalStartups: this.totalStartups,
      totalValuation: this.totalValuation,
      totalInvestment: this.totalInvestment,
      totalRaisedCapital: this.totalRaisedCapital,
      parcialAverageScore: this.parcialAverageScore,
      averageScore: this.averageScore,
      scoreGrowthLast12Months: this.scoreGrowthLast12Months,
      scoreGrowthLastBefore: this.scoreGrowthLastBefore,
      bestStartup: this.bestStartup.aJSON(),
      worstStartup: this.worstStartup.aJSON()
    };
  }

  public clon<T extends PortfolioAnalyticsDTO>(clonBase: T): T;
  public clon<T extends PortfolioAnalyticsDTO>(): PortfolioAnalyticsDTO;
  public clon<T extends PortfolioAnalyticsDTO>(clonBase: Nilable<T> = null): T | PortfolioAnalyticsDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.scoringTotalPortfolio = this.scoringTotalPortfolio;
      clonBase.percentScoringDifference = this.percentScoringDifference;
      clonBase.totalStartups = this.totalStartups;
      clonBase.totalValuation = this.totalValuation;
      clonBase.totalInvestment = this.totalInvestment;
      clonBase.totalRaisedCapital = this.totalRaisedCapital;
      clonBase.parcialAverageScore = this.parcialAverageScore;
      clonBase.averageScore = this.averageScore;
      clonBase.scoreGrowthLast12Months = this.scoreGrowthLast12Months;
      clonBase.scoreGrowthLastBefore = this.scoreGrowthLastBefore;
      clonBase.bestStartup = this.bestStartup.clon();
      clonBase.worstStartup = this.worstStartup.clon();
      return clonBase;
    } else {
      const clon = new PortfolioAnalyticsDTO();
      clon.scoringTotalPortfolio = this.scoringTotalPortfolio;
      clon.percentScoringDifference = this.percentScoringDifference;
      clon.totalStartups = this.totalStartups;
      clon.totalValuation = this.totalValuation;
      clon.totalInvestment = this.totalInvestment;
      clon.totalRaisedCapital = this.totalRaisedCapital;
      clon.parcialAverageScore = this.parcialAverageScore;
      clon.averageScore = this.averageScore;
      clon.scoreGrowthLast12Months = this.scoreGrowthLast12Months;
      clon.scoreGrowthLastBefore = this.scoreGrowthLastBefore;
      clon.bestStartup = this.bestStartup.clon();
      clon.worstStartup = this.worstStartup.clon();
      return clon;
    }
  }
}

export class PortfolioAnalyticsModelDTO extends ModelDTO<PortfolioAnalyticsDTO> {
  constructor(entidad: PortfolioAnalyticsDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get ScoringTotalPortfolio(): number {
    return this._entidad.scoringTotalPortfolio;
  }

  public set ScoringTotalPortfolio(valor: number) {
    this.cambiarPropiedadYNotificar('scoringTotalPortfolio', valor);
  }

  public get PercentScoringDifference(): number {
    return this._entidad.percentScoringDifference;
  }

  public set PercentScoringDifference(valor: number) {
    this.cambiarPropiedadYNotificar('percentScoringDifference', valor);
  }

  public get TotalStartups(): number {
    return this._entidad.totalStartups;
  }

  public set TotalStartups(valor: number) {
    this.cambiarPropiedadYNotificar('totalStartups', valor);
  }

  public get TotalValuation(): number {
    return this._entidad.totalValuation;
  }

  public set TotalValuation(valor: number) {
    this.cambiarPropiedadYNotificar('totalValuation', valor);
  }

  public get TotalInvestment(): number {
    return this._entidad.totalInvestment;
  }

  public set TotalInvestment(valor: number) {
    this.cambiarPropiedadYNotificar('totalInvestment', valor);
  }

  public get TotalRaisedCapital(): number {
    return this._entidad.totalRaisedCapital;
  }

  public set TotalRaisedCapital(valor: number) {
    this.cambiarPropiedadYNotificar('totalRaisedCapital', valor);
  }

  public get ParcialAverageScore(): number {
    return this._entidad.parcialAverageScore;
  }

  public set ParcialAverageScore(valor: number) {
    this.cambiarPropiedadYNotificar('parcialAverageScore', valor);
  }

  public get AverageScore(): number {
    return this._entidad.averageScore;
  }

  public set AverageScore(valor: number) {
    this.cambiarPropiedadYNotificar('averageScore', valor);
  }

  public get ScoreGrowthLast12Months(): number {
    return this._entidad.scoreGrowthLast12Months;
  }

  public set ScoreGrowthLast12Months(valor: number) {
    this.cambiarPropiedadYNotificar('scoreGrowthLast12Months', valor);
  }

  public get ScoreGrowthLastBefore(): number {
    return this._entidad.scoreGrowthLastBefore;
  }

  public set ScoreGrowthLastBefore(valor: number) {
    this.cambiarPropiedadYNotificar('scoreGrowthLastBefore', valor);
  }

  public get BestStartup(): StartupScoreTotalDTO {
    return this._entidad.bestStartup;
  }

  public set BestStartup(valor: StartupScoreTotalDTO) {
    this.cambiarPropiedadYNotificar('bestStartup', valor);
  }

  public get WorstStartup(): StartupScoreTotalDTO {
    return this._entidad.worstStartup;
  }

  public set WorstStartup(valor: StartupScoreTotalDTO) {
    this.cambiarPropiedadYNotificar('worstStartup', valor);
  }
}
