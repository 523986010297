import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'chunkArray'
})
export class ChunkArrayPipe implements PipeTransform {
  transform<T>(array: T[], size: number, filtroUnElemento: ((elemento: T) => boolean) | null = null): T[][] {
    const chunkedArray: T[][] = [];
    let index = 0;
    let arrayChunk: T[] = [];

    while (index < array.length) {
      if (arrayChunk.length === size) {
        chunkedArray.push(arrayChunk);
        arrayChunk = [];
      }
      if (filtroUnElemento != null && filtroUnElemento(array[index])) {
        chunkedArray.push([array[index]]);
        index++;
        continue;
      }
      arrayChunk.push(array[index]);
      index++;
    }
    if (arrayChunk.length !== 0) {
      chunkedArray.push(arrayChunk);
    }
    return chunkedArray;
  }
}
