import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard, loginGuard } from '@nahuelmorata/framework-frontend';
import { MessageEnum } from './shared/enums/Message.enum';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule),
    canActivate: [authGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
    canActivate: [loginGuard]
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then(m => m.RegisterModule)
  },
  {
    path: 'terms-and-conditions',
    loadChildren: () => import('./terms-conditions/terms-conditions.module').then(m => m.TermsConditionsModule)
  },
  {
    path: 'recoverPassword',
    loadChildren: () => import('./recover-password/recover-password.module').then(m => m.RecoverPasswordModule)
  },
  {
    path: 'payment',
    loadChildren: () => import('./payment/payment.module').then(m => m.PaymentModule)
  },
  {
    path: 'email-confirmation',
    loadChildren: () => import('./messages/messages.module').then(m => m.MessagesModule),
    data: {
      message: MessageEnum.EMAIL_CONFIRMATION
    }
  },
  {
    path: 'confirm-email',
    loadChildren: () => import('./messages/messages.module').then(m => m.MessagesModule),
    data: {
      message: MessageEnum.MUST_CONFIRM_EMAIL
    }
  },
  {
    path: 'transfer-ownership',
    loadChildren: () => import('./messages/messages.module').then(m => m.MessagesModule),
    data: {
      message: MessageEnum.TRANSFER_OWNERSHIP
    }
  },
  /**
   * Esto es necesario ya que no es posible hacer un parseUrl('/').
   * Por eso en role y userType hacemos parseUrl('/home') que es capturado por ese redirect
   *
   */
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
