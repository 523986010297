import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';

export interface IStartupDateMissingAssessmentDTO {
  date: string;
  autoCreated: boolean;
}

export class StartupDateMissingAssessmentDTO
  implements IDTO<StartupDateMissingAssessmentDTO, IStartupDateMissingAssessmentDTO>
{
  public date: Date = new Date();
  public autoCreated: boolean = true;

  constructor(json?: Partial<IStartupDateMissingAssessmentDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<IStartupDateMissingAssessmentDTO>) {
    if (json.date != null) {
      this.date = new Date(json.date);
    }
    if (json.autoCreated != null) {
      this.autoCreated = json.autoCreated;
    }
  }

  public aJSON(): IStartupDateMissingAssessmentDTO {
    return {
      date: this.date.toJSON(),
      autoCreated: this.autoCreated
    };
  }

  public clon<T extends StartupDateMissingAssessmentDTO>(clonBase: T): T;
  public clon<T extends StartupDateMissingAssessmentDTO>(): StartupDateMissingAssessmentDTO;
  public clon<T extends StartupDateMissingAssessmentDTO>(
    clonBase: Nilable<T> = null
  ): T | StartupDateMissingAssessmentDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.date = new Date(this.date.toJSON());
      clonBase.autoCreated = this.autoCreated;
      return clonBase;
    } else {
      const clon = new StartupDateMissingAssessmentDTO();
      clon.date = new Date(this.date.toJSON());
      clon.autoCreated = this.autoCreated;
      return clon;
    }
  }
}

export class StartupDateMissingAssessmentModelDTO extends ModelDTO<StartupDateMissingAssessmentDTO> {
  constructor(entidad: StartupDateMissingAssessmentDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Date(): Date {
    return this._entidad.date;
  }

  public set Date(valor: Date) {
    this.cambiarPropiedadYNotificar('date', valor);
  }

  public get AutoCreated(): boolean {
    return this._entidad.autoCreated;
  }

  public set AutoCreated(valor: boolean) {
    this.cambiarPropiedadYNotificar('autoCreated', valor);
  }
}
