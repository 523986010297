import { IDTO, ModelDTO, IUsuarioDTO, UsuarioDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';
import { NoteSectionEnum } from './NoteSection.enum';

export interface INoteDTO {
  id: number;
  cacheStamp: number;
  text: string;
  userCreated: IUsuarioDTO;
  section: number;
  extra: string;
  timeCreated: string;
}

export class NoteDTO implements IDTO<NoteDTO, INoteDTO> {
  public id: number = 0;
  public cacheStamp: number = 0;
  public text: string = '';
  public userCreated: UsuarioDTO = new UsuarioDTO();
  public section: NoteSectionEnum = NoteSectionEnum.PortfolioTotalCompanies;
  public extra: string = '';
  public timeCreated: Date = new Date();

  constructor(json?: Partial<INoteDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<INoteDTO>) {
    if (json.id != null) {
      this.id = json.id;
    }
    if (json.cacheStamp != null) {
      this.cacheStamp = json.cacheStamp;
    }
    if (json.text != null) {
      this.text = json.text;
    }
    if (json.userCreated != null) {
      this.userCreated = new UsuarioDTO(json.userCreated);
    }
    if (json.section != null) {
      this.section = json.section as NoteSectionEnum;
    }
    if (json.extra != null) {
      this.extra = json.extra;
    }
    if (json.timeCreated != null) {
      this.timeCreated = new Date(json.timeCreated);
    }
  }

  public aJSON(): INoteDTO {
    return {
      id: this.id,
      cacheStamp: this.cacheStamp,
      text: this.text,
      userCreated: this.userCreated.aJSON(),
      section: this.section,
      extra: this.extra,
      timeCreated: this.timeCreated.toJSON()
    };
  }

  public clon<T extends NoteDTO>(clonBase: T): T;
  public clon<T extends NoteDTO>(): NoteDTO;
  public clon<T extends NoteDTO>(clonBase: Nilable<T> = null): T | NoteDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.id = this.id;
      clonBase.cacheStamp = this.cacheStamp;
      clonBase.text = this.text;
      clonBase.userCreated = this.userCreated.clon();
      clonBase.section = this.section;
      clonBase.extra = this.extra;
      clonBase.timeCreated = new Date(this.timeCreated.toJSON());
      return clonBase;
    } else {
      const clon = new NoteDTO();
      clon.id = this.id;
      clon.cacheStamp = this.cacheStamp;
      clon.text = this.text;
      clon.userCreated = this.userCreated.clon();
      clon.section = this.section;
      clon.extra = this.extra;
      clon.timeCreated = new Date(this.timeCreated.toJSON());
      return clon;
    }
  }
}

export class NoteModelDTO extends ModelDTO<NoteDTO> {
  constructor(entidad: NoteDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Id(): number {
    return this._entidad.id;
  }

  public set Id(valor: number) {
    this.cambiarPropiedadYNotificar('id', valor);
  }

  public get CacheStamp(): number {
    return this._entidad.cacheStamp;
  }

  public set CacheStamp(valor: number) {
    this.cambiarPropiedadYNotificar('cacheStamp', valor);
  }

  public get Text(): string {
    return this._entidad.text;
  }

  public set Text(valor: string) {
    this.cambiarPropiedadYNotificar('text', valor);
  }

  public get UserCreated(): UsuarioDTO {
    return this._entidad.userCreated;
  }

  public set UserCreated(valor: UsuarioDTO) {
    this.cambiarPropiedadYNotificar('userCreated', valor);
  }

  public get Section(): NoteSectionEnum {
    return this._entidad.section;
  }

  public set Section(valor: NoteSectionEnum) {
    this.cambiarPropiedadYNotificar('section', valor);
  }

  public get Extra(): string {
    return this._entidad.extra;
  }

  public set Extra(valor: string) {
    this.cambiarPropiedadYNotificar('extra', valor);
  }

  public get TimeCreated(): Date {
    return this._entidad.timeCreated;
  }

  public set TimeCreated(valor: Date) {
    this.cambiarPropiedadYNotificar('timeCreated', valor);
  }
}
