import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';
import { StartupTypeEnum } from './StartupType.enum';
import { FoundingTypeEnum } from './FoundingType.enum';
import { OperatingStatusEnum } from './OperatingStatus.enum';
import { ProfitTypeEnum } from './ProfitType.enum';

export interface IStartupCompanyBasicDTO {
  id: number;
  name: string;
  description: string;
  investment: number;
  type: number;
  isArchived: boolean;
  brandName: string;
  legalName: string;
  headquarters: string;
  website: string;
  totalInvestments: number;
  totalFundingAmount: number;
  foundedDate: string;
  quantityEmployees: string;
  foundersPercentage: string;
  percentageLoans: string;
  lastFundingType: number;
  operatingStatus: number;
  profitType: number;
  currentFundingStage: number;
  linkedin: string;
  image: string;
  fromConnection: boolean;
  contactEmail: string;
  connectionId: number;
  confirmCorrectInfo: boolean;
  confirmCorrectInfoAt: string;
  crunchbaseRank: number;
}

export class StartupCompanyBasicDTO implements IDTO<StartupCompanyBasicDTO, IStartupCompanyBasicDTO> {
  public id: number = 0;
  public name: string = '';
  public description: string = '';
  public investment: number = 0;
  public type: StartupTypeEnum = StartupTypeEnum.Lead;
  public isArchived: boolean = true;
  public brandName: string = '';
  public legalName: string = '';
  public headquarters: string = '';
  public website: string = '';
  public totalInvestments: number = 0;
  public totalFundingAmount: number = 0;
  public foundedDate: Date = new Date();
  public quantityEmployees: string = '';
  public foundersPercentage: string = '';
  public percentageLoans: string = '';
  public lastFundingType: FoundingTypeEnum = FoundingTypeEnum.None;
  public operatingStatus: OperatingStatusEnum = OperatingStatusEnum.Active;
  public profitType: ProfitTypeEnum = ProfitTypeEnum.ForProfit;
  public currentFundingStage: FoundingTypeEnum = FoundingTypeEnum.None;
  public linkedin: string = '';
  public image: Nilable<string>;
  public fromConnection: boolean = true;
  public contactEmail: Nilable<string> = null;
  public connectionId: number = 0;
  public confirmCorrectInfo: boolean = true;
  public confirmCorrectInfoAt: Date = new Date();
  public crunchbaseRank: number = 0;

  constructor(json?: Partial<IStartupCompanyBasicDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<IStartupCompanyBasicDTO>) {
    if (json.id != null) {
      this.id = json.id;
    }
    if (json.name != null) {
      this.name = json.name;
    }
    if (json.description != null) {
      this.description = json.description;
    }
    if (json.investment != null) {
      this.investment = json.investment;
    }
    if (json.type != null) {
      this.type = json.type as StartupTypeEnum;
    }
    if (json.isArchived != null) {
      this.isArchived = json.isArchived;
    }
    if (json.brandName != null) {
      this.brandName = json.brandName;
    }
    if (json.legalName != null) {
      this.legalName = json.legalName;
    }
    if (json.headquarters != null) {
      this.headquarters = json.headquarters;
    }
    if (json.website != null) {
      this.website = json.website;
    }
    if (json.totalInvestments != null) {
      this.totalInvestments = json.totalInvestments;
    }
    if (json.totalFundingAmount != null) {
      this.totalFundingAmount = json.totalFundingAmount;
    }
    if (json.foundedDate != null) {
      this.foundedDate = new Date(json.foundedDate);
    }
    if (json.quantityEmployees != null) {
      this.quantityEmployees = json.quantityEmployees;
    }
    if (json.foundersPercentage != null) {
      this.foundersPercentage = json.foundersPercentage;
    }
    if (json.percentageLoans != null) {
      this.percentageLoans = json.percentageLoans;
    }
    if (json.lastFundingType != null) {
      this.lastFundingType = json.lastFundingType as FoundingTypeEnum;
    }
    if (json.operatingStatus != null) {
      this.operatingStatus = json.operatingStatus as OperatingStatusEnum;
    }
    if (json.profitType != null) {
      this.profitType = json.profitType as ProfitTypeEnum;
    }
    if (json.currentFundingStage != null) {
      this.currentFundingStage = json.currentFundingStage as FoundingTypeEnum;
    }
    if (json.linkedin != null) {
      this.linkedin = json.linkedin;
    }
    if (json.image != null) {
      this.image = json.image;
    }
    if (json.fromConnection != null) {
      this.fromConnection = json.fromConnection;
    }
    if (json.contactEmail != null) {
      this.contactEmail = json.contactEmail;
    }
    if (json.connectionId != null) {
      this.connectionId = json.connectionId;
    }
    if (json.confirmCorrectInfo != null) {
      this.confirmCorrectInfo = json.confirmCorrectInfo;
    }
    if (json.confirmCorrectInfoAt != null) {
      this.confirmCorrectInfoAt = new Date(json.confirmCorrectInfoAt);
    }
    if (json.crunchbaseRank != null) {
      this.crunchbaseRank = json.crunchbaseRank;
    }
  }

  public aJSON(): IStartupCompanyBasicDTO {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      investment: this.investment,
      type: this.type,
      isArchived: this.isArchived,
      brandName: this.brandName,
      legalName: this.legalName,
      headquarters: this.headquarters,
      website: this.website,
      totalInvestments: this.totalInvestments,
      totalFundingAmount: this.totalFundingAmount,
      foundedDate: this.foundedDate.toJSON(),
      quantityEmployees: this.quantityEmployees,
      foundersPercentage: this.foundersPercentage,
      percentageLoans: this.percentageLoans,
      lastFundingType: this.lastFundingType,
      operatingStatus: this.operatingStatus,
      profitType: this.profitType,
      currentFundingStage: this.currentFundingStage,
      linkedin: this.linkedin,
      image: this.image,
      fromConnection: this.fromConnection,
      contactEmail: this.contactEmail,
      connectionId: this.connectionId,
      confirmCorrectInfo: this.confirmCorrectInfo,
      confirmCorrectInfoAt: this.confirmCorrectInfoAt.toJSON(),
      crunchbaseRank: this.crunchbaseRank
    };
  }

  public clon<T extends StartupCompanyBasicDTO>(clonBase: T): T;
  public clon<T extends StartupCompanyBasicDTO>(): StartupCompanyBasicDTO;
  public clon<T extends StartupCompanyBasicDTO>(clonBase: Nilable<T> = null): T | StartupCompanyBasicDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.id = this.id;
      clonBase.name = this.name;
      clonBase.description = this.description;
      clonBase.investment = this.investment;
      clonBase.type = this.type;
      clonBase.isArchived = this.isArchived;
      clonBase.brandName = this.brandName;
      clonBase.legalName = this.legalName;
      clonBase.headquarters = this.headquarters;
      clonBase.website = this.website;
      clonBase.totalInvestments = this.totalInvestments;
      clonBase.totalFundingAmount = this.totalFundingAmount;
      clonBase.foundedDate = new Date(this.foundedDate.toJSON());
      clonBase.quantityEmployees = this.quantityEmployees;
      clonBase.foundersPercentage = this.foundersPercentage;
      clonBase.percentageLoans = this.percentageLoans;
      clonBase.lastFundingType = this.lastFundingType;
      clonBase.operatingStatus = this.operatingStatus;
      clonBase.profitType = this.profitType;
      clonBase.currentFundingStage = this.currentFundingStage;
      clonBase.linkedin = this.linkedin;
      clonBase.image = this.image;
      clonBase.fromConnection = this.fromConnection;
      clonBase.contactEmail = this.contactEmail;
      clonBase.connectionId = this.connectionId;
      clonBase.confirmCorrectInfo = this.confirmCorrectInfo;
      clonBase.confirmCorrectInfoAt = new Date(this.confirmCorrectInfoAt.toJSON());
      clonBase.crunchbaseRank = this.crunchbaseRank;
      return clonBase;
    } else {
      const clon = new StartupCompanyBasicDTO();
      clon.id = this.id;
      clon.name = this.name;
      clon.description = this.description;
      clon.investment = this.investment;
      clon.type = this.type;
      clon.isArchived = this.isArchived;
      clon.brandName = this.brandName;
      clon.legalName = this.legalName;
      clon.headquarters = this.headquarters;
      clon.website = this.website;
      clon.totalInvestments = this.totalInvestments;
      clon.totalFundingAmount = this.totalFundingAmount;
      clon.foundedDate = new Date(this.foundedDate.toJSON());
      clon.quantityEmployees = this.quantityEmployees;
      clon.foundersPercentage = this.foundersPercentage;
      clon.percentageLoans = this.percentageLoans;
      clon.lastFundingType = this.lastFundingType;
      clon.operatingStatus = this.operatingStatus;
      clon.profitType = this.profitType;
      clon.currentFundingStage = this.currentFundingStage;
      clon.linkedin = this.linkedin;
      clon.image = this.image;
      clon.fromConnection = this.fromConnection;
      clon.contactEmail = this.contactEmail;
      clon.connectionId = this.connectionId;
      clon.confirmCorrectInfo = this.confirmCorrectInfo;
      clon.confirmCorrectInfoAt = new Date(this.confirmCorrectInfoAt.toJSON());
      clon.crunchbaseRank = this.crunchbaseRank;
      return clon;
    }
  }
}

export class StartupCompanyBasicModelDTO extends ModelDTO<StartupCompanyBasicDTO> {
  constructor(entidad: StartupCompanyBasicDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Id(): number {
    return this._entidad.id;
  }

  public set Id(valor: number) {
    this.cambiarPropiedadYNotificar('id', valor);
  }

  public get Name(): string {
    return this._entidad.name;
  }

  public set Name(valor: string) {
    this.cambiarPropiedadYNotificar('name', valor);
  }

  public get Description(): string {
    return this._entidad.description;
  }

  public set Description(valor: string) {
    this.cambiarPropiedadYNotificar('description', valor);
  }

  public get Investment(): number {
    return this._entidad.investment;
  }

  public set Investment(valor: number) {
    this.cambiarPropiedadYNotificar('investment', valor);
  }

  public get Type(): StartupTypeEnum {
    return this._entidad.type;
  }

  public set Type(valor: StartupTypeEnum) {
    this.cambiarPropiedadYNotificar('type', valor);
  }

  public get IsArchived(): boolean {
    return this._entidad.isArchived;
  }

  public set IsArchived(valor: boolean) {
    this.cambiarPropiedadYNotificar('isArchived', valor);
  }

  public get BrandName(): string {
    return this._entidad.brandName;
  }

  public set BrandName(valor: string) {
    this.cambiarPropiedadYNotificar('brandName', valor);
  }

  public get LegalName(): string {
    return this._entidad.legalName;
  }

  public set LegalName(valor: string) {
    this.cambiarPropiedadYNotificar('legalName', valor);
  }

  public get Headquarters(): string {
    return this._entidad.headquarters;
  }

  public set Headquarters(valor: string) {
    this.cambiarPropiedadYNotificar('headquarters', valor);
  }

  public get Website(): string {
    return this._entidad.website;
  }

  public set Website(valor: string) {
    this.cambiarPropiedadYNotificar('website', valor);
  }

  public get TotalInvestments(): number {
    return this._entidad.totalInvestments;
  }

  public set TotalInvestments(valor: number) {
    this.cambiarPropiedadYNotificar('totalInvestments', valor);
  }

  public get TotalFundingAmount(): number {
    return this._entidad.totalFundingAmount;
  }

  public set TotalFundingAmount(valor: number) {
    this.cambiarPropiedadYNotificar('totalFundingAmount', valor);
  }

  public get FoundedDate(): Date {
    return this._entidad.foundedDate;
  }

  public set FoundedDate(valor: Date) {
    this.cambiarPropiedadYNotificar('foundedDate', valor);
  }

  public get QuantityEmployees(): string {
    return this._entidad.quantityEmployees;
  }

  public set QuantityEmployees(valor: string) {
    this.cambiarPropiedadYNotificar('quantityEmployees', valor);
  }

  public get FoundersPercentage(): string {
    return this._entidad.foundersPercentage;
  }

  public set FoundersPercentage(valor: string) {
    this.cambiarPropiedadYNotificar('foundersPercentage', valor);
  }

  public get PercentageLoans(): string {
    return this._entidad.percentageLoans;
  }

  public set PercentageLoans(valor: string) {
    this.cambiarPropiedadYNotificar('percentageLoans', valor);
  }

  public get LastFundingType(): FoundingTypeEnum {
    return this._entidad.lastFundingType;
  }

  public set LastFundingType(valor: FoundingTypeEnum) {
    this.cambiarPropiedadYNotificar('lastFundingType', valor);
  }

  public get OperatingStatus(): OperatingStatusEnum {
    return this._entidad.operatingStatus;
  }

  public set OperatingStatus(valor: OperatingStatusEnum) {
    this.cambiarPropiedadYNotificar('operatingStatus', valor);
  }

  public get ProfitType(): ProfitTypeEnum {
    return this._entidad.profitType;
  }

  public set ProfitType(valor: ProfitTypeEnum) {
    this.cambiarPropiedadYNotificar('profitType', valor);
  }

  public get CurrentFundingStage(): FoundingTypeEnum {
    return this._entidad.currentFundingStage;
  }

  public set CurrentFundingStage(valor: FoundingTypeEnum) {
    this.cambiarPropiedadYNotificar('currentFundingStage', valor);
  }

  public get Linkedin(): string {
    return this._entidad.linkedin;
  }

  public set Linkedin(valor: string) {
    this.cambiarPropiedadYNotificar('linkedin', valor);
  }

  public get Image(): string {
    return this._entidad.image;
  }

  public set Image(valor: string) {
    this.cambiarPropiedadYNotificar('image', valor);
  }

  public get FromConnection(): boolean {
    return this._entidad.fromConnection;
  }

  public set FromConnection(valor: boolean) {
    this.cambiarPropiedadYNotificar('fromConnection', valor);
  }

  public get ContactEmail(): string {
    return this._entidad.contactEmail;
  }

  public set ContactEmail(valor: string) {
    this.cambiarPropiedadYNotificar('contactEmail', valor);
  }

  public get ConnectionId(): number {
    return this._entidad.connectionId;
  }

  public set ConnectionId(valor: number) {
    this.cambiarPropiedadYNotificar('connectionId', valor);
  }

  public get ConfirmCorrectInfo(): boolean {
    return this._entidad.confirmCorrectInfo;
  }

  public set ConfirmCorrectInfo(valor: boolean) {
    this.cambiarPropiedadYNotificar('confirmCorrectInfo', valor);
  }

  public get ConfirmCorrectInfoAt(): Date {
    return this._entidad.confirmCorrectInfoAt;
  }

  public set ConfirmCorrectInfoAt(valor: Date) {
    this.cambiarPropiedadYNotificar('confirmCorrectInfoAt', valor);
  }

  public get CrunchbaseRank(): number {
    return this._entidad.crunchbaseRank;
  }

  public set CrunchbaseRank(valor: number) {
    this.cambiarPropiedadYNotificar('crunchbaseRank', valor);
  }
}
