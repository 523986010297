import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';

import { ICompanyBasicDTO, CompanyBasicDTO } from './CompanyBasic.dto';
import { IVariableBasicDTO, VariableBasicDTO } from './VariableBasic.dto';
import { ProfitTypeEnum } from './ProfitType.enum';

export interface ICompanyDTO extends ICompanyBasicDTO {
  questions: IVariableBasicDTO[];
}

export class CompanyDTO extends CompanyBasicDTO implements IDTO<CompanyDTO, ICompanyDTO> {
  public questions: VariableBasicDTO[] = [];

  constructor(json?: Partial<ICompanyDTO>) {
    super(json);

    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public override cargarDTO(json: Partial<ICompanyDTO>) {
    super.cargarDTO(json);

    if (json.questions != null) {
      this.questions = json.questions.map(item => new VariableBasicDTO(item));
    }
  }

  public override aJSON(): ICompanyDTO {
    return {
      ...super.aJSON(),
      questions: this.questions.map(item => item.aJSON())
    };
  }

  public clon<T extends CompanyDTO>(clonBase: T): T;
  public clon<T extends CompanyDTO>(): CompanyDTO;
  public override clon<T extends CompanyDTO>(clonBase: Nilable<T> = null): T | CompanyDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.questions = this.questions.map(item => item.clon());
      return clonBase;
    } else {
      const clon = super.clon(new CompanyDTO());
      clon.questions = this.questions.map(item => item.clon());
      return clon;
    }
  }
}

export class CompanyModelDTO extends ModelDTO<CompanyDTO> {
  constructor(entidad: CompanyDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Id(): number {
    return this._entidad.id;
  }

  public set Id(valor: number) {
    this.cambiarPropiedadYNotificar('id', valor);
  }

  public get Name(): string {
    return this._entidad.name;
  }

  public set Name(valor: string) {
    this.cambiarPropiedadYNotificar('name', valor);
  }

  public get Image(): string {
    return this._entidad.image;
  }

  public set Image(valor: string) {
    this.cambiarPropiedadYNotificar('image', valor);
  }

  public get InvestmentRange(): number {
    return this._entidad.investmentRange;
  }

  public set InvestmentRange(valor: number) {
    this.cambiarPropiedadYNotificar('investmentRange', valor);
  }

  public get Description(): string {
    return this._entidad.description;
  }

  public set Description(valor: string) {
    this.cambiarPropiedadYNotificar('description', valor);
  }

  public get Headquarters(): string {
    return this._entidad.headquarters;
  }

  public set Headquarters(valor: string) {
    this.cambiarPropiedadYNotificar('headquarters', valor);
  }

  public get Funds(): number {
    return this._entidad.funds;
  }

  public set Funds(valor: number) {
    this.cambiarPropiedadYNotificar('funds', valor);
  }

  public get Investments(): number {
    return this._entidad.investments;
  }

  public set Investments(valor: number) {
    this.cambiarPropiedadYNotificar('investments', valor);
  }

  public get LeadInvestments(): number {
    return this._entidad.leadInvestments;
  }

  public set LeadInvestments(valor: number) {
    this.cambiarPropiedadYNotificar('leadInvestments', valor);
  }

  public get Exits(): number {
    return this._entidad.exits;
  }

  public set Exits(valor: number) {
    this.cambiarPropiedadYNotificar('exits', valor);
  }

  public get FoundingDate(): Date {
    return this._entidad.foundingDate;
  }

  public set FoundingDate(valor: Date) {
    this.cambiarPropiedadYNotificar('foundingDate', valor);
  }

  public get ProfitType(): ProfitTypeEnum {
    return this._entidad.profitType;
  }

  public set ProfitType(valor: ProfitTypeEnum) {
    this.cambiarPropiedadYNotificar('profitType', valor);
  }

  public get Linkedin(): string {
    return this._entidad.linkedin;
  }

  public set Linkedin(valor: string) {
    this.cambiarPropiedadYNotificar('linkedin', valor);
  }

  public get Website(): string {
    return this._entidad.website;
  }

  public set Website(valor: string) {
    this.cambiarPropiedadYNotificar('website', valor);
  }

  public get PhoneNumber(): string {
    return this._entidad.phoneNumber;
  }

  public set PhoneNumber(valor: string) {
    this.cambiarPropiedadYNotificar('phoneNumber', valor);
  }

  public get Email(): string {
    return this._entidad.email;
  }

  public set Email(valor: string) {
    this.cambiarPropiedadYNotificar('email', valor);
  }

  public get ConfirmCorrectInfo(): boolean {
    return this._entidad.confirmCorrectInfo;
  }

  public set ConfirmCorrectInfo(valor: boolean) {
    this.cambiarPropiedadYNotificar('confirmCorrectInfo', valor);
  }

  public get ShowInDiscover(): boolean {
    return this._entidad.showInDiscover;
  }

  public set ShowInDiscover(valor: boolean) {
    this.cambiarPropiedadYNotificar('showInDiscover', valor);
  }

  public get Questions(): VariableBasicDTO[] {
    return this._entidad.questions;
  }

  public set Questions(valor: VariableBasicDTO[]) {
    this.cambiarPropiedadYNotificar('questions', valor);
  }
}
