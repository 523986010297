<h1 mat-dialog-title class="text-primary">Mentorship</h1>
<div mat-dialog-content>
  <p [innerHTML]="data.text | textToHtml"></p>
</div>
<div mat-dialog-actions>
  <div class="flex grow" [class.justify-between]="data.hasPrevious" [class.justify-end]="!data.hasPrevious">
    <button mat-stroked-button color="primary" (click)="back()" *ngIf="data.hasPrevious">
      <mat-icon>arrow_back</mat-icon>
      Previous
    </button>
    <div class="flex grow justify-end">
      <button mat-stroked-button color="primary" [cdkCopyToClipboard]="data.text" class="ml-2 font-bold">
        <mat-icon>content_copy</mat-icon>
        Copy
      </button>
      <button mat-raised-button color="primary" *ngIf="data.showAskWarren" (click)="askWarren()">Ask warren</button>
      <button mat-raised-button color="primary" *ngIf="data.hasNext" (click)="next()">Get another point of view</button>
    </div>
  </div>
</div>
