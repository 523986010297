import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';
import { IStartupCountSendEmailDTO, StartupCountSendEmailDTO } from './StartupCountSendEmail.dto';

export interface IStartupCountSendEmailsDTO {
  successStartups: IStartupCountSendEmailDTO[];
  failedStartups: IStartupCountSendEmailDTO[];
}

export class StartupCountSendEmailsDTO implements IDTO<StartupCountSendEmailsDTO, IStartupCountSendEmailsDTO> {
  public successStartups: StartupCountSendEmailDTO[] = [];
  public failedStartups: StartupCountSendEmailDTO[] = [];

  constructor(json?: Partial<IStartupCountSendEmailsDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<IStartupCountSendEmailsDTO>) {
    if (json.successStartups != null) {
      this.successStartups = json.successStartups.map(item => new StartupCountSendEmailDTO(item));
    }
    if (json.failedStartups != null) {
      this.failedStartups = json.failedStartups.map(item => new StartupCountSendEmailDTO(item));
    }
  }

  public aJSON(): IStartupCountSendEmailsDTO {
    return {
      successStartups: this.successStartups.map(item => item.aJSON()),
      failedStartups: this.failedStartups.map(item => item.aJSON())
    };
  }

  public clon<T extends StartupCountSendEmailsDTO>(clonBase: T): T;
  public clon<T extends StartupCountSendEmailsDTO>(): StartupCountSendEmailsDTO;
  public clon<T extends StartupCountSendEmailsDTO>(clonBase: Nilable<T> = null): T | StartupCountSendEmailsDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.successStartups = this.successStartups.map(item => item.clon());
      clonBase.failedStartups = this.failedStartups.map(item => item.clon());
      return clonBase;
    } else {
      const clon = new StartupCountSendEmailsDTO();
      clon.successStartups = this.successStartups.map(item => item.clon());
      clon.failedStartups = this.failedStartups.map(item => item.clon());
      return clon;
    }
  }
}

export class StartupCountSendEmailsModelDTO extends ModelDTO<StartupCountSendEmailsDTO> {
  constructor(entidad: StartupCountSendEmailsDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get SuccessStartups(): StartupCountSendEmailDTO[] {
    return this._entidad.successStartups;
  }

  public set SuccessStartups(valor: StartupCountSendEmailDTO[]) {
    this.cambiarPropiedadYNotificar('successStartups', valor);
  }

  public get FailedStartups(): StartupCountSendEmailDTO[] {
    return this._entidad.failedStartups;
  }

  public set FailedStartups(valor: StartupCountSendEmailDTO[]) {
    this.cambiarPropiedadYNotificar('failedStartups', valor);
  }
}
