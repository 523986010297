import { Pipe, PipeTransform } from '@angular/core';
import { CompanyRuleDTO, CompanyRuleTypeEnum, ScoringRuleEnum, VariableDTO } from '@DTOS';
import { formatStartupProfile } from '../../utils/StartupProfile.utils';

@Pipe({
  name: 'companyRuleVariable'
})
export class CompanyRuleVariablePipe implements PipeTransform {
  transform(rule: CompanyRuleDTO, variables: VariableDTO[]): string {
    switch (rule.type) {
      case CompanyRuleTypeEnum.Answers: {
        const variableRule = variables.find(variable => variable.id === rule.variable);
        return variableRule?.name ?? '';
      }
      case CompanyRuleTypeEnum.Profile:
        return formatStartupProfile(rule.variable);
      case CompanyRuleTypeEnum.Scoring: {
        const scoringType = rule.variable as ScoringRuleEnum;
        switch (scoringType) {
          case ScoringRuleEnum.Parcial:
            return 'Parcial';
          case ScoringRuleEnum.Full:
            return 'Full';
          default:
            return '';
        }
      }
      case CompanyRuleTypeEnum.Topics:
        const topicRule = variables.find(variable => variable.topic.id === rule.variable);
        return topicRule.topic.name;
    }
  }
}
