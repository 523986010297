import { Injectable } from '@angular/core';
import { CompanyServicioBackend } from '@serviceBackend';
import {
  CompanyAutoRejectDTO,
  CompanyBasicDTO,
  CompanyDTO,
  CompanyFullDTO,
  CompanyRuleDTO,
  CustomPlanSelectedDTO,
  CustomPlanVariableDTO,
  SearchCompanyFilterDTO,
  SearchCompanySizeDTO,
  StartupCompanyBasicDTO
} from '@DTOS';
import { firstValueFrom, map, mergeMap, Observable } from 'rxjs';
import { UsuarioDTO } from '@nahuelmorata/framework-frontend';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  public companyRuleEditing: CompanyRuleDTO | null;

  constructor(private companyBackend: CompanyServicioBackend) {}

  public userCompanies(): Observable<Array<CompanyBasicDTO>> {
    return this.companyBackend.GetUserCompanies().pipe(map(x => x.items));
  }

  public getCompany(companyId: number): Observable<CompanyDTO> {
    return this.companyBackend.GetCompany(companyId);
  }

  public editCompany(company: CompanyBasicDTO): Observable<CompanyBasicDTO> {
    return this.companyBackend.UpdateCompany(company, company.id);
  }

  public usersFromCompany(company: CompanyBasicDTO): Observable<UsuarioDTO[]> {
    return this.companyBackend.GetUsersFromCompany(company.id).pipe(map(x => x.items));
  }

  public userCompaniesById(userId: number): Observable<Array<CompanyBasicDTO>> {
    return this.companyBackend.GetCompaniesFromUser(userId).pipe(map(x => x.items));
  }

  public userStartupsById(companyId: number, userId: number): Observable<Array<StartupCompanyBasicDTO>> {
    return this.companyBackend.GetStartupsFromUserByCompany(companyId, userId).pipe(map(x => x.items));
  }

  public async getCompanyStartupsUser(
    companyId?: number,
    includeArchived = false
  ): Promise<Array<StartupCompanyBasicDTO>> {
    let id = companyId;
    if (id == null) {
      const companyDefault = await firstValueFrom(this.getCompanyDefault());
      id = companyDefault.id;
    }
    return await firstValueFrom(this.companyBackend.GetUserStartups(id).pipe(map(x => x.items)));
  }
  public getAll(): Observable<Array<CompanyBasicDTO>> {
    return this.companyBackend.GetAllCompanies().pipe(map(x => x.items));
  }

  public getCompanyFull(company: CompanyBasicDTO): Observable<CompanyFullDTO> {
    return this.companyBackend.GetCompanyFull(company.id);
  }

  public insertCompany(companyDTO: CompanyBasicDTO): Observable<CompanyBasicDTO> {
    return this.companyBackend.CreateCompany(companyDTO);
  }

  public getRules(company: CompanyBasicDTO): Observable<CompanyRuleDTO[]> {
    return this.companyBackend.GetRules(company.id).pipe(map(x => x.items));
  }

  public addRule(company: CompanyBasicDTO, rule: CompanyRuleDTO): Observable<CompanyRuleDTO> {
    return this.companyBackend.CreateRule(rule, company.id);
  }

  public deleteRule(company: CompanyBasicDTO, rule: CompanyRuleDTO): Observable<void> {
    return this.companyBackend.DeleteRule(company.id, rule.id);
  }

  public getVariablePlans(company: CompanyBasicDTO): Observable<CustomPlanVariableDTO[]> {
    return this.companyBackend.GetCustomPlansVariable(company.id).pipe(map(x => x.items));
  }

  public updateVariablePlan(
    company: CompanyBasicDTO,
    variablePlan: CustomPlanVariableDTO
  ): Observable<CustomPlanVariableDTO> {
    return this.companyBackend.UpdateCustomPlanVariable(variablePlan, company.id, variablePlan.id);
  }

  public insertVariablePlan(
    company: CompanyBasicDTO,
    variablePlan: CustomPlanVariableDTO
  ): Observable<CustomPlanVariableDTO> {
    return this.companyBackend.AddCustomPlanVariable(variablePlan, company.id);
  }

  public getSelectedVariablePlan(company?: CompanyBasicDTO | null): Observable<CustomPlanSelectedDTO> {
    if (company == null) {
      return this.getCompanyDefault().pipe(
        mergeMap(defaultCompany => this.companyBackend.GetCustomPlanVariableSelected(defaultCompany.id))
      );
    }
    return this.companyBackend.GetCustomPlanVariableSelected(company.id);
  }

  public getCompanyDefault(): Observable<CompanyBasicDTO> {
    return this.companyBackend.GetDefaultCompany();
  }

  public addUserToStartup(
    usuarioDTO: UsuarioDTO,
    company: CompanyBasicDTO,
    startup: StartupCompanyBasicDTO
  ): Observable<void> {
    return this.companyBackend.AddUserToStartup(usuarioDTO, company.id, startup.id);
  }

  public discoverCompany(
    size: number,
    page: number,
    searchCompanyFilterDTO: SearchCompanyFilterDTO
  ): Observable<SearchCompanySizeDTO> {
    return this.companyBackend.Discover(searchCompanyFilterDTO, page, size);
  }

  public getStartupsAutoRejected(): Observable<CompanyAutoRejectDTO[]> {
    return this.companyBackend.GetAutoRejectStartups().pipe(map(x => x.items));
  }
}
