import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';
import { IStartupDateMissingAssessmentDTO, StartupDateMissingAssessmentDTO } from './StartupDateMissingAssessment.dto';

export interface IStartupMissingAssessmentDTO {
  missingAssessments: IStartupDateMissingAssessmentDTO[];
}

export class StartupMissingAssessmentDTO implements IDTO<StartupMissingAssessmentDTO, IStartupMissingAssessmentDTO> {
  public missingAssessments: StartupDateMissingAssessmentDTO[] = [];

  constructor(json?: Partial<IStartupMissingAssessmentDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<IStartupMissingAssessmentDTO>) {
    if (json.missingAssessments != null) {
      this.missingAssessments = json.missingAssessments.map(item => new StartupDateMissingAssessmentDTO(item));
    }
  }

  public aJSON(): IStartupMissingAssessmentDTO {
    return {
      missingAssessments: this.missingAssessments.map(item => item.aJSON())
    };
  }

  public clon<T extends StartupMissingAssessmentDTO>(clonBase: T): T;
  public clon<T extends StartupMissingAssessmentDTO>(): StartupMissingAssessmentDTO;
  public clon<T extends StartupMissingAssessmentDTO>(clonBase: Nilable<T> = null): T | StartupMissingAssessmentDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.missingAssessments = this.missingAssessments.map(item => item.clon());
      return clonBase;
    } else {
      const clon = new StartupMissingAssessmentDTO();
      clon.missingAssessments = this.missingAssessments.map(item => item.clon());
      return clon;
    }
  }
}

export class StartupMissingAssessmentModelDTO extends ModelDTO<StartupMissingAssessmentDTO> {
  constructor(entidad: StartupMissingAssessmentDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get MissingAssessments(): StartupDateMissingAssessmentDTO[] {
    return this._entidad.missingAssessments;
  }

  public set MissingAssessments(valor: StartupDateMissingAssessmentDTO[]) {
    this.cambiarPropiedadYNotificar('missingAssessments', valor);
  }
}
