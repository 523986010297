import { IDTO, ModelDTO, IArchivoHostDTO, ArchivoHostDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';

import { IStartupCompanyBasicDTO, StartupCompanyBasicDTO } from './StartupCompanyBasic.dto';
import { ICompanyBasicDTO, CompanyBasicDTO } from './CompanyBasic.dto';
import { ICountryDTO, CountryDTO } from './Country.dto';
import { IIndustryDTO, IndustryDTO } from './Industry.dto';
import { IFounderDTO, FounderDTO } from './Founder.dto';
import { StartupTypeEnum } from './StartupType.enum';
import { FoundingTypeEnum } from './FoundingType.enum';
import { OperatingStatusEnum } from './OperatingStatus.enum';
import { ProfitTypeEnum } from './ProfitType.enum';

export interface IStartupCompanyDTO extends IStartupCompanyBasicDTO {
  companies: ICompanyBasicDTO[];
  countries: ICountryDTO[];
  industries: IIndustryDTO[];
  founders: IFounderDTO[];
  files: IArchivoHostDTO[];
}

export class StartupCompanyDTO extends StartupCompanyBasicDTO implements IDTO<StartupCompanyDTO, IStartupCompanyDTO> {
  public companies: CompanyBasicDTO[] = [];
  public countries: CountryDTO[] = [];
  public industries: IndustryDTO[] = [];
  public founders: FounderDTO[] = [];
  public files: ArchivoHostDTO[] = [];

  constructor(json?: Partial<IStartupCompanyDTO>) {
    super(json);

    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public override cargarDTO(json: Partial<IStartupCompanyDTO>) {
    super.cargarDTO(json);

    if (json.companies != null) {
      this.companies = json.companies.map(item => new CompanyBasicDTO(item));
    }
    if (json.countries != null) {
      this.countries = json.countries.map(item => new CountryDTO(item));
    }
    if (json.industries != null) {
      this.industries = json.industries.map(item => new IndustryDTO(item));
    }
    if (json.founders != null) {
      this.founders = json.founders.map(item => new FounderDTO(item));
    }
    if (json.files != null) {
      this.files = json.files.map(item => new ArchivoHostDTO(item));
    }
  }

  public override aJSON(): IStartupCompanyDTO {
    return {
      ...super.aJSON(),
      companies: this.companies.map(item => item.aJSON()),
      countries: this.countries.map(item => item.aJSON()),
      industries: this.industries.map(item => item.aJSON()),
      founders: this.founders.map(item => item.aJSON()),
      files: this.files.map(item => item.aJSON())
    };
  }

  public clon<T extends StartupCompanyDTO>(clonBase: T): T;
  public clon<T extends StartupCompanyDTO>(): StartupCompanyDTO;
  public override clon<T extends StartupCompanyDTO>(clonBase: Nilable<T> = null): T | StartupCompanyDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.companies = this.companies.map(item => item.clon());
      clonBase.countries = this.countries.map(item => item.clon());
      clonBase.industries = this.industries.map(item => item.clon());
      clonBase.founders = this.founders.map(item => item.clon());
      clonBase.files = this.files.map(item => item.clon());
      return clonBase;
    } else {
      const clon = super.clon(new StartupCompanyDTO());
      clon.companies = this.companies.map(item => item.clon());
      clon.countries = this.countries.map(item => item.clon());
      clon.industries = this.industries.map(item => item.clon());
      clon.founders = this.founders.map(item => item.clon());
      clon.files = this.files.map(item => item.clon());
      return clon;
    }
  }
}

export class StartupCompanyModelDTO extends ModelDTO<StartupCompanyDTO> {
  constructor(entidad: StartupCompanyDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Id(): number {
    return this._entidad.id;
  }

  public set Id(valor: number) {
    this.cambiarPropiedadYNotificar('id', valor);
  }

  public get Name(): string {
    return this._entidad.name;
  }

  public set Name(valor: string) {
    this.cambiarPropiedadYNotificar('name', valor);
  }

  public get Description(): string {
    return this._entidad.description;
  }

  public set Description(valor: string) {
    this.cambiarPropiedadYNotificar('description', valor);
  }

  public get Investment(): number {
    return this._entidad.investment;
  }

  public set Investment(valor: number) {
    this.cambiarPropiedadYNotificar('investment', valor);
  }

  public get Type(): StartupTypeEnum {
    return this._entidad.type;
  }

  public set Type(valor: StartupTypeEnum) {
    this.cambiarPropiedadYNotificar('type', valor);
  }

  public get IsArchived(): boolean {
    return this._entidad.isArchived;
  }

  public set IsArchived(valor: boolean) {
    this.cambiarPropiedadYNotificar('isArchived', valor);
  }

  public get BrandName(): string {
    return this._entidad.brandName;
  }

  public set BrandName(valor: string) {
    this.cambiarPropiedadYNotificar('brandName', valor);
  }

  public get LegalName(): string {
    return this._entidad.legalName;
  }

  public set LegalName(valor: string) {
    this.cambiarPropiedadYNotificar('legalName', valor);
  }

  public get Headquarters(): string {
    return this._entidad.headquarters;
  }

  public set Headquarters(valor: string) {
    this.cambiarPropiedadYNotificar('headquarters', valor);
  }

  public get Website(): string {
    return this._entidad.website;
  }

  public set Website(valor: string) {
    this.cambiarPropiedadYNotificar('website', valor);
  }

  public get TotalInvestments(): number {
    return this._entidad.totalInvestments;
  }

  public set TotalInvestments(valor: number) {
    this.cambiarPropiedadYNotificar('totalInvestments', valor);
  }

  public get TotalFundingAmount(): number {
    return this._entidad.totalFundingAmount;
  }

  public set TotalFundingAmount(valor: number) {
    this.cambiarPropiedadYNotificar('totalFundingAmount', valor);
  }

  public get FoundedDate(): Date {
    return this._entidad.foundedDate;
  }

  public set FoundedDate(valor: Date) {
    this.cambiarPropiedadYNotificar('foundedDate', valor);
  }

  public get QuantityEmployees(): string {
    return this._entidad.quantityEmployees;
  }

  public set QuantityEmployees(valor: string) {
    this.cambiarPropiedadYNotificar('quantityEmployees', valor);
  }

  public get FoundersPercentage(): string {
    return this._entidad.foundersPercentage;
  }

  public set FoundersPercentage(valor: string) {
    this.cambiarPropiedadYNotificar('foundersPercentage', valor);
  }

  public get PercentageLoans(): string {
    return this._entidad.percentageLoans;
  }

  public set PercentageLoans(valor: string) {
    this.cambiarPropiedadYNotificar('percentageLoans', valor);
  }

  public get LastFundingType(): FoundingTypeEnum {
    return this._entidad.lastFundingType;
  }

  public set LastFundingType(valor: FoundingTypeEnum) {
    this.cambiarPropiedadYNotificar('lastFundingType', valor);
  }

  public get OperatingStatus(): OperatingStatusEnum {
    return this._entidad.operatingStatus;
  }

  public set OperatingStatus(valor: OperatingStatusEnum) {
    this.cambiarPropiedadYNotificar('operatingStatus', valor);
  }

  public get ProfitType(): ProfitTypeEnum {
    return this._entidad.profitType;
  }

  public set ProfitType(valor: ProfitTypeEnum) {
    this.cambiarPropiedadYNotificar('profitType', valor);
  }

  public get CurrentFundingStage(): FoundingTypeEnum {
    return this._entidad.currentFundingStage;
  }

  public set CurrentFundingStage(valor: FoundingTypeEnum) {
    this.cambiarPropiedadYNotificar('currentFundingStage', valor);
  }

  public get Linkedin(): string {
    return this._entidad.linkedin;
  }

  public set Linkedin(valor: string) {
    this.cambiarPropiedadYNotificar('linkedin', valor);
  }

  public get Image(): string {
    return this._entidad.image;
  }

  public set Image(valor: string) {
    this.cambiarPropiedadYNotificar('image', valor);
  }

  public get FromConnection(): boolean {
    return this._entidad.fromConnection;
  }

  public set FromConnection(valor: boolean) {
    this.cambiarPropiedadYNotificar('fromConnection', valor);
  }

  public get ContactEmail(): string {
    return this._entidad.contactEmail;
  }

  public set ContactEmail(valor: string) {
    this.cambiarPropiedadYNotificar('contactEmail', valor);
  }

  public get ConnectionId(): number {
    return this._entidad.connectionId;
  }

  public set ConnectionId(valor: number) {
    this.cambiarPropiedadYNotificar('connectionId', valor);
  }

  public get ConfirmCorrectInfo(): boolean {
    return this._entidad.confirmCorrectInfo;
  }

  public set ConfirmCorrectInfo(valor: boolean) {
    this.cambiarPropiedadYNotificar('confirmCorrectInfo', valor);
  }

  public get ConfirmCorrectInfoAt(): Date {
    return this._entidad.confirmCorrectInfoAt;
  }

  public set ConfirmCorrectInfoAt(valor: Date) {
    this.cambiarPropiedadYNotificar('confirmCorrectInfoAt', valor);
  }

  public get CrunchbaseRank(): number {
    return this._entidad.crunchbaseRank;
  }

  public set CrunchbaseRank(valor: number) {
    this.cambiarPropiedadYNotificar('crunchbaseRank', valor);
  }

  public get Companies(): CompanyBasicDTO[] {
    return this._entidad.companies;
  }

  public set Companies(valor: CompanyBasicDTO[]) {
    this.cambiarPropiedadYNotificar('companies', valor);
  }

  public get Countries(): CountryDTO[] {
    return this._entidad.countries;
  }

  public set Countries(valor: CountryDTO[]) {
    this.cambiarPropiedadYNotificar('countries', valor);
  }

  public get Industries(): IndustryDTO[] {
    return this._entidad.industries;
  }

  public set Industries(valor: IndustryDTO[]) {
    this.cambiarPropiedadYNotificar('industries', valor);
  }

  public get Founders(): FounderDTO[] {
    return this._entidad.founders;
  }

  public set Founders(valor: FounderDTO[]) {
    this.cambiarPropiedadYNotificar('founders', valor);
  }

  public get Files(): ArchivoHostDTO[] {
    return this._entidad.files;
  }

  public set Files(valor: ArchivoHostDTO[]) {
    this.cambiarPropiedadYNotificar('files', valor);
  }
}
