<div class="flex flex-col grow">
  <div class="grow flex flex-col">
    @for (topic of topics | sortTopic; track topic.id) {
      <app-topic-interview
        [topic]="getTopicQuestionsModel(topic.name)"
        [topicName]="topic.name"
        [variablesShow]="getIdsShow(topic.id)"></app-topic-interview>
    }
  </div>
  <div class="flex grow mt-4 mb-4">
    <div>
      <mat-checkbox [(ngModel)]="confirmCorrectInfo" name="correct-info">
        I agree that all information provided is correct and true.
        <mat-icon
          class="size-1 text-neutral-500"
          matTooltip="By checking this box, I hereby confirm that all the information provided in this form is accurate, correct, and true to the best of my knowledge. I understand that any misrepresentation or false information may result in consequences as per the applicable laws and regulations.">
          help
        </mat-icon>
      </mat-checkbox>
    </div>
    <div class="flex justify-end grow">
      <button id="canc" type="button" class="mr-2" mat-stroked-button (click)="cancelar()">Cancel</button>
      <button
        id="agreg"
        type="button"
        mat-raised-button
        color="primary"
        [command]="saveCmd"
        [disabled]="isViewOnly || !confirmCorrectInfo || isDemo"
        [matTooltip]="isDemo ? 'Disabled on demo' : null">
        Save
      </button>
    </div>
  </div>
</div>
