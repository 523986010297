import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textToHtml'
})
export class TextToHtmlPipe implements PipeTransform {
  transform(text: string): string {
    return text.replace(/\n/g, '<br />');
  }
}
