import { Injectable } from '@angular/core';
import { AuthService } from '@nahuelmorata/framework-frontend';
import { RoleEnum } from 'src/app/shared/enums/Role.enum';
import { UsersUtils } from '../Users.utils';

@Injectable()
export class UsersImplUtils implements UsersUtils {
  constructor(private authService: AuthService) {}

  public isVCAdmin(): boolean {
    return this.authService.usuario.rol.id === RoleEnum.VC_ADMIN;
  }

  public isVCAnalyst(): boolean {
    return this.authService.usuario.rol.id === RoleEnum.VC_ANALYST;
  }

  public isVCAssessment(): boolean {
    return this.authService.usuario.rol.id === RoleEnum.VC_ASSESSMENT;
  }

  public isVC(): boolean {
    return this.isVCAdmin() || this.isVCAssessment() || this.isVCAnalyst();
  }

  public isStartupAdmin(): boolean {
    return this.authService.usuario.rol.id === RoleEnum.STARTUP_ADMIN;
  }

  public isStartupAnalyst(): boolean {
    return this.authService.usuario.rol.id === RoleEnum.STARTUP_ANALYST;
  }

  public isStartupAssessment(): boolean {
    return this.authService.usuario.rol.id === RoleEnum.STARTUP_ASSESSMENT;
  }

  public isStartupFree(): boolean {
    return this.authService.usuario.rol.id === RoleEnum.STARTUP_FREE;
  }

  public isStartup(): boolean {
    return this.isStartupAdmin() || this.isStartupAnalyst() || this.isStartupAssessment() || this.isStartupFree();
  }

  public isNinetyAdmin(): boolean {
    return this.authService.usuario.rol.id === RoleEnum.ADMIN;
  }

  public isNinetySupport(): boolean {
    return this.authService.usuario.rol.id === RoleEnum.CONTACT_SUPPORT;
  }

  public isNinetyAssessment(): boolean {
    return this.authService.usuario.rol.id === RoleEnum.ASSESSMENT;
  }

  public isNinety(): boolean {
    return this.isNinetyAdmin() || this.isNinetySupport() || this.isNinetyAssessment();
  }

  public isAnalyst(): boolean {
    return this.isStartupAnalyst() || this.isVCAnalyst() || this.isNinetySupport();
  }

  public isAssessment(): boolean {
    return this.isVCAssessment() || this.isNinetyAssessment() || this.isStartupAssessment();
  }

  public isAdmin(): boolean {
    return this.isStartupAdmin() || this.isNinetyAdmin() || this.isVCAdmin();
  }

  public isCompany(): boolean {
    return this.isVC() || this.isNinety();
  }

  public isAnyUser(): boolean {
    return this.isVC() || this.isNinety() || this.isStartup() || this.isDemo();
  }

  public isDemo(): boolean {
    return this.authService.usuario.rol.id === RoleEnum.DEMO;
  }

  public isVCAdvisor(): boolean {
    return this.authService.usuario.rol.id === RoleEnum.VC_ADVISOR;
  }

  public isVCMentor(): boolean {
    return this.authService.usuario.rol.id === RoleEnum.VC_MENTOR;
  }

  public isVCJury(): boolean {
    return this.authService.usuario.rol.id === RoleEnum.VC_JURY;
  }

  public isVCAuditor(): boolean {
    return this.authService.usuario.rol.id === RoleEnum.VC_AUDITOR;
  }

  public isStartupAdvisor(): boolean {
    return this.authService.usuario.rol.id === RoleEnum.STARTUP_ADVISOR;
  }

  public isStartupMentor(): boolean {
    return this.authService.usuario.rol.id === RoleEnum.STARTUP_MENTOR;
  }

  public isStartupAuditor(): boolean {
    return this.authService.usuario.rol.id === RoleEnum.STARTUP_AUDITOR;
  }

  public isUserProfessional(): boolean {
    return (
      this.isVCAdvisor() ||
      this.isVCMentor() ||
      this.isVCJury() ||
      this.isVCAuditor() ||
      this.isStartupAdvisor() ||
      this.isStartupMentor() ||
      this.isStartupAuditor()
    );
  }
}
