import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';

export interface ICompanyRegisterDTO {
  name: string;
  domain: string;
  adminEmail: string;
  adminName: string;
  adminLastName: string;
  password: string;
}

export class CompanyRegisterDTO implements IDTO<CompanyRegisterDTO, ICompanyRegisterDTO> {
  public name: string = '';
  public domain: string = '';
  public adminEmail: string = '';
  public adminName: string = '';
  public adminLastName: string = '';
  public password: string = '';

  constructor(json?: Partial<ICompanyRegisterDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<ICompanyRegisterDTO>) {
    if (json.name != null) {
      this.name = json.name;
    }
    if (json.domain != null) {
      this.domain = json.domain;
    }
    if (json.adminEmail != null) {
      this.adminEmail = json.adminEmail;
    }
    if (json.adminName != null) {
      this.adminName = json.adminName;
    }
    if (json.adminLastName != null) {
      this.adminLastName = json.adminLastName;
    }
    if (json.password != null) {
      this.password = json.password;
    }
  }

  public aJSON(): ICompanyRegisterDTO {
    return {
      name: this.name,
      domain: this.domain,
      adminEmail: this.adminEmail,
      adminName: this.adminName,
      adminLastName: this.adminLastName,
      password: this.password
    };
  }

  public clon<T extends CompanyRegisterDTO>(clonBase: T): T;
  public clon<T extends CompanyRegisterDTO>(): CompanyRegisterDTO;
  public clon<T extends CompanyRegisterDTO>(clonBase: Nilable<T> = null): T | CompanyRegisterDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.name = this.name;
      clonBase.domain = this.domain;
      clonBase.adminEmail = this.adminEmail;
      clonBase.adminName = this.adminName;
      clonBase.adminLastName = this.adminLastName;
      clonBase.password = this.password;
      return clonBase;
    } else {
      const clon = new CompanyRegisterDTO();
      clon.name = this.name;
      clon.domain = this.domain;
      clon.adminEmail = this.adminEmail;
      clon.adminName = this.adminName;
      clon.adminLastName = this.adminLastName;
      clon.password = this.password;
      return clon;
    }
  }
}

export class CompanyRegisterModelDTO extends ModelDTO<CompanyRegisterDTO> {
  constructor(entidad: CompanyRegisterDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Name(): string {
    return this._entidad.name;
  }

  public set Name(valor: string) {
    this.cambiarPropiedadYNotificar('name', valor);
  }

  public get Domain(): string {
    return this._entidad.domain;
  }

  public set Domain(valor: string) {
    this.cambiarPropiedadYNotificar('domain', valor);
  }

  public get AdminEmail(): string {
    return this._entidad.adminEmail;
  }

  public set AdminEmail(valor: string) {
    this.cambiarPropiedadYNotificar('adminEmail', valor);
  }

  public get AdminName(): string {
    return this._entidad.adminName;
  }

  public set AdminName(valor: string) {
    this.cambiarPropiedadYNotificar('adminName', valor);
  }

  public get AdminLastName(): string {
    return this._entidad.adminLastName;
  }

  public set AdminLastName(valor: string) {
    this.cambiarPropiedadYNotificar('adminLastName', valor);
  }

  public get Password(): string {
    return this._entidad.password;
  }

  public set Password(valor: string) {
    this.cambiarPropiedadYNotificar('password', valor);
  }
}
