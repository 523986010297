import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';

export interface IStartupSummaryDTO {
  name: string;
  description: string;
  scoring: number;
  investment: number;
  totalAmount: number;
  headquarters: string;
  linkedin: string;
}

export class StartupSummaryDTO implements IDTO<StartupSummaryDTO, IStartupSummaryDTO> {
  public name: string = '';
  public description: string = '';
  public scoring: number = 0;
  public investment: number = 0;
  public totalAmount: number = 0;
  public headquarters: string = '';
  public linkedin: string = '';

  constructor(json?: Partial<IStartupSummaryDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<IStartupSummaryDTO>) {
    if (json.name != null) {
      this.name = json.name;
    }
    if (json.description != null) {
      this.description = json.description;
    }
    if (json.scoring != null) {
      this.scoring = json.scoring;
    }
    if (json.investment != null) {
      this.investment = json.investment;
    }
    if (json.totalAmount != null) {
      this.totalAmount = json.totalAmount;
    }
    if (json.headquarters != null) {
      this.headquarters = json.headquarters;
    }
    if (json.linkedin != null) {
      this.linkedin = json.linkedin;
    }
  }

  public aJSON(): IStartupSummaryDTO {
    return {
      name: this.name,
      description: this.description,
      scoring: this.scoring,
      investment: this.investment,
      totalAmount: this.totalAmount,
      headquarters: this.headquarters,
      linkedin: this.linkedin
    };
  }

  public clon<T extends StartupSummaryDTO>(clonBase: T): T;
  public clon<T extends StartupSummaryDTO>(): StartupSummaryDTO;
  public clon<T extends StartupSummaryDTO>(clonBase: Nilable<T> = null): T | StartupSummaryDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.name = this.name;
      clonBase.description = this.description;
      clonBase.scoring = this.scoring;
      clonBase.investment = this.investment;
      clonBase.totalAmount = this.totalAmount;
      clonBase.headquarters = this.headquarters;
      clonBase.linkedin = this.linkedin;
      return clonBase;
    } else {
      const clon = new StartupSummaryDTO();
      clon.name = this.name;
      clon.description = this.description;
      clon.scoring = this.scoring;
      clon.investment = this.investment;
      clon.totalAmount = this.totalAmount;
      clon.headquarters = this.headquarters;
      clon.linkedin = this.linkedin;
      return clon;
    }
  }
}

export class StartupSummaryModelDTO extends ModelDTO<StartupSummaryDTO> {
  constructor(entidad: StartupSummaryDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Name(): string {
    return this._entidad.name;
  }

  public set Name(valor: string) {
    this.cambiarPropiedadYNotificar('name', valor);
  }

  public get Description(): string {
    return this._entidad.description;
  }

  public set Description(valor: string) {
    this.cambiarPropiedadYNotificar('description', valor);
  }

  public get Scoring(): number {
    return this._entidad.scoring;
  }

  public set Scoring(valor: number) {
    this.cambiarPropiedadYNotificar('scoring', valor);
  }

  public get Investment(): number {
    return this._entidad.investment;
  }

  public set Investment(valor: number) {
    this.cambiarPropiedadYNotificar('investment', valor);
  }

  public get TotalAmount(): number {
    return this._entidad.totalAmount;
  }

  public set TotalAmount(valor: number) {
    this.cambiarPropiedadYNotificar('totalAmount', valor);
  }

  public get Headquarters(): string {
    return this._entidad.headquarters;
  }

  public set Headquarters(valor: string) {
    this.cambiarPropiedadYNotificar('headquarters', valor);
  }

  public get Linkedin(): string {
    return this._entidad.linkedin;
  }

  public set Linkedin(valor: string) {
    this.cambiarPropiedadYNotificar('linkedin', valor);
  }
}
