import { IDTO, ModelDTO, IUsuarioDTO, UsuarioDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';

export interface IUserProfessionalBasicDTO {
  user: IUsuarioDTO;
  expirationDate: string;
  createdAt: string;
  createdBy: IUsuarioDTO;
}

export class UserProfessionalBasicDTO implements IDTO<UserProfessionalBasicDTO, IUserProfessionalBasicDTO> {
  public user: UsuarioDTO = new UsuarioDTO();
  public expirationDate: Date = new Date();
  public createdAt: Date = new Date();
  public createdBy: UsuarioDTO = new UsuarioDTO();

  constructor(json?: Partial<IUserProfessionalBasicDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<IUserProfessionalBasicDTO>) {
    if (json.user != null) {
      this.user = new UsuarioDTO(json.user);
    }
    if (json.expirationDate != null) {
      this.expirationDate = new Date(json.expirationDate);
    }
    if (json.createdAt != null) {
      this.createdAt = new Date(json.createdAt);
    }
    if (json.createdBy != null) {
      this.createdBy = new UsuarioDTO(json.createdBy);
    }
  }

  public aJSON(): IUserProfessionalBasicDTO {
    return {
      user: this.user.aJSON(),
      expirationDate: this.expirationDate.toJSON(),
      createdAt: this.createdAt.toJSON(),
      createdBy: this.createdBy.aJSON()
    };
  }

  public clon<T extends UserProfessionalBasicDTO>(clonBase: T): T;
  public clon<T extends UserProfessionalBasicDTO>(): UserProfessionalBasicDTO;
  public clon<T extends UserProfessionalBasicDTO>(clonBase: Nilable<T> = null): T | UserProfessionalBasicDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.user = this.user.clon();
      clonBase.expirationDate = new Date(this.expirationDate.toJSON());
      clonBase.createdAt = new Date(this.createdAt.toJSON());
      clonBase.createdBy = this.createdBy.clon();
      return clonBase;
    } else {
      const clon = new UserProfessionalBasicDTO();
      clon.user = this.user.clon();
      clon.expirationDate = new Date(this.expirationDate.toJSON());
      clon.createdAt = new Date(this.createdAt.toJSON());
      clon.createdBy = this.createdBy.clon();
      return clon;
    }
  }
}

export class UserProfessionalBasicModelDTO extends ModelDTO<UserProfessionalBasicDTO> {
  constructor(entidad: UserProfessionalBasicDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get User(): UsuarioDTO {
    return this._entidad.user;
  }

  public set User(valor: UsuarioDTO) {
    this.cambiarPropiedadYNotificar('user', valor);
  }

  public get ExpirationDate(): Date {
    return this._entidad.expirationDate;
  }

  public set ExpirationDate(valor: Date) {
    this.cambiarPropiedadYNotificar('expirationDate', valor);
  }

  public get CreatedAt(): Date {
    return this._entidad.createdAt;
  }

  public set CreatedAt(valor: Date) {
    this.cambiarPropiedadYNotificar('createdAt', valor);
  }

  public get CreatedBy(): UsuarioDTO {
    return this._entidad.createdBy;
  }

  public set CreatedBy(valor: UsuarioDTO) {
    this.cambiarPropiedadYNotificar('createdBy', valor);
  }
}
