import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';

export interface ICustomPlanVariableDTO {
  id: number;
  name: string;
  selected: boolean;
  variables: any;
}

export class CustomPlanVariableDTO implements IDTO<CustomPlanVariableDTO, ICustomPlanVariableDTO> {
  public id: number = 0;
  public name: string = '';
  public selected: boolean = true;
  public variables: any = {};

  constructor(json?: Partial<ICustomPlanVariableDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<ICustomPlanVariableDTO>) {
    if (json.id != null) {
      this.id = json.id;
    }
    if (json.name != null) {
      this.name = json.name;
    }
    if (json.selected != null) {
      this.selected = json.selected;
    }
    if (json.variables != null) {
      this.variables = json.variables;
    }
  }

  public aJSON(): ICustomPlanVariableDTO {
    return {
      id: this.id,
      name: this.name,
      selected: this.selected,
      variables: this.variables
    };
  }

  public clon<T extends CustomPlanVariableDTO>(clonBase: T): T;
  public clon<T extends CustomPlanVariableDTO>(): CustomPlanVariableDTO;
  public clon<T extends CustomPlanVariableDTO>(clonBase: Nilable<T> = null): T | CustomPlanVariableDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.id = this.id;
      clonBase.name = this.name;
      clonBase.selected = this.selected;
      clonBase.variables = this.variables;
      return clonBase;
    } else {
      const clon = new CustomPlanVariableDTO();
      clon.id = this.id;
      clon.name = this.name;
      clon.selected = this.selected;
      clon.variables = this.variables;
      return clon;
    }
  }
}

export class CustomPlanVariableModelDTO extends ModelDTO<CustomPlanVariableDTO> {
  constructor(entidad: CustomPlanVariableDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Id(): number {
    return this._entidad.id;
  }

  public set Id(valor: number) {
    this.cambiarPropiedadYNotificar('id', valor);
  }

  public get Name(): string {
    return this._entidad.name;
  }

  public set Name(valor: string) {
    this.cambiarPropiedadYNotificar('name', valor);
  }

  public get Selected(): boolean {
    return this._entidad.selected;
  }

  public set Selected(valor: boolean) {
    this.cambiarPropiedadYNotificar('selected', valor);
  }

  public get Variables(): any {
    return this._entidad.variables;
  }

  public set Variables(valor: any) {
    this.cambiarPropiedadYNotificar('variables', valor);
  }
}
