export enum StartupColumnOrderEnum {
  Name,
  Scoring,
  Founders,
  Industries,
  Headquarters,
  TotalFundingAmount,
  CurrentFundingType,
  Employees
}
