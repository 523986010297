import { Directive, ElementRef, Input, HostListener } from '@angular/core';

@Directive({
  selector: '[appMaxLength]'
})
export class MaxLengthDirective {
  @Input() appMaxLength: any;

  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event']) onKeyDown(e: KeyboardEvent) {
    if (
      e.key === 'Delete' ||
      e.key === 'Tab' ||
      e.key === 'Backspace' ||
      e.key === 'ArrowLeft' ||
      e.altKey ||
      e.ctrlKey ||
      e.key === 'Home' ||
      e.key === 'End' ||
      e.key === 'PageDown' ||
      e.key === 'PageUp' ||
      e.key === 'ArrowRight' ||
      e.key === 'Control' ||
      e.key === 'Alt' ||
      e.key === 'Shift'
    ) {
      return;
    }

    const element = this.el.nativeElement.value;
    if (element && element.length >= this.appMaxLength) {
      e.preventDefault();
    }
  }
}
