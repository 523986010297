import { Injectable, inject } from '@angular/core';
import { CacheEntityService } from '../../cache/cache-entity.service';
import { StartupCompanyEntity } from '@domain/startup-company';
import { StartupCompanyPort } from '@ports/startup-company';
import { Observable, ReplaySubject, map, take, tap } from 'rxjs';
import { List } from 'immutable';
import { StartupCompanyServicioBackend } from '@serviceBackend';
import { CompanyEntity } from '@domain/company';

/**
 * @deprecated Use StartupCompanyPort instead
 */
@Injectable({
  providedIn: 'root'
})
export class StartupCompanyAdapterService
  extends CacheEntityService<StartupCompanyEntity>
  implements StartupCompanyPort
{
  public startupsUser$ = this.entities$;
  public startups$: Observable<List<StartupCompanyEntity>>;

  private _startups$ = new ReplaySubject<List<StartupCompanyEntity>>(1);

  private readonly startupCompanyServiceBackend = inject(StartupCompanyServicioBackend);

  constructor() {
    super();
    this.startups$ = this._startups$.asObservable();
    this.loadStartups();
  }

  public loadStartupCompany(company: CompanyEntity): Observable<void> {
    return this.startupCompanyServiceBackend.GetUserCompaniesByCompany(company.id).pipe(
      take(1),
      tap(x => this._startups$.next(List(x.items))),
      map(() => void 0)
    );
  }

  private loadStartups() {
    this.startupCompanyServiceBackend
      .GetUserCompanies()
      .pipe(
        take(1),
        tap(x => this.setEntities(x.items))
      )
      .subscribe();
  }
}
