import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataMentorshipModal } from './interfaces/DataMentorshipModal';

@Component({
  selector: 'app-mentorship-modal',
  templateUrl: './mentorship-modal.component.html',
  styleUrls: ['./mentorship-modal.component.scss']
})
export class MentorshipModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: DataMentorshipModal,
    private modalRef: MatDialogRef<MentorshipModalComponent, string | null>
  ) {}

  public back() {
    this.modalRef.close('back');
  }

  public askWarren() {
    this.modalRef.close('next');
  }

  public next() {
    this.modalRef.close('next');
  }
}
