import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Constants } from '../shared/constants';

import { ISubscriptionStatusDTO, SubscriptionStatusDTO } from '../DTO/SubscriptionStatus.dto';
import { ISessionInfoDTO, SessionInfoDTO } from '../DTO/SessionInfo.dto';
import { IRegisterResponseDTO, RegisterResponseDTO } from '../DTO/RegisterResponse.dto';
import { SubscriptionTrialEndDTO } from '../DTO/SubscriptionTrialEnd.dto';
import { ISubscriptionInfoDTO, SubscriptionInfoDTO } from '../DTO/SubscriptionInfo.dto';

@Injectable({
  providedIn: 'root'
})
export class PaymentServicioBackend {
  constructor(protected http: HttpClient) {}

  public GetStatusSubscription(): Observable<SubscriptionStatusDTO> {
    return this.http
      .get<ISubscriptionStatusDTO>(`${Constants.apiURL}/payment/subscription/status`)
      .pipe(map(entidad => new SubscriptionStatusDTO(entidad)));
  }

  public CancelSubscription(): Observable<void> {
    return this.http.post<void>(`${Constants.apiURL}/payment/subscription/cancel`, null);
  }

  public ResumeSubscription(): Observable<SessionInfoDTO> {
    return this.http
      .post<ISessionInfoDTO>(`${Constants.apiURL}/payment/subscription/resume`, null)
      .pipe(map(entidad => new SessionInfoDTO(entidad)));
  }

  public VerifySessionId(sessionId: string): Observable<void> {
    const params = new HttpParams().append('sessionId', sessionId);
    const opciones = { params };
    return this.http.get<void>(`${Constants.apiURL}/payment/session/info`, opciones);
  }

  public VerifySubscription(): Observable<RegisterResponseDTO> {
    return this.http
      .post<IRegisterResponseDTO>(`${Constants.apiURL}/payment/subscription/verify`, null)
      .pipe(map(entidad => new RegisterResponseDTO(entidad)));
  }

  public AddSubscriptionTrial(subscriptionTrialEndDTO: SubscriptionTrialEndDTO, userId: number): Observable<void> {
    return this.http.post<void>(`${Constants.apiURL}/subscription/${userId}/trial`, subscriptionTrialEndDTO);
  }

  public UpdateSubscription(sessionId: string): Observable<RegisterResponseDTO> {
    const params = new HttpParams().append('sessionId', sessionId);
    const opciones = { params };
    return this.http
      .post<IRegisterResponseDTO>(`${Constants.apiURL}/payment/update`, null, opciones)
      .pipe(map(entidad => new RegisterResponseDTO(entidad)));
  }

  public GetSubscriptionInfo(): Observable<SubscriptionInfoDTO> {
    return this.http
      .get<ISubscriptionInfoDTO>(`${Constants.apiURL}/payment/info`)
      .pipe(map(entidad => new SubscriptionInfoDTO(entidad)));
  }

  public UpdatePayment(): Observable<RegisterResponseDTO> {
    return this.http
      .get<IRegisterResponseDTO>(`${Constants.apiURL}/payment/updatePayment`)
      .pipe(map(entidad => new RegisterResponseDTO(entidad)));
  }
}
