import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Constants } from '../shared/constants';

import { FeedbackDTO } from '../DTO/Feedback.dto';

@Injectable({
  providedIn: 'root'
})
export class FeedbackServicioBackend {
  constructor(protected http: HttpClient) {}

  public SendFeedback(feedbackDTO: FeedbackDTO): Observable<void> {
    return this.http.post<void>(`${Constants.apiURL}/feedback`, feedbackDTO);
  }
}
