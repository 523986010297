<input hidden type="file" #uploader (change)="sendImageCmd.ejecutar($event)" [disabled]="disabled" />
<div
  class="image-container flex justify-center items-center cursor-pointer"
  (mouseenter)="showUploadIcon()"
  (mouseleave)="hideUploadIcon()"
  (click)="uploader.click()">
  <mat-icon
    class="size-2-5"
    *ngIf="(image == null && imageTemp == null) || isShowUploadIcon"
    [class.text-neutral-500]="image == null && imageTemp == null"
    >upload
  </mat-icon>
  <img *ngIf="image != null || imageTemp != null" [src]="(image | addBaseUrl) ?? imageTemp" alt="logo" class="w-full" />
</div>
