<ng-container *ngIf="cardTitleTemplate != null; else titleDefault">
  <ng-template [ngTemplateOutlet]="cardTitleTemplate"></ng-template>
</ng-container>
<ng-template #titleDefault>
  <h3 class="text-lg text-neutral-400">{{ title }}</h3>
</ng-template>
<div
  class="flex grow shadow-box p-6 rounded-lg flex-col mt-2"
  [ngClass]="{
    'bg-white': !backgroundWithColor,
    'bg-primary-light': backgroundWithColor
  }">
  <ng-content></ng-content>
</div>
