<app-profile-normal
  class="grow hidden md:flex flex-col mt-4"
  [dashboardAnalytics]="dashboardAnalytics()"
  [startupSelected]="startup()"
  [scores]="scores()"></app-profile-normal>
<app-profile-mobile
  class="grow flex md:hidden flex-col mt-4"
  [dashboardAnalytics]="dashboardAnalytics()"
  [startupSelected]="startup()"
  [scores]="scores()"></app-profile-mobile>
