import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';
import { IStartupCompanyDTO, StartupCompanyDTO } from './StartupCompany.dto';
import { ICompanyDTO, CompanyDTO } from './Company.dto';
import { IResponseBasicDTO, ResponseBasicDTO } from './ResponseBasic.dto';

export interface ISurveyResponseDTO {
  startup: IStartupCompanyDTO;
  company: ICompanyDTO;
  month: number;
  year: number;
  confirmCorrectInfo: boolean;
  response: IResponseBasicDTO[];
}

export class SurveyResponseDTO implements IDTO<SurveyResponseDTO, ISurveyResponseDTO> {
  public startup: StartupCompanyDTO = new StartupCompanyDTO();
  public company: CompanyDTO = new CompanyDTO();
  public month: number = 0;
  public year: number = 0;
  public confirmCorrectInfo: boolean = true;
  public response: ResponseBasicDTO[] = [];

  constructor(json?: Partial<ISurveyResponseDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<ISurveyResponseDTO>) {
    if (json.startup != null) {
      this.startup = new StartupCompanyDTO(json.startup);
    }
    if (json.company != null) {
      this.company = new CompanyDTO(json.company);
    }
    if (json.month != null) {
      this.month = json.month;
    }
    if (json.year != null) {
      this.year = json.year;
    }
    if (json.confirmCorrectInfo != null) {
      this.confirmCorrectInfo = json.confirmCorrectInfo;
    }
    if (json.response != null) {
      this.response = json.response.map(item => new ResponseBasicDTO(item));
    }
  }

  public aJSON(): ISurveyResponseDTO {
    return {
      startup: this.startup.aJSON(),
      company: this.company.aJSON(),
      month: this.month,
      year: this.year,
      confirmCorrectInfo: this.confirmCorrectInfo,
      response: this.response.map(item => item.aJSON())
    };
  }

  public clon<T extends SurveyResponseDTO>(clonBase: T): T;
  public clon<T extends SurveyResponseDTO>(): SurveyResponseDTO;
  public clon<T extends SurveyResponseDTO>(clonBase: Nilable<T> = null): T | SurveyResponseDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.startup = this.startup.clon();
      clonBase.company = this.company.clon();
      clonBase.month = this.month;
      clonBase.year = this.year;
      clonBase.confirmCorrectInfo = this.confirmCorrectInfo;
      clonBase.response = this.response.map(item => item.clon());
      return clonBase;
    } else {
      const clon = new SurveyResponseDTO();
      clon.startup = this.startup.clon();
      clon.company = this.company.clon();
      clon.month = this.month;
      clon.year = this.year;
      clon.confirmCorrectInfo = this.confirmCorrectInfo;
      clon.response = this.response.map(item => item.clon());
      return clon;
    }
  }
}

export class SurveyResponseModelDTO extends ModelDTO<SurveyResponseDTO> {
  constructor(entidad: SurveyResponseDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Startup(): StartupCompanyDTO {
    return this._entidad.startup;
  }

  public set Startup(valor: StartupCompanyDTO) {
    this.cambiarPropiedadYNotificar('startup', valor);
  }

  public get Company(): CompanyDTO {
    return this._entidad.company;
  }

  public set Company(valor: CompanyDTO) {
    this.cambiarPropiedadYNotificar('company', valor);
  }

  public get Month(): number {
    return this._entidad.month;
  }

  public set Month(valor: number) {
    this.cambiarPropiedadYNotificar('month', valor);
  }

  public get Year(): number {
    return this._entidad.year;
  }

  public set Year(valor: number) {
    this.cambiarPropiedadYNotificar('year', valor);
  }

  public get ConfirmCorrectInfo(): boolean {
    return this._entidad.confirmCorrectInfo;
  }

  public set ConfirmCorrectInfo(valor: boolean) {
    this.cambiarPropiedadYNotificar('confirmCorrectInfo', valor);
  }

  public get Response(): ResponseBasicDTO[] {
    return this._entidad.response;
  }

  public set Response(valor: ResponseBasicDTO[]) {
    this.cambiarPropiedadYNotificar('response', valor);
  }
}
