import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { StartupDateMissingAssessmentDTO, StartupMissingAssessmentDTO } from '@DTOS';
import { MatTableDataSource } from '@angular/material/table';
import moment, { Moment } from 'moment';

@Component({
  selector: 'app-assessment-missing',
  templateUrl: './assessment-missing.component.html',
  styleUrls: ['./assessment-missing.component.scss']
})
export class AssessmentMissingComponent implements OnChanges {
  @Input() public missingAssessment: StartupMissingAssessmentDTO = new StartupMissingAssessmentDTO();
  @Output() public missingDateSelected = new EventEmitter<Moment>();

  public displayedColumns = ['date', ' '];
  public dataSource: MatTableDataSource<StartupDateMissingAssessmentDTO> = new MatTableDataSource([]);

  ngOnChanges(changes: SimpleChanges) {
    if (changes.missingAssessment != null) {
      this.dataSource.data = this.missingAssessment.missingAssessments;
    }
  }

  public selectDate(dateMissing: StartupDateMissingAssessmentDTO) {
    this.missingDateSelected.emit(moment(dateMissing.date));
  }
}
