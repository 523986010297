<h2 mat-dialog-title class="bg-primary-light !mb-0 text-neutral-600">Startup profile</h2>
<mat-dialog-content class="bg-primary-light">
  @if (loading()) {
    <div class="flex grow items-center justify-center">
      <mat-spinner></mat-spinner>
    </div>
  } @else {
    <app-summary-profile [startup]="startup()" [scoring]="scoring()"></app-summary-profile>
  }
</mat-dialog-content>
<mat-dialog-actions align="end" class="bg-primary-light">
  <button mat-stroked-button color="primary" mat-dialog-close>Close</button>
</mat-dialog-actions>
