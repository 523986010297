<div mat-dialog-title>
  <div class="flex justify-between items-center">
    <h1 class="mr-5 h-fit">Files from {{ startup.name }}</h1>
    <button mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<div mat-dialog-content>
  <app-empty-screen-info
    *ngIf="startup.files.length === 0"
    textInfo="There are no files yet"
    [showCallback]="false"
    [size]="8">
  </app-empty-screen-info>
  <ng-container *ngIf="startup.files.length !== 0">
    <mat-list>
      @for (file of startup.files; track file.id) {
        <mat-list-item class="flex justify-end without-padding" [class.border-b]="!$last">
          <mat-icon matListIcon>description</mat-icon>
          <div mat-line>
            <div class="flex justify-between items-center">
              <span class="mr-5">{{ file.nombreOriginal }}</span>
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item [command]="downloadCmd" [commandArg]="file">
                  <mat-icon>download</mat-icon>
                  <span>Download</span>
                </button>
                <button mat-menu-item [command]="deleteCmd" [commandArg]="file">
                  <mat-icon>delete</mat-icon>
                  <span>Delete</span>
                </button>
              </mat-menu>
            </div>
          </div>
        </mat-list-item>
      }
    </mat-list>
  </ng-container>
</div>
<div mat-dialog-actions align="end">
  <mat-spinner class="mr-2" [diameter]="30" *ngIf="uploadingFile"></mat-spinner>
  <input type="file" name="upload-file" (change)="uploadFileCmd.ejecutar($event)" #uploadFile class="hidden" />
  <button
    mat-raised-button
    color="primary"
    cdkFocusInitial
    (click)="uploadFile.click()"
    [disabled]="!uploadFileCmd.puedeEjecutarse || isDemo"
    [matTooltip]="isDemo ? 'Disabled on demo' : null">
    Upload
  </button>
</div>
