import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';

export interface ICrunchbaseRequestDTO {
  url: string;
}

export class CrunchbaseRequestDTO implements IDTO<CrunchbaseRequestDTO, ICrunchbaseRequestDTO> {
  public url: string = '';

  constructor(json?: Partial<ICrunchbaseRequestDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<ICrunchbaseRequestDTO>) {
    if (json.url != null) {
      this.url = json.url;
    }
  }

  public aJSON(): ICrunchbaseRequestDTO {
    return {
      url: this.url
    };
  }

  public clon<T extends CrunchbaseRequestDTO>(clonBase: T): T;
  public clon<T extends CrunchbaseRequestDTO>(): CrunchbaseRequestDTO;
  public clon<T extends CrunchbaseRequestDTO>(clonBase: Nilable<T> = null): T | CrunchbaseRequestDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.url = this.url;
      return clonBase;
    } else {
      const clon = new CrunchbaseRequestDTO();
      clon.url = this.url;
      return clon;
    }
  }
}

export class CrunchbaseRequestModelDTO extends ModelDTO<CrunchbaseRequestDTO> {
  constructor(entidad: CrunchbaseRequestDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Url(): string {
    return this._entidad.url;
  }

  public set Url(valor: string) {
    this.cambiarPropiedadYNotificar('url', valor);
  }
}
