import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommandAsync, ICommand } from '@nahuelmorata/ngx-command';
import { firstValueFrom } from 'rxjs';
import { CroppedImageData } from '../photo-editor-dialog/interfaces';
import { PhotoEditorDialogComponent } from '../photo-editor-dialog/photo-editor-dialog.component';

@Component({
  selector: 'app-logo-show-upload',
  templateUrl: './logo-show-upload.component.html',
  styleUrls: ['./logo-show-upload.component.scss']
})
export class LogoShowUploadComponent {
  @Input() protected image: string | null;
  @Input() public disabled = false;
  @Output() protected uploadImage = new EventEmitter<FormData>();

  protected isShowUploadIcon = false;
  protected imageTemp: string | null;
  protected linkImage = '';

  protected sendImageCmd: ICommand = new CommandAsync((event: any) => this.sendImage(event));

  constructor(private dialog: MatDialog) {}

  protected showUploadIcon() {
    this.isShowUploadIcon = true;
  }

  protected hideUploadIcon() {
    this.isShowUploadIcon = false;
  }

  private async sendImage(event: any) {
    const dialogRef = this.dialog.open<PhotoEditorDialogComponent, any, CroppedImageData>(PhotoEditorDialogComponent, {
      data: event
    });
    const croppedImageData = await firstValueFrom(dialogRef.afterClosed());
    if (croppedImageData == null) {
      return;
    }
    const data = new FormData();
    data.set('image', croppedImageData.image, croppedImageData.filename);
    this.uploadImage.emit(data);
  }

  /* private async uploadImage(event: any) {
    const dialogRef = this.dialog.open<PhotoEditorDialogComponent, any, CroppedImageData>(
      PhotoEditorDialogComponent, {data: event}
    );
    const croppedImageData = await firstValueFrom(dialogRef.afterClosed());
    if (croppedImageData == null) {
      return;
    }
    const data = new FormData();
    data.set('image', croppedImageData.image, croppedImageData.filename);
    if (this.companyModel.isNewEntity()) {
      this.formDataImagen = data;
      const reader = new FileReader();
      reader.onload = () => {
        this.imageTemp = reader.result as string;
      };
      reader.readAsDataURL(croppedImageData.image);
    } else {
      await this.sendImage(this.companyModel.getEntityDTO(), data);
    }
  }

  private async sendImage(company: CompanyBasicDTO, data: FormData) {
    const startupCompanyDTO = await firstValueFrom(
      this.filesService.uploadImageCompany(company, data)
    );

    this.companyModel.Image = startupCompanyDTO.image;
    this.linkImage = `${Constants.imageURL}/${startupCompanyDTO.image}`;
  } */
}
