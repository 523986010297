import { NgModule, Type } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { AddFilterModalComponent } from './filter-modal/filter-modal.component';
import { AssessmentComponent } from './assessment/assessment.component';
import { TopicInterviewComponent } from './assessment/topic-interview/topic-interview.component';
import { QuestionComponent } from './assessment/topic-interview/question/question.component';
import { CohortViewComponent } from './cohort-view/cohort-view.component';
import { CohortBoxComponent } from './cohort-view/cohort-box/cohort-box.component';
import { FilterStartupComponent } from './filter-startup/filter-startup.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AssessmentMissingComponent } from './assessment-missing/assessment-missing.component';
import { EmptyScreenInfoComponent } from './empty-screen-info/empty-screen-info.component';
import { NotesModalComponent } from './notes-modal/notes-modal.component';
import { NoteComponent } from './note/note.component';
import { UsersSharedNoteComponent } from './users-shared-note/users-shared-note.component';
import { NgxCommandModule } from '@nahuelmorata/ngx-command';
import { ModalsComponent } from './modals/modals/modals.component';
import { ListComponent } from './list/list.component';
import { ListItemComponent } from './list/list-item/list-item.component';
import { FounderModalComponent } from './founder-modal/founder-modal.component';
import { UrlModalComponent } from './url-modal/url-modal.component';
import { MatTableModule } from '@angular/material/table';
import { MatListModule } from '@angular/material/list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { CardAnalyticComponent } from './card-analytic/card-analytic.component';
import { MatMenuModule } from '@angular/material/menu';
import { A11yModule } from '@angular/cdk/a11y';
import { PhotoEditorDialogComponent } from './photo-editor-dialog/photo-editor-dialog.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { RowFormComponent } from './row-form/row-form.component';
import { FirstFieldDirective } from './row-form/directives/first-field/first-field.directive';
import { SecondFieldDirective } from './row-form/directives/second-field/second-field.directive';
import { ModalVideoComponent } from './modal-video/modal-video.component';
import { MentorshipModalComponent } from './mentorship-modal/mentorship-modal.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FilesDialogComponent } from './files-dialog/files-dialog.component';
import { TableVariablesComponent } from './table-variables/table-variables.component';
import { StartupProfileComponent } from './startup-profile/startup-profile.component';
import { ProfileNormalComponent } from './startup-profile/profile-normal/profile-normal.component';
import { BusinessInformationComponent } from './startup-profile/components/business-information/business-information.component';
import { FinantialsComponent } from './startup-profile/components/finantials/finantials.component';
import { GeneralInformationComponent } from './startup-profile/components/general-information/general-information.component';
import { PerformanceInformationComponent } from './startup-profile/components/performance-information/performance-information.component';
import { MatChipsModule } from '@angular/material/chips';
import { StartupProfileModalComponent } from './startup-profile-modal/startup-profile-modal.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ProfileMobileComponent } from './startup-profile/profile-mobile/profile-mobile.component';
import { MatBadgeModule } from '@angular/material/badge';
import { LogoShowUploadComponent } from './logo-show-upload/logo-show-upload.component';
import { GenerateRuleVCComponent } from './generate-rule-vc/generate-rule-vc.component';
import { FilterAnswersPipe } from './generate-rule-vc/pipes/filter-answers/filter-answers.pipe';
import { AlertModalComponent } from './alert-modal/alert-modal.component';
import { FirstRowTemplateDirective } from './card-analytic/directives/first-row-template/first-row-template.directive';
import { SecondRowTemplateDirective } from './card-analytic/directives/second-row-template/second-row-template.directive';
import { CardTitleTemplateDirective } from './card-with-title/directives/card-title-template/card-title-template.directive';
import { CardWithTitleComponent } from './card-with-title/card-with-title.component';
import { ActionsTemplateDirective } from './cohort-view/directives/actions-template/actions-template.directive';
import { CustomCohortViewDirective } from './cohort-view/directives/custom-cohort-view/custom-cohort-view.directive';
import { SummaryStartupComponent } from './summary-startup/summary-startup.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ProgressCircleComponent } from './card-analytic-contents/progress-circle/progress-circle.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ScoringInformationComponent } from './startup-profile/components/scoring-information/scoring-information.component';
import { ScoresMonthInformationComponent } from './startup-profile/components/scores-month-information/scores-month-information.component';
import { FoundersTopicsInformationComponent } from './startup-profile/components/founders-topics-information/founders-topics-information.component';
import { BusinessFinantialsInformationComponent } from './startup-profile/components/business-finantials-information/business-finantials-information.component';
import { CustomTemplateDirective } from './card-analytic/directives/custom-template/custom-template.directive';

const componentsExport: Type<any>[] = [
  AddFilterModalComponent,
  AssessmentComponent,
  CohortViewComponent,
  FilterStartupComponent,
  AssessmentMissingComponent,
  EmptyScreenInfoComponent,
  ListComponent,
  ListItemComponent,
  CardAnalyticComponent,
  RowFormComponent,
  TableVariablesComponent,
  StartupProfileComponent,
  StartupProfileModalComponent,
  LogoShowUploadComponent,
  GenerateRuleVCComponent,
  CardWithTitleComponent,
  SummaryStartupComponent
];

const components: Type<any>[] = [
  TopicInterviewComponent,
  QuestionComponent,
  CohortBoxComponent,
  NotesModalComponent,
  NoteComponent,
  UsersSharedNoteComponent,
  ModalsComponent,
  FounderModalComponent,
  UrlModalComponent,
  PhotoEditorDialogComponent,
  ModalVideoComponent,
  MentorshipModalComponent,
  FilesDialogComponent,
  ProfileNormalComponent,
  BusinessInformationComponent,
  FinantialsComponent,
  GeneralInformationComponent,
  PerformanceInformationComponent,
  ProfileMobileComponent,
  AlertModalComponent,
  ...componentsExport
];

const directives: Type<any>[] = [
  FirstFieldDirective,
  SecondFieldDirective,
  FirstRowTemplateDirective,
  SecondRowTemplateDirective,
  CardTitleTemplateDirective,
  ActionsTemplateDirective,
  CustomCohortViewDirective,
  CustomTemplateDirective
];

const pipes: Type<any>[] = [FilterAnswersPipe];

const materialModules = [
  MatTableModule,
  MatListModule,
  MatFormFieldModule,
  MatSelectModule,
  MatCardModule,
  MatTooltipModule,
  MatIconModule,
  MatDialogModule,
  MatButtonModule,
  MatInputModule,
  MatMenuModule,
  MatCheckboxModule,
  MatChipsModule,
  MatPaginatorModule,
  MatBadgeModule,
  MatDatepickerModule,
  MatSelectModule,
  MatListModule,
  MatProgressSpinnerModule
];

@NgModule({
  declarations: [
    ...components,
    ...directives,
    ...pipes,
    ScoringInformationComponent,
    ScoresMonthInformationComponent,
    FoundersTopicsInformationComponent,
    BusinessFinantialsInformationComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    NgxCommandModule,
    ...materialModules,
    A11yModule,
    ImageCropperModule,
    ClipboardModule,
    ProgressCircleComponent,
    NgApexchartsModule
  ],
  exports: [...directives, ...componentsExport]
})
export class AppSharedModule {}
