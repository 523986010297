import { Component, Input, input } from '@angular/core';
import { Router } from '@angular/router';
import { CommandAsync, ICommand } from '@nahuelmorata/ngx-command';
import { StartupsService } from 'src/app/app-shared/services/startups.service';
import { DashboardAnalyticsDTO, StartupCompanyDTO } from '@DTOS';

@Component({
  selector: 'app-founders-topics-information',
  templateUrl: './founders-topics-information.component.html',
  styleUrls: ['./founders-topics-information.component.scss']
})
export class FoundersTopicsInformationComponent {
  @Input() public startupSelected = new StartupCompanyDTO();
  @Input() public dashboardAnalytics = new DashboardAnalyticsDTO();
  public isMobile = input<boolean>(false);

  public goToEditStartupCmd: ICommand = new CommandAsync(() => this.goToEditStartup());

  constructor(
    private startupService: StartupsService,
    private router: Router
  ) {}

  private async goToEditStartup() {
    this.startupService.StartupToEdit = this.startupSelected;
    await this.router.navigate(['/startups/add-edit']);
  }
}
