import { StartupCompanyBasicDTO } from '@DTOS';
import { DecimalPipe } from '@angular/common';
import { Component, input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxExtrasModule } from '@nahuelmorata/ngx-extras';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
  selector: 'app-summary-profile',
  standalone: true,
  imports: [MatTooltipModule, DecimalPipe, NgxExtrasModule, SharedModule],
  templateUrl: './summary-profile.component.html',
  styleUrl: './summary-profile.component.scss'
})
export class SummaryProfileComponent {
  public scoring = input.required<number>();
  public startup = input.required<StartupCompanyBasicDTO>();

  public formatNumber(value: number) {
    if (value > 1000000) {
      return `${value / 1000000}M`;
    }
    if (value > 1000) {
      return `${value / 1000}K`;
    }
    return value.toString();
  }
}
