import { Directive, AfterViewInit, Input, ElementRef } from '@angular/core';
import { Subscription, timer } from 'rxjs';

@Directive({
  selector: '[appAutofocus]'
})
export class AutofocusDirective implements AfterViewInit {
  @Input() appAutofocus: boolean;

  private subscription: Subscription;

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    this.subscription = timer(100).subscribe(() => {
      if (this.appAutofocus) {
        this.setFocus();
      }
      this.subscription.unsubscribe();
    });
  }

  private setFocus() {
    if (this.el) {
      if (
        this.el instanceof HTMLInputElement ||
        this.el instanceof HTMLButtonElement ||
        this.el instanceof HTMLSelectElement ||
        this.el instanceof HTMLSelectElement
      ) {
        if (!this.el.disabled) {
          this.el.focus();
        }
      } else {
        if (
          (this.el && this.el.nativeElement instanceof HTMLInputElement) ||
          this.el.nativeElement instanceof HTMLButtonElement ||
          this.el.nativeElement instanceof HTMLSelectElement
        ) {
          if (!this.el.nativeElement.disabled) {
            this.el.nativeElement.focus();
          }
        }
      }
    }
  }
}
