import { Pipe, PipeTransform } from '@angular/core';
import { StartupRuleTypeEnum } from '@DTOS';

@Pipe({
  name: 'startupRuleType'
})
export class StartupRuleTypePipe implements PipeTransform {
  transform(companyRuleType: StartupRuleTypeEnum): string {
    switch (companyRuleType) {
      case StartupRuleTypeEnum.Profile:
        return 'Company profile';
      case StartupRuleTypeEnum.Scoring:
        return 'Scoring';
    }
  }
}
