import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';
import { IStartupCompanyBasicDTO, StartupCompanyBasicDTO } from './StartupCompanyBasic.dto';

export interface ICompanyAutoRejectDTO {
  startup: IStartupCompanyBasicDTO;
  rejectAt: string;
  emailSent: boolean;
}

export class CompanyAutoRejectDTO implements IDTO<CompanyAutoRejectDTO, ICompanyAutoRejectDTO> {
  public startup: StartupCompanyBasicDTO = new StartupCompanyBasicDTO();
  public rejectAt: Date = new Date();
  public emailSent: boolean = true;

  constructor(json?: Partial<ICompanyAutoRejectDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<ICompanyAutoRejectDTO>) {
    if (json.startup != null) {
      this.startup = new StartupCompanyBasicDTO(json.startup);
    }
    if (json.rejectAt != null) {
      this.rejectAt = new Date(json.rejectAt);
    }
    if (json.emailSent != null) {
      this.emailSent = json.emailSent;
    }
  }

  public aJSON(): ICompanyAutoRejectDTO {
    return {
      startup: this.startup.aJSON(),
      rejectAt: this.rejectAt.toJSON(),
      emailSent: this.emailSent
    };
  }

  public clon<T extends CompanyAutoRejectDTO>(clonBase: T): T;
  public clon<T extends CompanyAutoRejectDTO>(): CompanyAutoRejectDTO;
  public clon<T extends CompanyAutoRejectDTO>(clonBase: Nilable<T> = null): T | CompanyAutoRejectDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.startup = this.startup.clon();
      clonBase.rejectAt = new Date(this.rejectAt.toJSON());
      clonBase.emailSent = this.emailSent;
      return clonBase;
    } else {
      const clon = new CompanyAutoRejectDTO();
      clon.startup = this.startup.clon();
      clon.rejectAt = new Date(this.rejectAt.toJSON());
      clon.emailSent = this.emailSent;
      return clon;
    }
  }
}

export class CompanyAutoRejectModelDTO extends ModelDTO<CompanyAutoRejectDTO> {
  constructor(entidad: CompanyAutoRejectDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Startup(): StartupCompanyBasicDTO {
    return this._entidad.startup;
  }

  public set Startup(valor: StartupCompanyBasicDTO) {
    this.cambiarPropiedadYNotificar('startup', valor);
  }

  public get RejectAt(): Date {
    return this._entidad.rejectAt;
  }

  public set RejectAt(valor: Date) {
    this.cambiarPropiedadYNotificar('rejectAt', valor);
  }

  public get EmailSent(): boolean {
    return this._entidad.emailSent;
  }

  public set EmailSent(valor: boolean) {
    this.cambiarPropiedadYNotificar('emailSent', valor);
  }
}
