import { FoundingTypeEnum } from '@DTOS';

export const formatFoundingType: (type: FoundingTypeEnum) => string = type => {
  switch (type) {
    case FoundingTypeEnum.None:
      return 'None';
    case FoundingTypeEnum.PreSeed:
      return 'Pre-Seed';
    case FoundingTypeEnum.Seed:
      return 'Seed';
    case FoundingTypeEnum.SeriesA:
      return 'Series A';
    case FoundingTypeEnum.SeriesB:
      return 'Series B';
    case FoundingTypeEnum.SeriesC:
      return 'Series C';
    case FoundingTypeEnum.Angel:
      return 'Angel';
    case FoundingTypeEnum.ConvertibleNote:
      return 'Convertible Note';
    case FoundingTypeEnum.CorporateRound:
      return 'Corporate Round';
    case FoundingTypeEnum.DebtFinancing:
      return 'Debt Financing';
    case FoundingTypeEnum.EquityCrowdfunding:
      return 'Equity Crowdfunding';
    case FoundingTypeEnum.Grant:
      return 'Grant';
    case FoundingTypeEnum.InitialCoinOffering:
      return 'Initial Coin Offering';
    case FoundingTypeEnum.NonEquityAssistance:
      return 'Non-equity Assistance';
    case FoundingTypeEnum.PostIpoDebt:
      return 'Post-IPO Debt';
    case FoundingTypeEnum.PostIpoEquity:
      return 'Post-IPO Equity';
    case FoundingTypeEnum.PostIpoSecondary:
      return 'Post-IPO Secondary';
    case FoundingTypeEnum.PrivateEquity:
      return 'Private Equity';
    case FoundingTypeEnum.ProductCrowdfunding:
      return 'Product Crowdfunding';
    case FoundingTypeEnum.SecondaryMarket:
      return 'Secondary Market';
    case FoundingTypeEnum.SeriesD:
      return 'Series D';
    case FoundingTypeEnum.SeriesE:
      return 'Series E';
    case FoundingTypeEnum.SeriesF:
      return 'Series F';
    case FoundingTypeEnum.SeriesG:
      return 'Series G';
    case FoundingTypeEnum.SeriesH:
      return 'Series H';
    case FoundingTypeEnum.SeriesI:
      return 'Series I';
    case FoundingTypeEnum.SeriesJ:
      return 'Series J';
    case FoundingTypeEnum.SeriesUnknown:
      return 'Venture - Series Unknown';
    case FoundingTypeEnum.Undisclosed:
      return 'Undisclosed';
  }
};
