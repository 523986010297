import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';
import { ICompanyBasicDTO, CompanyBasicDTO } from './CompanyBasic.dto';
import { IStartupCompanyBasicDTO, StartupCompanyBasicDTO } from './StartupCompanyBasic.dto';
import { ConnectionTopicEnum } from './ConnectionTopic.enum';
import { ShareTimeTypeEnum } from './ShareTimeType.enum';
import { StartupTypeEnum } from './StartupType.enum';

export interface IConnectionDTO {
  id: number;
  cacheStamp: number;
  email: string;
  company: ICompanyBasicDTO;
  startup: IStartupCompanyBasicDTO;
  connectionTopic: number;
  companyAccept: boolean;
  startupAccept: boolean;
  fromDate: string;
  toDate: string;
  shareTimeType: number;
  shareTime: string;
  startupType: number;
  isArchived: boolean;
  createdAt: string;
  saveInformation: boolean;
}

export class ConnectionDTO implements IDTO<ConnectionDTO, IConnectionDTO> {
  public id: number = 0;
  public cacheStamp: number = 0;
  public email: string = '';
  public company: CompanyBasicDTO = new CompanyBasicDTO();
  public startup: StartupCompanyBasicDTO = new StartupCompanyBasicDTO();
  public connectionTopic: ConnectionTopicEnum = ConnectionTopicEnum.AllWithPerformance;
  public companyAccept: boolean = true;
  public startupAccept: boolean = true;
  public fromDate: Date = new Date();
  public toDate: Date = new Date();
  public shareTimeType: ShareTimeTypeEnum = ShareTimeTypeEnum.OneDay;
  public shareTime: Date = new Date();
  public startupType: StartupTypeEnum = StartupTypeEnum.Lead;
  public isArchived: boolean = true;
  public createdAt: Date = new Date();
  public saveInformation: boolean = true;

  constructor(json?: Partial<IConnectionDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<IConnectionDTO>) {
    if (json.id != null) {
      this.id = json.id;
    }
    if (json.cacheStamp != null) {
      this.cacheStamp = json.cacheStamp;
    }
    if (json.email != null) {
      this.email = json.email;
    }
    if (json.company != null) {
      this.company = new CompanyBasicDTO(json.company);
    }
    if (json.startup != null) {
      this.startup = new StartupCompanyBasicDTO(json.startup);
    }
    if (json.connectionTopic != null) {
      this.connectionTopic = json.connectionTopic as ConnectionTopicEnum;
    }
    if (json.companyAccept != null) {
      this.companyAccept = json.companyAccept;
    }
    if (json.startupAccept != null) {
      this.startupAccept = json.startupAccept;
    }
    if (json.fromDate != null) {
      this.fromDate = new Date(json.fromDate);
    }
    if (json.toDate != null) {
      this.toDate = new Date(json.toDate);
    }
    if (json.shareTimeType != null) {
      this.shareTimeType = json.shareTimeType as ShareTimeTypeEnum;
    }
    if (json.shareTime != null) {
      this.shareTime = new Date(json.shareTime);
    }
    if (json.startupType != null) {
      this.startupType = json.startupType as StartupTypeEnum;
    }
    if (json.isArchived != null) {
      this.isArchived = json.isArchived;
    }
    if (json.createdAt != null) {
      this.createdAt = new Date(json.createdAt);
    }
    if (json.saveInformation != null) {
      this.saveInformation = json.saveInformation;
    }
  }

  public aJSON(): IConnectionDTO {
    return {
      id: this.id,
      cacheStamp: this.cacheStamp,
      email: this.email,
      company: this.company.aJSON(),
      startup: this.startup.aJSON(),
      connectionTopic: this.connectionTopic,
      companyAccept: this.companyAccept,
      startupAccept: this.startupAccept,
      fromDate: this.fromDate.toJSON(),
      toDate: this.toDate.toJSON(),
      shareTimeType: this.shareTimeType,
      shareTime: this.shareTime.toJSON(),
      startupType: this.startupType,
      isArchived: this.isArchived,
      createdAt: this.createdAt.toJSON(),
      saveInformation: this.saveInformation
    };
  }

  public clon<T extends ConnectionDTO>(clonBase: T): T;
  public clon<T extends ConnectionDTO>(): ConnectionDTO;
  public clon<T extends ConnectionDTO>(clonBase: Nilable<T> = null): T | ConnectionDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.id = this.id;
      clonBase.cacheStamp = this.cacheStamp;
      clonBase.email = this.email;
      clonBase.company = this.company.clon();
      clonBase.startup = this.startup.clon();
      clonBase.connectionTopic = this.connectionTopic;
      clonBase.companyAccept = this.companyAccept;
      clonBase.startupAccept = this.startupAccept;
      clonBase.fromDate = new Date(this.fromDate.toJSON());
      clonBase.toDate = new Date(this.toDate.toJSON());
      clonBase.shareTimeType = this.shareTimeType;
      clonBase.shareTime = new Date(this.shareTime.toJSON());
      clonBase.startupType = this.startupType;
      clonBase.isArchived = this.isArchived;
      clonBase.createdAt = new Date(this.createdAt.toJSON());
      clonBase.saveInformation = this.saveInformation;
      return clonBase;
    } else {
      const clon = new ConnectionDTO();
      clon.id = this.id;
      clon.cacheStamp = this.cacheStamp;
      clon.email = this.email;
      clon.company = this.company.clon();
      clon.startup = this.startup.clon();
      clon.connectionTopic = this.connectionTopic;
      clon.companyAccept = this.companyAccept;
      clon.startupAccept = this.startupAccept;
      clon.fromDate = new Date(this.fromDate.toJSON());
      clon.toDate = new Date(this.toDate.toJSON());
      clon.shareTimeType = this.shareTimeType;
      clon.shareTime = new Date(this.shareTime.toJSON());
      clon.startupType = this.startupType;
      clon.isArchived = this.isArchived;
      clon.createdAt = new Date(this.createdAt.toJSON());
      clon.saveInformation = this.saveInformation;
      return clon;
    }
  }
}

export class ConnectionModelDTO extends ModelDTO<ConnectionDTO> {
  constructor(entidad: ConnectionDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Id(): number {
    return this._entidad.id;
  }

  public set Id(valor: number) {
    this.cambiarPropiedadYNotificar('id', valor);
  }

  public get CacheStamp(): number {
    return this._entidad.cacheStamp;
  }

  public set CacheStamp(valor: number) {
    this.cambiarPropiedadYNotificar('cacheStamp', valor);
  }

  public get Email(): string {
    return this._entidad.email;
  }

  public set Email(valor: string) {
    this.cambiarPropiedadYNotificar('email', valor);
  }

  public get Company(): CompanyBasicDTO {
    return this._entidad.company;
  }

  public set Company(valor: CompanyBasicDTO) {
    this.cambiarPropiedadYNotificar('company', valor);
  }

  public get Startup(): StartupCompanyBasicDTO {
    return this._entidad.startup;
  }

  public set Startup(valor: StartupCompanyBasicDTO) {
    this.cambiarPropiedadYNotificar('startup', valor);
  }

  public get ConnectionTopic(): ConnectionTopicEnum {
    return this._entidad.connectionTopic;
  }

  public set ConnectionTopic(valor: ConnectionTopicEnum) {
    this.cambiarPropiedadYNotificar('connectionTopic', valor);
  }

  public get CompanyAccept(): boolean {
    return this._entidad.companyAccept;
  }

  public set CompanyAccept(valor: boolean) {
    this.cambiarPropiedadYNotificar('companyAccept', valor);
  }

  public get StartupAccept(): boolean {
    return this._entidad.startupAccept;
  }

  public set StartupAccept(valor: boolean) {
    this.cambiarPropiedadYNotificar('startupAccept', valor);
  }

  public get FromDate(): Date {
    return this._entidad.fromDate;
  }

  public set FromDate(valor: Date) {
    this.cambiarPropiedadYNotificar('fromDate', valor);
  }

  public get ToDate(): Date {
    return this._entidad.toDate;
  }

  public set ToDate(valor: Date) {
    this.cambiarPropiedadYNotificar('toDate', valor);
  }

  public get ShareTimeType(): ShareTimeTypeEnum {
    return this._entidad.shareTimeType;
  }

  public set ShareTimeType(valor: ShareTimeTypeEnum) {
    this.cambiarPropiedadYNotificar('shareTimeType', valor);
  }

  public get ShareTime(): Date {
    return this._entidad.shareTime;
  }

  public set ShareTime(valor: Date) {
    this.cambiarPropiedadYNotificar('shareTime', valor);
  }

  public get StartupType(): StartupTypeEnum {
    return this._entidad.startupType;
  }

  public set StartupType(valor: StartupTypeEnum) {
    this.cambiarPropiedadYNotificar('startupType', valor);
  }

  public get IsArchived(): boolean {
    return this._entidad.isArchived;
  }

  public set IsArchived(valor: boolean) {
    this.cambiarPropiedadYNotificar('isArchived', valor);
  }

  public get CreatedAt(): Date {
    return this._entidad.createdAt;
  }

  public set CreatedAt(valor: Date) {
    this.cambiarPropiedadYNotificar('createdAt', valor);
  }

  public get SaveInformation(): boolean {
    return this._entidad.saveInformation;
  }

  public set SaveInformation(valor: boolean) {
    this.cambiarPropiedadYNotificar('saveInformation', valor);
  }
}
