<div class="flex grow pt-2 pr-1">
  <div class="shadow-box bg-white px-6 rounded-xl flex h-full grow">
    <apx-chart
      class="grow"
      [series]="scores"
      [chart]="chartOptions.chart"
      [yaxis]="chartOptions.yaxis"
      [xaxis]="{ categories: labelsMonth }"
      [plotOptions]="chartOptions.plotOptions"
      [legend]="chartOptions.legend"
      [grid]="chartOptions.grid"
      [stroke]="chartOptions.stroke"
      [dataLabels]="chartOptions.dataLabels"
      [tooltip]="chartOptions.tooltip"
      [colors]="chartOptions.colors"></apx-chart>
  </div>
</div>
