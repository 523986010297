import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';

export interface ICustomPlanSelectedDTO {
  variablesShow: number[];
}

export class CustomPlanSelectedDTO implements IDTO<CustomPlanSelectedDTO, ICustomPlanSelectedDTO> {
  public variablesShow: number[] = [];

  constructor(json?: Partial<ICustomPlanSelectedDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<ICustomPlanSelectedDTO>) {
    if (json.variablesShow != null) {
      this.variablesShow = json.variablesShow;
    }
  }

  public aJSON(): ICustomPlanSelectedDTO {
    return {
      variablesShow: this.variablesShow
    };
  }

  public clon<T extends CustomPlanSelectedDTO>(clonBase: T): T;
  public clon<T extends CustomPlanSelectedDTO>(): CustomPlanSelectedDTO;
  public clon<T extends CustomPlanSelectedDTO>(clonBase: Nilable<T> = null): T | CustomPlanSelectedDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.variablesShow = this.variablesShow;
      return clonBase;
    } else {
      const clon = new CustomPlanSelectedDTO();
      clon.variablesShow = this.variablesShow;
      return clon;
    }
  }
}

export class CustomPlanSelectedModelDTO extends ModelDTO<CustomPlanSelectedDTO> {
  constructor(entidad: CustomPlanSelectedDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get VariablesShow(): number[] {
    return this._entidad.variablesShow;
  }

  public set VariablesShow(valor: number[]) {
    this.cambiarPropiedadYNotificar('variablesShow', valor);
  }
}
