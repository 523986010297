export enum VariableStartupProfileEnum {
  Investment,
  TotalFoundingAmount,
  FoundersQuantity,
  YearsInTheMarket,
  LastFoundingType,
  ProfitType,
  CurrentFounding,
  QuantityEmployees,
  PercentageLoansRevenue,
  PercentageCompanyHandsFounders,
  Headquarters
}
