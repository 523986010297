import { Injectable, OnDestroy } from '@angular/core';
import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import { ICommand } from '@nahuelmorata/ngx-command';
import { Subject } from 'rxjs';
import { ListItemComponent } from '../../list/list-item/list-item.component';
import { Labeled } from 'src/app/shared/interfaces/Labeled';

@Injectable({
  providedIn: 'root'
})
export class ListService implements OnDestroy {
  private _preSelectIndex: number = null;

  private _itemsLength = 0;

  private _keyManager: ActiveDescendantKeyManager<ListItemComponent<Labeled>>;

  private _command: ICommand;

  private _commandOnClick: boolean;

  private _commandOnEnter: boolean;

  private _activeObservable = new Subject<number>();
  public activeObservable = this._activeObservable.asObservable();

  public clickedObservable = new Subject<ListItemComponent<Labeled>>();

  public focusedObservable = new Subject<Labeled>();

  public leavedObservable = new Subject<Labeled>();

  public htmlInputElement: any;

  public linkedWithInputElem = false;

  get preSelectIndex(): number {
    return this._preSelectIndex;
  }
  set preSelectIndex(value: number) {
    this._preSelectIndex = value;
  }

  get itemsLength(): number {
    return this._itemsLength;
  }
  set itemsLength(value: number) {
    this._itemsLength = value;
  }

  get commandOnClick(): boolean {
    return this._commandOnClick;
  }
  set commandOnClick(value: boolean) {
    this._commandOnClick = value;
  }

  get commandOnEnter(): boolean {
    return this._commandOnEnter;
  }
  set commandOnEnter(value: boolean) {
    this._commandOnEnter = value;
  }

  get keyManager(): ActiveDescendantKeyManager<ListItemComponent<Labeled>> {
    return this._keyManager;
  }
  set keyManager(value: ActiveDescendantKeyManager<ListItemComponent<Labeled>>) {
    this._keyManager = value;
  }

  get icommand(): ICommand {
    return this._command;
  }
  set icommand(value: ICommand) {
    this._command = value;
  }

  constructor() {}

  public keyListenerFunc(event: KeyboardEvent) {
    if (this._keyManager) {
      const active = this._keyManager.activeItemIndex;
      switch (event.keyCode) {
        case 13:
          if (this._commandOnEnter) {
            this.executeCommand();
          }
          break;
        case 9: // Tab
          if (this.linkedWithInputElem && this.htmlInputElement) {
            event.preventDefault();
            if (!this._keyManager.activeItem) {
              this._keyManager.setFirstItemActive();
            } else {
              this._keyManager.setActiveItem(-1);
              this.htmlInputElement.focus();
            }
          } else {
            this._keyManager.onKeydown(event);
          }
          break;
        case 33:
          event.preventDefault();
          if (this._keyManager.activeItemIndex - 10 >= 0) {
            this._keyManager.setActiveItem(active - 10);
          } else {
            this._keyManager.setFirstItemActive();
          }
          break;
        case 34:
          event.preventDefault();
          if (this._keyManager.activeItemIndex + 10 < this._itemsLength) {
            this._keyManager.setActiveItem(active + 10);
          } else {
            this._keyManager.setLastItemActive();
          }
          break;
        case 35:
          event.preventDefault();
          this._keyManager.setLastItemActive();
          break;
        case 36:
          event.preventDefault();
          this._keyManager.setFirstItemActive();
          break;
        case 38: // Arrow up
          this._keyManager.onKeydown(event);
          break;
        case 40: // Arrow down
          this._keyManager.onKeydown(event);
          break;
        default:
          if (this.linkedWithInputElem && this.htmlInputElement && this.isValidKey(event)) {
            this._keyManager.setActiveItem(-1);
            this.htmlInputElement.focus();
          }
          this._keyManager.onKeydown(event);
      }
      if (this._keyManager !== null && this._keyManager !== undefined && active !== this._keyManager.activeItemIndex) {
        this.emitSelectedIndex();
      }
    } else {
      throw Error('keyManager was not setted in appListKeydown');
    }
  }

  public emitSelectedIndex(): void {
    this._activeObservable.next(this._keyManager.activeItemIndex);
  }

  public executeCommand() {
    if (this.icommand) {
      if (this._keyManager.activeItem) {
        this.icommand.ejecutar(this._keyManager.activeItem.item);
      } else {
        console.warn('Not selected item');
      }
    }
  }

  public isValidKey(event: KeyboardEvent) {
    const invalidKeys = [17, 18, 27];
    const invalidKey = invalidKeys.find(value => value === event.keyCode);
    return invalidKey === undefined;
  }

  ngOnDestroy() {
    this._command = null;
    this._keyManager = null;
    this._preSelectIndex = null;
    this.itemsLength = 0;
  }
}
