<div class="flex flex-col">
  <div class="flex items-stretch">
    <div class="grow pr-2 flex flex-col">
      <app-general-information
        [startup]="startupSelected"
        [scoring]="dashboardAnalytics.scoreLastMonth"></app-general-information>
    </div>
    <div class="pl-2 flex flex-col">
      <app-scoring-information [dashboardAnalytics]="dashboardAnalytics"></app-scoring-information>
      <app-scores-month-information [scores]="scores()"></app-scores-month-information>
    </div>
  </div>
  <div class="flex mt-8">
    <div class="w-3/5 pr-2 pl-1">
      <app-founders-topics-information
        [startupSelected]="startupSelected"
        [dashboardAnalytics]="dashboardAnalytics"></app-founders-topics-information>
    </div>
    <div class="grow pl-2">
      <app-business-finantials-information [startupSelected]="startupSelected"></app-business-finantials-information>
    </div>
  </div>
  <div class="flex mt-8 px-1">
    <div class="shadow-box bg-primary-light p-8 rounded-xl flex flex-col h-full grow">
      <div class="flex justify-between">
        <div class="flex items-center">
          <div class="h-[40px] w-[40px] bg-primary text-white rounded-full mr-4 flex justify-center items-center">
            <i class="fa-solid fa-star fa-lg"></i>
          </div>
          <div class="flex flex-col">
            <h3 class="text-lg">Variables</h3>
          </div>
        </div>
        <div class="flex items-center text-neutral-700">
          <mat-label class="mr-3">Topic: </mat-label>
          <mat-form-field>
            <mat-select [formControl]="topicControl">
              <mat-option *ngFor="let topic of topics" [value]="topic">
                {{ topic.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <app-table-variables
        class="mt-4"
        [variables]="variablesTopic"
        (openMentorship)="showMentorship($event)"></app-table-variables>
    </div>
  </div>
</div>
