import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';

export interface ISearchCompanyDTO {
  id: number;
  name: string;
  description: string;
  image: string;
  scoring: number;
  funds: number;
  investments: number;
  leadInvestments: number;
  exits: number;
  email: string;
}

export class SearchCompanyDTO implements IDTO<SearchCompanyDTO, ISearchCompanyDTO> {
  public id: number = 0;
  public name: string = '';
  public description: string = '';
  public image: string = '';
  public scoring: number = 0;
  public funds: number = 0;
  public investments: number = 0;
  public leadInvestments: number = 0;
  public exits: number = 0;
  public email: string = '';

  constructor(json?: Partial<ISearchCompanyDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<ISearchCompanyDTO>) {
    if (json.id != null) {
      this.id = json.id;
    }
    if (json.name != null) {
      this.name = json.name;
    }
    if (json.description != null) {
      this.description = json.description;
    }
    if (json.image != null) {
      this.image = json.image;
    }
    if (json.scoring != null) {
      this.scoring = json.scoring;
    }
    if (json.funds != null) {
      this.funds = json.funds;
    }
    if (json.investments != null) {
      this.investments = json.investments;
    }
    if (json.leadInvestments != null) {
      this.leadInvestments = json.leadInvestments;
    }
    if (json.exits != null) {
      this.exits = json.exits;
    }
    if (json.email != null) {
      this.email = json.email;
    }
  }

  public aJSON(): ISearchCompanyDTO {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      image: this.image,
      scoring: this.scoring,
      funds: this.funds,
      investments: this.investments,
      leadInvestments: this.leadInvestments,
      exits: this.exits,
      email: this.email
    };
  }

  public clon<T extends SearchCompanyDTO>(clonBase: T): T;
  public clon<T extends SearchCompanyDTO>(): SearchCompanyDTO;
  public clon<T extends SearchCompanyDTO>(clonBase: Nilable<T> = null): T | SearchCompanyDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.id = this.id;
      clonBase.name = this.name;
      clonBase.description = this.description;
      clonBase.image = this.image;
      clonBase.scoring = this.scoring;
      clonBase.funds = this.funds;
      clonBase.investments = this.investments;
      clonBase.leadInvestments = this.leadInvestments;
      clonBase.exits = this.exits;
      clonBase.email = this.email;
      return clonBase;
    } else {
      const clon = new SearchCompanyDTO();
      clon.id = this.id;
      clon.name = this.name;
      clon.description = this.description;
      clon.image = this.image;
      clon.scoring = this.scoring;
      clon.funds = this.funds;
      clon.investments = this.investments;
      clon.leadInvestments = this.leadInvestments;
      clon.exits = this.exits;
      clon.email = this.email;
      return clon;
    }
  }
}

export class SearchCompanyModelDTO extends ModelDTO<SearchCompanyDTO> {
  constructor(entidad: SearchCompanyDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Id(): number {
    return this._entidad.id;
  }

  public set Id(valor: number) {
    this.cambiarPropiedadYNotificar('id', valor);
  }

  public get Name(): string {
    return this._entidad.name;
  }

  public set Name(valor: string) {
    this.cambiarPropiedadYNotificar('name', valor);
  }

  public get Description(): string {
    return this._entidad.description;
  }

  public set Description(valor: string) {
    this.cambiarPropiedadYNotificar('description', valor);
  }

  public get Image(): string {
    return this._entidad.image;
  }

  public set Image(valor: string) {
    this.cambiarPropiedadYNotificar('image', valor);
  }

  public get Scoring(): number {
    return this._entidad.scoring;
  }

  public set Scoring(valor: number) {
    this.cambiarPropiedadYNotificar('scoring', valor);
  }

  public get Funds(): number {
    return this._entidad.funds;
  }

  public set Funds(valor: number) {
    this.cambiarPropiedadYNotificar('funds', valor);
  }

  public get Investments(): number {
    return this._entidad.investments;
  }

  public set Investments(valor: number) {
    this.cambiarPropiedadYNotificar('investments', valor);
  }

  public get LeadInvestments(): number {
    return this._entidad.leadInvestments;
  }

  public set LeadInvestments(valor: number) {
    this.cambiarPropiedadYNotificar('leadInvestments', valor);
  }

  public get Exits(): number {
    return this._entidad.exits;
  }

  public set Exits(valor: number) {
    this.cambiarPropiedadYNotificar('exits', valor);
  }

  public get Email(): string {
    return this._entidad.email;
  }

  public set Email(valor: string) {
    this.cambiarPropiedadYNotificar('email', valor);
  }
}
