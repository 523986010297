import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';
import { IIndustryDTO, IndustryDTO } from './Industry.dto';
import { FoundingTypeEnum } from './FoundingType.enum';

export interface ISearchStartupDTO {
  id: number;
  name: string;
  description: string;
  image: string;
  scoring: number;
  scoringParcial: number;
  crunchbaseRank: number;
  foundersCount: number;
  industries: IIndustryDTO[];
  headQuarters: string;
  totalFoundingAmount: number;
  currentFoundingType: number;
  employees: string;
  email: string;
  fromConnection: boolean;
}

export class SearchStartupDTO implements IDTO<SearchStartupDTO, ISearchStartupDTO> {
  public id: number = 0;
  public name: string = '';
  public description: string = '';
  public image: string = '';
  public scoring: number = 0;
  public scoringParcial: number = 0;
  public crunchbaseRank: number = 0;
  public foundersCount: number = 0;
  public industries: IndustryDTO[] = [];
  public headQuarters: string = '';
  public totalFoundingAmount: number = 0;
  public currentFoundingType: FoundingTypeEnum = FoundingTypeEnum.None;
  public employees: string = '';
  public email: string = '';
  public fromConnection: boolean = true;

  constructor(json?: Partial<ISearchStartupDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<ISearchStartupDTO>) {
    if (json.id != null) {
      this.id = json.id;
    }
    if (json.name != null) {
      this.name = json.name;
    }
    if (json.description != null) {
      this.description = json.description;
    }
    if (json.image != null) {
      this.image = json.image;
    }
    if (json.scoring != null) {
      this.scoring = json.scoring;
    }
    if (json.scoringParcial != null) {
      this.scoringParcial = json.scoringParcial;
    }
    if (json.crunchbaseRank != null) {
      this.crunchbaseRank = json.crunchbaseRank;
    }
    if (json.foundersCount != null) {
      this.foundersCount = json.foundersCount;
    }
    if (json.industries != null) {
      this.industries = json.industries.map(item => new IndustryDTO(item));
    }
    if (json.headQuarters != null) {
      this.headQuarters = json.headQuarters;
    }
    if (json.totalFoundingAmount != null) {
      this.totalFoundingAmount = json.totalFoundingAmount;
    }
    if (json.currentFoundingType != null) {
      this.currentFoundingType = json.currentFoundingType as FoundingTypeEnum;
    }
    if (json.employees != null) {
      this.employees = json.employees;
    }
    if (json.email != null) {
      this.email = json.email;
    }
    if (json.fromConnection != null) {
      this.fromConnection = json.fromConnection;
    }
  }

  public aJSON(): ISearchStartupDTO {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      image: this.image,
      scoring: this.scoring,
      scoringParcial: this.scoringParcial,
      crunchbaseRank: this.crunchbaseRank,
      foundersCount: this.foundersCount,
      industries: this.industries.map(item => item.aJSON()),
      headQuarters: this.headQuarters,
      totalFoundingAmount: this.totalFoundingAmount,
      currentFoundingType: this.currentFoundingType,
      employees: this.employees,
      email: this.email,
      fromConnection: this.fromConnection
    };
  }

  public clon<T extends SearchStartupDTO>(clonBase: T): T;
  public clon<T extends SearchStartupDTO>(): SearchStartupDTO;
  public clon<T extends SearchStartupDTO>(clonBase: Nilable<T> = null): T | SearchStartupDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.id = this.id;
      clonBase.name = this.name;
      clonBase.description = this.description;
      clonBase.image = this.image;
      clonBase.scoring = this.scoring;
      clonBase.scoringParcial = this.scoringParcial;
      clonBase.crunchbaseRank = this.crunchbaseRank;
      clonBase.foundersCount = this.foundersCount;
      clonBase.industries = this.industries.map(item => item.clon());
      clonBase.headQuarters = this.headQuarters;
      clonBase.totalFoundingAmount = this.totalFoundingAmount;
      clonBase.currentFoundingType = this.currentFoundingType;
      clonBase.employees = this.employees;
      clonBase.email = this.email;
      clonBase.fromConnection = this.fromConnection;
      return clonBase;
    } else {
      const clon = new SearchStartupDTO();
      clon.id = this.id;
      clon.name = this.name;
      clon.description = this.description;
      clon.image = this.image;
      clon.scoring = this.scoring;
      clon.scoringParcial = this.scoringParcial;
      clon.crunchbaseRank = this.crunchbaseRank;
      clon.foundersCount = this.foundersCount;
      clon.industries = this.industries.map(item => item.clon());
      clon.headQuarters = this.headQuarters;
      clon.totalFoundingAmount = this.totalFoundingAmount;
      clon.currentFoundingType = this.currentFoundingType;
      clon.employees = this.employees;
      clon.email = this.email;
      clon.fromConnection = this.fromConnection;
      return clon;
    }
  }
}

export class SearchStartupModelDTO extends ModelDTO<SearchStartupDTO> {
  constructor(entidad: SearchStartupDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Id(): number {
    return this._entidad.id;
  }

  public set Id(valor: number) {
    this.cambiarPropiedadYNotificar('id', valor);
  }

  public get Name(): string {
    return this._entidad.name;
  }

  public set Name(valor: string) {
    this.cambiarPropiedadYNotificar('name', valor);
  }

  public get Description(): string {
    return this._entidad.description;
  }

  public set Description(valor: string) {
    this.cambiarPropiedadYNotificar('description', valor);
  }

  public get Image(): string {
    return this._entidad.image;
  }

  public set Image(valor: string) {
    this.cambiarPropiedadYNotificar('image', valor);
  }

  public get Scoring(): number {
    return this._entidad.scoring;
  }

  public set Scoring(valor: number) {
    this.cambiarPropiedadYNotificar('scoring', valor);
  }

  public get ScoringParcial(): number {
    return this._entidad.scoringParcial;
  }

  public set ScoringParcial(valor: number) {
    this.cambiarPropiedadYNotificar('scoringParcial', valor);
  }

  public get CrunchbaseRank(): number {
    return this._entidad.crunchbaseRank;
  }

  public set CrunchbaseRank(valor: number) {
    this.cambiarPropiedadYNotificar('crunchbaseRank', valor);
  }

  public get FoundersCount(): number {
    return this._entidad.foundersCount;
  }

  public set FoundersCount(valor: number) {
    this.cambiarPropiedadYNotificar('foundersCount', valor);
  }

  public get Industries(): IndustryDTO[] {
    return this._entidad.industries;
  }

  public set Industries(valor: IndustryDTO[]) {
    this.cambiarPropiedadYNotificar('industries', valor);
  }

  public get HeadQuarters(): string {
    return this._entidad.headQuarters;
  }

  public set HeadQuarters(valor: string) {
    this.cambiarPropiedadYNotificar('headQuarters', valor);
  }

  public get TotalFoundingAmount(): number {
    return this._entidad.totalFoundingAmount;
  }

  public set TotalFoundingAmount(valor: number) {
    this.cambiarPropiedadYNotificar('totalFoundingAmount', valor);
  }

  public get CurrentFoundingType(): FoundingTypeEnum {
    return this._entidad.currentFoundingType;
  }

  public set CurrentFoundingType(valor: FoundingTypeEnum) {
    this.cambiarPropiedadYNotificar('currentFoundingType', valor);
  }

  public get Employees(): string {
    return this._entidad.employees;
  }

  public set Employees(valor: string) {
    this.cambiarPropiedadYNotificar('employees', valor);
  }

  public get Email(): string {
    return this._entidad.email;
  }

  public set Email(valor: string) {
    this.cambiarPropiedadYNotificar('email', valor);
  }

  public get FromConnection(): boolean {
    return this._entidad.fromConnection;
  }

  public set FromConnection(valor: boolean) {
    this.cambiarPropiedadYNotificar('fromConnection', valor);
  }
}
