import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';
import { IStartupCompanyBasicDTO, StartupCompanyBasicDTO } from './StartupCompanyBasic.dto';

export interface IStartupMonthScoreDTO {
  startup: IStartupCompanyBasicDTO;
  scores: number[];
}

export class StartupMonthScoreDTO implements IDTO<StartupMonthScoreDTO, IStartupMonthScoreDTO> {
  public startup: StartupCompanyBasicDTO = new StartupCompanyBasicDTO();
  public scores: number[] = [];

  constructor(json?: Partial<IStartupMonthScoreDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<IStartupMonthScoreDTO>) {
    if (json.startup != null) {
      this.startup = new StartupCompanyBasicDTO(json.startup);
    }
    if (json.scores != null) {
      this.scores = json.scores;
    }
  }

  public aJSON(): IStartupMonthScoreDTO {
    return {
      startup: this.startup.aJSON(),
      scores: this.scores
    };
  }

  public clon<T extends StartupMonthScoreDTO>(clonBase: T): T;
  public clon<T extends StartupMonthScoreDTO>(): StartupMonthScoreDTO;
  public clon<T extends StartupMonthScoreDTO>(clonBase: Nilable<T> = null): T | StartupMonthScoreDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.startup = this.startup.clon();
      clonBase.scores = this.scores;
      return clonBase;
    } else {
      const clon = new StartupMonthScoreDTO();
      clon.startup = this.startup.clon();
      clon.scores = this.scores;
      return clon;
    }
  }
}

export class StartupMonthScoreModelDTO extends ModelDTO<StartupMonthScoreDTO> {
  constructor(entidad: StartupMonthScoreDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Startup(): StartupCompanyBasicDTO {
    return this._entidad.startup;
  }

  public set Startup(valor: StartupCompanyBasicDTO) {
    this.cambiarPropiedadYNotificar('startup', valor);
  }

  public get Scores(): number[] {
    return this._entidad.scores;
  }

  public set Scores(valor: number[]) {
    this.cambiarPropiedadYNotificar('scores', valor);
  }
}
