import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';

export interface IOptionDTO {
  id: number;
  version: number;
  weight: number;
  insight: string;
  recomendation: string;
  questionAI: string;
}

export class OptionDTO implements IDTO<OptionDTO, IOptionDTO> {
  public id: number = 0;
  public version: number = 0;
  public weight: number = 0;
  public insight: string = '';
  public recomendation: string = '';
  public questionAI: string = '';

  constructor(json?: Partial<IOptionDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<IOptionDTO>) {
    if (json.id != null) {
      this.id = json.id;
    }
    if (json.version != null) {
      this.version = json.version;
    }
    if (json.weight != null) {
      this.weight = json.weight;
    }
    if (json.insight != null) {
      this.insight = json.insight;
    }
    if (json.recomendation != null) {
      this.recomendation = json.recomendation;
    }
    if (json.questionAI != null) {
      this.questionAI = json.questionAI;
    }
  }

  public aJSON(): IOptionDTO {
    return {
      id: this.id,
      version: this.version,
      weight: this.weight,
      insight: this.insight,
      recomendation: this.recomendation,
      questionAI: this.questionAI
    };
  }

  public clon<T extends OptionDTO>(clonBase: T): T;
  public clon<T extends OptionDTO>(): OptionDTO;
  public clon<T extends OptionDTO>(clonBase: Nilable<T> = null): T | OptionDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.id = this.id;
      clonBase.version = this.version;
      clonBase.weight = this.weight;
      clonBase.insight = this.insight;
      clonBase.recomendation = this.recomendation;
      clonBase.questionAI = this.questionAI;
      return clonBase;
    } else {
      const clon = new OptionDTO();
      clon.id = this.id;
      clon.version = this.version;
      clon.weight = this.weight;
      clon.insight = this.insight;
      clon.recomendation = this.recomendation;
      clon.questionAI = this.questionAI;
      return clon;
    }
  }
}

export class OptionModelDTO extends ModelDTO<OptionDTO> {
  constructor(entidad: OptionDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Id(): number {
    return this._entidad.id;
  }

  public set Id(valor: number) {
    this.cambiarPropiedadYNotificar('id', valor);
  }

  public get Version(): number {
    return this._entidad.version;
  }

  public set Version(valor: number) {
    this.cambiarPropiedadYNotificar('version', valor);
  }

  public get Weight(): number {
    return this._entidad.weight;
  }

  public set Weight(valor: number) {
    this.cambiarPropiedadYNotificar('weight', valor);
  }

  public get Insight(): string {
    return this._entidad.insight;
  }

  public set Insight(valor: string) {
    this.cambiarPropiedadYNotificar('insight', valor);
  }

  public get Recomendation(): string {
    return this._entidad.recomendation;
  }

  public set Recomendation(valor: string) {
    this.cambiarPropiedadYNotificar('recomendation', valor);
  }

  public get QuestionAI(): string {
    return this._entidad.questionAI;
  }

  public set QuestionAI(valor: string) {
    this.cambiarPropiedadYNotificar('questionAI', valor);
  }
}
