import { Injectable, inject } from '@angular/core';
import { CacheEntityService } from '../../cache/cache-entity.service';
import { UserEntity, UserPasswordEntity } from '@domain/user';
import { UserPort } from '@ports/user';
import { List } from 'immutable';
import { EMPTY, Observable, defer, iif, map, mapTo, of, switchMap, tap } from 'rxjs';
import { UsuarioBackendService } from '@nahuelmorata/framework-frontend';
import { UserMapper, UserPasswordMapper } from './mappers';
import { Nilable } from '@nahuelmorata/ngx-extras';

/**
 * @deprecated Use UserPort instead
 */
@Injectable({
  providedIn: 'root'
})
export class UserAdapterService extends CacheEntityService<UserEntity> implements UserPort {
  public users$ = this.entities$;

  private userBackendService = inject(UsuarioBackendService);

  public getUsers(): Observable<List<UserEntity>> {
    return defer(() =>
      this.hasCache()
        ? of(List(this.getEntities()))
        : this.userBackendService.ObtenerTodos().pipe(
            map(x => x.items),
            map(x => x.map(y => UserMapper.toEntity(y))),
            map(x => this.setEntities(x))
          )
    );
  }

  public insertUser(user: UserEntity): Observable<UserEntity> {
    return this.userBackendService.Agregar(UserMapper.toDTO(user)).pipe(
      map(x => UserMapper.toEntity(x)),
      tap(x => this.updateEntity(entity => (entity.id === user.id ? x : entity)))
    );
  }

  public updateUser(user: UserEntity): Observable<UserEntity> {
    return this.userBackendService.ActualizarPorId(UserMapper.toDTO(user), user.id).pipe(
      map(x => UserMapper.toEntity(x)),
      tap(x => this.updateEntity(entity => (entity.id === user.id ? x : entity)))
    );
  }

  public deleteUser(user: UserEntity): Observable<void> {
    return this.userBackendService
      .BorrarPorId(user.id)
      .pipe(tap(() => this.filterEntities(entity => entity.id !== user.id)));
  }

  public changePassword(user: UserEntity, userPassword?: Nilable<UserPasswordEntity>): Observable<void> {
    return of(userPassword).pipe(
      switchMap(password =>
        password == null
          ? this.userBackendService.CambiarPasswordDefault(user.id)
          : this.userBackendService.CambiarPassword(UserPasswordMapper.toDTO(password))
      ),
      map(() => void 0)
    );
  }
}
