import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';
import { IStartupCompanyBasicDTO, StartupCompanyBasicDTO } from './StartupCompanyBasic.dto';

export interface IStartupScoreTotalDTO {
  score: number;
  startup: IStartupCompanyBasicDTO;
}

export class StartupScoreTotalDTO implements IDTO<StartupScoreTotalDTO, IStartupScoreTotalDTO> {
  public score: number = 0;
  public startup: StartupCompanyBasicDTO = new StartupCompanyBasicDTO();

  constructor(json?: Partial<IStartupScoreTotalDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<IStartupScoreTotalDTO>) {
    if (json.score != null) {
      this.score = json.score;
    }
    if (json.startup != null) {
      this.startup = new StartupCompanyBasicDTO(json.startup);
    }
  }

  public aJSON(): IStartupScoreTotalDTO {
    return {
      score: this.score,
      startup: this.startup.aJSON()
    };
  }

  public clon<T extends StartupScoreTotalDTO>(clonBase: T): T;
  public clon<T extends StartupScoreTotalDTO>(): StartupScoreTotalDTO;
  public clon<T extends StartupScoreTotalDTO>(clonBase: Nilable<T> = null): T | StartupScoreTotalDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.score = this.score;
      clonBase.startup = this.startup.clon();
      return clonBase;
    } else {
      const clon = new StartupScoreTotalDTO();
      clon.score = this.score;
      clon.startup = this.startup.clon();
      return clon;
    }
  }
}

export class StartupScoreTotalModelDTO extends ModelDTO<StartupScoreTotalDTO> {
  constructor(entidad: StartupScoreTotalDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Score(): number {
    return this._entidad.score;
  }

  public set Score(valor: number) {
    this.cambiarPropiedadYNotificar('score', valor);
  }

  public get Startup(): StartupCompanyBasicDTO {
    return this._entidad.startup;
  }

  public set Startup(valor: StartupCompanyBasicDTO) {
    this.cambiarPropiedadYNotificar('startup', valor);
  }
}
