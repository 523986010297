import { Injectable, inject } from '@angular/core';
import { PermissionPort } from '@ports/permission';
import { PermissionEntity } from '@domain/permission';
import { Observable, iif, map, of, tap } from 'rxjs';
import { EndpointBackendService } from '@nahuelmorata/framework-frontend';
import { PermissionMapper } from './mappers/permission.mapper';
import { CacheEntityService } from '../../cache/cache-entity.service';
import { List } from 'immutable';

/**
 * @deprecated Use PermissionPort instead
 */
@Injectable({
  providedIn: 'root'
})
export class PermissionAdapterService extends CacheEntityService<PermissionEntity> implements PermissionPort {
  private permissionServiceBackend = inject(EndpointBackendService);

  getPermission(): Observable<List<PermissionEntity>> {
    return iif(
      () => this.hasCache(),
      of(List(this.getEntities())),
      this.permissionServiceBackend.ObtenerEndpoints().pipe(
        map(x => x.items),
        map(x => x.map(y => PermissionMapper.toEntity(y))),
        tap(x => this.setEntities(x)),
        map(x => List(x))
      )
    );
  }
}
