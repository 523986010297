import { Component, Inject, OnInit } from '@angular/core';
import { DIALOG_DATA } from '@angular/cdk/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { LinkVideo } from '../../shared/interfaces/videos/LinkVideo';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-video',
  templateUrl: './modal-video.component.html',
  styleUrls: ['./modal-video.component.scss']
})
export class ModalVideoComponent implements OnInit {
  protected safeUrl: SafeUrl | null = null;

  constructor(
    @Inject(DIALOG_DATA) protected videoData: LinkVideo,
    private domSanitizer: DomSanitizer,
    private dialogRef: MatDialogRef<any>
  ) {}

  ngOnInit(): void {
    this.safeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.videoData.link);
  }

  protected close() {
    this.dialogRef.close();
  }
}
