import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';

export interface IStartupCountSendEmailDTO {
  id: number;
  name: string;
  email: string;
}

export class StartupCountSendEmailDTO implements IDTO<StartupCountSendEmailDTO, IStartupCountSendEmailDTO> {
  public id: number = 0;
  public name: string = '';
  public email: string = '';

  constructor(json?: Partial<IStartupCountSendEmailDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<IStartupCountSendEmailDTO>) {
    if (json.id != null) {
      this.id = json.id;
    }
    if (json.name != null) {
      this.name = json.name;
    }
    if (json.email != null) {
      this.email = json.email;
    }
  }

  public aJSON(): IStartupCountSendEmailDTO {
    return {
      id: this.id,
      name: this.name,
      email: this.email
    };
  }

  public clon<T extends StartupCountSendEmailDTO>(clonBase: T): T;
  public clon<T extends StartupCountSendEmailDTO>(): StartupCountSendEmailDTO;
  public clon<T extends StartupCountSendEmailDTO>(clonBase: Nilable<T> = null): T | StartupCountSendEmailDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.id = this.id;
      clonBase.name = this.name;
      clonBase.email = this.email;
      return clonBase;
    } else {
      const clon = new StartupCountSendEmailDTO();
      clon.id = this.id;
      clon.name = this.name;
      clon.email = this.email;
      return clon;
    }
  }
}

export class StartupCountSendEmailModelDTO extends ModelDTO<StartupCountSendEmailDTO> {
  constructor(entidad: StartupCountSendEmailDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Id(): number {
    return this._entidad.id;
  }

  public set Id(valor: number) {
    this.cambiarPropiedadYNotificar('id', valor);
  }

  public get Name(): string {
    return this._entidad.name;
  }

  public set Name(valor: string) {
    this.cambiarPropiedadYNotificar('name', valor);
  }

  public get Email(): string {
    return this._entidad.email;
  }

  public set Email(valor: string) {
    this.cambiarPropiedadYNotificar('email', valor);
  }
}
