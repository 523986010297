<h1 mat-dialog-title>Edit founder</h1>
<div mat-dialog-content>
  <div class="flex">
    <mat-form-field class="grow">
      <mat-label>Name</mat-label>
      <input
        matInput
        type="text"
        id="founder-name"
        name="founder-name"
        placeholder="Name"
        [(ngModel)]="founder.name"
        required />
    </mat-form-field>
  </div>
  <div class="flex">
    <mat-form-field class="grow">
      <mat-label>Lastname</mat-label>
      <input
        matInput
        type="text"
        id="founder-lastname"
        name="founder-lastname"
        placeholder="Lastname"
        [(ngModel)]="founder.lastName"
        required />
    </mat-form-field>
  </div>
  <div class="flex">
    <mat-form-field class="grow">
      <mat-label>Position</mat-label>
      <input
        matInput
        type="text"
        id="founder-position"
        name="founder-position"
        placeholder="Position"
        [(ngModel)]="founder.position"
        required />
    </mat-form-field>
  </div>
  <div class="flex">
    <mat-form-field class="grow">
      <mat-label>Linkedin</mat-label>
      <input
        matInput
        type="url"
        id="founder-linkedin"
        name="founder-linkedin"
        placeholder="Linkedin"
        [(ngModel)]="founder.linkedin"
        required />
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions class="flex justify-end">
  <button mat-stroked-button color="primary" (click)="close()">Cancel</button>
  <button mat-flat-button color="primary" (click)="save()">Save</button>
</div>
