import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';

export interface ITopicDTO {
  id: number;
  cacheStamp: number;
  name: string;
  order: number;
}

export class TopicDTO implements IDTO<TopicDTO, ITopicDTO> {
  public id: number = 0;
  public cacheStamp: number = 0;
  public name: string = '';
  public order: number = 0;

  constructor(json?: Partial<ITopicDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<ITopicDTO>) {
    if (json.id != null) {
      this.id = json.id;
    }
    if (json.cacheStamp != null) {
      this.cacheStamp = json.cacheStamp;
    }
    if (json.name != null) {
      this.name = json.name;
    }
    if (json.order != null) {
      this.order = json.order;
    }
  }

  public aJSON(): ITopicDTO {
    return {
      id: this.id,
      cacheStamp: this.cacheStamp,
      name: this.name,
      order: this.order
    };
  }

  public clon<T extends TopicDTO>(clonBase: T): T;
  public clon<T extends TopicDTO>(): TopicDTO;
  public clon<T extends TopicDTO>(clonBase: Nilable<T> = null): T | TopicDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.id = this.id;
      clonBase.cacheStamp = this.cacheStamp;
      clonBase.name = this.name;
      clonBase.order = this.order;
      return clonBase;
    } else {
      const clon = new TopicDTO();
      clon.id = this.id;
      clon.cacheStamp = this.cacheStamp;
      clon.name = this.name;
      clon.order = this.order;
      return clon;
    }
  }
}

export class TopicModelDTO extends ModelDTO<TopicDTO> {
  constructor(entidad: TopicDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Id(): number {
    return this._entidad.id;
  }

  public set Id(valor: number) {
    this.cambiarPropiedadYNotificar('id', valor);
  }

  public get CacheStamp(): number {
    return this._entidad.cacheStamp;
  }

  public set CacheStamp(valor: number) {
    this.cambiarPropiedadYNotificar('cacheStamp', valor);
  }

  public get Name(): string {
    return this._entidad.name;
  }

  public set Name(valor: string) {
    this.cambiarPropiedadYNotificar('name', valor);
  }

  public get Order(): number {
    return this._entidad.order;
  }

  public set Order(valor: number) {
    this.cambiarPropiedadYNotificar('order', valor);
  }
}
