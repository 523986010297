import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, Subscription, take } from 'rxjs';
import { DashboardAnalyticsDTO, NoteSectionEnum, StartupCompanyDTO, TopicDTO, VariableOptionAnalyticsDTO } from '@DTOS';
import { CardAnalyticsTypeEnum } from 'src/app/shared/enums/CardAnalyticsType.enum';
import { CardTypeEnum } from 'src/app/shared/enums/CardType.enum';
import { NotesUtils } from 'src/app/shared/utils/Notes.utils';
import { DataMentorshipModal } from '../../mentorship-modal/interfaces/DataMentorshipModal';
import { MentorshipModalComponent } from '../../mentorship-modal/mentorship-modal.component';
import { StartupsService } from '../../services/startups.service';
import { State } from 'src/app/shared/store/State';
import { Select } from '@ngxs/store';
import { ApexAxisChartSeries } from 'ng-apexcharts';

@Component({
  selector: 'app-profile-mobile',
  templateUrl: './profile-mobile.component.html',
  styleUrls: ['./profile-mobile.component.scss']
})
export class ProfileMobileComponent implements OnInit, OnChanges, OnDestroy {
  @Select((state: State) => state.global.isMobile)
  public isMobile$: Observable<boolean>;

  @Input() public dashboardAnalytics = new DashboardAnalyticsDTO();
  @Input() public startupSelected = new StartupCompanyDTO();
  public scores = input.required<ApexAxisChartSeries>();
  protected topicControl = new FormControl<TopicDTO>(null);
  protected topics: TopicDTO[] = [];
  protected variablesTopic: VariableOptionAnalyticsDTO[] = [];

  protected NoteSectionEnum = NoteSectionEnum;
  protected CardTypeEnum = CardTypeEnum;
  protected CardAnalyticsTypeEnum = CardAnalyticsTypeEnum;

  private subs = new Subscription();

  constructor(
    private notesUtils: NotesUtils,
    private router: Router,
    private startupService: StartupsService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.subs.add(
      this.topicControl.valueChanges.subscribe(value => {
        this.variablesTopic = this.dashboardAnalytics.variables.filter(
          variableAnalytics => variableAnalytics.variable.topic.id === value.id
        );
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dashboardAnalytics != null && this.dashboardAnalytics != null) {
      const topics: TopicDTO[] = [];
      this.dashboardAnalytics.variables
        .map(variableAnalytics => variableAnalytics.variable.topic)
        .forEach(topic => {
          if (topics.some(topicSelect => topicSelect.id === topic.id)) {
            return;
          }
          topics.push(topic);
        });
      this.topics = topics;
      if (this.topics.length !== 0) {
        this.topicControl.setValue(this.topics[0]);
        this.variablesTopic = this.dashboardAnalytics.variables.filter(
          variableAnalytics => variableAnalytics.variable.topic.id === this.topicControl.value.id
        );
      }
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  protected openNotes(section: NoteSectionEnum): void {
    this.notesUtils.openNotes(section);
  }

  protected async goToEditStartup() {
    this.startupService.StartupToEdit = this.startupSelected;
    await this.router.navigate(['/startups/add-edit']);
  }

  /*protected mapFounderToItem(founder: FounderDTO): ItemListContentConfig {
    return {
      title: founder.name,
      subtitle: founder.position
    };
  }

  protected mapTopicToItem(topicScore: StartupAnalyticsTopicScoreDTO): ItemListContentConfig {
    return {
      title: `${topicScore.topic.name} - ${topicScore.score} %`,
    };
  }*/

  protected showMentorship(dashboardVariable: VariableOptionAnalyticsDTO): void {
    const dialogRef = this.dialog.open<MentorshipModalComponent, DataMentorshipModal, string | null>(
      MentorshipModalComponent,
      {
        maxWidth: '70vw',
        data: {
          text: dashboardVariable.insight,
          showAskWarren: dashboardVariable.recommendations.length !== 0 && dashboardVariable.recommendations[0] !== '',
          hasNext: false,
          hasPrevious: false
        } as DataMentorshipModal
      }
    );
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(action => {
        if (action == null) {
          return;
        }
        this.askWarren(dashboardVariable);
      });
  }

  protected askWarren(dashboardVariable: VariableOptionAnalyticsDTO) {
    const dialogRef = this.dialog.open<MentorshipModalComponent, DataMentorshipModal, string | null>(
      MentorshipModalComponent,
      {
        maxWidth: '70vw',
        data: {
          text: dashboardVariable.recommendations[0],
          showAskWarren: false,
          hasNext: dashboardVariable.recommendations.length > 1 && dashboardVariable.recommendations[1] !== '',
          hasPrevious: true
        } as DataMentorshipModal
      }
    );
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(action => {
        if (action == null) {
          return;
        }
        if (action === 'back') {
          this.showMentorship(dashboardVariable);
        } else {
          this.otherPointsView(dashboardVariable);
        }
      });
  }

  protected otherPointsView(dashboardVariable: VariableOptionAnalyticsDTO, index: number = 1) {
    const dialogRef = this.dialog.open<MentorshipModalComponent, DataMentorshipModal, string | null>(
      MentorshipModalComponent,
      {
        maxWidth: '70vw',
        data: {
          text: dashboardVariable.recommendations[index],
          showAskWarren: false,
          hasNext:
            dashboardVariable.recommendations[index + 1] != null && dashboardVariable.recommendations[index + 1] !== '',
          hasPrevious: true
        } as DataMentorshipModal
      }
    );
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(action => {
        if (action == null) {
          return;
        }
        if (action === 'back') {
          if (index > 1) {
            this.otherPointsView(dashboardVariable, index - 1);
          } else {
            this.askWarren(dashboardVariable);
          }
        } else {
          this.otherPointsView(dashboardVariable, index + 1);
        }
      });
  }
}
