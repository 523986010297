import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';
import { ISendEmailDTO, SendEmailDTO } from './SendEmail.dto';

export interface IStartupSendEmailsDTO {
  startups: ISendEmailDTO[];
}

export class StartupSendEmailsDTO implements IDTO<StartupSendEmailsDTO, IStartupSendEmailsDTO> {
  public startups: SendEmailDTO[] = [];

  constructor(json?: Partial<IStartupSendEmailsDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<IStartupSendEmailsDTO>) {
    if (json.startups != null) {
      this.startups = json.startups.map(item => new SendEmailDTO(item));
    }
  }

  public aJSON(): IStartupSendEmailsDTO {
    return {
      startups: this.startups.map(item => item.aJSON())
    };
  }

  public clon<T extends StartupSendEmailsDTO>(clonBase: T): T;
  public clon<T extends StartupSendEmailsDTO>(): StartupSendEmailsDTO;
  public clon<T extends StartupSendEmailsDTO>(clonBase: Nilable<T> = null): T | StartupSendEmailsDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.startups = this.startups.map(item => item.clon());
      return clonBase;
    } else {
      const clon = new StartupSendEmailsDTO();
      clon.startups = this.startups.map(item => item.clon());
      return clon;
    }
  }
}

export class StartupSendEmailsModelDTO extends ModelDTO<StartupSendEmailsDTO> {
  constructor(entidad: StartupSendEmailsDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Startups(): SendEmailDTO[] {
    return this._entidad.startups;
  }

  public set Startups(valor: SendEmailDTO[]) {
    this.cambiarPropiedadYNotificar('startups', valor);
  }
}
