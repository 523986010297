import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Constants } from '../shared/constants';

import { AlertHeadDTO } from '../DTO/AlertHead.dto';

@Injectable({
  providedIn: 'root'
})
export class AlertServicioBackend {
  constructor(protected http: HttpClient) {}

  public CreateAlert(alertHeadDTO: AlertHeadDTO): Observable<void> {
    return this.http.post<void>(`${Constants.apiURL}/alerts`, alertHeadDTO);
  }
}
