import { StartupTypeEnum } from '@DTOS';

export const formatStartupType = (startupType: StartupTypeEnum) => {
  switch (startupType) {
    case StartupTypeEnum.Lead:
      return 'Lead';
    case StartupTypeEnum.Shortlist:
      return 'Shortlist';
    case StartupTypeEnum.Selected:
      return 'Selected';
    case StartupTypeEnum.Invested:
      return 'Invested';
    case StartupTypeEnum.Exit:
      return 'Exit';
  }
};
