import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';
import { TimeTypesEnum } from './TimeTypes.enum';
import { PlatformSend } from './PlatformSend.enum';

export interface IAlertHeadDTO {
  id: number;
  shortMessage: string;
  message: string;
  countTimeAfter: number;
  timeType: number;
  platformsSend: number;
}

export class AlertHeadDTO implements IDTO<AlertHeadDTO, IAlertHeadDTO> {
  public id: number = 0;
  public shortMessage: string = '';
  public message: string = '';
  public countTimeAfter: number = 0;
  public timeType: TimeTypesEnum = TimeTypesEnum.Hours;
  public platformsSend: PlatformSend = PlatformSend.NoNotify;

  constructor(json?: Partial<IAlertHeadDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<IAlertHeadDTO>) {
    if (json.id != null) {
      this.id = json.id;
    }
    if (json.shortMessage != null) {
      this.shortMessage = json.shortMessage;
    }
    if (json.message != null) {
      this.message = json.message;
    }
    if (json.countTimeAfter != null) {
      this.countTimeAfter = json.countTimeAfter;
    }
    if (json.timeType != null) {
      this.timeType = json.timeType as TimeTypesEnum;
    }
    if (json.platformsSend != null) {
      this.platformsSend = json.platformsSend as PlatformSend;
    }
  }

  public aJSON(): IAlertHeadDTO {
    return {
      id: this.id,
      shortMessage: this.shortMessage,
      message: this.message,
      countTimeAfter: this.countTimeAfter,
      timeType: this.timeType,
      platformsSend: this.platformsSend
    };
  }

  public clon<T extends AlertHeadDTO>(clonBase: T): T;
  public clon<T extends AlertHeadDTO>(): AlertHeadDTO;
  public clon<T extends AlertHeadDTO>(clonBase: Nilable<T> = null): T | AlertHeadDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.id = this.id;
      clonBase.shortMessage = this.shortMessage;
      clonBase.message = this.message;
      clonBase.countTimeAfter = this.countTimeAfter;
      clonBase.timeType = this.timeType;
      clonBase.platformsSend = this.platformsSend;
      return clonBase;
    } else {
      const clon = new AlertHeadDTO();
      clon.id = this.id;
      clon.shortMessage = this.shortMessage;
      clon.message = this.message;
      clon.countTimeAfter = this.countTimeAfter;
      clon.timeType = this.timeType;
      clon.platformsSend = this.platformsSend;
      return clon;
    }
  }
}

export class AlertHeadModelDTO extends ModelDTO<AlertHeadDTO> {
  constructor(entidad: AlertHeadDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Id(): number {
    return this._entidad.id;
  }

  public set Id(valor: number) {
    this.cambiarPropiedadYNotificar('id', valor);
  }

  public get ShortMessage(): string {
    return this._entidad.shortMessage;
  }

  public set ShortMessage(valor: string) {
    this.cambiarPropiedadYNotificar('shortMessage', valor);
  }

  public get Message(): string {
    return this._entidad.message;
  }

  public set Message(valor: string) {
    this.cambiarPropiedadYNotificar('message', valor);
  }

  public get CountTimeAfter(): number {
    return this._entidad.countTimeAfter;
  }

  public set CountTimeAfter(valor: number) {
    this.cambiarPropiedadYNotificar('countTimeAfter', valor);
  }

  public get TimeType(): TimeTypesEnum {
    return this._entidad.timeType;
  }

  public set TimeType(valor: TimeTypesEnum) {
    this.cambiarPropiedadYNotificar('timeType', valor);
  }

  public get PlatformsSend(): PlatformSend {
    return this._entidad.platformsSend;
  }

  public set PlatformsSend(valor: PlatformSend) {
    this.cambiarPropiedadYNotificar('platformsSend', valor);
  }
}
