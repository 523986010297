import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IListaDTO, ListaDTO, IUsuarioDTO, UsuarioDTO } from '@nahuelmorata/framework-frontend';
import { Constants } from '../shared/constants';

import { ICompanyBasicDTO, CompanyBasicDTO } from '../DTO/CompanyBasic.dto';
import { ICompanyDTO, CompanyDTO } from '../DTO/Company.dto';
import { IStartupCompanyBasicDTO, StartupCompanyBasicDTO } from '../DTO/StartupCompanyBasic.dto';
import { ICompanyFullDTO, CompanyFullDTO } from '../DTO/CompanyFull.dto';
import { CompanyListDTO } from '../DTO/CompanyList.dto';
import { ICompanyRuleDTO, CompanyRuleDTO } from '../DTO/CompanyRule.dto';
import { ICustomPlanVariableDTO, CustomPlanVariableDTO } from '../DTO/CustomPlanVariable.dto';
import { ICustomPlanSelectedDTO, CustomPlanSelectedDTO } from '../DTO/CustomPlanSelected.dto';
import { SearchCompanyFilterDTO } from '../DTO/SearchCompanyFilter.dto';
import { ISearchCompanySizeDTO, SearchCompanySizeDTO } from '../DTO/SearchCompanySize.dto';
import { ICompanyAutoRejectDTO, CompanyAutoRejectDTO } from '../DTO/CompanyAutoReject.dto';
import { CompanyUserStartupDTO } from '../DTO/CompanyUserStartup.dto';

@Injectable({
  providedIn: 'root'
})
export class CompanyServicioBackend {
  constructor(protected http: HttpClient) {}

  public GetUserCompanies(): Observable<ListaDTO<CompanyBasicDTO>> {
    return this.http.get<IListaDTO<ICompanyBasicDTO>>(`${Constants.apiURL}/company`).pipe(
      map(
        lista =>
          new ListaDTO({
            items: lista.items.map(item => new CompanyBasicDTO(item)),
            cantidad: lista.cantidad
          })
      )
    );
  }

  public CreateCompany(companyBasicDTO: CompanyBasicDTO): Observable<CompanyBasicDTO> {
    return this.http
      .post<ICompanyBasicDTO>(`${Constants.apiURL}/company`, companyBasicDTO)
      .pipe(map(entidad => new CompanyBasicDTO(entidad)));
  }

  public GetCompany(id: number): Observable<CompanyDTO> {
    return this.http
      .get<ICompanyDTO>(`${Constants.apiURL}/company/${id}`)
      .pipe(map(entidad => new CompanyDTO(entidad)));
  }

  public UpdateCompany(companyBasicDTO: CompanyBasicDTO, id: number): Observable<CompanyBasicDTO> {
    return this.http
      .put<ICompanyBasicDTO>(`${Constants.apiURL}/company/${id}`, companyBasicDTO)
      .pipe(map(entidad => new CompanyBasicDTO(entidad)));
  }

  public GetAllCompanies(): Observable<ListaDTO<CompanyBasicDTO>> {
    return this.http.get<IListaDTO<ICompanyBasicDTO>>(`${Constants.apiURL}/company/all`).pipe(
      map(
        lista =>
          new ListaDTO({
            items: lista.items.map(item => new CompanyBasicDTO(item)),
            cantidad: lista.cantidad
          })
      )
    );
  }

  public GetUserStartups(companyId: number): Observable<ListaDTO<StartupCompanyBasicDTO>> {
    return this.http
      .get<IListaDTO<IStartupCompanyBasicDTO>>(`${Constants.apiURL}/company/${companyId}/userstartups`)
      .pipe(
        map(
          lista =>
            new ListaDTO({
              items: lista.items.map(item => new StartupCompanyBasicDTO(item)),
              cantidad: lista.cantidad
            })
        )
      );
  }

  public GetStartupsFromUserByCompany(companyId: number, userId: number): Observable<ListaDTO<StartupCompanyBasicDTO>> {
    return this.http
      .get<IListaDTO<IStartupCompanyBasicDTO>>(`${Constants.apiURL}/company/${companyId}/users/${userId}/startups`)
      .pipe(
        map(
          lista =>
            new ListaDTO({
              items: lista.items.map(item => new StartupCompanyBasicDTO(item)),
              cantidad: lista.cantidad
            })
        )
      );
  }

  public GetUsersFromCompany(companyId: number): Observable<ListaDTO<UsuarioDTO>> {
    return this.http.get<IListaDTO<IUsuarioDTO>>(`${Constants.apiURL}/company/${companyId}/users`).pipe(
      map(
        lista =>
          new ListaDTO({
            items: lista.items.map(item => new UsuarioDTO(item)),
            cantidad: lista.cantidad
          })
      )
    );
  }

  public GetCompaniesFromUser(userId: number): Observable<ListaDTO<CompanyBasicDTO>> {
    return this.http.get<IListaDTO<ICompanyBasicDTO>>(`${Constants.apiURL}/company/user/${userId}`).pipe(
      map(
        lista =>
          new ListaDTO({
            items: lista.items.map(item => new CompanyBasicDTO(item)),
            cantidad: lista.cantidad
          })
      )
    );
  }

  public GetUserCompaniesFullVariables(): Observable<ListaDTO<CompanyFullDTO>> {
    return this.http.get<IListaDTO<ICompanyFullDTO>>(`${Constants.apiURL}/company/variables/full`).pipe(
      map(
        lista =>
          new ListaDTO({
            items: lista.items.map(item => new CompanyFullDTO(item)),
            cantidad: lista.cantidad
          })
      )
    );
  }

  public UpdateVariablesCompanies(companyListDTO: CompanyListDTO): Observable<ListaDTO<CompanyDTO>> {
    return this.http.put<IListaDTO<ICompanyDTO>>(`${Constants.apiURL}/company/variables`, companyListDTO).pipe(
      map(
        lista =>
          new ListaDTO({
            items: lista.items.map(item => new CompanyDTO(item)),
            cantidad: lista.cantidad
          })
      )
    );
  }

  public ToggleUserToStartups(
    companyUserStartupDTO: CompanyUserStartupDTO,
    companyId: number,
    userId: number
  ): Observable<void> {
    return this.http.put<void>(
      `${Constants.apiURL}/company/${companyId}/user/${userId}/startups`,
      companyUserStartupDTO
    );
  }

  public AddUserToStartup(usuarioDTO: UsuarioDTO, companyId: number, startupId: number): Observable<void> {
    return this.http.post<void>(`${Constants.apiURL}/company/${companyId}/startups/${startupId}/addUser`, usuarioDTO);
  }

  public RemoveUserToStartup(usuarioDTO: UsuarioDTO, companyId: number, startupId: number): Observable<void> {
    return this.http.post<void>(
      `${Constants.apiURL}/company/${companyId}/startups/${startupId}/removeUser`,
      usuarioDTO
    );
  }

  public GetRules(companyId: number): Observable<ListaDTO<CompanyRuleDTO>> {
    return this.http.get<IListaDTO<ICompanyRuleDTO>>(`${Constants.apiURL}/company/${companyId}/rules`).pipe(
      map(
        lista =>
          new ListaDTO({
            items: lista.items.map(item => new CompanyRuleDTO(item)),
            cantidad: lista.cantidad
          })
      )
    );
  }

  public CreateRule(companyRuleDTO: CompanyRuleDTO, companyId: number): Observable<CompanyRuleDTO> {
    return this.http
      .post<ICompanyRuleDTO>(`${Constants.apiURL}/company/${companyId}/rules`, companyRuleDTO)
      .pipe(map(entidad => new CompanyRuleDTO(entidad)));
  }

  public DeleteRule(companyId: number, idRule: number): Observable<void> {
    return this.http.delete<void>(`${Constants.apiURL}/company/${companyId}/rules/${idRule}`);
  }

  public GetCustomPlansVariable(companyId: number): Observable<ListaDTO<CustomPlanVariableDTO>> {
    return this.http
      .get<IListaDTO<ICustomPlanVariableDTO>>(`${Constants.apiURL}/company/${companyId}/customPlanVariable`)
      .pipe(
        map(
          lista =>
            new ListaDTO({
              items: lista.items.map(item => new CustomPlanVariableDTO(item)),
              cantidad: lista.cantidad
            })
        )
      );
  }

  public AddCustomPlanVariable(
    customPlanVariableDTO: CustomPlanVariableDTO,
    companyId: number
  ): Observable<CustomPlanVariableDTO> {
    return this.http
      .post<ICustomPlanVariableDTO>(
        `${Constants.apiURL}/company/${companyId}/customPlanVariable`,
        customPlanVariableDTO
      )
      .pipe(map(entidad => new CustomPlanVariableDTO(entidad)));
  }

  public GetCustomPlanVariableSelected(companyId: number): Observable<CustomPlanSelectedDTO> {
    return this.http
      .get<ICustomPlanSelectedDTO>(`${Constants.apiURL}/company/${companyId}/customPlanVariable/selected`)
      .pipe(map(entidad => new CustomPlanSelectedDTO(entidad)));
  }

  public UpdateCustomPlanVariable(
    customPlanVariableDTO: CustomPlanVariableDTO,
    companyId: number,
    planId: number
  ): Observable<CustomPlanVariableDTO> {
    return this.http
      .put<ICustomPlanVariableDTO>(
        `${Constants.apiURL}/company/${companyId}/customPlanVariable/${planId}`,
        customPlanVariableDTO
      )
      .pipe(map(entidad => new CustomPlanVariableDTO(entidad)));
  }

  public GetDefaultCompany(): Observable<CompanyBasicDTO> {
    return this.http
      .get<ICompanyBasicDTO>(`${Constants.apiURL}/company/default`)
      .pipe(map(entidad => new CompanyBasicDTO(entidad)));
  }

  public GetCompanyFull(companyId: number): Observable<CompanyFullDTO> {
    return this.http
      .get<ICompanyFullDTO>(`${Constants.apiURL}/company/${companyId}/full`)
      .pipe(map(entidad => new CompanyFullDTO(entidad)));
  }

  public Discover(
    searchCompanyFilterDTO: SearchCompanyFilterDTO,
    page: number,
    size: number
  ): Observable<SearchCompanySizeDTO> {
    const params = new HttpParams().append('page', page.toString()).append('size', size.toString());
    const opciones = { params };
    return this.http
      .post<ISearchCompanySizeDTO>(`${Constants.apiURL}/company/discover`, searchCompanyFilterDTO, opciones)
      .pipe(map(entidad => new SearchCompanySizeDTO(entidad)));
  }

  public GetAutoRejectStartups(): Observable<ListaDTO<CompanyAutoRejectDTO>> {
    return this.http.get<IListaDTO<ICompanyAutoRejectDTO>>(`${Constants.apiURL}/company/autoReject/startups`).pipe(
      map(
        lista =>
          new ListaDTO({
            items: lista.items.map(item => new CompanyAutoRejectDTO(item)),
            cantidad: lista.cantidad
          })
      )
    );
  }
}
