<div class="flex px-8">
  <div class="w-2/5 flex flex-col border-r border-black pr-8">
    <div class="flex flex-col">
      <div class="flex justify-center items-center">
        <div class="h-[150px] w-[150px] rounded-full bg-neutral-300 flex justify-center items-center">
          @if (startup().image != null) {
            <img [src]="startup().image | addBaseUrl" [alt]="startup().name + ' logo'" />
          } @else {
            <i class="fa-light fa-image-slash fa-2xl"></i>
          }
        </div>
      </div>
      <h2 class="text-3xl mt-8 text-black font-semibold">{{ startup().name }}</h2>
      <p>{{ startup().description }}</p>
      <div class="flex flex-col px-4 mt-8">
        <div class="flex items-center">
          <div class="text-primary mr-8 w-[32px] h-[32px]">
            <i class="fa-solid fa-envelope fa-2xl"></i>
          </div>
          <div class="flex flex-col text-black">
            <span class="text-neutral-500">Email</span>
            <span>{{ startup().contactEmail != null ? startup().contactEmail : 'No data' }}</span>
          </div>
        </div>
        <div class="flex mt-4 items-center">
          <div class="text-primary mr-8 w-[32px] h-[32px]">
            <i class="fa-brands fa-linkedin fa-2xl"></i>
          </div>
          <div class="flex flex-col text-black">
            <span class="text-neutral-500">Linkedin</span>
            @if (startup().linkedin) {
              <a [href]="startup().linkedin" [matTooltip]="startup().linkedin">Open</a>
            } @else {
              <span>No data</span>
            }
          </div>
        </div>
        <div class="flex mt-4 items-center">
          <div class="text-primary mr-8 w-[32px] h-[32px]">
            <i class="fa-light fa-globe fa-2xl"></i>
          </div>
          <div class="flex flex-col text-black">
            <span class="text-neutral-500">Website</span>
            @if (startup().website) {
              <a [href]="startup().website" [matTooltip]="startup().website">Open</a>
            } @else {
              <span>No data</span>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="w-3/5 flex flex-col pl-8">
    <div class="flex justify-evenly">
      <div class="flex w-1/3 flex-col items-center">
        <span class="text-primary">
          <i class="fa-solid fa-bullseye-arrow text-7xl"></i>
        </span>
        <span class="text-4xl mt-4 font-semibold text-black">{{ scoring() | number: '1.1-1' }}</span>
        <span class="text-lg text-neutral-400 mt-1">Scoring</span>
      </div>
      <div class="flex w-1/3 flex-col items-center">
        <span class="text-primary">
          <i class="fa-regular fa-chart-pie-simple text-7xl"></i>
        </span>
        <span class="text-4xl mt-4 font-semibold text-black">{{ startup().investment | format: formatNumber }}</span>
        <span class="text-lg text-neutral-400 mt-1">Investment</span>
      </div>
      <div class="flex w-1/3 flex-col items-center">
        <span class="text-primary">
          <i class="fa-regular fa-money-bill text-7xl"></i>
        </span>
        <span class="text-4xl mt-4 font-semibold text-black">{{
          startup().totalFundingAmount | format: formatNumber
        }}</span>
        <span class="text-lg text-neutral-400 mt-1">Total Amount</span>
      </div>
    </div>
    <div class="flex mt-8">
      <div class="flex w-1/3 items-center px-2">
        <div class="w-[30px] h-[30px] text-primary mr-2">
          <i class="fa-regular fa-globe fa-xl"></i>
        </div>
        <div class="flex flex-col text-black font-bold">
          <span class="text-neutral-500 font-normal">Website</span>
          @if (startup().website) {
            <a [href]="startup().website" [matTooltip]="startup().website">Open</a>
          } @else {
            <span>No data</span>
          }
        </div>
      </div>
      <div class="flex w-1/3 items-center px-2">
        <div class="w-[30px] h-[30px] text-primary mr-2">
          <i class="fa-regular fa-sack-dollar fa-xl"></i>
        </div>
        <div class="flex flex-col text-black font-bold">
          <span class="text-neutral-500 font-normal">Total investments</span>
          <span>{{ startup().totalInvestments != null ? startup().totalInvestments : 'No data' }}</span>
        </div>
      </div>
      <div class="flex w-1/3 items-center px-2">
        <div class="w-[30px] h-[30px] text-primary mr-2">
          <i class="fa-sharp fa-regular fa-circle-dollar fa-xl"></i>
        </div>
        <div class="flex flex-col text-black font-bold">
          <span class="text-neutral-500 font-normal">Total funding amount</span>
          <span>{{ startup().totalFundingAmount != null ? startup().totalFundingAmount : 'No data' }}</span>
        </div>
      </div>
    </div>
    <div class="flex mt-8">
      <div class="flex w-1/3 items-center px-2">
        <div class="w-[30px] h-[30px] text-primary mr-2">
          <i class="fa-regular fa-building-magnifying-glass fa-xl"></i>
        </div>
        <div class="flex flex-col text-black font-bold">
          <span class="text-neutral-500 font-normal">Last funding type</span>
          <span>{{ startup().lastFundingType != null ? (startup().lastFundingType | foundingType) : 'No data' }}</span>
        </div>
      </div>
      <div class="flex w-1/3 items-center px-2">
        <div class="w-[30px] h-[30px] text-primary mr-2">
          <i class="fa-regular fa-power-off fa-xl"></i>
        </div>
        <div class="flex flex-col text-black font-bold">
          <span class="text-neutral-500 font-normal">Operating status</span>
          <span>{{
            startup().operatingStatus != null ? (startup().operatingStatus | operatingStatus) : 'No data'
          }}</span>
        </div>
      </div>
      <div class="flex w-1/3 items-center px-2">
        <div class="w-[30px] h-[30px] text-primary mr-2">
          <i class="fa-regular fa-comments-dollar fa-xl"></i>
        </div>
        <div class="flex flex-col text-black font-bold">
          <span class="text-neutral-500 font-normal">Profit type</span>
          <span>{{ startup().profitType != null ? (startup().profitType | profitType) : 'No data' }}</span>
        </div>
      </div>
    </div>
    <div class="flex mt-8">
      <div class="flex w-1/3 items-center px-2">
        <div class="w-[30px] h-[30px] text-primary mr-2">
          <i class="fa-regular fa-building-magnifying-glass fa-xl"></i>
        </div>
        <div class="flex flex-col text-black font-bold">
          <span class="text-neutral-500 font-normal">Current funding type</span>
          <span>{{
            startup().currentFundingStage != null ? (startup().currentFundingStage | foundingType) : 'No data'
          }}</span>
        </div>
      </div>
      <div class="flex w-1/3 items-center px-2">
        <div class="w-[30px] h-[30px] text-primary mr-2">
          <i class="fa-brands fa-linkedin fa-xl"></i>
        </div>
        <div class="flex flex-col text-black font-bold">
          <span class="text-neutral-500 font-normal">Linkedin</span>
          @if (startup().linkedin) {
            <a [href]="startup().linkedin" [matTooltip]="startup().linkedin">Open</a>
          } @else {
            <span>No data</span>
          }
        </div>
      </div>
      <div class="flex w-1/3 items-center px-2">
        <div class="w-[30px] h-[30px] text-primary mr-2">
          <i class="fa-regular fa-envelope fa-xl"></i>
        </div>
        <div class="flex flex-col text-black font-bold">
          <span class="text-neutral-500 font-normal">Contact email</span>
          <span>{{ startup().contactEmail != null ? startup().contactEmail : 'No data' }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
