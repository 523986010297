import { DecimalPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ProgressSpinnerBackgroundComponent } from '../../progress-spinner-background/progress-spinner-background.component';

@Component({
  selector: 'app-progress-circle',
  templateUrl: './progress-circle.component.html',
  styleUrls: ['./progress-circle.component.scss'],
  standalone: true,
  imports: [ProgressSpinnerBackgroundComponent, DecimalPipe]
})
export class ProgressCircleComponent {
  @Input() public max = 0;
  @Input() public value = 0;
  @Input() public circleClass = '';
  @Input() public diameter = 150;
}
