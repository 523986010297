import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';
import { ICountryDTO, CountryDTO } from './Country.dto';
import { IIndustryDTO, IndustryDTO } from './Industry.dto';
import { IFounderDTO, FounderDTO } from './Founder.dto';

export interface IStartupRegisterDTO {
  name: string;
  website: string;
  email: string;
  password: string;
  countries: ICountryDTO[];
  industries: IIndustryDTO[];
  founders: IFounderDTO[];
  brandName: string;
  legalName: string;
  headquarters: string;
  totalFundingAmount: number;
  foundedDate: string;
  linkedin: string;
  domain: string;
}

export class StartupRegisterDTO implements IDTO<StartupRegisterDTO, IStartupRegisterDTO> {
  public name: string = '';
  public website: string = '';
  public email: string = '';
  public password: string = '';
  public countries: CountryDTO[] = [];
  public industries: IndustryDTO[] = [];
  public founders: FounderDTO[] = [];
  public brandName: string = '';
  public legalName: string = '';
  public headquarters: string = '';
  public totalFundingAmount: number = 0;
  public foundedDate: Date = new Date();
  public linkedin: string = '';
  public domain: string = '';

  constructor(json?: Partial<IStartupRegisterDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<IStartupRegisterDTO>) {
    if (json.name != null) {
      this.name = json.name;
    }
    if (json.website != null) {
      this.website = json.website;
    }
    if (json.email != null) {
      this.email = json.email;
    }
    if (json.password != null) {
      this.password = json.password;
    }
    if (json.countries != null) {
      this.countries = json.countries.map(item => new CountryDTO(item));
    }
    if (json.industries != null) {
      this.industries = json.industries.map(item => new IndustryDTO(item));
    }
    if (json.founders != null) {
      this.founders = json.founders.map(item => new FounderDTO(item));
    }
    if (json.brandName != null) {
      this.brandName = json.brandName;
    }
    if (json.legalName != null) {
      this.legalName = json.legalName;
    }
    if (json.headquarters != null) {
      this.headquarters = json.headquarters;
    }
    if (json.totalFundingAmount != null) {
      this.totalFundingAmount = json.totalFundingAmount;
    }
    if (json.foundedDate != null) {
      this.foundedDate = new Date(json.foundedDate);
    }
    if (json.linkedin != null) {
      this.linkedin = json.linkedin;
    }
    if (json.domain != null) {
      this.domain = json.domain;
    }
  }

  public aJSON(): IStartupRegisterDTO {
    return {
      name: this.name,
      website: this.website,
      email: this.email,
      password: this.password,
      countries: this.countries.map(item => item.aJSON()),
      industries: this.industries.map(item => item.aJSON()),
      founders: this.founders.map(item => item.aJSON()),
      brandName: this.brandName,
      legalName: this.legalName,
      headquarters: this.headquarters,
      totalFundingAmount: this.totalFundingAmount,
      foundedDate: this.foundedDate.toJSON(),
      linkedin: this.linkedin,
      domain: this.domain
    };
  }

  public clon<T extends StartupRegisterDTO>(clonBase: T): T;
  public clon<T extends StartupRegisterDTO>(): StartupRegisterDTO;
  public clon<T extends StartupRegisterDTO>(clonBase: Nilable<T> = null): T | StartupRegisterDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.name = this.name;
      clonBase.website = this.website;
      clonBase.email = this.email;
      clonBase.password = this.password;
      clonBase.countries = this.countries.map(item => item.clon());
      clonBase.industries = this.industries.map(item => item.clon());
      clonBase.founders = this.founders.map(item => item.clon());
      clonBase.brandName = this.brandName;
      clonBase.legalName = this.legalName;
      clonBase.headquarters = this.headquarters;
      clonBase.totalFundingAmount = this.totalFundingAmount;
      clonBase.foundedDate = new Date(this.foundedDate.toJSON());
      clonBase.linkedin = this.linkedin;
      clonBase.domain = this.domain;
      return clonBase;
    } else {
      const clon = new StartupRegisterDTO();
      clon.name = this.name;
      clon.website = this.website;
      clon.email = this.email;
      clon.password = this.password;
      clon.countries = this.countries.map(item => item.clon());
      clon.industries = this.industries.map(item => item.clon());
      clon.founders = this.founders.map(item => item.clon());
      clon.brandName = this.brandName;
      clon.legalName = this.legalName;
      clon.headquarters = this.headquarters;
      clon.totalFundingAmount = this.totalFundingAmount;
      clon.foundedDate = new Date(this.foundedDate.toJSON());
      clon.linkedin = this.linkedin;
      clon.domain = this.domain;
      return clon;
    }
  }
}

export class StartupRegisterModelDTO extends ModelDTO<StartupRegisterDTO> {
  constructor(entidad: StartupRegisterDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Name(): string {
    return this._entidad.name;
  }

  public set Name(valor: string) {
    this.cambiarPropiedadYNotificar('name', valor);
  }

  public get Website(): string {
    return this._entidad.website;
  }

  public set Website(valor: string) {
    this.cambiarPropiedadYNotificar('website', valor);
  }

  public get Email(): string {
    return this._entidad.email;
  }

  public set Email(valor: string) {
    this.cambiarPropiedadYNotificar('email', valor);
  }

  public get Password(): string {
    return this._entidad.password;
  }

  public set Password(valor: string) {
    this.cambiarPropiedadYNotificar('password', valor);
  }

  public get Countries(): CountryDTO[] {
    return this._entidad.countries;
  }

  public set Countries(valor: CountryDTO[]) {
    this.cambiarPropiedadYNotificar('countries', valor);
  }

  public get Industries(): IndustryDTO[] {
    return this._entidad.industries;
  }

  public set Industries(valor: IndustryDTO[]) {
    this.cambiarPropiedadYNotificar('industries', valor);
  }

  public get Founders(): FounderDTO[] {
    return this._entidad.founders;
  }

  public set Founders(valor: FounderDTO[]) {
    this.cambiarPropiedadYNotificar('founders', valor);
  }

  public get BrandName(): string {
    return this._entidad.brandName;
  }

  public set BrandName(valor: string) {
    this.cambiarPropiedadYNotificar('brandName', valor);
  }

  public get LegalName(): string {
    return this._entidad.legalName;
  }

  public set LegalName(valor: string) {
    this.cambiarPropiedadYNotificar('legalName', valor);
  }

  public get Headquarters(): string {
    return this._entidad.headquarters;
  }

  public set Headquarters(valor: string) {
    this.cambiarPropiedadYNotificar('headquarters', valor);
  }

  public get TotalFundingAmount(): number {
    return this._entidad.totalFundingAmount;
  }

  public set TotalFundingAmount(valor: number) {
    this.cambiarPropiedadYNotificar('totalFundingAmount', valor);
  }

  public get FoundedDate(): Date {
    return this._entidad.foundedDate;
  }

  public set FoundedDate(valor: Date) {
    this.cambiarPropiedadYNotificar('foundedDate', valor);
  }

  public get Linkedin(): string {
    return this._entidad.linkedin;
  }

  public set Linkedin(valor: string) {
    this.cambiarPropiedadYNotificar('linkedin', valor);
  }

  public get Domain(): string {
    return this._entidad.domain;
  }

  public set Domain(valor: string) {
    this.cambiarPropiedadYNotificar('domain', valor);
  }
}
