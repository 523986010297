import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isEmptyValue'
})
export class IsEmptyValuePipe implements PipeTransform {
  transform(value: string | null | number, defaultValue: string): string {
    return value == null || value === '' ? defaultValue : value.toString();
  }
}
