<div class="relative w-fit text-center">
  <app-progress-spinner-background
    [diameter]="diameter"
    [circleClass]="circleClass"
    [withBackground]="true"
    [style.height.px]="diameter"
    [style.width.px]="diameter"
    [value]="max === 0 ? 100 : (value / max) * 100">
  </app-progress-spinner-background>
  <h1 class="absolute center-absolute font-medium text-4xl">
    {{ value | number: '1.0-0' }}
  </h1>
</div>
