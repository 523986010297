export namespace GlobalActions {
  export class NewVersion {
    public static readonly type = '[Global] New version';
  }

  export class IsMobile {
    public static readonly type = '[Global] Is mobile';
    constructor(public isMobile: boolean) {}
  }
}
