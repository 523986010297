import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IUsuarioDTO, UsuarioDTO } from '@nahuelmorata/framework-frontend';
import { Constants } from '../shared/constants';

@Injectable({
  providedIn: 'root'
})
export class CreateUsersServicioBackend {
  constructor(protected http: HttpClient) {}

  public CreateUserForCompany(usuarioDTO: UsuarioDTO, companyId: number): Observable<UsuarioDTO> {
    return this.http
      .post<IUsuarioDTO>(`${Constants.apiURL}/createusers/company/${companyId}`, usuarioDTO)
      .pipe(map(entidad => new UsuarioDTO(entidad)));
  }

  public CreateUserForStartup(usuarioDTO: UsuarioDTO, companyId: number): Observable<UsuarioDTO> {
    return this.http
      .post<IUsuarioDTO>(`${Constants.apiURL}/createusers/startup/${companyId}`, usuarioDTO)
      .pipe(map(entidad => new UsuarioDTO(entidad)));
  }

  public CreateUserForDemo(usuarioDTO: UsuarioDTO): Observable<UsuarioDTO> {
    return this.http
      .post<IUsuarioDTO>(`${Constants.apiURL}/createusers/demo`, usuarioDTO)
      .pipe(map(entidad => new UsuarioDTO(entidad)));
  }
}
