import { CompanyEntity } from '@domain/company';
import { StartupCompanyEntity } from '@domain/startup-company';
import { UserEntity } from '@domain/user';
import { List } from 'immutable';
import { Observable } from 'rxjs';

export abstract class StartupCompanyPort {
  startupsUser$: Observable<List<StartupCompanyEntity>>;
  startups$: Observable<List<StartupCompanyEntity>>;
  abstract loadStartupCompany(company: CompanyEntity): Observable<void>;
}
