import { Component, input } from '@angular/core';
import { StartupCompanyDTO } from '@DTOS';
import { IndustryDTO } from '@DTOS';

@Component({
  selector: 'app-business-finantials-information',
  templateUrl: './business-finantials-information.component.html',
  styleUrls: ['./business-finantials-information.component.scss']
})
export class BusinessFinantialsInformationComponent {
  public startupSelected = input.required<StartupCompanyDTO>();
  public isMobile = input<boolean>(false);

  public industryName(industry: IndustryDTO): string {
    return industry.name;
  }
}
