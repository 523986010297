import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IListaDTO, ListaDTO } from '@nahuelmorata/framework-frontend';
import { Constants } from '../shared/constants';

import { ICountryDTO, CountryDTO } from '../DTO/Country.dto';
import { IIndustryDTO, IndustryDTO } from '../DTO/Industry.dto';
import { IPublicInfoStartupDTO, PublicInfoStartupDTO } from '../DTO/PublicInfoStartup.dto';

@Injectable({
  providedIn: 'root'
})
export class PublicInfoServicioBackend {
  constructor(protected http: HttpClient) {}

  public GetCountries(): Observable<ListaDTO<CountryDTO>> {
    return this.http.get<IListaDTO<ICountryDTO>>(`${Constants.apiURL}/info/countries`).pipe(
      map(
        lista =>
          new ListaDTO({
            items: lista.items.map(item => new CountryDTO(item)),
            cantidad: lista.cantidad
          })
      )
    );
  }

  public GetIndustries(): Observable<ListaDTO<IndustryDTO>> {
    return this.http.get<IListaDTO<IIndustryDTO>>(`${Constants.apiURL}/info/industries`).pipe(
      map(
        lista =>
          new ListaDTO({
            items: lista.items.map(item => new IndustryDTO(item)),
            cantidad: lista.cantidad
          })
      )
    );
  }

  public GetImage(filename: string): Observable<ArrayBuffer> {
    return this.http.get(`${Constants.apiURL}/info/image/${filename}`, { responseType: 'arraybuffer' });
  }

  public SearchStartups(
    name: string,
    pageNumber: number,
    pageSize: number
  ): Observable<ListaDTO<PublicInfoStartupDTO>> {
    const params = new HttpParams()
      .append('name', name)
      .append('pageNumber', pageNumber.toString())
      .append('pageSize', pageSize.toString());
    const opciones = { params };
    return this.http.get<IListaDTO<IPublicInfoStartupDTO>>(`${Constants.apiURL}/info/startups`, opciones).pipe(
      map(
        lista =>
          new ListaDTO({
            items: lista.items.map(item => new PublicInfoStartupDTO(item)),
            cantidad: lista.cantidad
          })
      )
    );
  }
}
