import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { DataCohortBox } from '../interfaces/DataCohortBox';

@Component({
  selector: 'app-cohort-box',
  templateUrl: './cohort-box.component.html',
  styleUrls: ['./cohort-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CohortBoxComponent {
  @Input() data: DataCohortBox;
}
