import { Pipe, PipeTransform } from '@angular/core';
import { SelectOptionDTO, VariableDTO } from '@DTOS';

@Pipe({
  name: 'filterAnswers'
})
export class FilterAnswersPipe implements PipeTransform {
  transform(variables: VariableDTO[], variableId: number): SelectOptionDTO[] {
    return variables.find(variable => variable.id === variableId).selectOptions;
  }
}
