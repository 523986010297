import { Provider } from '@angular/core';
import { PermissionPort } from './ports/permission';
import { PermissionAdapterService } from './adapters/permission';
import { RolePort } from '@ports/role';
import { RoleAdapterService } from '@adapters/role';
import { UserPort } from '@ports/user';
import { UserAdapterService } from '@adapters/user';
import { CompanyPort } from '@ports/company';
import { CompanyAdapterService } from '@adapters/company';
import { StartupCompanyPort } from '@ports/startup-company';
import { StartupCompanyAdapterService } from '@adapters/startup-company';

export const infraestructureProviders: Provider[] = [
  { provide: PermissionPort, useClass: PermissionAdapterService },
  { provide: RolePort, useClass: RoleAdapterService },
  { provide: UserPort, useClass: UserAdapterService },
  { provide: CompanyPort, useClass: CompanyAdapterService },
  { provide: StartupCompanyPort, useClass: StartupCompanyAdapterService }
];
