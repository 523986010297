import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';

import { IBasicDataUserProfesionalDTO, BasicDataUserProfesionalDTO } from './BasicDataUserProfesional.dto';
import { UserProfessionalEnum } from './UserProfessional.enum';

export interface ICreateUserProfessionalDTO extends IBasicDataUserProfesionalDTO {
  email: string;
  role: number;
}

export class CreateUserProfessionalDTO
  extends BasicDataUserProfesionalDTO
  implements IDTO<CreateUserProfessionalDTO, ICreateUserProfessionalDTO>
{
  public email: string = '';
  public role: UserProfessionalEnum = UserProfessionalEnum.Advisor;

  constructor(json?: Partial<ICreateUserProfessionalDTO>) {
    super(json);

    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public override cargarDTO(json: Partial<ICreateUserProfessionalDTO>) {
    super.cargarDTO(json);

    if (json.email != null) {
      this.email = json.email;
    }
    if (json.role != null) {
      this.role = json.role as UserProfessionalEnum;
    }
  }

  public override aJSON(): ICreateUserProfessionalDTO {
    return {
      ...super.aJSON(),
      email: this.email,
      role: this.role
    };
  }

  public clon<T extends CreateUserProfessionalDTO>(clonBase: T): T;
  public clon<T extends CreateUserProfessionalDTO>(): CreateUserProfessionalDTO;
  public override clon<T extends CreateUserProfessionalDTO>(
    clonBase: Nilable<T> = null
  ): T | CreateUserProfessionalDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.email = this.email;
      clonBase.role = this.role;
      return clonBase;
    } else {
      const clon = super.clon(new CreateUserProfessionalDTO());
      clon.email = this.email;
      clon.role = this.role;
      return clon;
    }
  }
}

export class CreateUserProfessionalModelDTO extends ModelDTO<CreateUserProfessionalDTO> {
  constructor(entidad: CreateUserProfessionalDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Name(): string {
    return this._entidad.name;
  }

  public set Name(valor: string) {
    this.cambiarPropiedadYNotificar('name', valor);
  }

  public get LastName(): string {
    return this._entidad.lastName;
  }

  public set LastName(valor: string) {
    this.cambiarPropiedadYNotificar('lastName', valor);
  }

  public get Startups(): number[] {
    return this._entidad.startups;
  }

  public set Startups(valor: number[]) {
    this.cambiarPropiedadYNotificar('startups', valor);
  }

  public get Email(): string {
    return this._entidad.email;
  }

  public set Email(valor: string) {
    this.cambiarPropiedadYNotificar('email', valor);
  }

  public get Role(): UserProfessionalEnum {
    return this._entidad.role;
  }

  public set Role(valor: UserProfessionalEnum) {
    this.cambiarPropiedadYNotificar('role', valor);
  }
}
