import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';

import { IVariableBasicDTO, VariableBasicDTO } from './VariableBasic.dto';
import { IRangeOptionDTO, RangeOptionDTO } from './RangeOption.dto';
import { ISelectOptionDTO, SelectOptionDTO } from './SelectOption.dto';
import { TopicDTO } from './Topic.dto';
import { VariableTypeEnum } from './VariableType.enum';

export interface IVariableDTO extends IVariableBasicDTO {
  limits: IRangeOptionDTO;
  rangeOptions: IRangeOptionDTO[];
  selectOptions: ISelectOptionDTO[];
}

export class VariableDTO extends VariableBasicDTO implements IDTO<VariableDTO, IVariableDTO> {
  public limits: RangeOptionDTO = new RangeOptionDTO();
  public rangeOptions: RangeOptionDTO[] = [];
  public selectOptions: SelectOptionDTO[] = [];

  constructor(json?: Partial<IVariableDTO>) {
    super(json);

    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public override cargarDTO(json: Partial<IVariableDTO>) {
    super.cargarDTO(json);

    if (json.limits != null) {
      this.limits = new RangeOptionDTO(json.limits);
    }
    if (json.rangeOptions != null) {
      this.rangeOptions = json.rangeOptions.map(item => new RangeOptionDTO(item));
    }
    if (json.selectOptions != null) {
      this.selectOptions = json.selectOptions.map(item => new SelectOptionDTO(item));
    }
  }

  public override aJSON(): IVariableDTO {
    return {
      ...super.aJSON(),
      limits: this.limits.aJSON(),
      rangeOptions: this.rangeOptions.map(item => item.aJSON()),
      selectOptions: this.selectOptions.map(item => item.aJSON())
    };
  }

  public clon<T extends VariableDTO>(clonBase: T): T;
  public clon<T extends VariableDTO>(): VariableDTO;
  public override clon<T extends VariableDTO>(clonBase: Nilable<T> = null): T | VariableDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.limits = this.limits.clon();
      clonBase.rangeOptions = this.rangeOptions.map(item => item.clon());
      clonBase.selectOptions = this.selectOptions.map(item => item.clon());
      return clonBase;
    } else {
      const clon = super.clon(new VariableDTO());
      clon.limits = this.limits.clon();
      clon.rangeOptions = this.rangeOptions.map(item => item.clon());
      clon.selectOptions = this.selectOptions.map(item => item.clon());
      return clon;
    }
  }
}

export class VariableModelDTO extends ModelDTO<VariableDTO> {
  constructor(entidad: VariableDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Id(): number {
    return this._entidad.id;
  }

  public set Id(valor: number) {
    this.cambiarPropiedadYNotificar('id', valor);
  }

  public get CacheStamp(): number {
    return this._entidad.cacheStamp;
  }

  public set CacheStamp(valor: number) {
    this.cambiarPropiedadYNotificar('cacheStamp', valor);
  }

  public get Type(): VariableTypeEnum {
    return this._entidad.type;
  }

  public set Type(valor: VariableTypeEnum) {
    this.cambiarPropiedadYNotificar('type', valor);
  }

  public get Code(): string {
    return this._entidad.code;
  }

  public set Code(valor: string) {
    this.cambiarPropiedadYNotificar('code', valor);
  }

  public get Topic(): TopicDTO {
    return this._entidad.topic;
  }

  public set Topic(valor: TopicDTO) {
    this.cambiarPropiedadYNotificar('topic', valor);
  }

  public get Name(): string {
    return this._entidad.name;
  }

  public set Name(valor: string) {
    this.cambiarPropiedadYNotificar('name', valor);
  }

  public get Description(): string {
    return this._entidad.description;
  }

  public set Description(valor: string) {
    this.cambiarPropiedadYNotificar('description', valor);
  }

  public get Limits(): RangeOptionDTO {
    return this._entidad.limits;
  }

  public set Limits(valor: RangeOptionDTO) {
    this.cambiarPropiedadYNotificar('limits', valor);
  }

  public get RangeOptions(): RangeOptionDTO[] {
    return this._entidad.rangeOptions;
  }

  public set RangeOptions(valor: RangeOptionDTO[]) {
    this.cambiarPropiedadYNotificar('rangeOptions', valor);
  }

  public get SelectOptions(): SelectOptionDTO[] {
    return this._entidad.selectOptions;
  }

  public set SelectOptions(valor: SelectOptionDTO[]) {
    this.cambiarPropiedadYNotificar('selectOptions', valor);
  }
}
