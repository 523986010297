import { RoleEntity } from '@domain/role';
import { List } from 'immutable';
import { Observable } from 'rxjs';

export abstract class RolePort {
  roles$: Observable<List<RoleEntity>>;
  abstract getRoles(): Observable<List<RoleEntity>>;
  abstract loadRolePermissions(role: RoleEntity): Observable<RoleEntity>;
  abstract insertRole(role: RoleEntity): Observable<RoleEntity>;
  abstract updateRole(role: RoleEntity): Observable<RoleEntity>;
  abstract deleteRole(role: RoleEntity): Observable<void>;
}
