<div class="rounded-3xl background-primary-light flex flex-col grow py-8" [class.px-8]="!isMobile()">
  <h1 class="text-4xl font-semibold">{{ startupSummary.name }}</h1>
  <p class="mt-4">
    {{ startupSummary.description }}
  </p>
  <div class="flex justify-evenly mt-10">
    <div class="flex flex-col items-center">
      <span class="text-primary">
        <i class="fa-solid fa-bullseye-arrow fa-2xl"></i>
      </span>
      <span class="text-2xl mt-2 font-semibold">{{ startupSummary.scoring | number: '1.1-1' }}</span>
      <span class="text-xs text-neutral-400 mt-1">Scoring</span>
    </div>
    <div class="flex flex-col items-center">
      <span class="text-primary">
        <i class="fa-light fa-chart-pie-simple fa-2xl"></i>
      </span>
      <span class="text-2xl mt-2 font-semibold">{{ startupSummary.investment | format: formatNumber }}</span>
      <span class="text-xs text-neutral-400 mt-1">Investment</span>
    </div>
    <div class="flex flex-col items-center">
      <span class="text-primary">
        <i class="fa-light fa-money-bill fa-2xl"></i>
      </span>
      <span class="text-2xl mt-2 font-semibold">{{ startupSummary.totalAmount | format: formatNumber }}</span>
      <span class="text-xs text-neutral-400 mt-1">Total Amount</span>
    </div>
  </div>
  <div class="flex mt-10">
    <div class="flex flex-col grow">
      <div class="flex items-center">
        <span class="text-white p-2 bg-primary rounded-full flex justify-center items-center">
          <i class="fa-solid fa-circle-info"></i>
        </span>
        <span class="ml-4">General Information</span>
      </div>
      <div class="flex flex-col pl-2" [class.mt-4]="!isMobile()" [class.mt-8]="isMobile()">
        <div class="flex items-center">
          <div class="text-primary flex items-center justify-center max-w-[20px] min-w-[20px]">
            <i class="fa-solid fa-user fa-lg"></i>
          </div>
          <div class="flex flex-col ml-2">
            <span class="text-neutral-500 text-sm">Name</span>
            <span>{{ startupSummary.name }}</span>
          </div>
        </div>
        <div class="flex items-center mt-2">
          <div class="text-primary flex items-center justify-center max-w-[20px] min-w-[20px]">
            <i class="fa-light fa-buildings fa-lg"></i>
          </div>
          <div class="flex flex-col ml-2">
            <span class="text-neutral-500 text-sm">Headquarters</span>
            <span>{{ startupSummary.headquarters }}</span>
          </div>
        </div>
        <div class="flex items-center mt-2">
          <div class="text-primary flex items-center justify-center max-w-[20px] min-w-[20px]">
            <i class="fa-brands fa-linkedin fa-lg"></i>
          </div>
          <div class="flex flex-col ml-2">
            <span class="text-neutral-500 text-sm">Linkedin</span>
            <a [href]="startupSummary.linkedin" *ngIf="startupSummary.linkedin != null">Open</a>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col w-1/3 justify-center items-center">
      <img src="/assets/img/building.svg" alt="building" class="w-full" />
    </div>
  </div>
  <div class="flex justify-center items-center" [class.mt-4]="!isMobile()" [class.mt-10]="isMobile()">
    <button mat-flat-button color="primary" (click)="viewMore.emit()">View More</button>
  </div>
</div>
