import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';
import { IVariableOptionAnalyticsDTO, VariableOptionAnalyticsDTO } from './VariableOptionAnalytics.dto';

export interface IInsightsRecomendationsAnalyticsDTO {
  insightsRecomendations: IVariableOptionAnalyticsDTO[];
}

export class InsightsRecomendationsAnalyticsDTO
  implements IDTO<InsightsRecomendationsAnalyticsDTO, IInsightsRecomendationsAnalyticsDTO>
{
  public insightsRecomendations: VariableOptionAnalyticsDTO[] = [];

  constructor(json?: Partial<IInsightsRecomendationsAnalyticsDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<IInsightsRecomendationsAnalyticsDTO>) {
    if (json.insightsRecomendations != null) {
      this.insightsRecomendations = json.insightsRecomendations.map(item => new VariableOptionAnalyticsDTO(item));
    }
  }

  public aJSON(): IInsightsRecomendationsAnalyticsDTO {
    return {
      insightsRecomendations: this.insightsRecomendations.map(item => item.aJSON())
    };
  }

  public clon<T extends InsightsRecomendationsAnalyticsDTO>(clonBase: T): T;
  public clon<T extends InsightsRecomendationsAnalyticsDTO>(): InsightsRecomendationsAnalyticsDTO;
  public clon<T extends InsightsRecomendationsAnalyticsDTO>(
    clonBase: Nilable<T> = null
  ): T | InsightsRecomendationsAnalyticsDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.insightsRecomendations = this.insightsRecomendations.map(item => item.clon());
      return clonBase;
    } else {
      const clon = new InsightsRecomendationsAnalyticsDTO();
      clon.insightsRecomendations = this.insightsRecomendations.map(item => item.clon());
      return clon;
    }
  }
}

export class InsightsRecomendationsAnalyticsModelDTO extends ModelDTO<InsightsRecomendationsAnalyticsDTO> {
  constructor(entidad: InsightsRecomendationsAnalyticsDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get InsightsRecomendations(): VariableOptionAnalyticsDTO[] {
    return this._entidad.insightsRecomendations;
  }

  public set InsightsRecomendations(valor: VariableOptionAnalyticsDTO[]) {
    this.cambiarPropiedadYNotificar('insightsRecomendations', valor);
  }
}
