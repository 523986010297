export enum RoleEnum {
  ADMIN = 1,
  ASSESSMENT,
  CONTACT_SUPPORT,
  VC_ADMIN,
  VC_ASSESSMENT,
  VC_ANALYST,
  STARTUP_ADMIN,
  STARTUP_ASSESSMENT,
  STARTUP_ANALYST,
  DEMO,
  STARTUP_FREE,
  VC_ADVISOR,
  VC_MENTOR,
  VC_JURY,
  VC_AUDITOR,
  STARTUP_ADVISOR,
  STARTUP_MENTOR,
  STARTUP_AUDITOR
}
