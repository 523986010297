import { VariableStartupProfileEnum } from '@DTOS';

export const formatStartupProfile: (startupProfile: VariableStartupProfileEnum) => string = startupProfile => {
  switch (startupProfile) {
    case VariableStartupProfileEnum.Investment:
      return 'Investment';
    case VariableStartupProfileEnum.TotalFoundingAmount:
      return 'Total founding amount';
    case VariableStartupProfileEnum.FoundersQuantity:
      return 'Founders quantity';
    case VariableStartupProfileEnum.YearsInTheMarket:
      return 'Years in the market';
    case VariableStartupProfileEnum.LastFoundingType:
      return 'Last founding type';
    case VariableStartupProfileEnum.ProfitType:
      return 'Profit type';
    case VariableStartupProfileEnum.CurrentFounding:
      return 'Current founding';
    case VariableStartupProfileEnum.QuantityEmployees:
      return 'Quantity of employees';
    case VariableStartupProfileEnum.PercentageLoansRevenue:
      return 'Percentage of loans or convertible loans over revenue';
    case VariableStartupProfileEnum.PercentageCompanyHandsFounders:
      return 'Percentage of company in hands of founders';
    case VariableStartupProfileEnum.Headquarters:
      return 'Headquarters';
  }
};
