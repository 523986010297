import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';
import { StartupRuleTypeEnum } from './StartupRuleType.enum';
import { OperatorEnum } from './Operator.enum';

export interface IStartupRuleDTO {
  type: number;
  variable: number;
  value: number;
  valueString: string;
  operator: number;
  createdAt: string;
}

export class StartupRuleDTO implements IDTO<StartupRuleDTO, IStartupRuleDTO> {
  public type: StartupRuleTypeEnum = StartupRuleTypeEnum.Scoring;
  public variable: number = 0;
  public value: number = 0;
  public valueString: string = '';
  public operator: OperatorEnum = OperatorEnum.Equal;
  public createdAt: Date = new Date();

  constructor(json?: Partial<IStartupRuleDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<IStartupRuleDTO>) {
    if (json.type != null) {
      this.type = json.type as StartupRuleTypeEnum;
    }
    if (json.variable != null) {
      this.variable = json.variable;
    }
    if (json.value != null) {
      this.value = json.value;
    }
    if (json.valueString != null) {
      this.valueString = json.valueString;
    }
    if (json.operator != null) {
      this.operator = json.operator as OperatorEnum;
    }
    if (json.createdAt != null) {
      this.createdAt = new Date(json.createdAt);
    }
  }

  public aJSON(): IStartupRuleDTO {
    return {
      type: this.type,
      variable: this.variable,
      value: this.value,
      valueString: this.valueString,
      operator: this.operator,
      createdAt: this.createdAt.toJSON()
    };
  }

  public clon<T extends StartupRuleDTO>(clonBase: T): T;
  public clon<T extends StartupRuleDTO>(): StartupRuleDTO;
  public clon<T extends StartupRuleDTO>(clonBase: Nilable<T> = null): T | StartupRuleDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.type = this.type;
      clonBase.variable = this.variable;
      clonBase.value = this.value;
      clonBase.valueString = this.valueString;
      clonBase.operator = this.operator;
      clonBase.createdAt = new Date(this.createdAt.toJSON());
      return clonBase;
    } else {
      const clon = new StartupRuleDTO();
      clon.type = this.type;
      clon.variable = this.variable;
      clon.value = this.value;
      clon.valueString = this.valueString;
      clon.operator = this.operator;
      clon.createdAt = new Date(this.createdAt.toJSON());
      return clon;
    }
  }
}

export class StartupRuleModelDTO extends ModelDTO<StartupRuleDTO> {
  constructor(entidad: StartupRuleDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Type(): StartupRuleTypeEnum {
    return this._entidad.type;
  }

  public set Type(valor: StartupRuleTypeEnum) {
    this.cambiarPropiedadYNotificar('type', valor);
  }

  public get Variable(): number {
    return this._entidad.variable;
  }

  public set Variable(valor: number) {
    this.cambiarPropiedadYNotificar('variable', valor);
  }

  public get Value(): number {
    return this._entidad.value;
  }

  public set Value(valor: number) {
    this.cambiarPropiedadYNotificar('value', valor);
  }

  public get ValueString(): string {
    return this._entidad.valueString;
  }

  public set ValueString(valor: string) {
    this.cambiarPropiedadYNotificar('valueString', valor);
  }

  public get Operator(): OperatorEnum {
    return this._entidad.operator;
  }

  public set Operator(valor: OperatorEnum) {
    this.cambiarPropiedadYNotificar('operator', valor);
  }

  public get CreatedAt(): Date {
    return this._entidad.createdAt;
  }

  public set CreatedAt(valor: Date) {
    this.cambiarPropiedadYNotificar('createdAt', valor);
  }
}
