import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IListaDTO, ListaDTO } from '@nahuelmorata/framework-frontend';
import { Constants } from '../shared/constants';

import { IVariableDTO, VariableDTO } from '../DTO/Variable.dto';

@Injectable({
  providedIn: 'root'
})
export class VariableServicioBackend {
  constructor(protected http: HttpClient) {}

  public GetEntidad(id: number): Observable<VariableDTO> {
    return this.http
      .get<IVariableDTO>(`${Constants.apiURL}/variables/${id}`)
      .pipe(map(entidad => new VariableDTO(entidad)));
  }

  public PutEntidad(variableDTO: VariableDTO, id: number): Observable<VariableDTO> {
    return this.http
      .put<IVariableDTO>(`${Constants.apiURL}/variables/${id}`, variableDTO)
      .pipe(map(entidad => new VariableDTO(entidad)));
  }

  public DeleteEntidad(id: number): Observable<void> {
    return this.http.delete<void>(`${Constants.apiURL}/variables/${id}`);
  }

  public GetEntidades(offset: number = 0, limit: number = 0): Observable<ListaDTO<VariableDTO>> {
    const params = new HttpParams().append('offset', offset.toString()).append('limit', limit.toString());
    const opciones = { params };
    return this.http.get<IListaDTO<IVariableDTO>>(`${Constants.apiURL}/variables`, opciones).pipe(
      map(
        lista =>
          new ListaDTO({
            items: lista.items.map(item => new VariableDTO(item)),
            cantidad: lista.cantidad
          })
      )
    );
  }

  public PostEntidad(variableDTO: VariableDTO): Observable<VariableDTO> {
    return this.http
      .post<IVariableDTO>(`${Constants.apiURL}/variables`, variableDTO)
      .pipe(map(entidad => new VariableDTO(entidad)));
  }
}
