import { MethodHttpType, PermissionEntity } from '@domain/permission';
import { EndpointDTO, MetodoEnum } from '@nahuelmorata/framework-frontend';

export class PermissionMapper {
  static toEntity(dto: EndpointDTO): PermissionEntity {
    return {
      id: dto.id,
      group: dto.path.split('/')[1],
      method: this.methodToHttpType(dto.metodo),
      path: dto.path
    };
  }

  static methodToHttpType(method: MetodoEnum): MethodHttpType {
    switch (method) {
      case MetodoEnum.GET:
        return 'GET';
      case MetodoEnum.POST:
        return 'POST';
      case MetodoEnum.PUT:
        return 'PUT';
      case MetodoEnum.PATCH:
        return 'PATCH';
      case MetodoEnum.DELETE:
        return 'DELETE';
      default:
        throw new Error("Method doesn't exists");
    }
  }

  static httpTypeToMethod(method: MethodHttpType): MetodoEnum {
    switch (method) {
      case 'GET':
        return MetodoEnum.GET;
      case 'POST':
        return MetodoEnum.POST;
      case 'PUT':
        return MetodoEnum.PUT;
      case 'PATCH':
        return MetodoEnum.PATCH;
      case 'DELETE':
        return MetodoEnum.DELETE;
      default:
        throw new Error("Method doesn't exists");
    }
  }

  static toDTO(permission: PermissionEntity): EndpointDTO {
    return new EndpointDTO({
      id: permission.id,
      metodo: this.httpTypeToMethod(permission.method),
      path: permission.path
    });
  }
}
