import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';
import { ITopicDTO, TopicDTO } from './Topic.dto';

export interface IStartupAnalyticsTopicScoreDTO {
  topic: ITopicDTO;
  score: number;
}

export class StartupAnalyticsTopicScoreDTO
  implements IDTO<StartupAnalyticsTopicScoreDTO, IStartupAnalyticsTopicScoreDTO>
{
  public topic: TopicDTO = new TopicDTO();
  public score: number = 0;

  constructor(json?: Partial<IStartupAnalyticsTopicScoreDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<IStartupAnalyticsTopicScoreDTO>) {
    if (json.topic != null) {
      this.topic = new TopicDTO(json.topic);
    }
    if (json.score != null) {
      this.score = json.score;
    }
  }

  public aJSON(): IStartupAnalyticsTopicScoreDTO {
    return {
      topic: this.topic.aJSON(),
      score: this.score
    };
  }

  public clon<T extends StartupAnalyticsTopicScoreDTO>(clonBase: T): T;
  public clon<T extends StartupAnalyticsTopicScoreDTO>(): StartupAnalyticsTopicScoreDTO;
  public clon<T extends StartupAnalyticsTopicScoreDTO>(clonBase: Nilable<T> = null): T | StartupAnalyticsTopicScoreDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.topic = this.topic.clon();
      clonBase.score = this.score;
      return clonBase;
    } else {
      const clon = new StartupAnalyticsTopicScoreDTO();
      clon.topic = this.topic.clon();
      clon.score = this.score;
      return clon;
    }
  }
}

export class StartupAnalyticsTopicScoreModelDTO extends ModelDTO<StartupAnalyticsTopicScoreDTO> {
  constructor(entidad: StartupAnalyticsTopicScoreDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Topic(): TopicDTO {
    return this._entidad.topic;
  }

  public set Topic(valor: TopicDTO) {
    this.cambiarPropiedadYNotificar('topic', valor);
  }

  public get Score(): number {
    return this._entidad.score;
  }

  public set Score(valor: number) {
    this.cambiarPropiedadYNotificar('score', valor);
  }
}
