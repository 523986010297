import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';

export interface ICountryDTO {
  id: number;
  code: string;
  name: string;
}

export class CountryDTO implements IDTO<CountryDTO, ICountryDTO> {
  public id: number = 0;
  public code: string = '';
  public name: string = '';

  constructor(json?: Partial<ICountryDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<ICountryDTO>) {
    if (json.id != null) {
      this.id = json.id;
    }
    if (json.code != null) {
      this.code = json.code;
    }
    if (json.name != null) {
      this.name = json.name;
    }
  }

  public aJSON(): ICountryDTO {
    return {
      id: this.id,
      code: this.code,
      name: this.name
    };
  }

  public clon<T extends CountryDTO>(clonBase: T): T;
  public clon<T extends CountryDTO>(): CountryDTO;
  public clon<T extends CountryDTO>(clonBase: Nilable<T> = null): T | CountryDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.id = this.id;
      clonBase.code = this.code;
      clonBase.name = this.name;
      return clonBase;
    } else {
      const clon = new CountryDTO();
      clon.id = this.id;
      clon.code = this.code;
      clon.name = this.name;
      return clon;
    }
  }
}

export class CountryModelDTO extends ModelDTO<CountryDTO> {
  constructor(entidad: CountryDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Id(): number {
    return this._entidad.id;
  }

  public set Id(valor: number) {
    this.cambiarPropiedadYNotificar('id', valor);
  }

  public get Code(): string {
    return this._entidad.code;
  }

  public set Code(valor: string) {
    this.cambiarPropiedadYNotificar('code', valor);
  }

  public get Name(): string {
    return this._entidad.name;
  }

  public set Name(valor: string) {
    this.cambiarPropiedadYNotificar('name', valor);
  }
}
