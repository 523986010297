import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';

import { IOptionDTO, OptionDTO } from './Option.dto';

export interface ISelectOptionDTO extends IOptionDTO {
  text: string;
}

export class SelectOptionDTO extends OptionDTO implements IDTO<SelectOptionDTO, ISelectOptionDTO> {
  public text: string = '';

  constructor(json?: Partial<ISelectOptionDTO>) {
    super(json);

    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public override cargarDTO(json: Partial<ISelectOptionDTO>) {
    super.cargarDTO(json);

    if (json.text != null) {
      this.text = json.text;
    }
  }

  public override aJSON(): ISelectOptionDTO {
    return {
      ...super.aJSON(),
      text: this.text
    };
  }

  public clon<T extends SelectOptionDTO>(clonBase: T): T;
  public clon<T extends SelectOptionDTO>(): SelectOptionDTO;
  public override clon<T extends SelectOptionDTO>(clonBase: Nilable<T> = null): T | SelectOptionDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.text = this.text;
      return clonBase;
    } else {
      const clon = super.clon(new SelectOptionDTO());
      clon.text = this.text;
      return clon;
    }
  }
}

export class SelectOptionModelDTO extends ModelDTO<SelectOptionDTO> {
  constructor(entidad: SelectOptionDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Id(): number {
    return this._entidad.id;
  }

  public set Id(valor: number) {
    this.cambiarPropiedadYNotificar('id', valor);
  }

  public get Version(): number {
    return this._entidad.version;
  }

  public set Version(valor: number) {
    this.cambiarPropiedadYNotificar('version', valor);
  }

  public get Weight(): number {
    return this._entidad.weight;
  }

  public set Weight(valor: number) {
    this.cambiarPropiedadYNotificar('weight', valor);
  }

  public get Insight(): string {
    return this._entidad.insight;
  }

  public set Insight(valor: string) {
    this.cambiarPropiedadYNotificar('insight', valor);
  }

  public get Recomendation(): string {
    return this._entidad.recomendation;
  }

  public set Recomendation(valor: string) {
    this.cambiarPropiedadYNotificar('recomendation', valor);
  }

  public get QuestionAI(): string {
    return this._entidad.questionAI;
  }

  public set QuestionAI(valor: string) {
    this.cambiarPropiedadYNotificar('questionAI', valor);
  }

  public get Text(): string {
    return this._entidad.text;
  }

  public set Text(valor: string) {
    this.cambiarPropiedadYNotificar('text', valor);
  }
}
