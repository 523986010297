import { Pipe, PipeTransform } from '@angular/core';
import { Moment } from 'moment';

@Pipe({
  name: 'formatMoment'
})
export class FormatMomentPipe implements PipeTransform {
  transform(date: Moment, format: string): string {
    return date.format(format);
  }
}
