import { Injectable } from '@angular/core';
import { NoteServicioBackend } from '@serviceBackend';
import { NoteSectionEnum, NoteDTO } from '@DTOS';
import { map, Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotesService {
  private _notes = new Map<NoteSectionEnum, NoteDTO[]>();

  constructor(private noteServicioBackend: NoteServicioBackend) {}

  public getNotesBySection(section: NoteSectionEnum): NoteDTO[] {
    return [...(this._notes.get(section) ?? [])];
  }

  public loadSections(sections: NoteSectionEnum[]): Observable<boolean> {
    const sectionsJoin = sections.join(',');
    return this.noteServicioBackend.GetNotesBySection(sectionsJoin).pipe(
      tap(notes => {
        for (const section of sections) {
          this._notes.set(
            section,
            notes.items.filter(x => x.section === section)
          );
        }
      }),
      map(() => true)
    );
  }

  public addNote(note: NoteDTO): Observable<NoteDTO> {
    return this.noteServicioBackend.CreateNote(note).pipe(
      tap(noteDTO => {
        if (!this._notes.has(note.section)) {
          this._notes.set(note.section, []);
        }
        this._notes.get(note.section).push(noteDTO);
      })
    );
  }

  public updateNote(note: NoteDTO): Observable<NoteDTO> {
    return this.noteServicioBackend.EditNote(note, note.id).pipe(
      tap(noteDTO => {
        const notes = this._notes.get(note.section);
        this._notes.set(
          note.section,
          notes.map(x => (x.id === noteDTO.id ? noteDTO : x))
        );
      })
    );
  }

  public shareNoteWith(note: NoteDTO, users: number[]): Observable<void> {
    return this.noteServicioBackend.ShareNote(note.id, users.join(','));
  }

  public deleteNote(note: NoteDTO): Observable<void> {
    return this.noteServicioBackend.RemoveNote(note.id).pipe(
      tap(() => {
        const notes = this._notes.get(note.section);
        this._notes.set(
          note.section,
          notes.filter(x => x.id !== note.id)
        );
      })
    );
  }
}
