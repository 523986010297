<mat-card class="mb-4 overflow-hidden">
  <div class="flex grow overflow-hidden -mx-4 px-4">
    <div class="flex flex-col">
      <div class="startup-container header text-neutral-500" [style.min-width.px]="minWidth">Startups</div>
      <div class="flex items-center" *ngFor="let data of datas; index as i">
        <mat-checkbox
          (change)="selectedIndexChange.emit(i)"
          [checked]="selectedIndex === i"
          class="mr-2"></mat-checkbox>
        <div
          class="startup-container truncate flex items-center cursor-pointer"
          [style.min-width.px]="minWidth"
          (click)="openStartupInfoDialogCmd.ejecutar(data)"
          (mouseenter)="indexHover = i"
          (mouseleave)="indexHover = null"
          [ngClass]="{
            'text-bold': indexHover === i,
            'opacity-50': indexHover != null && indexHover !== i
          }">
          <span>{{ data.entity.name }}</span>
        </div>
      </div>
    </div>
    <div class="flex flex-col overflow-x-auto dark-scrollbar grow">
      <ng-container *ngIf="!showCustomComponent">
        <div class="flex pt-3">
          <div
            *ngFor="let month of months"
            matTooltip="{{ month | date: 'MM/yyyy' }}"
            class="assessment-box text-truncate cohort-label text-center !h-fit mb-4 text-neutral-500">
            {{ month | date: 'MM/yy' }}
          </div>
        </div>
        <ng-container *ngFor="let data of datas; index as i">
          <div class="flex">
            <app-cohort-box
              *ngFor="let score of data.scores"
              [data]="score"
              (mouseenter)="indexHover = i"
              (mouseleave)="indexHover = null"
              class="assessment-box with-borders text-truncate"
              [ngClass]="{
                'text-bold': indexHover === i,
                'opacity-50': indexHover != null && indexHover !== i
              }"></app-cohort-box>
          </div>
        </ng-container>
      </ng-container>
      <ng-template *ngIf="showCustomComponent" [ngTemplateOutlet]="customCohortViewTemplate"></ng-template>
    </div>
    <div class="flex flex-col" *ngIf="actionsTemplate != null">
      <ng-template [ngTemplateOutlet]="actionsTemplate"></ng-template>
    </div>
  </div>
</mat-card>
