import { VariableDTO } from '@DTOS';
import { UntypedFormControl } from '@angular/forms';

export class VariableExtendedModel {
  public companiesList: UntypedFormControl = new UntypedFormControl();

  constructor(private variable: VariableDTO) {}

  get Variable() {
    return this.variable;
  }

  set Variable(v: VariableDTO) {
    this.variable = v;
  }
}
