import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IListaDTO, ListaDTO } from '@nahuelmorata/framework-frontend';
import { Constants } from '../shared/constants';

import { IConnectionDTO, ConnectionDTO } from '../DTO/Connection.dto';

@Injectable({
  providedIn: 'root'
})
export class ConnectionServicioBackend {
  constructor(protected http: HttpClient) {}

  public GetConnectionsByCompany(companyId: number): Observable<ListaDTO<ConnectionDTO>> {
    return this.http.get<IListaDTO<IConnectionDTO>>(`${Constants.apiURL}/connections/byCompany/${companyId}`).pipe(
      map(
        lista =>
          new ListaDTO({
            items: lista.items.map(item => new ConnectionDTO(item)),
            cantidad: lista.cantidad
          })
      )
    );
  }

  public GetConnectionsByStartup(startupId: number): Observable<ListaDTO<ConnectionDTO>> {
    return this.http.get<IListaDTO<IConnectionDTO>>(`${Constants.apiURL}/connections/byStartup/${startupId}`).pipe(
      map(
        lista =>
          new ListaDTO({
            items: lista.items.map(item => new ConnectionDTO(item)),
            cantidad: lista.cantidad
          })
      )
    );
  }

  public CreateConnection(connectionDTO: ConnectionDTO): Observable<void> {
    return this.http.post<void>(`${Constants.apiURL}/connections`, connectionDTO);
  }

  public AcceptConnection(id: number): Observable<ConnectionDTO> {
    return this.http
      .put<IConnectionDTO>(`${Constants.apiURL}/connections/${id}/accept`, null)
      .pipe(map(entidad => new ConnectionDTO(entidad)));
  }

  public DeleteConnection(id: number): Observable<void> {
    return this.http.delete<void>(`${Constants.apiURL}/connections/${id}`);
  }

  public EditConnection(connectionDTO: ConnectionDTO, id: number): Observable<ConnectionDTO> {
    return this.http
      .put<IConnectionDTO>(`${Constants.apiURL}/connections/${id}`, connectionDTO)
      .pipe(map(entidad => new ConnectionDTO(entidad)));
  }

  public GetConnection(id: number): Observable<ConnectionDTO> {
    return this.http
      .get<IConnectionDTO>(`${Constants.apiURL}/connections/${id}`)
      .pipe(map(entidad => new ConnectionDTO(entidad)));
  }

  public ArchiveConnection(id: number): Observable<void> {
    return this.http.patch<void>(`${Constants.apiURL}/connections/${id}/archive`, null);
  }
}
