import { Component, Inject } from '@angular/core';
import { FounderDTO } from '@DTOS';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-founder-modal',
  templateUrl: './founder-modal.component.html',
  styleUrls: ['./founder-modal.component.scss']
})
export class FounderModalComponent {
  public founder = new FounderDTO();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FounderDTO,
    public dialogRef: MatDialogRef<FounderModalComponent>
  ) {
    this.founder.cargarDTO(this.data);
  }

  public close() {
    this.dialogRef.close();
  }

  public save() {
    this.dialogRef.close(this.founder);
  }
}
