import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AllInfoVcComponent } from 'src/app/layout/selection-process/discover-compare/discover-compare-vc/search-table-vc/all-info-vc/all-info-vc.component';

@Injectable({
  providedIn: 'root'
})
export class StartupUtils {
  private readonly dialog = inject(MatDialog);

  public openStartupInfo(startupId: number, scoring: number): Observable<void> {
    const dialogRef = this.dialog.open(AllInfoVcComponent);
    return dialogRef.componentInstance.loadData(startupId, scoring);
  }
}
