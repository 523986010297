import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';

import { IOptionDTO, OptionDTO } from './Option.dto';

export interface IRangeOptionDTO extends IOptionDTO {
  max: number;
  min: number;
  previousId: number;
}

export class RangeOptionDTO extends OptionDTO implements IDTO<RangeOptionDTO, IRangeOptionDTO> {
  public max: number = 0;
  public min: number = 0;
  public previousId: number = 0;

  constructor(json?: Partial<IRangeOptionDTO>) {
    super(json);

    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public override cargarDTO(json: Partial<IRangeOptionDTO>) {
    super.cargarDTO(json);

    if (json.max != null) {
      this.max = json.max;
    }
    if (json.min != null) {
      this.min = json.min;
    }
    if (json.previousId != null) {
      this.previousId = json.previousId;
    }
  }

  public override aJSON(): IRangeOptionDTO {
    return {
      ...super.aJSON(),
      max: this.max,
      min: this.min,
      previousId: this.previousId
    };
  }

  public clon<T extends RangeOptionDTO>(clonBase: T): T;
  public clon<T extends RangeOptionDTO>(): RangeOptionDTO;
  public override clon<T extends RangeOptionDTO>(clonBase: Nilable<T> = null): T | RangeOptionDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.max = this.max;
      clonBase.min = this.min;
      clonBase.previousId = this.previousId;
      return clonBase;
    } else {
      const clon = super.clon(new RangeOptionDTO());
      clon.max = this.max;
      clon.min = this.min;
      clon.previousId = this.previousId;
      return clon;
    }
  }
}

export class RangeOptionModelDTO extends ModelDTO<RangeOptionDTO> {
  constructor(entidad: RangeOptionDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Id(): number {
    return this._entidad.id;
  }

  public set Id(valor: number) {
    this.cambiarPropiedadYNotificar('id', valor);
  }

  public get Version(): number {
    return this._entidad.version;
  }

  public set Version(valor: number) {
    this.cambiarPropiedadYNotificar('version', valor);
  }

  public get Weight(): number {
    return this._entidad.weight;
  }

  public set Weight(valor: number) {
    this.cambiarPropiedadYNotificar('weight', valor);
  }

  public get Insight(): string {
    return this._entidad.insight;
  }

  public set Insight(valor: string) {
    this.cambiarPropiedadYNotificar('insight', valor);
  }

  public get Recomendation(): string {
    return this._entidad.recomendation;
  }

  public set Recomendation(valor: string) {
    this.cambiarPropiedadYNotificar('recomendation', valor);
  }

  public get QuestionAI(): string {
    return this._entidad.questionAI;
  }

  public set QuestionAI(valor: string) {
    this.cambiarPropiedadYNotificar('questionAI', valor);
  }

  public get Max(): number {
    return this._entidad.max;
  }

  public set Max(valor: number) {
    this.cambiarPropiedadYNotificar('max', valor);
  }

  public get Min(): number {
    return this._entidad.min;
  }

  public set Min(valor: number) {
    this.cambiarPropiedadYNotificar('min', valor);
  }

  public get PreviousId(): number {
    return this._entidad.previousId;
  }

  public set PreviousId(valor: number) {
    this.cambiarPropiedadYNotificar('previousId', valor);
  }
}
