import { Component, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, NonNullableFormBuilder, Validators } from '@angular/forms';
import { AlertHeadDTO, TimeTypesEnum } from '@DTOS';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss']
})
export class AlertModalComponent {
  private readonly dialogRef = inject(MatDialogRef);
  public readonly alertFormGroup = inject(NonNullableFormBuilder).group({
    shortMessage: new FormControl<string>(null, [Validators.required]),
    message: new FormControl<string>(null, [Validators.required]),
    countTimeAfter: new FormControl<number>(null, [Validators.required]),
    timeType: new FormControl<TimeTypesEnum>(1, [Validators.required]),
    platformsSend: new FormControl<number[]>([], [Validators.minLength(1)])
  });

  public cancel() {
    this.dialogRef.close();
  }

  public create() {
    if (this.alertFormGroup.invalid) {
      return;
    }
    this.dialogRef.close(this.generateAlert());
  }

  private generateAlert(): AlertHeadDTO {
    const alert = new AlertHeadDTO();
    const valuesForm = this.alertFormGroup.value;
    alert.message = valuesForm.message;
    alert.shortMessage = valuesForm.shortMessage;
    alert.countTimeAfter = valuesForm.countTimeAfter;
    alert.timeType = valuesForm.timeType;
    alert.platformsSend = valuesForm.platformsSend.reduce((acc, cur) => acc + cur, 0);
    return alert;
  }
}
