import { CustomState } from './CustomState';
import { GlobalActions } from '../actions';
import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';

export interface IGlobalState {
  isNewVersion: boolean;
  isMobile: boolean;
}

@State<IGlobalState>({
  name: 'global',
  defaults: {
    isNewVersion: false,
    isMobile: false
  }
})
@Injectable()
export class GlobalState extends CustomState<IGlobalState> {
  @Action(GlobalActions.NewVersion)
  public hasNewVersion(ctx: StateContext<IGlobalState>) {
    this.sendNewState(ctx, { isNewVersion: true });
  }

  @Action(GlobalActions.IsMobile)
  public setIsMobile(ctx: StateContext<IGlobalState>, action: GlobalActions.IsMobile) {
    this.sendNewState(ctx, { isMobile: action.isMobile });
  }
}
