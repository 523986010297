import { Injectable } from '@angular/core';

@Injectable()
export class CacheService<T> {
  protected _items: T[] = [];
  private _comparator: (x: T, y: T) => boolean;

  public addItems(items: T[]) {
    this._items.push(...items.filter(x => !this._items.some(y => this._comparator(x, y))));
  }

  public getItems(): T[] {
    return this._items;
  }

  public clean() {
    this._items = [];
  }

  public setComparator(comparator: (x: T, y: T) => boolean) {
    this._comparator = comparator;
  }

  static build<T>(comparator: (x: T, y: T) => boolean): CacheService<T> {
    const cacheService = new CacheService<T>();
    cacheService.setComparator(comparator);
    return cacheService;
  }
}
