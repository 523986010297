<form class="hidden md:flex items-center w-full">
  <mat-form-field class="grow pad-form" *ngIf="startupFilterActive && multiple">
    <mat-label>Startups</mat-label>
    <mat-select
      #multipleSelect
      [multiple]="true"
      [formControl]="startupsControl"
      name="startups"
      [compareWith]="compareById">
      <mat-select-trigger>
        {{ startupsControl.value | filterArray: startupWithoutAll | joinArray: ', ' : getNameCompany }}
      </mat-select-trigger>
      <mat-option>
        <ngx-mat-select-search [formControl]="startupsFilterControl" placeholderLabel="Search" ngDefaultControl>
        </ngx-mat-select-search>
      </mat-option>
      <mat-option [value]="allStartupsOption" *ngIf="startupsFilterControl.value === ''"> All </mat-option>
      <mat-option
        *ngFor="let startup of startupsCompany | filterArray: filterByName : startupsFilterControl.value"
        [value]="startup">
        {{ startup.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="grow pad-form" *ngIf="startupFilterActive && !multiple">
    <mat-label>Startup</mat-label>
    <mat-select #singleSelect [formControl]="startupControl" name="startup" [compareWith]="compareById">
      <mat-option>
        <ngx-mat-select-search [formControl]="startupsFilterControl" placeholderLabel="Search"></ngx-mat-select-search>
      </mat-option>
      <mat-option
        *ngFor="let startup of startupsCompany | filterArray: filterByName : startupsFilterControl.value"
        [value]="startup">
        {{ startup.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <ng-container *ngTemplateOutlet="filterRef"></ng-container>
  <button mat-flat-button color="primary" [command]="searchCmd">Search</button>
</form>
<form class="flex md:hidden flex-col items-stretch grow">
  <div class="flex">
    <mat-form-field class="grow pad-form" *ngIf="multiple">
      <mat-label>Startups</mat-label>
      <mat-select
        #multipleSelect
        [multiple]="true"
        [formControl]="startupsControl"
        name="startups"
        [compareWith]="compareById">
        <mat-select-trigger>
          {{ startupsControl.value | filterArray: startupWithoutAll | joinArray: ', ' : getNameCompany }}
        </mat-select-trigger>
        <mat-option>
          <ngx-mat-select-search [formControl]="startupsFilterControl" placeholderLabel="Search" ngDefaultControl>
          </ngx-mat-select-search>
        </mat-option>
        <mat-option [value]="allStartupsOption" *ngIf="startupsFilterControl.value === ''"> All </mat-option>
        <mat-option
          *ngFor="let startup of startupsCompany | filterArray: filterByName : startupsFilterControl.value"
          [value]="startup">
          {{ startup.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="flex">
    <mat-form-field class="grow pad-form" *ngIf="!multiple">
      <mat-label>Startup</mat-label>
      <mat-select #singleSelect [formControl]="startupControl" name="startups" [compareWith]="compareById">
        <mat-option>
          <ngx-mat-select-search
            [formControl]="startupsFilterControl"
            placeholderLabel="Search"></ngx-mat-select-search>
        </mat-option>
        <mat-option
          *ngFor="let startup of startupsCompany | filterArray: filterByName : startupsFilterControl.value"
          [value]="startup">
          {{ startup.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="flex">
    <ng-container *ngTemplateOutlet="filterRef"></ng-container>
  </div>
  <div class="flex">
    <button mat-flat-button color="primary" [command]="searchCmd" class="w-full">Search</button>
  </div>
</form>
