<div class="flex flex-col grow">
  @if (variablesShow().length !== 0) {
    <div class="bg-primary/30 text-primary font-semibold grow h-[48px] flex items-center pl-4">
      <strong>{{ topicName() }}</strong>
    </div>
  }

  @for (
    qa of topic()?.questionsAnswers | filterArray: filterShow : variablesShow() | sortQuestionAnswer;
    track qa.question.id
  ) {
    @if ((isMobile$ | async) === true) {
      <div class="flex flex-col px-4 bg-primary-light py-2">
        {{ qa.question.name }}
        <app-question [questionAnswer]="qa" class="mt-2"></app-question>
      </div>
    } @else {
      <div class="flex items-center px-4 bg-primary-light">
        <div class="w-1/2">
          {{ qa.question.name }}
        </div>
        <div class="w-1/2">
          <app-question [questionAnswer]="qa"></app-question>
        </div>
      </div>
    }
  }
</div>
