import { Pipe, PipeTransform } from '@angular/core';
import { TopicDTO } from '@DTOS';

@Pipe({
  name: 'sortTopic',
  pure: true
})
export class SortTopicPipe implements PipeTransform {
  transform(topics: TopicDTO[]): TopicDTO[] {
    return topics.sort((topic1, topic2) => (topic1.order === 0 ? 1000 : topic1.order - topic2.order));
  }
}
