import { Injectable } from '@angular/core';
import { CompanyServicioBackend, StartupCompanyServicioBackend, SurveyServicioBackend } from '@serviceBackend';
import {
  CompanyBasicDTO,
  CompanyFullDTO,
  CrunchbaseRequestDTO,
  ResponseBasicDTO,
  ScoreDTO,
  SearchStartupFilterDTO,
  SearchStartupSizeDTO,
  StartupChangeTypeDTO,
  StartupCompanyBasicDTO,
  StartupCompanyDTO,
  StartupCountSendEmailsDTO,
  StartupMissingAssessmentDTO,
  SurveyResponseDTO,
  StartupCompanyModelDTO,
  CompletedSurveysResponseDTO,
  StartupRuleDTO,
  StartupSendEmailsDTO
} from '@DTOS';
import { Moment } from 'moment';
import { map, Observable } from 'rxjs';
import { StartupSummaryDTO } from '@DTOS';

@Injectable({
  providedIn: 'root'
})
export class StartupsService {
  private startupToEdit: StartupCompanyDTO;
  private ventureSelected: CompanyBasicDTO;
  private companySelected: CompanyFullDTO | null;
  public startupRuleEditing: StartupRuleDTO | null;

  constructor(
    private startupsServicioBackend: StartupCompanyServicioBackend,
    private surveyBackend: SurveyServicioBackend,
    private companyBackend: CompanyServicioBackend
  ) {}

  get StartupToEdit(): StartupCompanyDTO {
    return this.startupToEdit;
  }

  set StartupToEdit(t: StartupCompanyDTO) {
    this.startupToEdit = t;
  }

  get VentureSelected(): CompanyBasicDTO {
    return this.ventureSelected;
  }

  set VentureSelected(t: CompanyBasicDTO) {
    this.ventureSelected = t;
  }

  public getCompanyDefault(): Observable<CompanyBasicDTO> {
    return this.companyBackend.GetDefaultCompany();
  }

  public getStartups(): Observable<Array<StartupCompanyDTO>> {
    return this.startupsServicioBackend.GetEntidades().pipe(map(x => x.items));
  }

  public getStartup(id: number): Observable<StartupCompanyModelDTO> {
    return this.startupsServicioBackend.GetEntidad(id).pipe(map(x => new StartupCompanyModelDTO(x)));
  }

  public getStartupsScore(company: CompanyBasicDTO, answers = ''): Observable<Array<ScoreDTO>> {
    return this.surveyBackend.GetStartupsWithScore(company.id, answers).pipe(map(x => x.items));
  }

  public addStartup(banco: StartupCompanyDTO): Observable<StartupCompanyDTO> {
    return this.startupsServicioBackend.PostEntidad(banco);
  }

  public editStartup(banco: StartupCompanyDTO): Observable<StartupCompanyDTO> {
    return this.startupsServicioBackend.PutEntidad(banco, banco.id);
  }

  public deleteStartup(banco: StartupCompanyDTO): Observable<void> {
    return this.startupsServicioBackend.DeleteEntidad(banco.id);
  }

  public insertInterviewAnswer(surveyAnswer: SurveyResponseDTO): Observable<void> {
    return this.surveyBackend.SaveAnswers(surveyAnswer);
  }

  public getCompaniesForInterview(): Observable<Array<CompanyFullDTO>> {
    return this.companyBackend.GetUserCompaniesFullVariables().pipe(map(x => x.items));
  }

  public getInterviewAnswers(
    startupCompanyDTO: StartupCompanyBasicDTO,
    company: CompanyBasicDTO,
    dateInterview: Date
  ): Observable<Array<ResponseBasicDTO>> {
    return this.surveyBackend
      .GetAnswersStartup(company.id, startupCompanyDTO.id, dateInterview.getMonth() + 1, dateInterview.getFullYear())
      .pipe(map(x => x.items));
  }

  public getInterviewCompletedAnswers(
    startupCompanyDTO: StartupCompanyBasicDTO,
    company: CompanyBasicDTO
  ): Observable<Array<CompletedSurveysResponseDTO>> {
    return this.surveyBackend.GetCompletedAnswersStartup(company.id, startupCompanyDTO.id).pipe(map(x => x.items));
  }

  public getStartupsUser(): Observable<StartupCompanyBasicDTO[]> {
    return this.startupsServicioBackend.GetUserCompanies().pipe(map(x => x.items));
  }

  public getMissingAssessments(startup: StartupCompanyBasicDTO): Observable<StartupMissingAssessmentDTO> {
    return this.startupsServicioBackend.GetMissingAssessments(startup.id);
  }

  public getStartupsByCompany(company: CompanyBasicDTO): Observable<StartupCompanyBasicDTO[]> {
    return this.startupsServicioBackend.GetUserCompaniesByCompany(company.id).pipe(map(x => x.items));
  }

  public getImportFromCrunchbase(crunchbaseRequest: CrunchbaseRequestDTO): Observable<StartupCompanyDTO> {
    return this.startupsServicioBackend.ImportFromCrunchbase(crunchbaseRequest);
  }

  public copyAssessmentToRange(
    startup: StartupCompanyBasicDTO,
    copyDate: Moment,
    startDate: Moment,
    endDate: Moment
  ): Observable<void> {
    return this.startupsServicioBackend.CopyAssessment(startup.id, copyDate, startDate, endDate);
  }

  public getLastAnsweredResponsesBeforeMonth(startup: StartupCompanyBasicDTO, date: Moment) {
    return this.surveyBackend.LastAnsweredByStartup(startup.id, date);
  }

  public discoverStartup(
    size: number,
    page: number,
    searchStartupFilterDTO: SearchStartupFilterDTO
  ): Observable<SearchStartupSizeDTO> {
    return this.startupsServicioBackend.Discover(searchStartupFilterDTO, size, page);
  }

  public startupsSendEmail(startupsSendEmail: StartupSendEmailsDTO): Observable<StartupCountSendEmailsDTO> {
    return this.startupsServicioBackend.SendEmails(startupsSendEmail);
  }

  public startupUpdateType(startupId: number, startupCompanyDTO: StartupChangeTypeDTO): Observable<void> {
    return this.startupsServicioBackend.UpdateType(startupCompanyDTO, startupId);
  }

  public toggleArchive(startup: StartupCompanyBasicDTO): Observable<void> {
    return this.startupsServicioBackend.ArchiveStartup(startup.id);
  }

  public getStartupSummary(startupId: number): Observable<StartupSummaryDTO> {
    return this.startupsServicioBackend.GetStartupSummary(startupId);
  }
}
