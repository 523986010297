import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IListaDTO, ListaDTO } from '@nahuelmorata/framework-frontend';
import { Constants } from '../shared/constants';
import { Moment } from 'moment';

import { IVoteDTO, VoteDTO } from '../DTO/Vote.dto';
import { IChoiceVoteDTO, ChoiceVoteDTO } from '../DTO/ChoiceVote.dto';
import { IStartupMonthScoreDTO, StartupMonthScoreDTO } from '@DTOS';

@Injectable({
  providedIn: 'root'
})
export class VotesServicioBackend {
  constructor(protected http: HttpClient) {}

  public GetVotesByUser(): Observable<ListaDTO<VoteDTO>> {
    return this.http.get<IListaDTO<IVoteDTO>>(`${Constants.apiURL}/votes`).pipe(
      map(
        lista =>
          new ListaDTO({
            items: lista.items.map(item => new VoteDTO(item)),
            cantidad: lista.cantidad
          })
      )
    );
  }

  public CreateVote(voteDTO: VoteDTO): Observable<VoteDTO> {
    return this.http.post<IVoteDTO>(`${Constants.apiURL}/votes`, voteDTO).pipe(map(entidad => new VoteDTO(entidad)));
  }

  public AddChoice(choiceVoteDTO: ChoiceVoteDTO, voteId: number): Observable<void> {
    return this.http.post<void>(`${Constants.apiURL}/votes/${voteId}/choice`, choiceVoteDTO);
  }

  public GetChoicesMadeByUser(): Observable<ListaDTO<ChoiceVoteDTO>> {
    return this.http.get<IListaDTO<IChoiceVoteDTO>>(`${Constants.apiURL}/votes/choices`).pipe(
      map(
        lista =>
          new ListaDTO({
            items: lista.items.map(item => new ChoiceVoteDTO(item)),
            cantidad: lista.cantidad
          })
      )
    );
  }

  public GetVoteById(id: number): Observable<VoteDTO> {
    return this.http.get<IVoteDTO>(`${Constants.apiURL}/votes/${id}`).pipe(map(entidad => new VoteDTO(entidad)));
  }

  public RemoveVote(id: number): Observable<void> {
    return this.http.delete<void>(`${Constants.apiURL}/votes/${id}`);
  }

  public GetStartupsScores(
    id: number,
    monthStart: Moment,
    monthEnd: Moment
  ): Observable<ListaDTO<StartupMonthScoreDTO>> {
    const params = new HttpParams()
      .append('monthStart', monthStart.format('YYYY-MM-DD'))
      .append('monthEnd', monthEnd.format('YYYY-MM-DD'));
    const opciones = { params };
    return this.http.get<IListaDTO<IStartupMonthScoreDTO>>(`${Constants.apiURL}/votes/${id}/score`, opciones).pipe(
      map(
        lista =>
          new ListaDTO({
            items: lista.items.map(item => new StartupMonthScoreDTO(item)),
            cantidad: lista.cantidad
          })
      )
    );
  }
}
