export enum FoundingTypeEnum {
  None,
  PreSeed,
  Seed,
  SeriesA,
  SeriesB,
  SeriesC,
  Angel,
  ConvertibleNote,
  CorporateRound,
  DebtFinancing,
  EquityCrowdfunding,
  Grant,
  InitialCoinOffering,
  NonEquityAssistance,
  PostIpoDebt,
  PostIpoEquity,
  PostIpoSecondary,
  PrivateEquity,
  ProductCrowdfunding,
  SecondaryMarket,
  SeriesD,
  SeriesE,
  SeriesF,
  SeriesG,
  SeriesH,
  SeriesI,
  SeriesJ,
  SeriesUnknown,
  Undisclosed
}
