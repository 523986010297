<div
  class="shadow-box bg-white px-8 rounded-2xl flex flex-col h-full"
  (mouseenter)="onHoverListen() ? onHover.set(true) : null"
  (mouseleave)="onHoverListen() ? onHover.set(false) : null"
  [ngClass]="{
    'bg-white': !onHover(),
    'bg-primary': onHover()
  }">
  <ng-container *ngIf="cardType() === CardTypeEnum.TwoRows">
    <div class="py-4">
      <ng-template *ngTemplateOutlet="firstRowTemplate(); context: { onHover: onHover() }"></ng-template>
    </div>
    <div class="py-4 grow flex flex-col justify-end">
      <ng-template *ngTemplateOutlet="secondRowTemplate(); context: { onHover: onHover() }"></ng-template>
    </div>
  </ng-container>
  <ng-container *ngIf="cardType() === CardTypeEnum.Custom">
    <ng-template *ngTemplateOutlet="customTemplate(); context: { onHover: onHover() }"></ng-template>
  </ng-container>
</div>
