import { Pipe, PipeTransform } from '@angular/core';
import { StartupTypeEnum } from '@DTOS';
import { formatStartupType } from '../../utils/startupType.utils';

@Pipe({
  name: 'startupType'
})
export class StartupTypePipe implements PipeTransform {
  transform(type: StartupTypeEnum) {
    return formatStartupType(type);
  }
}
