import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';
import { StartupTypeEnum } from './StartupType.enum';

export interface IStartupPipelineDTO {
  id: number;
  name: string;
  email: string;
  partialScore: number;
  fullScore: number;
  pipelineStatus: number;
  isArchived: boolean;
  mustSelected: boolean;
  reasonCount: any;
}

export class StartupPipelineDTO implements IDTO<StartupPipelineDTO, IStartupPipelineDTO> {
  public id: number = 0;
  public name: string = '';
  public email: string = '';
  public partialScore: number = 0;
  public fullScore: number = 0;
  public pipelineStatus: StartupTypeEnum = StartupTypeEnum.Lead;
  public isArchived: boolean = true;
  public mustSelected: boolean = true;
  public reasonCount: any = {};

  constructor(json?: Partial<IStartupPipelineDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<IStartupPipelineDTO>) {
    if (json.id != null) {
      this.id = json.id;
    }
    if (json.name != null) {
      this.name = json.name;
    }
    if (json.email != null) {
      this.email = json.email;
    }
    if (json.partialScore != null) {
      this.partialScore = json.partialScore;
    }
    if (json.fullScore != null) {
      this.fullScore = json.fullScore;
    }
    if (json.pipelineStatus != null) {
      this.pipelineStatus = json.pipelineStatus as StartupTypeEnum;
    }
    if (json.isArchived != null) {
      this.isArchived = json.isArchived;
    }
    if (json.mustSelected != null) {
      this.mustSelected = json.mustSelected;
    }
    if (json.reasonCount != null) {
      this.reasonCount = json.reasonCount;
    }
  }

  public aJSON(): IStartupPipelineDTO {
    return {
      id: this.id,
      name: this.name,
      email: this.email,
      partialScore: this.partialScore,
      fullScore: this.fullScore,
      pipelineStatus: this.pipelineStatus,
      isArchived: this.isArchived,
      mustSelected: this.mustSelected,
      reasonCount: this.reasonCount
    };
  }

  public clon<T extends StartupPipelineDTO>(clonBase: T): T;
  public clon<T extends StartupPipelineDTO>(): StartupPipelineDTO;
  public clon<T extends StartupPipelineDTO>(clonBase: Nilable<T> = null): T | StartupPipelineDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.id = this.id;
      clonBase.name = this.name;
      clonBase.email = this.email;
      clonBase.partialScore = this.partialScore;
      clonBase.fullScore = this.fullScore;
      clonBase.pipelineStatus = this.pipelineStatus;
      clonBase.isArchived = this.isArchived;
      clonBase.mustSelected = this.mustSelected;
      clonBase.reasonCount = this.reasonCount;
      return clonBase;
    } else {
      const clon = new StartupPipelineDTO();
      clon.id = this.id;
      clon.name = this.name;
      clon.email = this.email;
      clon.partialScore = this.partialScore;
      clon.fullScore = this.fullScore;
      clon.pipelineStatus = this.pipelineStatus;
      clon.isArchived = this.isArchived;
      clon.mustSelected = this.mustSelected;
      clon.reasonCount = this.reasonCount;
      return clon;
    }
  }
}

export class StartupPipelineModelDTO extends ModelDTO<StartupPipelineDTO> {
  constructor(entidad: StartupPipelineDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Id(): number {
    return this._entidad.id;
  }

  public set Id(valor: number) {
    this.cambiarPropiedadYNotificar('id', valor);
  }

  public get Name(): string {
    return this._entidad.name;
  }

  public set Name(valor: string) {
    this.cambiarPropiedadYNotificar('name', valor);
  }

  public get Email(): string {
    return this._entidad.email;
  }

  public set Email(valor: string) {
    this.cambiarPropiedadYNotificar('email', valor);
  }

  public get PartialScore(): number {
    return this._entidad.partialScore;
  }

  public set PartialScore(valor: number) {
    this.cambiarPropiedadYNotificar('partialScore', valor);
  }

  public get FullScore(): number {
    return this._entidad.fullScore;
  }

  public set FullScore(valor: number) {
    this.cambiarPropiedadYNotificar('fullScore', valor);
  }

  public get PipelineStatus(): StartupTypeEnum {
    return this._entidad.pipelineStatus;
  }

  public set PipelineStatus(valor: StartupTypeEnum) {
    this.cambiarPropiedadYNotificar('pipelineStatus', valor);
  }

  public get IsArchived(): boolean {
    return this._entidad.isArchived;
  }

  public set IsArchived(valor: boolean) {
    this.cambiarPropiedadYNotificar('isArchived', valor);
  }

  public get MustSelected(): boolean {
    return this._entidad.mustSelected;
  }

  public set MustSelected(valor: boolean) {
    this.cambiarPropiedadYNotificar('mustSelected', valor);
  }

  public get ReasonCount(): any {
    return this._entidad.reasonCount;
  }

  public set ReasonCount(valor: any) {
    this.cambiarPropiedadYNotificar('reasonCount', valor);
  }
}
