<h1 mat-dialog-title>Share note</h1>
<div mat-dialog-content>
  <app-empty-screen-info
    textInfo=""
    (clickCallback)="toCreateUser()"
    [showCallback]="showCallbackEmpty"
    *ngIf="users.length === 0">
  </app-empty-screen-info>
  <mat-selection-list [formControl]="usersSelected" *ngIf="users.length !== 0">
    <mat-list-option *ngFor="let user of users" [value]="user">
      {{ user.fullName }}
    </mat-list-option>
  </mat-selection-list>
</div>
<div mat-dialog-actions class="flex justify-end">
  <button mat-flat-button color="primary" (click)="save()">Save</button>
  <button mat-stroked-button color="primary" (click)="close()">Close</button>
</div>
