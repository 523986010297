<form *ngIf="edit" class="flex flex-col">
  <mat-form-field appearance="fill">
    <mat-label>Note</mat-label>
    <textarea matInput placeholder="Write a comment..." [formControl]="textControl"></textarea>
  </mat-form-field>
  <div class="flex justify-between">
    <button
      mat-stroked-button
      color="primary"
      [command]="deleteCmd"
      *ngIf="note != null"
      [disabled]="isDemo"
      [matTooltip]="isDemo ? 'Disabled on demo' : null">
      Delete
    </button>
    <button
      mat-raised-button
      color="primary"
      [command]="saveCmd"
      [disabled]="isDemo"
      [matTooltip]="isDemo ? 'Disabled on demo' : null">
      Save
    </button>
  </div>
</form>
<div class="flex flex-col" *ngIf="!edit">
  <h3 *ngIf="isMy"><span class="text-primary">My</span> note</h3>
  <h3 *ngIf="!isMy">
    <span class="text-primary">Note</span> from {{ note.userCreated.apellidos }} {{ note.userCreated.nombres }}
  </h3>
  <p class="text-neutral-500">{{ note.text }}</p>
</div>
