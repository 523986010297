export enum SearchFilterVCEnum {
  FOUNDERS,
  INDUSTRIES,
  HEADQUARTERS,
  TOTAL_FOUNDING_AMOUNT,
  CURRENT_FOUNDING_TYPE,
  EMPLOYEES,
  INVESTMENT_RULES,
  CUSTOM,
  SCORING
}
