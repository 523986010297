<div class="hidden md:flex grow">
  <div class="w-1/2 flex pr-2">
    <ng-template [ngTemplateOutlet]="firstFieldTemplate"></ng-template>
  </div>
  <div class="w-1/2 flex pl-2" *ngIf="secondFieldTemplate != null">
    <ng-template [ngTemplateOutlet]="secondFieldTemplate"></ng-template>
  </div>
</div>
<div class="flex md:hidden grow flex-col">
  <div class="w-full flex">
    <ng-template [ngTemplateOutlet]="firstFieldTemplate"></ng-template>
  </div>
  <div class="w-full flex" *ngIf="secondFieldTemplate">
    <ng-template [ngTemplateOutlet]="secondFieldTemplate"></ng-template>
  </div>
</div>
