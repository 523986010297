import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';
import { ICompanyRuleDTO, CompanyRuleDTO } from './CompanyRule.dto';
import { OrderEnum } from './Order.enum';
import { StartupColumnOrderEnum } from './StartupColumnOrder.enum';

export interface ISearchStartupFilterDTO {
  name: string;
  rules: ICompanyRuleDTO[];
  order: number;
  columnOrder: number;
  extraOrder: number;
}

export class SearchStartupFilterDTO implements IDTO<SearchStartupFilterDTO, ISearchStartupFilterDTO> {
  public name: string = '';
  public rules: CompanyRuleDTO[] = [];
  public order: OrderEnum = OrderEnum.Asc;
  public columnOrder: StartupColumnOrderEnum = StartupColumnOrderEnum.Name;
  public extraOrder: number = 0;

  constructor(json?: Partial<ISearchStartupFilterDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<ISearchStartupFilterDTO>) {
    if (json.name != null) {
      this.name = json.name;
    }
    if (json.rules != null) {
      this.rules = json.rules.map(item => new CompanyRuleDTO(item));
    }
    if (json.order != null) {
      this.order = json.order as OrderEnum;
    }
    if (json.columnOrder != null) {
      this.columnOrder = json.columnOrder as StartupColumnOrderEnum;
    }
    if (json.extraOrder != null) {
      this.extraOrder = json.extraOrder;
    }
  }

  public aJSON(): ISearchStartupFilterDTO {
    return {
      name: this.name,
      rules: this.rules.map(item => item.aJSON()),
      order: this.order,
      columnOrder: this.columnOrder,
      extraOrder: this.extraOrder
    };
  }

  public clon<T extends SearchStartupFilterDTO>(clonBase: T): T;
  public clon<T extends SearchStartupFilterDTO>(): SearchStartupFilterDTO;
  public clon<T extends SearchStartupFilterDTO>(clonBase: Nilable<T> = null): T | SearchStartupFilterDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.name = this.name;
      clonBase.rules = this.rules.map(item => item.clon());
      clonBase.order = this.order;
      clonBase.columnOrder = this.columnOrder;
      clonBase.extraOrder = this.extraOrder;
      return clonBase;
    } else {
      const clon = new SearchStartupFilterDTO();
      clon.name = this.name;
      clon.rules = this.rules.map(item => item.clon());
      clon.order = this.order;
      clon.columnOrder = this.columnOrder;
      clon.extraOrder = this.extraOrder;
      return clon;
    }
  }
}

export class SearchStartupFilterModelDTO extends ModelDTO<SearchStartupFilterDTO> {
  constructor(entidad: SearchStartupFilterDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Name(): string {
    return this._entidad.name;
  }

  public set Name(valor: string) {
    this.cambiarPropiedadYNotificar('name', valor);
  }

  public get Rules(): CompanyRuleDTO[] {
    return this._entidad.rules;
  }

  public set Rules(valor: CompanyRuleDTO[]) {
    this.cambiarPropiedadYNotificar('rules', valor);
  }

  public get Order(): OrderEnum {
    return this._entidad.order;
  }

  public set Order(valor: OrderEnum) {
    this.cambiarPropiedadYNotificar('order', valor);
  }

  public get ColumnOrder(): StartupColumnOrderEnum {
    return this._entidad.columnOrder;
  }

  public set ColumnOrder(valor: StartupColumnOrderEnum) {
    this.cambiarPropiedadYNotificar('columnOrder', valor);
  }

  public get ExtraOrder(): number {
    return this._entidad.extraOrder;
  }

  public set ExtraOrder(valor: number) {
    this.cambiarPropiedadYNotificar('extraOrder', valor);
  }
}
