import { Component, ContentChild, TemplateRef } from '@angular/core';
import { FirstFieldDirective } from './directives/first-field/first-field.directive';
import { SecondFieldDirective } from './directives/second-field/second-field.directive';

@Component({
  selector: 'app-row-form',
  templateUrl: './row-form.component.html',
  styleUrls: ['./row-form.component.scss']
})
export class RowFormComponent {
  @ContentChild(FirstFieldDirective, { read: TemplateRef }) public firstFieldTemplate: TemplateRef<any>;
  @ContentChild(SecondFieldDirective, { read: TemplateRef }) public secondFieldTemplate?: TemplateRef<any>;
}
