import { Injectable, inject } from '@angular/core';
import { Observable, map, of } from 'rxjs';
import { CompanyBasicDTO, StartupCompanyBasicDTO } from '@DTOS';
import { FileServicioBackend } from '@serviceBackend';
import { ArchivoHostDTO } from '@nahuelmorata/framework-frontend';
import FileSaver from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class FilesService {
  private fileServiceBackend = inject(FileServicioBackend);

  public uploadFile(data: FormData): Observable<ArchivoHostDTO> {
    return this.fileServiceBackend.UploadFile(data);
  }

  public uploadImageCompany(company: CompanyBasicDTO, data: FormData): Observable<CompanyBasicDTO> {
    // return this.filesServicioBackend.insertFilesCompanyidUploadImageProfile(company.id, data);
    return of(new CompanyBasicDTO());
  }

  public uploadImageStartup(startup: StartupCompanyBasicDTO, data: FormData): Observable<StartupCompanyBasicDTO> {
    // return this.filesServicioBackend.insertFilesStartupidUploadImageProfile(startup.id, data);
    return of(new StartupCompanyBasicDTO());
  }

  public downloadStartupFile(fileId: number, filenameSave: string): Observable<void> {
    return this.fileServiceBackend.DownloadFile(fileId).pipe(
      map(data => {
        FileSaver.saveAs(new Blob([data]), filenameSave);
      })
    );
  }
}
