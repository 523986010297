import { Pipe, PipeTransform } from '@angular/core';
import { FoundingTypeEnum } from '@DTOS';
import { formatFoundingType } from '../../utils/FoundingType.utils';

@Pipe({
  name: 'foundingType'
})
export class FoundingTypePipe implements PipeTransform {
  transform(type: FoundingTypeEnum): string {
    return formatFoundingType(type);
  }
}
