import { Pipe, PipeTransform } from '@angular/core';
import { VariableStartupProfileEnum } from '@DTOS';
import { formatStartupProfile } from '../../utils/StartupProfile.utils';

@Pipe({
  name: 'startupProfileRule'
})
export class StartupProfileRulePipe implements PipeTransform {
  transform(startupProfile: VariableStartupProfileEnum): string {
    return formatStartupProfile(startupProfile);
  }
}
