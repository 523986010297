import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';
import { TypeFeedbackEnum } from './TypeFeedback.enum';

export interface IFeedbackDTO {
  type: number;
  subject: string;
  message: string;
}

export class FeedbackDTO implements IDTO<FeedbackDTO, IFeedbackDTO> {
  public type: TypeFeedbackEnum = TypeFeedbackEnum.Recommendation;
  public subject: string = '';
  public message: string = '';

  constructor(json?: Partial<IFeedbackDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<IFeedbackDTO>) {
    if (json.type != null) {
      this.type = json.type as TypeFeedbackEnum;
    }
    if (json.subject != null) {
      this.subject = json.subject;
    }
    if (json.message != null) {
      this.message = json.message;
    }
  }

  public aJSON(): IFeedbackDTO {
    return {
      type: this.type,
      subject: this.subject,
      message: this.message
    };
  }

  public clon<T extends FeedbackDTO>(clonBase: T): T;
  public clon<T extends FeedbackDTO>(): FeedbackDTO;
  public clon<T extends FeedbackDTO>(clonBase: Nilable<T> = null): T | FeedbackDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.type = this.type;
      clonBase.subject = this.subject;
      clonBase.message = this.message;
      return clonBase;
    } else {
      const clon = new FeedbackDTO();
      clon.type = this.type;
      clon.subject = this.subject;
      clon.message = this.message;
      return clon;
    }
  }
}

export class FeedbackModelDTO extends ModelDTO<FeedbackDTO> {
  constructor(entidad: FeedbackDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get Type(): TypeFeedbackEnum {
    return this._entidad.type;
  }

  public set Type(valor: TypeFeedbackEnum) {
    this.cambiarPropiedadYNotificar('type', valor);
  }

  public get Subject(): string {
    return this._entidad.subject;
  }

  public set Subject(valor: string) {
    this.cambiarPropiedadYNotificar('subject', valor);
  }

  public get Message(): string {
    return this._entidad.message;
  }

  public set Message(valor: string) {
    this.cambiarPropiedadYNotificar('message', valor);
  }
}
