import { IDTO, ModelDTO } from '@nahuelmorata/framework-frontend';
import { Nilable } from '@nahuelmorata/ngx-extras';

export interface IPipelineAnalyticsDTO {
  totalStartups: number;
  totalPotencialInvestment: number;
  countPotencialInvestmentStartup: number;
  totalCapitalInvested: number;
  countCapitalInvestedStartup: number;
  totalEvaluatedCompaniesToDate: number;
}

export class PipelineAnalyticsDTO implements IDTO<PipelineAnalyticsDTO, IPipelineAnalyticsDTO> {
  public totalStartups: number = 0;
  public totalPotencialInvestment: number = 0;
  public countPotencialInvestmentStartup: number = 0;
  public totalCapitalInvested: number = 0;
  public countCapitalInvestedStartup: number = 0;
  public totalEvaluatedCompaniesToDate: number = 0;

  constructor(json?: Partial<IPipelineAnalyticsDTO>) {
    if (json != null) {
      this.cargarDTO(json);
    }
  }

  public cargarDTO(json: Partial<IPipelineAnalyticsDTO>) {
    if (json.totalStartups != null) {
      this.totalStartups = json.totalStartups;
    }
    if (json.totalPotencialInvestment != null) {
      this.totalPotencialInvestment = json.totalPotencialInvestment;
    }
    if (json.countPotencialInvestmentStartup != null) {
      this.countPotencialInvestmentStartup = json.countPotencialInvestmentStartup;
    }
    if (json.totalCapitalInvested != null) {
      this.totalCapitalInvested = json.totalCapitalInvested;
    }
    if (json.countCapitalInvestedStartup != null) {
      this.countCapitalInvestedStartup = json.countCapitalInvestedStartup;
    }
    if (json.totalEvaluatedCompaniesToDate != null) {
      this.totalEvaluatedCompaniesToDate = json.totalEvaluatedCompaniesToDate;
    }
  }

  public aJSON(): IPipelineAnalyticsDTO {
    return {
      totalStartups: this.totalStartups,
      totalPotencialInvestment: this.totalPotencialInvestment,
      countPotencialInvestmentStartup: this.countPotencialInvestmentStartup,
      totalCapitalInvested: this.totalCapitalInvested,
      countCapitalInvestedStartup: this.countCapitalInvestedStartup,
      totalEvaluatedCompaniesToDate: this.totalEvaluatedCompaniesToDate
    };
  }

  public clon<T extends PipelineAnalyticsDTO>(clonBase: T): T;
  public clon<T extends PipelineAnalyticsDTO>(): PipelineAnalyticsDTO;
  public clon<T extends PipelineAnalyticsDTO>(clonBase: Nilable<T> = null): T | PipelineAnalyticsDTO {
    if (clonBase !== undefined && clonBase !== null) {
      clonBase.totalStartups = this.totalStartups;
      clonBase.totalPotencialInvestment = this.totalPotencialInvestment;
      clonBase.countPotencialInvestmentStartup = this.countPotencialInvestmentStartup;
      clonBase.totalCapitalInvested = this.totalCapitalInvested;
      clonBase.countCapitalInvestedStartup = this.countCapitalInvestedStartup;
      clonBase.totalEvaluatedCompaniesToDate = this.totalEvaluatedCompaniesToDate;
      return clonBase;
    } else {
      const clon = new PipelineAnalyticsDTO();
      clon.totalStartups = this.totalStartups;
      clon.totalPotencialInvestment = this.totalPotencialInvestment;
      clon.countPotencialInvestmentStartup = this.countPotencialInvestmentStartup;
      clon.totalCapitalInvested = this.totalCapitalInvested;
      clon.countCapitalInvestedStartup = this.countCapitalInvestedStartup;
      clon.totalEvaluatedCompaniesToDate = this.totalEvaluatedCompaniesToDate;
      return clon;
    }
  }
}

export class PipelineAnalyticsModelDTO extends ModelDTO<PipelineAnalyticsDTO> {
  constructor(entidad: PipelineAnalyticsDTO) {
    super(entidad);
  }

  protected limpiar(): void {}

  public get TotalStartups(): number {
    return this._entidad.totalStartups;
  }

  public set TotalStartups(valor: number) {
    this.cambiarPropiedadYNotificar('totalStartups', valor);
  }

  public get TotalPotencialInvestment(): number {
    return this._entidad.totalPotencialInvestment;
  }

  public set TotalPotencialInvestment(valor: number) {
    this.cambiarPropiedadYNotificar('totalPotencialInvestment', valor);
  }

  public get CountPotencialInvestmentStartup(): number {
    return this._entidad.countPotencialInvestmentStartup;
  }

  public set CountPotencialInvestmentStartup(valor: number) {
    this.cambiarPropiedadYNotificar('countPotencialInvestmentStartup', valor);
  }

  public get TotalCapitalInvested(): number {
    return this._entidad.totalCapitalInvested;
  }

  public set TotalCapitalInvested(valor: number) {
    this.cambiarPropiedadYNotificar('totalCapitalInvested', valor);
  }

  public get CountCapitalInvestedStartup(): number {
    return this._entidad.countCapitalInvestedStartup;
  }

  public set CountCapitalInvestedStartup(valor: number) {
    this.cambiarPropiedadYNotificar('countCapitalInvestedStartup', valor);
  }

  public get TotalEvaluatedCompaniesToDate(): number {
    return this._entidad.totalEvaluatedCompaniesToDate;
  }

  public set TotalEvaluatedCompaniesToDate(valor: number) {
    this.cambiarPropiedadYNotificar('totalEvaluatedCompaniesToDate', valor);
  }
}
