import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { QuestionAnswerItemModel } from './question-answer-item.model';
import { ResponseBasicDTO, VariableDTO, VariableTypeEnum } from '@DTOS';
import { transformSimpleUnit } from '../../../../shared/utils/NumberUnits.utils';
import { UsersUtils } from 'src/app/shared/utils/users/Users.utils';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuestionComponent implements OnInit {
  @Input() public questionAnswer: QuestionAnswerItemModel = new QuestionAnswerItemModel(
    new VariableDTO(),
    new ResponseBasicDTO()
  );

  public displayUnits = transformSimpleUnit;
  public variableTypeEnum = VariableTypeEnum;
  public isViewOnly = false;

  constructor(private usersUtils: UsersUtils) {}

  ngOnInit() {
    this.isViewOnly = this.usersUtils.isNinetySupport();
  }
}
