import { Pipe, PipeTransform } from '@angular/core';
import { ScoringRuleEnum } from '@DTOS';

@Pipe({
  name: 'scoringFormat'
})
export class ScoringFormatPipe implements PipeTransform {
  transform(value: ScoringRuleEnum): string {
    switch (value) {
      case ScoringRuleEnum.Full:
        return 'Full';
      case ScoringRuleEnum.Parcial:
        return 'Parcial';
      case ScoringRuleEnum.Rank:
        return 'CB Rank';
    }
  }
}
