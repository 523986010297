import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'timeRemaining'
})
export class TimeRemainingPipe implements PipeTransform {
  transform(date: Date): string {
    const today = moment();
    const expiredDate = moment(date);
    const hoursRemaining = expiredDate.diff(today, 'hours');
    if (hoursRemaining > 24) {
      const daysRemaining = expiredDate.diff(today, 'days');
      return `${daysRemaining} days`;
    } else {
      return `${hoursRemaining} hours`;
    }
  }
}
